import axios from 'axios';

import getAuthSession from './auth';
import { CompanyApplication, CompanyApplicationSubmitData } from '../interfaces/users.interface';

export default async function createCompanyApplication(companyApplication: CompanyApplicationSubmitData) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/company-applications/`,
    { ...companyApplication },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyApplication;
}

export async function updateCompanyApplication(companyApplication: CompanyApplicationSubmitData) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-applications/${companyApplication.id}/`,
    { ...companyApplication },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyApplication;
}

export async function updateCompanyApplicationSimple(id: number | string, companyApplicationKeys:{ [key: string]: any }) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-applications/${id}/`,
    companyApplicationKeys,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyApplication;
}

export async function updateCompanyApplicationShort(companyApplication: any) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-applications/${companyApplication.id}/`,
    { ...companyApplication },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyApplication;
}

export async function deleteCompanyApplication(companyApplication: CompanyApplication) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/company-applications/${companyApplication.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}