import React, { useEffect, useState, useRef, FC } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

import { Paper, Typography, Grid, Box, Button, Autocomplete, TextField, Pagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { createFilterOptions } from '@mui/material/Autocomplete';

export function DataListContain(props: { title: string, buttonTitle?: string, buttonClick?: any, children: any, searchList?: any, searchKey?:string, onChange?: any }) {

  const filterOptions = createFilterOptions({
    stringify: (option:any) => props.searchKey ? option[props.searchKey] : option.label,
  });

  return (
    <>
      <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '100%', maxWidth: 'calc(100vw - 288px)', alignItems: 'center' }}>
        {props.title &&
          <Typography variant='subtitle1' component="div" sx={{
            background: 'white',
            padding: '1em',
            borderRadius: '4px 4px 0 0',
            transform: 'translateY(4px)',
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            position: 'relative',
            '&:after': {
              content: '""',
              background: 'white',
              height: '8px',
              position: 'absolute',
              top: 'calc(100% - 4px)',
              left: '0',
              right: '0',
            },
          }}>{props.title}</Typography>
        }
        {!props.title && <Box></Box>}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          { props.searchList ?
            <Autocomplete
              disablePortal
              clearOnEscape
              freeSolo
              getOptionLabel={(option:any) => typeof option === 'string' ? option : props.searchKey ? option[props.searchKey] : option.label}
              filterOptions={filterOptions}
              id={`search${props.title}`}
              options={props.searchList}
              sx={{ width: 300, backgroundColor: 'white' }}
              onChange={(e, value) => props.onChange ? props.onChange(e, value) : null}
              renderInput={(params) => <TextField {...params} label={`Search ${props.title}`} />}
            />
            : null
          }
          { props.buttonTitle ? <Button sx={{ marginLeft: '2em' }} size='large' variant='contained' color='primary' onClick={props.buttonClick} startIcon={<AddIcon />}>{props.buttonTitle}</Button> : null }
        </Box>
      </Box>
      <Box sx={{
        minWidth: '100%',
        maxWidth: 'calc(100vw - 288px)',
        position: 'relative',
        '&:after': {
          content: '""',
          background: 'white',
          position: 'absolute',
          bottom: '16px',
          height: '1px',
          left: '0',
          right: '0',
        },
      }} component={Paper} p={2}>
        {props.children}
      </Box>
    </>
  );
}


export function DataRow(props: { children: any, p?: number, mb?: number, onClick?:any, hover?:boolean, sx?:any, header?:boolean, columns?:number, columnSpacing?:number }) {
  const hover = props.hover ? {
    '&:hover > .MuiBox-root': {
      top: 0,
      height: 'auto',
      backgroundColor: '#F3F5FB',
    },
  } : {};

  const boxClasses = props.header ? { '& .MuiGrid-root.MuiGrid-item > *': { background: '#F9F9F9', borderRadius: '5px', color: '#747474' } } : {};

  return (
    <Grid
      onClick={(e) => {if (props.onClick) props.onClick(e);}}
      container
      p={props.p ? props.p : 1}
      mb={props.mb ? props.mb : 0}
      columnSpacing={props.columnSpacing ? props.columnSpacing : 2}
      columns={props.columns ? props.columns : 12}
      className={props.header ? 'list-header' : ''}
      sx={{ alignItems: 'center', cursor: props.onClick ? 'pointer' : 'unset', position: 'relative', ...boxClasses, ...props.sx, ...hover }}
    >
      { !props.header &&
        <Box className="hoverBox" sx={{
          position: 'absolute',
          left: '1.5em',
          right: 0,
          bottom: 0,
          height: '1px',
          backgroundColor: 'rgba(0,0,0,.08)',
          display: 'block',
        }} />
      }
      {props.children}
    </Grid>
  );
}

export function DataCell(props: { children: any, xs: number, header?: boolean, p?: number, sx?: any, textAlign?: string }) {
  return (
    <Grid item xs={props.xs}>
      <Box p={props.p ? props.p : 2} sx={{ position: 'relative', zIndex: '2', textAlign: props.textAlign ? props.textAlign : 'left', ...props.sx }}>
        {props.children}
      </Box>
    </Grid>
  );
}

export function StrippedRows(props: { children: any }) {
  return (
    <Box sx={{
      width: '100%',
      '& > .MuiGrid-root:not(.header)::before': {
        position: 'absolute',
        left: '1.5em',
        right: 0,
        top: 0,
        bottom: 0,
        content: '""',
      },
      '& > .MuiGrid-root:not(.header):nth-of-type(even)::before': {
        backgroundColor: 'rgba(150, 150, 150, .5)',
      },
      '& > .MuiGrid-root:not(.header):nth-of-type(odd)::before': {
        backgroundColor: 'rgba(150, 150, 150, .2)',
      },
    }}>
      {props.children}
    </Box>
  );
}

export function DataRowActions(props: { children: any }) {
  return (
    <Box sx={{ position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)' }}>
      {props.children}
    </Box>
  );
}

export function DataPagination(props: { total: number, limit: number, offset: number, handlePageChange: (n: number) => void  }) {
  const { total, limit, offset, handlePageChange } = props;
  const pages = Math.ceil(total / limit);
  const [curPage, setCurPage] = useState(offset ? offset / limit + 1 : 1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePageChange((value - 1) * limit);
    setCurPage(value);
  };

  useEffect(() => {
    setCurPage(offset ? offset / limit + 1 : 1);
  }, [ offset ]);

  return (
    <Pagination sx={{ mt: 2, '& ul': { justifyContent: 'flex-end' } }} count={pages} page={curPage} onChange={handleChange} showFirstButton showLastButton />
  );
}

export interface ItemProps {
  id: any,
  children: any,
  index: number,
  moveItem: (dragIndex: number, hoverIndex: number) => void,
  dropItem: () => void,
}

interface DragItem {
  index: number,
  id: string,
  type: string,
}

export const DraggableItem: FC<ItemProps> = ({ id, children, index, moveItem, dropItem }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
  DragItem,
  void,
  { handlerId: Identifier | null }
  >({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      dropItem();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </Box>
  );
};