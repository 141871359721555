import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { BestPracticeResponse, BestPractice, BestPracticeErrorResponse } from '../../interfaces/bestPractice.interface';
import { FieldValues } from 'react-hook-form';
import { Question } from '../../interfaces/question.interface';

interface InitialBestPracticeState {
  count: number;
  next?: string;
  previous?: string;
  bestPractice?: BestPractice;
  results: BestPractice[];
  isPosting: boolean;
  deletedBestPracticeId?: number;
  error?: BestPracticeErrorResponse;
  targetTopicId?: number;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  bestPractice: undefined,
  results: [],
  isPosting: false,
  deletedBestPracticeId: undefined,
  error: undefined,
  targetTopicId: undefined,
} as InitialBestPracticeState;
const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAll = createAsyncThunk(
  'bestPractices/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/best-practices/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as BestPracticeResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchBestPractice = createAsyncThunk(
  'bestPractices/fetchBestPractice',
  async (bestPracticeSlug: string, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/best-practices/${bestPracticeSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as BestPractice;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createBestPractice = createAsyncThunk(
  'bestPractices/createBestPractice',
  async ({ title, description, targetTopicId, domain, questionnaire }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/best-practices/`, {
          title,
          description,
          questions: [],
          inclusions: [],
          excludedByCertifications: [],
          topic: targetTopicId,
          domain,
          questionnaire,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as BestPractice;
    } catch (error: any) {
      error.response.data.targetTopicId = targetTopicId;
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteBestPractice = createAsyncThunk(
  'bestPractices/deleteBestPractice',
  async (bestPracticeId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/best-practices/${bestPracticeId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return bestPracticeId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateBestPractice = createAsyncThunk(
  'bestPractices/updateBestPractice',
  async ({ id, title, description, questions, slug, inclusions, excludedByCertifications, topic, domain, questionnaire }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/best-practices/${slug}/`, {
          id,
          title,
          description,
          questions: questions.map((question:Question) => typeof question === 'number' ? question : question.id),
          inclusions,
          excludedByCertifications,
          topic,
          domain,
          questionnaire,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as BestPractice;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const bestPracticeSlice = createSlice({
  name: 'bestPractices',
  initialState,
  reducers: {
    resetBestPractice: (state) => {
      state.bestPractice = undefined;
      state.error = undefined;
      state.targetTopicId = undefined;
    },
    resetDeletedBestPracticeId: (state) => {
      state.deletedBestPracticeId = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<BestPracticeResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
    });
    builder.addCase(createBestPractice.fulfilled, (state, action: PayloadAction<BestPractice>) => {
      state.bestPractice = action.payload;
    });
    builder.addCase(createBestPractice.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.targetTopicId = action.payload.targetTopicId;
    });
    builder.addCase(deleteBestPractice.fulfilled, (state, action: PayloadAction<number>) => {
      state.deletedBestPracticeId = action.payload;
      state.results = state.results.filter((bestPractice) => bestPractice.id !== action.payload);
    });
    builder.addCase(updateBestPractice.fulfilled, (state, action: PayloadAction<BestPractice>) => {
      state.bestPractice = action.payload;
      state.error = undefined;
    });
    builder.addCase(updateBestPractice.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchBestPractice.fulfilled, (state, action: PayloadAction<BestPractice>) => {
      state.bestPractice = action.payload;
    });
  },
});

export const { resetBestPractice, resetDeletedBestPracticeId } = bestPracticeSlice.actions;

export default bestPracticeSlice.reducer;
