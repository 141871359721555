import React, { useState } from 'react';
import { Typography, Box, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ReportSelectDropdown from './ReportSelectDropdown';
import { downloadReport } from './downloadUtils';
import { ReportOption } from '../../interfaces/reports.interface';

const DownloadReportHeader = (props: { title?: string }) => {
  const [selectedReportOption, setSelectedReportOption] = useState<ReportOption | undefined>();
  const [selectedReportName, setSelectedReportName] = useState<string | null>(null);
  const [showSelectDropDown, setShowSelectDropDown] = useState<boolean>(false);

  const handleDownload = () => {
    if (selectedReportOption && selectedReportName) {
      downloadReport(selectedReportOption, selectedReportName);
    }
  };

  return (
    <Box position='relative' marginY={2} marginTop={3.5}>
      {props?.title && <Typography textAlign='center' variant='h5'>{props.title}</Typography>}
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showSelectDropDown ? (
          <>
            <ReportSelectDropdown
              onSelect={setSelectedReportOption}
              selectedReportName={selectedReportName}
              setSelectedReportName={setSelectedReportName}
            />
            <Button
              variant='contained'
              size='large'
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              sx={{ mt: 0.5, ml: 0.5 }}
            >
              Download
            </Button>
            <IconButton onClick={() => setShowSelectDropDown(false)}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Button
            onClick={() => setShowSelectDropDown(true)}
            variant='contained'
            startIcon={<AddIcon />}
            size='large'
          >
            TPN Reports
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DownloadReportHeader;