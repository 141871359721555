import React, { useCallback, useEffect, useState } from 'react';
import { Card, Link, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { updateUser } from '../../services/user';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import BooleanFilterComponent from '../Admin/BooleanFilter';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridOverlay,
  GridRowParams,
} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import User from '../../interfaces/users.interface';
import { getUsersForDataGrid, resendUserInvite, resetUser, getUser, deleteUser } from '../../services/user';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import Button from '@mui/material/Button';
import NewUserForm from '../Users/NewUserForm';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import Stack from '@mui/material/Stack';
import UserForm from '../Users/UserForm';

interface UserRow {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  company: string | null;
  type: string;
  createdAt: string | null;
  lastLogin: string | null;
  actions: JSX.Element | string;
  isCognitoUserActive: boolean;
}

interface RowActionsMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: any;
  currentRow: any;
  setRows: (newRows: UserRow[]) => void;
  rows: UserRow[];
}

function createUserRow(user: any) {
  const isAdminUser = user.company?.admins?.includes(user.id);
  let userType: string = '';
  const { id, firstName, isConsultant, lastName, email, company, type, createdAt, lastLogin, isCognitoUserActive } = user;
  if (type === 'vendor_admin') userType = 'Service Provider';
  if (type === 'assessor') userType = 'Assessor';
  if (type === 'content_owner') userType = 'Content Owner';
  if (type === 'tpn_admin') userType = 'TPN Admin';

  if (type !== 'assessor') userType += isAdminUser && type !== 'tpn_admin' ? ' Admin' : ' User';

  if (type !== 'assessor' && type !== 'content_owner') {
    userType += isConsultant ? ' Consultant' : '';
  }

  return {
    id,
    firstName,
    lastName,
    email,
    company: company?.name || '',
    companyId: company?.companyId || null,
    type: userType,
    createdAt,
    lastLogin,
    companyType: company?.type || '',
    actions: '',
    isCognitoUserActive,
    isConsultant,
  };
}

function CustomToolbar(props: any) {
  const [openInviteAdminModal, setOpenInviteAdminModal] = useState(false);
  const handleInviteAdminSuccess = (newUser: User) => {
    setOpenInviteAdminModal(false);
    props.onInviteUserSuccess(newUser);
  };
  return (
    <GridToolbarContainer>
      <StandardDialog title="Invite TPN Admin" handleClose={() => setOpenInviteAdminModal(false)} isOpen={openInviteAdminModal}>
        <NewUserForm successCallback={handleInviteAdminSuccess} modalClose={() => setOpenInviteAdminModal(false)} userType='tpn_admin' />
      </StandardDialog>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{
        fileName: `tpn_users_${new Date().toLocaleDateString()}`,
        fields: ['id', 'firstName', 'lastName', 'email', 'company', 'companyType', 'type', 'createdAt', 'isCognitoUserActive', 'lastLogin'],
      }}/>
      <Button
        variant="text"
        size='small'
        startIcon={<PersonAddAlt1OutlinedIcon />}
        onClick={() => setOpenInviteAdminModal(true)}>
        Invite TPN Admin
      </Button>
    </GridToolbarContainer>
  );
}

function RowActionsMenu({ anchorEl, handleClose, currentRow, setRows, rows }: RowActionsMenuProps) {
  const open = Boolean(anchorEl);
  const [rowUser, setRowUser] = useState<User>();
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);

  const handleResendInvite = () => {
    handleClose();
    if (rowUser) resendUserInvite(rowUser);
  };

  const handleResetUser = () => {
    handleClose();
    if (rowUser) resetUser(rowUser);
  };

  const handleEditUserSubmit = () => {
    setEditUserOpen(false);
    handleClose();
  };

  const handleUserDeleteSubmit = () => {
    if (!rowUser) return;
    deleteUser(rowUser).then(() => {
      setRows(rows.filter((row) => row.id !== rowUser.id));
      setDeleteUserOpen(false);
      handleClose();
    });
  };

  useEffect(() => {
    if (currentRow) {
      getUser(currentRow.id).then((user) => {
        setRowUser(user);
        setIsFetchingUser(false);
      });
    }
  }, [currentRow]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >{rowUser &&
      <div>
        <StandardDialog title={`Delete ${rowUser.email}?`} handleClose={() => setDeleteUserOpen(false)} isOpen={deleteUserOpen}>
            <Typography>Are you sure you want delete {rowUser.email}?</Typography>
            <StandardDialogActions>
                <Button variant="outlined" onClick={() => setDeleteUserOpen(false)}>Cancel</Button>
                <Button variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => handleUserDeleteSubmit()}>Delete</Button>
            </StandardDialogActions>
        </StandardDialog>
        <StandardDialog title={`Edit ${rowUser.email}?`} handleClose={() => setEditUserOpen(false)} isOpen={editUserOpen}>
            <Stack direction="row" spacing={2}>
                <UserForm successCallback={handleEditUserSubmit} userType={rowUser.type} user={rowUser} />
            </Stack>
        </StandardDialog>
      </div>
    }
      <MenuItem onClick={() => setEditUserOpen(true)}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setDeleteUserOpen(true)}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <MenuItem disabled={isFetchingUser} onClick={handleResendInvite}>
        <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
        <ListItemText>Resend Invite</ListItemText>
      </MenuItem>
      <MenuItem disabled={isFetchingUser} onClick={handleResetUser}>
        <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
        <ListItemText>Reset</ListItemText>
      </MenuItem>
    </Menu>
  );
}

export default function UsersAdminPanel() {
  const [rows, setRows] = useState<UserRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentRow, setCurrentRow] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const inviteAdminSuccessHandler = (newUser: User) => {
    const newUserRow = createUserRow(newUser);
    setRows([...rows, newUserRow]);
  };

  const handleMenuClose = (event: any) => {
    if (event) event.preventDefault();
    setMenuAnchorEl(null);
    setCurrentRow(null);
  };

  function handleRowClick(params: GridRowParams, event: any) {
    setCurrentRow(params.row);
    setMenuAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    getUsersForDataGrid().then((response) => {
      const mappedRows = response.map(createUserRow);
      setRows(mappedRows);
      setIsLoading(false);
    });
  }, []);

  const handleUserIsActiveChange = useCallback(async (params: any, isCognitoUserActive: boolean) => {
    const { id, email, firstName, lastName, type, lastLogin, isConsultant } = params.row;
    const newUser = { id, email, firstName, lastName, type, lastLogin, isConsultant, isCognitoUserActive } as any;
    try {
      await updateUser(newUser);
      setRows((prevRows) => {
        const newRows = [...prevRows];
        const rowIndex = newRows.findIndex((row) => row.id === id);
        if (rowIndex > -1) {
          newRows[rowIndex] = { ...newRows[rowIndex], isCognitoUserActive };
        }
        return newRows;
      });
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1  },
    { field: 'firstName', headerName: 'First Name', flex: 2 },
    { field: 'lastName', headerName: 'Last Name', flex: 2 },
    { field: 'email', headerName: 'Email', flex: 3, renderCell: (params) => <Link onClick={(e) => e.stopPropagation()} href={`mailto:${params.row.email}`}>{params.row.email}</Link> },
    { field: 'company', headerName: 'Company', flex: 3, renderCell: (params) => <Link onClick={(e) => e.stopPropagation()} href={`registry/${params.row.companyId}`}>{params.row.company}</Link> },
    { field: 'companyType', headerName: 'Company Type', flex: 2, valueGetter: (params) => {
      if (!params.value) return '';
      if (params.value === 'vendor') return 'Service Provider';
      if (params.value === 'assessor') return 'Assessor';
      if (params.value === 'content_owner') return 'Content Owner';
      return params.value as string;
    } },
    {
      field: 'type',
      headerName: 'Type',
      flex: 3,
    },
    {
      field: 'isCognitoUserActive',
      headerName: 'Is Active',
      flex: 3,
      renderCell: (params) => (
        <Select
          label="Is Active"
          value={params.value ? 'Yes' : 'No'}
          onChange={(event) => {
            // Handle change to convert 'Yes'/'No' back to true/false and update accordingly
            const isActive = event.target.value === 'Yes';
            handleUserIsActiveChange(params as any, isActive);
          }}
          size="small"
          sx={{ 
            width: '100%',
            border: 'none', // Remove border
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Ensure no border is visible
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove border when the select is focused
              boxShadow: 'none', // Optionally remove shadow if any
            },
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>),
      filterOperators: [
        {
          label: 'Is',
          value: 'is',
          getApplyFilterFn: (filterItem) => {
            return ({ value }) =>  value === filterItem.value;
          },
          InputComponent: BooleanFilterComponent,
        },
      ],
    },
    { field: 'createdAt', headerName: 'Created At', flex: 2, valueFormatter: (params) => {
      if (!params.value) return '';
      return new Date(params.value as string).toLocaleDateString();
    } },
    { field: 'lastLogin', headerName: 'Last Login', flex: 2, valueFormatter: (params) => {
      if (!params.value) return '';
      return new Date(params.value as string).toLocaleDateString();
    } },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: () => (
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
        componentsProps={{
          toolbar: { onInviteUserSuccess: inviteAdminSuccessHandler },
        }}
        onRowClick={handleRowClick}
        disableSelectionOnClick
        pageSize={20}
      />
      <RowActionsMenu
        anchorEl={menuAnchorEl}
        handleClose={(e: any) => handleMenuClose(e)}
        currentRow={currentRow}
        setRows={setRows}
        rows={rows}
      />
    </Card>
  );
}
