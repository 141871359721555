import React, { useEffect, useState } from 'react';
import StandardDialog from '../../../Modals/StandardDialog';
import { Button, Grid, Theme, Typography } from '@mui/material';
import NewCustomApp from './NewCustomApp';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Company } from '../../../../interfaces/users.interface';
import SearchLicensedApps from './SearchLicensedApps';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchAllCompaniesStore } from '../../../../app/store/companyList';

type NewApplicationProps = {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  callBack: any;
};

const useStyles = makeStyles((theme:Theme) => ({
  gridCol: {
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    '&:first-of-type': {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
}));

export default function NewApplication(props:NewApplicationProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { companies, fetchingCompanies } = useAppSelector(state => state.companyList);
  const [isNewCustomAppOpen, setNewCustomAppOpen] = useState(false);
  const [isNewLicensedAppOpen, setNewLicensedAppOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    if (props.isOpen === isOpen) return;
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen === isOpen) return;
    if (!isOpen) props.handleClose();
  }, [isOpen]);

  useEffect(() => {
    if (companies.length === 0 && !fetchingCompanies) dispatch(fetchAllCompaniesStore);
  }, []);

  return (<>
    <StandardDialog title="Add Application" isOpen={isOpen} handleClose={props.handleClose}>
    <Typography sx={{ textAlign: 'center', mb: 4 }}>Would you like to create a new in-house application developed by you or add a licensed application?</Typography>
      <Grid container>
        <Grid className={classes.gridCol} item xs={6}>
          <Typography>{'An "in-house developed application" is developed and owned by your business. If you would like to add a version to your existing in-house developed application please close this box and choose the edit icon for the desired application in your profile.'}</Typography>
          <Button variant='contained' startIcon={<Add/>} onClick={() => {setIsOpen(false); setNewCustomAppOpen(true);}}>In-House Developed Application</Button></Grid>
        <Grid className={classes.gridCol} item xs={6}>
          <Typography>A 3rd Party Licensed Application application is developed by a 3rd party and licensed by your company for use. Prior to adding a new licensed application, please first check the TPN+ directory to select it if available. If it is not available, please add it to the TPN+ directory.</Typography>
          <Button variant='contained' startIcon={<Add/>} onClick={() => { setIsOpen(false); setNewLicensedAppOpen(true);}}>3rd Party Licensed Application</Button>
        </Grid>
      </Grid>
      {/* <StandardDialogActions>
        <Button variant='outlined' onClick={props.handleClose}>Cancel</Button>
      </StandardDialogActions> */}
    </StandardDialog>
    <NewCustomApp callBack={props.callBack} company={props.company} isOpen={isNewCustomAppOpen} handleClose={() => setNewCustomAppOpen(false)} back={() => { setIsOpen(true); setNewCustomAppOpen(false); }} />
    <SearchLicensedApps callBack={props.callBack} isOpen={isNewLicensedAppOpen} company={props.company} handleClose={() => setNewLicensedAppOpen(false)} back={() => { setIsOpen(true); setNewLicensedAppOpen(false); }} />
  </>);
}