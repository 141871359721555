import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchDomain, updateDomain, resetDomain } from '../../app/store/domainSlice';
import { Topic } from '../../interfaces/topic.interface';
import { DomainEditForm } from './DomainForm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import TopicListItem from '../Topics/TopicListItem';
import { DataListContain, DataRow, DataCell } from '../Tables/DataList';
import HeaderDetailCard from '../Cards/HeaderDetailCard';
import StandardDialog from '../Modals/StandardDialog';
import TopicForm from '../Topics/TopicForm';

import { resetTopic } from '../../app/store/topicSlice';
import { resetBestPractice } from '../../app/store/bestPracticeSlice';

import { buildQuestionnaireBreadCrumbs } from '../../services/breadCrumbs';

import QuestionnairePreview from '../Preview/QuestionnairePreview';

export default function Domain() {
  const params = useParams();
  const domainSlug:string = params.domainSlug!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetDomain());
    dispatch(fetchDomain(domainSlug));
    dispatch(resetTopic());
    dispatch(resetBestPractice());
  }, []);

  const { domain } = useAppSelector(state => state.domains);
  const [curView, setCurView] = useState('edit');
  const { user } = useAppSelector(state => state.user);
  const [newTopicOpen, setNewTopicOpen] = useState(false);
  const handleNewTopicSetOpen = () => setNewTopicOpen(true);
  const handleNewTopicClose = () => setNewTopicOpen(false);

  const [editDomainOpen, setEditDomainOpen] = useState(false);
  const handleEditDomainOpen = () => setEditDomainOpen(true);
  const handleEditDomainClose = () => setEditDomainOpen(false);

  const [topics, setTopics] = useState(domain?.topics as Topic[]);
  const [topicFilter, setTopicFilter] = useState(null);

  useEffect(() => {
    let newTopicList = domain?.topics as Topic[];
    if (topicFilter) {
      if (typeof(topicFilter) === 'number') {
        newTopicList = newTopicList.filter(topic => topic.id === topicFilter);
      } else {
        newTopicList = newTopicList.filter(topic => topic.title.toLowerCase().includes(topicFilter));
      }
    }
    setTopics(newTopicList);
  }, [domain?.topics, topicFilter]);

  const topicSearchClickCallback = useCallback((e: Event, value: any) => {
    e.preventDefault();
    if (value) { setTopicFilter(typeof(value) !== 'string' ? value.topic.id : value); } else { setTopicFilter(null); }
  }, []);

  const moveTopic = useCallback((dragIndex: number, hoverIndex: number) => {
    setTopics((prevTopics: Topic[]) =>
      update(prevTopics, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevTopics[dragIndex] as Topic],
        ],
      }),
    );
  }, []);

  const dropTopic = () => {
    const topicsIdsArr = topics.map((topic) => topic.id);
    dispatch(updateDomain({ ...domain, topics: topicsIdsArr }));
  };

  const buildTopicSearchList = () => {
    if (!domain) return false;
    return domain.topics.map(topic => {return { label: topic.title, topic: topic };});
  };

  const [topicSearchList, setTopicSearchList] = useState(buildTopicSearchList);
  const [breadCrumbs, setBreadCrumbs] = useState<any[] | undefined>(undefined);
  
  const buildBreadCrumbs = () => {
    if (!domain) return;
    if (typeof domain.questionnaire === 'number') return;

    const crumbItems = [{ ...domain.questionnaire, label: 'Questionnaire' }];
    const breadCrumbsList = buildQuestionnaireBreadCrumbs(crumbItems, '/questionnaires/');
    setBreadCrumbs(breadCrumbsList);
  };

  useEffect(() => {
    if (!domain) return;
    if (domain.topics) setTopicSearchList(buildTopicSearchList);

    buildBreadCrumbs();
  }, [domain]);

  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      { curView === 'edit' && domain ? 
        <>
          <HeaderDetailCard canEdit={canEdit} overTitle='Domain' title={domain.code + '. ' + domain.title} detail={domain.description} editClick={handleEditDomainOpen} breadCrumbs={breadCrumbs} previewClick={() => setCurView('preview')} />
          <StandardDialog title={`Edit Domain: ${domain.title}`} handleClose={handleEditDomainClose} isOpen={editDomainOpen}>
            <DomainEditForm domain={domain} handleClose={handleEditDomainClose} />
          </StandardDialog>
          <StandardDialog title="New Topic" handleClose={handleNewTopicClose} isOpen={newTopicOpen}>
            <TopicForm domain={domain} handleClose={handleNewTopicClose} />
          </StandardDialog>
          <DataListContain title="Topics" buttonTitle={canEdit ? 'Topics' : '' } buttonClick={handleNewTopicSetOpen} searchList={topicSearchList} onChange={topicSearchClickCallback}>
            <DataRow header>
              <DataCell xs={6}>Title</DataCell>
              <DataCell xs={4}>Description</DataCell>
              <DataCell xs={2}>Order</DataCell>
            </DataRow>
            { topicFilter ?
              <>
                {topics && topics.map((topic: Topic) => (
                  <TopicListItem canEdit={canEdit} key={topic.id} topic={topic} domain={domain} idx={domain?.topics.indexOf(topic)} />
                ))}
              </>
              :
              <DndProvider backend={HTML5Backend}>
                {topics && topics.map((topic: Topic) => (
                  <TopicListItem canEdit={canEdit} key={topic.id} topic={topic} domain={domain} idx={domain?.topics.indexOf(topic)} moveItem={moveTopic} dropItem={dropTopic} />
                ))}
              </DndProvider>
            }
          </DataListContain>
        </>
        :
        <></>
      }
      { curView === 'preview' && domain &&
        <QuestionnairePreview
          buttonClick={() => setCurView('edit')}
          buttonText={domain.title}
          domain={domain}
        />
      }
    </>
  );
}