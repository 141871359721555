import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createAnswer, resetAnswer } from '../../app/store/answerSlice';
import axios from 'axios';
import getAuthSession from '../../services/auth';
import { useForm, FieldValues } from 'react-hook-form';
import { Answer } from '../../interfaces/answer.interface';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function NewAnswerForm(props: { handleClose:any, updateQuestion:any, answers:Answer[], questionId: number }) {
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const { answer: answerResult, error: answerError } = useAppSelector(state => state.answers);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();
  const [isPosting, setIsPosting] = useState(false);
  const [answers, setAnswers] = useState(props.answers);

  useEffect(() => {
    setAnswers(props.answers);
  }, [props.answers]);

  const addDefaultAnswers = async () => {
    if (isPosting) return;

    setIsPosting(true);
    const authSession = await getAuthSession();
    const answersArray = [
      { text: 'Fully Implemented' },
      { text: 'Partially Implemented' },
      { text: 'Not Implemented' },
      { text: 'Not Applicable' },
    ];

    const promises:Array<any> = [];
    answersArray.forEach(answer => {
      promises.push(axios.post(
        `${baseUrl}/answers/`, {
          text: answer.text,
          boolean: false,
          completedRemediation: false,
          requiresRemediation:	false,
          question: props.questionId,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      ));
    });
    
    axios
      .all(promises)
      .then(res => {
        const nanswers = res.map(answer => answer.data);
        let newAnswerArray:Answer[] = [];
        answersArray.forEach(defaultAnswer => {
          newAnswerArray.push(nanswers.find(answer => answer.text === defaultAnswer.text));
        });
        props.updateQuestion({ answers: newAnswerArray });
        setIsPosting(false);
        props.handleClose();
        reset();
      });
  };

  const onSubmit = (data: FieldValues) => {
    const postData = { ...data, question: props.questionId };
    dispatch(createAnswer(postData));
  };

  useEffect(() => {
    if (answerError) console.log(answerError);
  }, [answerError]);

  useEffect(() => {
    if (answerResult) {
      const newAnswerList = answers ? [ ...answers, answerResult ] : [ answerResult ];
      props.updateQuestion({ answers: newAnswerList });
      props.handleClose();
      reset();
      dispatch(resetAnswer());
    }
  }, [answerResult]);

  return (
    <Box sx={{ width: '100%', display: 'flex' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        required
        id='text'
        label='Text'
        sx={{ flexGrow: 2 }}
        fullWidth
        {...register('text')}
      />
      <FormControlLabel sx={{ ml: 2 }} control={<Checkbox onChange={(e) => {setValue('isCompliant', e.target.checked);}} />} label="Compliant" />
      <Button sx={{ marginLeft: '2em', minWidth: 'max-content', whiteSpace: 'nowrap' }} size='large' variant='contained' color='primary' type="submit" startIcon={<AddIcon />}>Add Answer</Button>
      { (!answers || answers.length === 0) && <Button sx={{ marginLeft: '1em', minWidth: 'max-content', whiteSpace: 'nowrap' }} size='large' variant='contained' color='primary' onClick={() => { addDefaultAnswers(); }}>Add Default Answers</Button> }
    </Box>
  );
}