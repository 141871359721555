import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import User from '../../../interfaces/users.interface';
import { Question } from '../../../interfaces/question.interface';
import FileUploaderComp, { FileUpload } from '../../Forms/FileUpload';
import { REMEDIATION_OPTIONS } from '../../../services/surveyHelpers';
import moment from 'moment';

type RemediationFormProps = {
  remediationDateErr: string;
  user: User | undefined;
  question: Question;
  remediationComment: string;
  remediationDate: string | null;
  vendorStatus: string;
  vfiles: FileUpload[];
  errorMessage: string;
  origRemediationDate?: string;
  setRemediationDateErr: (err: string) => void;
  onRemediationCommentChange: (newRemediationComment: string) => void;
  onRemediationDateChange: (newRemediationDate: string | null) => void;
  onVendorStatusChange: (newVendorStatus: string) => void;
  onVFilesChange: (newVfiles: FileUpload[]) => void;
};

const RemediationForm = (props: RemediationFormProps) => {
  const {
    user,
    question,
    remediationComment,
    remediationDate,
    vendorStatus,
    vfiles,
    errorMessage,
    origRemediationDate,
    remediationDateErr,
    onRemediationCommentChange,
    onRemediationDateChange,
    onVendorStatusChange,
    onVFilesChange,
    setRemediationDateErr,
  } = props;

  const handleRemediationCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRemediationCommentChange(e.target.value);
  };

  const handleRemediationDateChange = (newRemediationDate: string | null) => {
    if (vendorStatus === REMEDIATION_OPTIONS.remediated && newRemediationDate && moment(newRemediationDate).isAfter(moment(), 'day')) {
      setRemediationDateErr('Remediated status requires a current or past date');
    } else {
      setRemediationDateErr('');
    }
    onRemediationDateChange(newRemediationDate);
  };

  const handleVendorStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemediationDateErr('');
    if (e.target.value === REMEDIATION_OPTIONS.willNotRemediate) {
      onRemediationDateChange(!origRemediationDate ? new Date().toISOString() : new Date(origRemediationDate).toISOString());
    }
    onVendorStatusChange(e.target.value);
  };

  return (
    <>
      <RadioGroup
        aria-labelledby={question.title}
        name='assessor-answer'
        value={vendorStatus}
        onChange={handleVendorStatusChange}
      >
        <FormControlLabel
          disabled={user && user.type !== 'vendor_admin'}
          value={REMEDIATION_OPTIONS.willNotRemediate}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.willNotRemediate ? 'bold' : 'inherit' }}>
              {REMEDIATION_OPTIONS.willNotRemediate}
            </Typography>
          }
        />
        <FormControlLabel
          disabled={user && user.type !== 'vendor_admin'}
          value={REMEDIATION_OPTIONS.willRemediateLater}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.willRemediateLater ? 'bold' : 'inherit' }}>
              {REMEDIATION_OPTIONS.willRemediateLater}
            </Typography>
          }
        />
        <FormControlLabel
          disabled={user && user.type !== 'vendor_admin'}
          value={REMEDIATION_OPTIONS.remediated}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.remediated ? 'bold' : 'inherit' }}>{REMEDIATION_OPTIONS.remediated}</Typography>
          }
        />
      </RadioGroup>
        <Box sx={{ mt: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label='Remediation Date'
              inputFormat='MM/DD/yyyy'
              value={remediationDate}
              onChange={handleRemediationDateChange}
              disabled={vendorStatus === REMEDIATION_OPTIONS.willNotRemediate || (user && user.type !== 'vendor_admin') || !vendorStatus}
              renderInput={(params) => (
                <TextField
                {...params}
                helperText={remediationDateErr}
                error={Boolean(remediationDateErr)}
              />
              )}
            />
          </LocalizationProvider>
        </Box>
      <TextField
        id={`${question.id}-remediation-comment`}
        multiline
        rows={5}
        label={'Remediation Comment'}
        fullWidth
        required
        onChange={handleRemediationCommentChange}
        value={remediationComment}
        sx={{ mt: 2 }}
        disabled={user && user.type !== 'vendor_admin'}
        inputProps={{
          sx: { overflowY: 'scroll' },
        }}
      />
      {vendorStatus === REMEDIATION_OPTIONS.remediated && (
        <>
          {question.attachmentFormLabel && (
            <Typography mb={1} mt={1} sx={{ whiteSpace: 'pre-wrap' }}>
              {question.attachmentFormLabel}
            </Typography>
          )}
          <Box mt={1}>
            <FileUploaderComp
              showCOPublicModal={true}
              boxHeight={100}
              fileList={vfiles}
              callback={onVFilesChange}
              enablePublic
              notAssessorVisible={true}
              dontShowUpload={!user || user.type !== 'vendor_admin'}
            />
          </Box>
        </>
      )}
      <Box sx={{ mt: 2, height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {errorMessage.length > 0 && (
          <Typography color='error'>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default RemediationForm;
