export const isImageFile = (file: string) => /\.(jpg|jpeg|png|gif)$/i.test(file.split('?')[0]);
export const isPDFFile = (file: string) => /\.pdf$/i.test(file.split('?')[0]);
export const isDocxFile = (file: string) => /\.docx$/i.test(file.split('?')[0]);
export const isExcelFile = (file: string) => /\.xlsx$/i.test(file.split('?')[0]);

export const convertExcelDate = (serial: number) => {
  const excelEpoch = new Date(Date.UTC(1900, 0, 1)); // Excel epoch starts at Jan 1, 1900
  const daysOffset = serial - 1; // Excel dates are offset by 1 day
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return new Date(excelEpoch.getTime() + daysOffset * millisecondsPerDay);
};

export const isAcceptedFileType = (fileName: string) => {
  return isImageFile(fileName) || isPDFFile(fileName) || isDocxFile(fileName) || isExcelFile(fileName);
};