import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { DataRow, DataCell, DataRowActions, DraggableItem } from '../Tables/DataList';
import { Link } from 'react-router-dom';
import { Topic } from '../../interfaces/topic.interface';
import { Domain } from '../../interfaces/domain.interface';
import { updateDomain } from '../../app/store/domainSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TopicEditForm } from './TopicForm';
import { deleteTopic } from '../../app/store/topicSlice';

export default function TopicListItem(props: { canEdit?: boolean, topic: Topic, domain: Domain, idx: number, moveItem?: any, dropItem?: any }) {
  const [deleteTopicOpen, setDeleteTopicOpen] = useState(false);
  const handleDeleteTopicOpen = () => setDeleteTopicOpen(true);
  const handleDeleteTopicClose = () => setDeleteTopicOpen(false);

  const [topic, setTopic] = useState(props.topic);
  const [editTopicOpen, setEditTopicOpen] = useState(false);
  const handleEditTopicOpen = () => setEditTopicOpen(true);
  const handleEditTopicClose = (newTopic:Topic) =>{
    if (newTopic) setTopic(newTopic);
    setEditTopicOpen(false);
  };

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm();

  const dispatch = useAppDispatch();
  const onSubmit = () => {
    const { topics } = props.domain;
    const topicsIdsArr = topics.filter(topicItem => topicItem.id !== topic.id).map((topicItem) => topicItem.id);
    dispatch(updateDomain({ ...props.domain, topics: topicsIdsArr, questionnaire: typeof props.domain.questionnaire === 'number' ? props.domain.questionnaire : props.domain.questionnaire.id }));
    dispatch(deleteTopic(topic.id));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleDeleteTopicClose();
    }
  }, []);

  const renderRow = () => {
    return (
      <>
        <Link to={topic.slug} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 87%)' }}>
          <DataRow hover>
            <DataCell xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              { props.moveItem && props.canEdit ? <DragIndicatorIcon sx={{ cursor: 'grab', mr: '.25em' }} /> : null }
              <Box>{topic.title}</Box>
            </DataCell>
            <DataCell xs={4}><Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={topic.description}>{topic.description}</Box></DataCell>
            <DataCell xs={2}>
              {props.idx + 1}
              { props.canEdit && (
              <DataRowActions>
                <IconButton size="small" name="Edit Topic" onClick={(e)=> {e.preventDefault(); handleEditTopicOpen(); }}><EditIcon /></IconButton>
                <IconButton size="small" name="Delete Topic" onClick={(e)=> {e.preventDefault(); handleDeleteTopicOpen(); }}><DeleteIcon /></IconButton>
              </DataRowActions>)}
            </DataCell>
          </DataRow>
        </Link>
        <StandardDialog title={`Remove ${topic.title} from domain ${props.domain.title}`} handleClose={handleDeleteTopicClose} isOpen={deleteTopicOpen}>
          <Typography>Are you sure you want to remove {topic.title} from domain {props.domain.title}?</Typography>
          <StandardDialogActions component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Button variant="outlined" onClick={() => handleDeleteTopicClose()}>Cancel</Button>
            <Button type="submit" variant="contained" color="error">Remove</Button>
          </StandardDialogActions>
        </StandardDialog>
        <StandardDialog title={`Edit Topic: ${topic.title}`} handleClose={handleEditTopicClose} isOpen={editTopicOpen}>
          <TopicEditForm topic={topic} handleClose={handleEditTopicClose} />
        </StandardDialog>
      </>
    );
  };

  return (
    <>
      { props.moveItem && props.canEdit ?
        <DraggableItem
            key={props.idx}
            index={props.idx}
            id={props.topic.id}
            moveItem={props.moveItem}
            dropItem={props.dropItem}
          >
          {renderRow()}
        </DraggableItem>
        :
        <>{renderRow()}</>
      }
    </>
  );
}