import React, { useState, useEffect } from 'react';
import { Question } from '../../interfaces/question.interface';
import { CertificationControl } from '../../interfaces/certificationControl.interface';
import { DataRow, DataCell } from '../Tables/DataList';

import CertMappingListItem from './CertMappingListItem';
import { Answer } from '../../interfaces/answer.interface';

export default function CertMapping(props: {
  canEdit?: boolean,
  certList:CertificationControl[],
  questionUpdate: any,
  question: Question,
  answers?: Answer[],
}) {
  const [certs, setCerts] = useState(props.certList);
  const [answers, setAnswers] = useState(props.answers);
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    return (()=> {
      setAnswers(undefined);
    });
  }, []);

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  useEffect(() => {
    setAnswers(props.answers);
  }, [props.answers]);

  useEffect(() => {
    setCerts(props.certList);
  }, [props.certList]);

  const removeCertControl = (certId:number) => {
    const certIndex = certs.findIndex(cert => cert.id === certId);
    let newcertList = [ ...certs ];
    newcertList.splice(certIndex, 1);
    props.questionUpdate({ excludedByCertifications: newcertList });
  };

  return (
    <>
      <DataRow p={1} mb={.01} header>
        <DataCell xs={3} p={1}>Document</DataCell>
        <DataCell xs={2} p={1}>Control</DataCell>
        <DataCell xs={2} p={1}>Version</DataCell>
        <DataCell xs={2} p={1}>Date</DataCell>
        <DataCell xs={3} p={1}>Answers</DataCell>
      </DataRow>
      { certs.map((certificationControl: CertificationControl) => (
        <CertMappingListItem
          canEdit={props.canEdit}
          key={certificationControl.id}
          certListItem={certificationControl}
          questionUpdate={props.questionUpdate}
          question={question}
          removeCertControl={removeCertControl}
          answers={answers}
        />
      ))}
    </>
  );
}