import React, { useEffect, useState } from 'react';
import { convertExcelDate } from '../../../utils/fileUtils';
import { Box } from '@mui/material';
import * as XLSX from 'xlsx';

interface ExcelViewerProps {
  previewFile: string;
}

const ExcelViewer: React.FC<ExcelViewerProps> = ({ previewFile }) => {
  const [excelContent, setExcelContent] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    const fetchExcelContent = async () => {
      try {
        const response = await fetch(previewFile);
        if (!response.ok) {
          throw new Error(`Failed to fetch Excel file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData: (string | number | null)[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const tableElement = (
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
              {jsonData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    // Format cell if it's a date
                    let cellValue = cell;
                    if (typeof cell === 'number' && cell > 59 && cell < 2958465) {
                      // Excel serial date numbers start from 60, dates below this are invalid
                      cellValue = convertExcelDate(cell).toLocaleDateString();
                    }
                    return (
                      <td
                        key={cellIndex}
                        style={{
                          border: '1px solid #ddd',
                          padding: '8px',
                          textAlign: 'left',
                          backgroundColor: rowIndex === 0 ? '#f2f2f2' : 'transparent',
                        }}
                      >
                        {cellValue !== null && cellValue !== undefined ? cellValue.toString() : ''}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        );

        setExcelContent(tableElement);
      } catch (error) {
        console.error('Error converting Excel:', error);
      }
    };
    fetchExcelContent();
  }, [previewFile]);

  const excelStyle = {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '16px',
    margin: '16px auto',
    width: '100%',
    maxWidth: '1200px',
    overflowX: 'auto',
  };

  return (
    <Box sx={{ ...excelStyle, position: 'relative', zIndex: 1, height: '100%', overflowY: 'auto' }}>
      {excelContent}
    </Box>
  );
};

export default ExcelViewer;