import axios from 'axios';
import getAuthSession from './auth';
import { AssessmentSurveyAssessment } from '../interfaces/assessmentSurveyAssessments.interface';

export default async function getAssessmentSurveyAssessments(args?: { limit?: number, statuses?: string[] }) {
  const authSession = await getAuthSession();
  const { limit = 9999, statuses = ['Submitted'] } = args || {};
  const statusQuery = statuses.map(status => `status=${status}`).join('&');
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/assessment-survey-assessments/?${statusQuery}&limit=${limit}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as AssessmentSurveyAssessment[];
}