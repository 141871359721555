import React from 'react';
import User, { Company, Contact } from '../../../interfaces/users.interface';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { updateCompany } from '../../../services/company';
import { CompanySubmissionData } from '../../../interfaces/users.interface';


export default function CompanyPrimaryContactButton(props: { company: Company, companyCallback: any }) {
  const {
    company,
    companyCallback,
  } = props;
  const handleCompanyPrimaryContact = (event: SelectChangeEvent) => {
    const updatedCompanyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: event.target.value,
      employees: company.employees.map((employee) => employee.id),
      pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
      services: company.services.map((service) => service.id),
      locations: [...company.locations.map((contactLocation: Contact) => contactLocation.id)],
      admins: company.admins.map(item => item.id),
      subsidiaries: company.subsidiaries.map(item => item.id),
    };
    updateCompany(updatedCompanyData).then((responseCompany: Company) => {
      companyCallback(responseCompany);
    });
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="company-primary-contact">Primary Contact</InputLabel>
        <Select
          labelId="company-primary-contact"
          id="company-primary-contact-select"
          label="Primary Contact"
          onChange={handleCompanyPrimaryContact}
          defaultValue={company.primaryContactUser?.id?.toString()}
        >
          {company.employees.filter(e => e.firstName?.length > 0 || e.lastName?.length > 0).map((employee: User) => <MenuItem key={employee.id} value={employee.id} selected={true}>{employee.firstName} {employee.lastName}: {employee.email}</MenuItem>)}
        </Select>
      </FormControl>
      <Typography variant='caption' sx={{ mt: 1, display: 'block' }}>Primary contact user must have entered their name into their user info page</Typography>
    </>
  );
}