import { useEffect, useState } from 'react';
 
const useGradualProgress = (
  start: number,
  maxBeforeComplete: number, // Max value before slowing down
  nearCompletionMax: number, // Max value before API completion
  end: number,
  duration: number,
  apiCallCompleted: boolean,
  setHideLoader: (hide: boolean) => void,
  initialStep: number = 5, // Initial increment step
  slowIncrementStep: number = 1, // Slower increment step after reaching maxBeforeComplete
  nearCompletionStepFactor: number = 1.2, // Factor to multiply nearCompletionStep by
): number => {
  const [progress, setProgress] = useState<number>(start);
   
  useEffect(() => {
    if (apiCallCompleted) {
      setProgress(99);
      // Generates a random number between min and max, inclusive
      const getRandomDelay = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
      setTimeout(() => {
        setProgress(100);
      }, getRandomDelay(100, 750));
 
      setTimeout(() => {
        setHideLoader(true);
      }, getRandomDelay(750, 1500));

      return;
    }
   
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        let nearCompletionStep = slowIncrementStep;
        // Calculate nearCompletionStep based on the current progress
        if (prevProgress >= maxBeforeComplete && prevProgress < nearCompletionMax) {
          const distanceToEnd = end - prevProgress;
          // Decrease nearCompletionStep gradually as we approach the end
          nearCompletionStep = Math.max(1, Math.floor(distanceToEnd / nearCompletionStepFactor));
        } else if (prevProgress < maxBeforeComplete) {
          const increment = Math.floor(Math.random() * initialStep) + 1; // Random increment within initialStep
          return Math.min(prevProgress + increment, maxBeforeComplete);
        } else if (prevProgress >= 90 && prevProgress < end) {
          // Once progress is above 90 and below end, increment by 1
          return prevProgress + 1;
        }
        // Once we are at or above nearCompletionMax, we only increase if not yet at end
        return prevProgress < end ? Math.min(prevProgress + nearCompletionStep, end) : prevProgress;
      });
    }, duration);
   
    return () => clearInterval(interval);
  }, [
    maxBeforeComplete,
    nearCompletionMax,
    end,
    duration,
    apiCallCompleted,
    initialStep,
    slowIncrementStep,
    nearCompletionStepFactor,
    setHideLoader,
  ]);
 
  return progress;
};
 
export default useGradualProgress;