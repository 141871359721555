import React from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Description,
  Forum,
  Visibility,
  Warning,
  WorkspacePremium,
} from '@mui/icons-material';
import { PaletteKey } from '../../../app/store/questionnaireAnswerSlice';
import { Question } from '../../../interfaces/question.interface';
import { Comment } from '../../../interfaces/assessment.interface';
import { CertificationControl } from '../../../interfaces/certificationControl.interface';
import { InclusionConfiguration } from '../../../interfaces/inclusionConfiguration.interface';

type QuestionOnQuestionnaireHeaderProps = {
  questions: Question[] | undefined;
  question: Question;
  comments: Comment[] | undefined;
  inclusion: InclusionConfiguration | undefined;
  certificationControl: CertificationControl | undefined;
  curQuestionStatus: string;
  color: PaletteKey | 'transparent';
  onCommentModalOpen: () => void;
};

const QuestionOnQuestionnaireHeader = (props: QuestionOnQuestionnaireHeaderProps) => {
  const {
    questions,
    question,
    comments,
    inclusion,
    certificationControl,
    curQuestionStatus,
    color,
    onCommentModalOpen,
  } = props;

  const getInclusionOrder = (incQ: Question) => {
    if (questions?.find((q) => q.id === question.id)) incQ = questions?.find((q) => q.id === question.id)!;
    if (
      typeof incQ.domain === 'number' ||
      typeof incQ.topic === 'number' ||
      typeof incQ.questionnaire === 'number' ||
      typeof incQ.bestPractice === 'number'
    ) return;

    const topicOrder = incQ.domain.topics.findIndex((ntopic) => {
      return ntopic === (typeof incQ.topic === 'number' ? incQ.topic : incQ.topic.id);
    }) + 1;
    const bestPracticeOrder = incQ.topic.bestPractices.findIndex((bp) => {
      return bp === (typeof incQ.bestPractice === 'number' ? incQ.bestPractice : incQ.bestPractice.id);
    });
    const questionOrder = incQ.bestPractice.questions.findIndex((q) => q === incQ.id) + 1;

    return `${incQ.questionnaire.title}: ${incQ.domain.code}-${topicOrder}.${bestPracticeOrder}.${questionOrder}`;
  };

  const inclusionTitle = () => {
    if (!inclusion) return '';

    let title = 'You are seeing this because you answered ';
    title += typeof inclusion.answer !== 'number' ? inclusion.answer.text : '';
    title +=  ` on ${typeof inclusion.question !== 'number' ? getInclusionOrder(inclusion.question) : ''}`;
    return title;
  };

  return (
    <>
      <Grid item xs={10}>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          {question.isBestPractice && <WorkspacePremium htmlColor='black' sx={{ transform: 'translateY(3px)' }} />}
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              fontWeight: question.isBestPractice ? 'bold' : 'inherit',
              fontSize: '1.25rem',
              color: color === 'info' ? 'black' : color === 'transparent' ? 'transparent' : `${[color]}.main`,
            }}
          >
            {question.title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', gap: '.25em', alignItems: 'center', justifyContent: 'end' }}>
        {comments && comments.length > 0 && (
          <Box title='Comments'>
            <Forum
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onCommentModalOpen();
              }}
            />
          </Box>
        )}
        {inclusion && (
          <Tooltip title={inclusionTitle()}>
            <Visibility sx={{ transform: 'translateY(-4px)' }} />
          </Tooltip>
        )}
        {certificationControl && (
          <Box
            title={`${certificationControl.certification.title} ${certificationControl.certification.version}: ${certificationControl.control}`}
          >
            <Description />
          </Box>
        )}
        {curQuestionStatus && curQuestionStatus !== 'cert' && (
          <Box title={curQuestionStatus}>
            {curQuestionStatus === 'incomplete' && <CheckBoxOutlineBlank />}
            {(['complete', 'non-compliant', 'submitted'].indexOf(curQuestionStatus) > -1 ||
              curQuestionStatus === 'assessorApproved') && <CheckBox sx={{ color: `${[color]}.main` }} />}
            {['remediation', 'priority'].indexOf(curQuestionStatus) > -1 && (
              <Warning sx={{ color: `${[color]}.main` }} />
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default QuestionOnQuestionnaireHeader;
