import Auth from '@aws-amplify/auth';

interface Session {
  getIdToken: () => { getJwtToken: () => string };
}

const isTokenExpired = (expiresAt: number): boolean => {
  const now = new Date().getTime();
  return now >= expiresAt;
};

export default async function getAuthSession(): Promise<Session> {
  const storedExpiry = localStorage.getItem('amplifyTokenExpiry');
  const storedToken = localStorage.getItem('amplifyAuthToken');
  const expiresAt = storedExpiry ? parseInt(storedExpiry, 10) : 0;

  // Return session object if a valid token exists
  if (storedToken && storedExpiry && !isTokenExpired(expiresAt)) {
    return {
      getIdToken: () => ({ getJwtToken: () => storedToken }),
    };
  }

  // Fetch new session if no valid token is stored or is expired
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();
  const newExpiresAt = session.getIdToken().getExpiration() * 1000; // Convert to milliseconds

  localStorage.setItem('amplifyAuthToken', idToken);
  localStorage.setItem('amplifyTokenExpiry', newExpiresAt.toString());

  return session as Session;
}