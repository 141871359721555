import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import user from './userSlice';
import bestPractices from './bestPracticeSlice';
import topics from './topicSlice';
import questions from './questionSlice';
import domains from './domainSlice';
import questionnaires from './questionnaireSlice';
import answers from './answerSlice';
import inclusionConfigurations from './inclusionConfigurationSlice';
import certificationControl from './certificationControlSlice';
import certification from './certificationSlice';
import questionnaireAnswer from './questionnaireAnswerSlice';
import users from './usersSlice';
import serviceCategories from './serviceCategories';
import surveys from './surveySlice';
import assessments from './assessmentSlice';
import companyList from './companyList';
import workflow from './workflowSlice';
import fileSlice from './fileSlice';

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  user,
  users,
  bestPractices,
  topics,
  questions,
  questionnaires,
  domains,
  answers,
  inclusionConfigurations,
  certificationControl,
  certification,
  questionnaireAnswer,
  serviceCategories,
  surveys,
  assessments,
  companyList,
  workflow,
  fileSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: ['survey', 'questionnaireAnswer'],
      },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['survey', 'questionnaireAnswer'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
