import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAssessment, resetAssessment } from '../../app/store/assessmentSlice';
import { setAllQuestionStatus, setBestPractice, setBPStatus, setCertificationControlIds, setDomain, setInclusionConfigsById, setQuestion, setQuestionnaire, setQuestions, setTopic } from '../../app/store/questionnaireAnswerSlice';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Domain } from '../../interfaces/domain.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { Question } from '../../interfaces/question.interface';
import { QuestionAnswer } from '../../interfaces/questionAnswer.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { Topic } from '../../interfaces/topic.interface';
import { buildOutQuestionnaire, fetchQuestionDependantObjects, initialBuildOutQuestionnaire } from '../../services/buildQuestionnaire';
import { buildFullQuestionnaireObject } from '../../services/questionnaires';
import useGradualProgress from './useGradualProgress';

import { Box, Backdrop, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { batch } from 'react-redux';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: 'rgba(0,0,0,.5)',
    zIndex: '999999999',
  },
  loaderContainer: {
    width: '500px',
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
  },
  loaderBadgeContainer: {
    width: '100%',
    height: '402px',
    maxHeight: '90%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBadge: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  clipPath: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'clip-path 1s linear',
    width: '100%',
    height: '100%',
  },
  percentText: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  percentTextTitle: {
    fontSize: '20px',
    fontWeight: 'semibold',
  },
}));

export default function QuestionnaireAnswerLoader(props: {
  questionnaire: Questionnaire,
  setHideLoader: (hide: boolean) => void,
  domain?: Domain,
  topic?: Topic,
  bestPractice?: BestPractice,
}) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const params = useParams();
  const query = useQuery();
  const assessmentId:string | undefined = params.assessmentId;
  const questionId:string | null = query.get('question');
  const {
    questionnaire,
    questions,
    preview,
    bpStatus,
  } = useAppSelector(state => state.questionnaireAnswer);
  const { assessment } = useAppSelector(state => state.assessments);
  const { survey } = useAppSelector(state => state.surveys);
  const [loadingFinished, setLoadingFinished] = useState(false);
   
  const progressPercent = useGradualProgress(1, 50, 88, 98, 750, loadingFinished, props.setHideLoader, 25, 100, 2);

  const buildQuestionnaire = async () => {
    if (!preview && (!survey || questionnaire)) return;
    if (preview && questions !== undefined && questions.length > 0) return;
    const questionnaireObjects = await buildFullQuestionnaireObject(props.questionnaire.id);
    const builtQuestionnaire = initialBuildOutQuestionnaire( { ...questionnaireObjects, questionnaire: props.questionnaire } );

    const getQuestions = await fetchQuestionDependantObjects(false, builtQuestionnaire.allQuestions, builtQuestionnaire.allInclusions);    
    const inclusionQuestions = [ ...getQuestions.questions.map(bpq => bpq.inclusions.map(inc => (inc as InclusionConfiguration).question)).flat(1), ...getQuestions.inclusions.map(inc => (inc as InclusionConfiguration).question) ] as Question[];
    const additionalQuestionsList = inclusionQuestions.filter(incq => getQuestions.questions.findIndex(qu => qu.id === incq.id) === -1).map(qu => qu.id);

    const additionalQuestionsListNonDup = [ ...new Set(additionalQuestionsList) ];
    const restOfQuestions = await fetchQuestionDependantObjects(false, additionalQuestionsListNonDup);
    const restOfinclusionQuestions = restOfQuestions.questions.map(bpq => bpq.inclusions.map(inc => (inc as InclusionConfiguration).question)).flat(1);

    const allRestOfQuestions = [ ...restOfQuestions.questions ];
    (restOfinclusionQuestions as Question[]).forEach(incq => {
      if (allRestOfQuestions.findIndex(q => q.id === incq.id) === -1) allRestOfQuestions.push(incq);
    });
    const { questionnaire: fullQuestionnaire, certControls, questionStatusById } = buildOutQuestionnaire(builtQuestionnaire.questionnaire, [ ...getQuestions.questions, ...allRestOfQuestions ], survey ? survey.questionAnswers as QuestionAnswer[] : undefined);
    
    batch(() => {
      dispatch(setInclusionConfigsById(getQuestions.inclusions));
      dispatch(setCertificationControlIds(certControls));
      dispatch(setQuestions([ ...getQuestions.questions, ...allRestOfQuestions ]));
      dispatch(setAllQuestionStatus(questionStatusById));
      dispatch(setQuestionnaire(fullQuestionnaire));
      dispatch(setBPStatus());
    });
    if (survey?.bestPracticeStatuses?.length || preview) setLoadingFinished(true);
  };

  const setValuesFromQuestion = () => {
    if (!questionnaire || !questions || !questionId) return false;

    const selectedQuestion = questions.find(cq => cq.id == questionId);
    if (!selectedQuestion) return false;

    const domainId = typeof selectedQuestion.domain === 'number' ? selectedQuestion.domain : selectedQuestion.domain.id;
    const nDomain = questionnaire.domains.find(d => d.id === domainId);
    if (!nDomain) return false;

    const topicId = typeof selectedQuestion.topic === 'number' ? selectedQuestion.topic : selectedQuestion.topic.id;
    const nTopic = nDomain.topics.find(t => t.id === topicId);
    if (!nTopic) return false;

    const bestPracticeId = typeof selectedQuestion.bestPractice === 'number' ? selectedQuestion.bestPractice : selectedQuestion.bestPractice.id;
    const nBP = nTopic.bestPractices.find(bp => bp.id === bestPracticeId);
    if (!nBP) return false;

    batch(() => {
      dispatch(setDomain(nDomain));
      dispatch(setTopic(nTopic));
      dispatch(setBestPractice(nBP));
      dispatch(setQuestion(selectedQuestion));
    });
    return true;
  };

  useEffect(() => {
    if (!survey || questionnaire) return;
    buildQuestionnaire();
  }, [survey]);

  useEffect(() => {
    if (!bpStatus || !Object.keys(bpStatus).length || !bpStatus[Object.keys(bpStatus)[0]].id) return;
    setLoadingFinished(true);
  }, [bpStatus, questionnaire]);
  
  useEffect(() => {
    if (!params || assessment) return;
    dispatch(resetAssessment);
    if (assessmentId) dispatch(fetchAssessment(parseInt(assessmentId)));
    return () => {
      dispatch(resetAssessment);
    };
  }, [params]);

  useEffect(() => {
    if (!questionnaire) return;
    if (setValuesFromQuestion()) return;

    const domainId = props.domain ? props.domain.id : questionnaire.domains[0].id;
    const nDomain = questionnaire.domains.find(d => d.id === domainId);
    if (!nDomain) return;
    dispatch(setDomain(nDomain));

    const topicId = props.topic ? props.topic.id : nDomain.topics[0].id;
    const nTopic = nDomain.topics.find(t => t.id === topicId);
    if (!nTopic) return;
    dispatch(setTopic(nTopic));

    const bestPracticeId = props.bestPractice ? props.bestPractice.id : nTopic.bestPractices[0].id;
    const nBP = nTopic.bestPractices.find(bp => bp.id === bestPracticeId);
    if (!nBP) return;
    dispatch(setBestPractice(nBP));

    const nquestion = nBP.questions[0];
    if (!nquestion) return;
    dispatch(setQuestion(nquestion));
  }, [questionnaire]);

  return (
    <Backdrop className={classes.backdrop} open>
      <Box className={classes.loaderContainer}>
        <Box className={classes.loaderBadgeContainer}>
          <Box className={classes.clipPath} sx={{ clipPath: `polygon(0% 0%, 100% 0%, 100% ${100 - progressPercent}%, 0% ${100 - progressPercent}%);` }}><Box component='img' src={`${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`} className={classes.loaderBadge} /></Box>
          <Box className={classes.clipPath} sx={{ clipPath: `polygon(0% ${100 - progressPercent}%, 0% 100%, 100% 100%, 100% ${100 - progressPercent}%)` }}><Box component='img' src={`${process.env.PUBLIC_URL}/assets/TPN_GoldLogoTransparent_1280.png`} className={classes.loaderBadge} /></Box>
        </Box>
        <Typography className={classes.percentText}>{progressPercent}%</Typography>
        <Typography className={classes.percentTextTitle}>Loading {props.questionnaire?.title}</Typography>
      </Box>
    </Backdrop>
  );
}