import axios from 'axios';

import getAuthSession from './auth';
import { Contact, ContactSubmissionData, Service } from '../interfaces/users.interface';

function prepareContactServiceIds(contact: Contact | ContactSubmissionData) {
  return contact.services?.map((service: Service | undefined | number) => {
    if (service == undefined) return;
    if (typeof service == 'number') return service;
    if (service.hasOwnProperty('id')) {
      return service.id;
    }
  });
}

export default async function createContact(contact: ContactSubmissionData) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/contacts/`,
    {
      ...contact,
      services: prepareContactServiceIds(contact),
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Contact;
}

export async function updateContact(contact: ContactSubmissionData) {
  if (contact.createdBy) delete contact.createdBy;
  if (contact.createdAt) delete contact.createdAt;
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/contacts/${contact.id}/`,
    {
      ...contact,
      services: prepareContactServiceIds(contact),
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Contact;
}

export async function updateContactShort(contact: any) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/contacts/${contact.id}/`,
    {
      ...contact,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Contact;
}

export async function deleteContact(contact: Contact) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/contacts/${contact.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}