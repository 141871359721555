import { ExpandLess, ExpandMore, NotificationImportantRounded } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText, Paper, Tooltip, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAssessmentAuditLog, setAssessmentLocalStorage } from '../../app/store/assessmentSlice';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Question } from '../../interfaces/question.interface';
import User from '../../interfaces/users.interface';
import { buildBestPracticeTitle } from '../BestPractices/BestPractice';
import { setBestPractice, setDomain, setQuestion, setTopic } from '../../app/store/questionnaireAnswerSlice';
import { batch } from 'react-redux';

interface AuditLogListItem {
  assessorAnswer: number;
  comment: boolean;
  evidence: boolean;
  finding: boolean;
  answer: boolean;
  remediation: boolean;
  priority: boolean;
}

export default function AssessmentAuditLogSidebar(props: { assessmentSurvey: AssessmentSurvey, assessment: number }) {
  const [auditLogs, setAuditLogs] = useState<AuditLogListItem[]>([]);
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(false);
  const { assessmentAuditLog, assessmentLocalStorageItems } = useAppSelector(state => state.assessments);
  const { user } = useAppSelector(state => state.user);
  const { assessmentQuestionsStatus, questions, questionnaire } = useAppSelector(state => state.questionnaireAnswer);

  useEffect(() => {
    if (!assessmentLocalStorageItems.length) { dispatch(setAssessmentLocalStorage({ assessmentSurvey: props.assessmentSurvey.id!, assessment: props.assessment })); return; }
    const curAssLocalStorageItem = assessmentLocalStorageItems.find(assLoc => assLoc.assessmentSurvey === props.assessmentSurvey.id);
    if (!assessmentAuditLog.length && curAssLocalStorageItem) dispatch(fetchAssessmentAuditLog({ assessmentSurvey: props.assessmentSurvey.id!, date: curAssLocalStorageItem.date }));
    dispatch(setAssessmentLocalStorage({ assessmentSurvey: props.assessmentSurvey.id!, assessment: props.assessment }));
  }, []);

  useEffect(() => {
    if (!assessmentAuditLog.length) { setAuditLogs([]); return; }
    let filteredauditLogs = assessmentAuditLog.find(aal => aal.assessmentSurvey === props.assessmentSurvey.id)?.auditLogs.filter(al => (al.user as User).id !== user?.id && al.assessorAnswer);
    if (!filteredauditLogs || !filteredauditLogs.length) { setAuditLogs([]); return; }

    const newAlItems:AuditLogListItem[] = [];
    filteredauditLogs.forEach(fal => {
      const index = newAlItems.findIndex(alI => alI.assessorAnswer === fal.assessorAnswer);
      if (index === -1) {
        newAlItems.push({
          assessorAnswer: fal.assessorAnswer!,
          comment: fal.description.toLowerCase().indexOf('comment') > -1,
          evidence: fal.description.toLowerCase().indexOf('evidence') > -1,
          finding: fal.description.toLowerCase().indexOf('finding') > -1,
          answer: fal.description.toLowerCase().indexOf('question answer') > -1,
          remediation: fal.description.toLowerCase().indexOf('remediation') > -1,
          priority: fal.description.toLowerCase().indexOf('priority') > -1,
        });
      } else {
        if (fal.description.toLowerCase().indexOf('comment') > -1) newAlItems[index].comment = true;
        if (fal.description.toLowerCase().indexOf('evidence') > -1) newAlItems[index].evidence = true;
        if (fal.description.toLowerCase().indexOf('finding') > -1) newAlItems[index].finding = true;
        if (fal.description.toLowerCase().indexOf('question answer') > -1) newAlItems[index].answer = true;
        if (fal.description.toLowerCase().indexOf('remediation') > -1) newAlItems[index].remediation = true;
        if (fal.description.toLowerCase().indexOf('priority') > -1) newAlItems[index].priority = true;
      }

    });
    setAuditLogs(newAlItems);
  }, [assessmentAuditLog, props.assessmentSurvey]);

  const handleAuditItemClick = (question:Question) => {
    if (!questionnaire) return false;

    const domainId = typeof question.domain === 'number' ? question.domain : question.domain.id;
    const nDomain = questionnaire.domains.find(d => d.id === domainId);
    if (!nDomain) return false;

    const topicId = typeof question.topic === 'number' ? question.topic : question.topic.id;
    const nTopic = nDomain.topics.find(t => t.id === topicId);
    if (!nTopic) return false;

    const bestPracticeId = typeof question.bestPractice === 'number' ? question.bestPractice : question.bestPractice.id;
    const nBP = nTopic.bestPractices.find(bp => bp.id === bestPracticeId);
    if (!nBP) return false;

    batch(() => {
      dispatch(setDomain(nDomain));
      dispatch(setTopic(nTopic));
      dispatch(setBestPractice(nBP));
      dispatch(setQuestion(question));
    });
  };

  const CreateAuditLogItem = (prop: { auditLog: AuditLogListItem }) => {
    const { auditLog } = prop;
    const questionId = assessmentQuestionsStatus ? Object.keys(assessmentQuestionsStatus).find(qid => assessmentQuestionsStatus[qid].id == auditLog.assessorAnswer) : undefined;
    if (!questionId) return <></>;
    const question = questions?.find(q => q.id == questionId);
    if (!question) return <></>;
    const bpTitle = buildBestPracticeTitle({ ...(question.bestPractice as BestPractice), domain: question.domain, topic: question.topic });


    return (
      <ListItemButton onClick={() => handleAuditItemClick(question)}>
        <ListItemText primary={<>
          <Typography variant='caption'>{bpTitle}</Typography>
          {/* <Typography>{(auditLog.user as User).firstName} {(auditLog.user as User).lastName}: {auditLog.description}</Typography> */}
          <Tooltip title={question.title}><Typography sx={{ maxWidth: '1000px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{question.title}</Typography></Tooltip>
          <Typography variant='caption'>
            {auditLog.comment && ' | Comment'}
            {auditLog.evidence && ' | Evidence'}
            {auditLog.finding && ' | Finding'}
            {auditLog.answer && ' | Answer'}
            {auditLog.remediation && ' | Remediation'}
            {auditLog.priority && ' | CO Priority'}
            {' |'}
          </Typography>
        </>} />
      </ListItemButton>
    );
  };

  return (
    <>{auditLogs.length > 0 &&
      <Paper sx={{ mb: 1 }}>
        <List>
          <ListItemButton onClick={() => setExpanded(!expanded)}>
            <NotificationImportantRounded color="warning" sx={{ position: 'absolute', top: '0', left: '0', width: '.8em' }} />
            <ListItemText primary='Recent Activity' />
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ maxHeight: '500px', overflow: 'auto'  }}>
              <Typography variant='caption' sx={{ ml: 2 }}>Since Last view of Assessment</Typography>
              <List>
                {auditLogs.map(al => (
                  <CreateAuditLogItem key={'auditLogItems-' + al.assessorAnswer} auditLog={al} />
                ))}
              </List>
            </Box>
          </Collapse>
        </List>
      </Paper>
    }</>
  );
}