import axios from 'axios';

import getAuthSession from './auth';

export default async function logCloudWatchEvent(logGroup: string, logStream: string, message: string) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/cloudwatch-logging/`,
    { logGroup, logStream, message },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
}