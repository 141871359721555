import React, { useState, useEffect } from 'react';
import { AuditLog } from '../../interfaces/auditLog.interface';
import { NotificationImportantRounded } from '@mui/icons-material';
import { useAppSelector } from '../../app/hooks';
import User from '../../interfaces/users.interface';

export const AssessmentSurveyAuditLogs = (props: {
  assessmentSurveyId: number,
  status: string
}) => {
  const { assessmentSurveyId, status } = props;
  const [last5AuditLogs, setLast5AuditLogs] = useState<AuditLog[]>([]);
  const { assessmentAuditLog } = useAppSelector(state => state.assessments);
  const { user } = useAppSelector(state => state.user);

  useEffect(() => {
    if (!assessmentAuditLog.length || ['Remediation', 'Completed', 'Complete'].indexOf(status) > -1) { setLast5AuditLogs([]); return; }
    let auditLogs = assessmentAuditLog.find(aal => aal.assessmentSurvey === assessmentSurveyId)?.auditLogs.filter(al => (al.user as User).id !== user?.id);
    if (!auditLogs) { setLast5AuditLogs([]); return; }
    auditLogs = [ ...auditLogs ];
    setLast5AuditLogs(auditLogs.sort((a, b) => a.createdAt && b.createdAt ? b.createdAt.localeCompare(a.createdAt) : 0).slice(0, 4));
  }, [assessmentAuditLog]);

  return (<>
    {last5AuditLogs.length > 0 && <>
      <NotificationImportantRounded color="warning" sx={{ position: 'absolute', top: '0', left: '0', width: '.8em' }} />
    </>}
  </>);
};