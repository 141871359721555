import { Typography, Grid, TextField, Select, MenuItem, Button, FormControl, InputLabel, Divider, Box, Badge, Tooltip, Alert, Stack } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import MultipleSelectChip from '../../../Vendors/Forms/MultiSelect';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Application, ApplicationReponse, ApplicationType, Version, Company, ApplicationVersion } from '../../../../interfaces/users.interface';
import { debounce } from 'lodash';
import { DataPagination, DataRow } from '../../../Tables/DataList';
import { DataCell } from '../../../Tables/DataList';
import { Add, ChevronLeft, Star } from '@mui/icons-material';
import { ApplicationMeta } from '../../../../interfaces/users.interface';
import LicensedApplication from './LicensedApplication';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchAllApplicationTypes } from '../../../../app/store/serviceCategories';
import { getApplicationsFull, getBadgesFromVersions, requestVersion } from '../../../../services/applications';
import SelectSitesAndServices from './SelectSitesAndServices';
import ApplicationPill from './ApplicationPill';
import GetBadge from '../../GetBadge';
import { appTheme } from '../../../Themes/light';
import { selectBadgeFromArray } from '../../../../services/badge';

type SearchLicensedAppsProps = {
  isOpen: boolean;
  handleClose: () => void;
  callBack: any;
  selectedApplicationVersions?: ApplicationVersion[];
  back?: () => void;
  company?: Company;
  isCustom?: boolean;
};

export default function SearchLicensedApps(props: SearchLicensedAppsProps) {
  const dispatch = useAppDispatch();
  const { applicationTypes, fetchingApplicationTypes } = useAppSelector(state => state.serviceCategories);
  const { user } = useAppSelector(state => state.user);

  const [versionRequestOpen, setVersionRequestOpen] = useState(false);
  const [versionRequestCompleteOpen, setVersionRequestCompleteOpen] = useState<boolean | string>(false);
  const [newVersionApplication, setNewVersionApplication] = useState<undefined | Application>(undefined);
  const [newVersionString, setNewVersionString] = useState<undefined | string>(undefined);
  const [newVersionError, setNewVersionError] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState<Array<number>>([]);
  const [company, setCompany] = useState('');
  const [application, setApplication] = useState('');
  const [companyInput, setCompanyInput] = useState('');
  const [applicationInput, setApplicationInput] = useState('');
  const [applications, setApplications] = useState<ApplicationReponse | undefined>(undefined);
  const [selectedApplicationVersions, setSelectedApplicationVersions] = useState<ApplicationVersion[]>(props.selectedApplicationVersions || []);
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>(undefined);
  const [addSelectedApplication, setAddSelectedApplication] = useState<ApplicationVersion | undefined>(undefined);
  const [isLicensedApplicationOpen, setIsLicensedApplicationOpen] = useState(false);
  const [isSitesAndServicesOpen, setIsSitesAndServicesOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 4;
  const showNewApp = useMemo(() => applications && offset + limit >= applications.count, [applications, offset]);

  const fetchApplications = async () => {
    setApplications(undefined);
    const appList = await getApplicationsFull({ limit, search: application, offset, applicationType: selectedCategories[0], application_company_name: company, ...(user?.type === 'tpn_admin' ? {} : {
      is_public: true,
      admin_approved: true,
    }) });
    setApplications(appList);
  };

  const debounceSetApplication = useRef(debounce((value:string) => {
    setApplication(value);
    setOffset(0);
  }, 1000)).current;

  const debounceSetCompany = useRef(debounce((value:string) => {
    setCompany(value);
    setOffset(0);
  }, 1000)).current;

  const handleSetSelectedCategories = (cats:number[]) => {
    setSelectedCategories(cats);
    setOffset(0);
  };

  const handleApplicationDelete = (av:ApplicationVersion) => {
    setSelectedApplicationVersions(prevApps => {
      const newApps = prevApps.filter(app => (app.application as Application).id !== (av.application as Application).id || (app.version as Version).id !== (av.version as Version).id);
      return newApps;
    });
  };

  const handleAddApp = (av: ApplicationVersion) => {
    if (props.isCustom) {
      setSelectedApplicationVersions(prevApps => {
        const appExists = prevApps.find(a => (a.application as Application).id === (av.application as Application).id && (a.version as Version).id === (av.version as Version).id);
        if (appExists) return prevApps;
        return [ ...prevApps, av ];
      });
    } else {
      setAddSelectedApplication(av);
    }
  };

  const udpateLicensedApplicationList = (app: Application) => {
    setCompanyInput((app.meta![0] as ApplicationMeta).title);
    setApplicationInput(app.name);
    setCompany((app.meta![0] as ApplicationMeta).title);
    setApplication(app.name);
    setSelectedCategories([]);
    setOffset(0);
    return;
  };

  const requestNewVersion = () => {
    setNewVersionError(false);
    if (!newVersionApplication?.company || !user) return;
    if (!newVersionString || newVersionString.length === 0) return setNewVersionError(true);
    const ocompany = newVersionApplication.company!;
    const rcompany = user?.companies[0];
    requestVersion(typeof ocompany === 'number' ? ocompany : ocompany.id!, newVersionApplication.id!, rcompany.id!, newVersionString);
    setVersionRequestCompleteOpen(`${(newVersionApplication.company as Company)?.name} ${newVersionApplication.name}: Version ${newVersionString}`);

    setNewVersionString(undefined);
    setVersionRequestOpen(false);
  };

  const cleanUpAndClose = (dontClose?: boolean) => {
    setSelectedCategories([]);
    setCompany('');
    setApplication('');
    setCompanyInput('');
    setApplicationInput('');

    if (!dontClose) props.handleClose();
  };

  const callBack = (co:Company) => {
    cleanUpAndClose();
    props.callBack(co);
  };

  useEffect(() => {
    if (!applicationTypes.length && !fetchingApplicationTypes) dispatch(fetchAllApplicationTypes());
    return (() =>{
      setApplication('');
      setSelectedCategories([]);
      setSelectedApplicationVersions([]);
    });
  }, []);

  useEffect(() => {
    if (!props.selectedApplicationVersions) return;
    setSelectedApplicationVersions(props.selectedApplicationVersions);
  }, [props.selectedApplicationVersions]);

  useEffect(() => {
    fetchApplications();
  }, [company, application, selectedCategories, offset]);

  useEffect(() => {
    if (!selectedApplication) return;
    setIsLicensedApplicationOpen(true);
  }, [selectedApplication]);

  useEffect(() => {
    if (!addSelectedApplication) return;
    setIsSitesAndServicesOpen(true);
  }, [addSelectedApplication]);

  useEffect(() => {
    if (!newVersionApplication) {
      setVersionRequestCompleteOpen(false);
      setVersionRequestOpen(false);
    } else {
      setVersionRequestOpen(true);
    }
  }, [newVersionApplication]);

  return (
    <StandardDialog title='Search the TPN+ Registry & Add 3rd party Applications' isOpen={props.isOpen} handleClose={cleanUpAndClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Search the directory to find 3rd party applications. You can search by the name of the company (e.g. Adobe), or the application itself (e.g. Premiere).</Typography>
          <Typography variant='caption' sx={{ mt: 1 }}>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              badgeContent={<Star color='primary' sx={{ width: '20px', height: '20px', transform: 'translateX(-8px)' }} />}
            >
              This star denotes a TPN member Company
            </Badge>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth label='Company' value={companyInput} onChange={(e) => {setCompanyInput(e.target.value); debounceSetCompany(e.target.value);}} />
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth label='Application' value={applicationInput} onChange={(e) => {setApplicationInput(e.target.value); debounceSetApplication(e.target.value);}} />
        </Grid>
        <Grid item xs={4}>
          <MultipleSelectChip margin='0' options={applicationTypes} getOption={(option: ApplicationType) => option.type} optionsCallback={handleSetSelectedCategories} selectedOptions={selectedCategories} inputLabel='Application Types' />
        </Grid>
        <Box sx={{ width: '100%', transform: 'translateX(8px)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DataRow header>
            <DataCell xs={4}>Company</DataCell>
            <DataCell xs={4}>Application</DataCell>
            <DataCell xs={4}><Add /></DataCell>
          </DataRow>
          {!!applications?.results.length && applications.results.map(cApplication => (
            <DataRow key={cApplication.id}>
              <DataCell sx={{ padding: appTheme.spacing(.5) }} xs={4}>
                {cApplication.company && <Tooltip title={`TPN+ Member - ${(cApplication.company as Company)?.name}`}><Typography sx={{ maxWidth: '100%' }}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={<Star color='primary' sx={{ width: '20px', height: '20px', transform: 'translateX(-8px)' }} />}
                    sx={{ maxWidth: '100%' }}
                  >
                    <Typography sx={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'block' }} >{(cApplication.company as Company)?.name}</Typography>
                  </Badge>
                </Typography></Tooltip>}
                {!!cApplication.meta?.length && <Typography sx={{ maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'block', overflow: 'hidden' }}>{cApplication.meta ? (cApplication.meta as ApplicationMeta[])[0].title : ''}</Typography>}
              </DataCell>
              <DataCell sx={{ padding: appTheme.spacing(.5) }} xs={4}>
                {cApplication.name}
              </DataCell>
              <DataCell sx={{ padding: appTheme.spacing(.5) }} xs={4}>
                <FormControl fullWidth>
                <InputLabel id="select-version-label">Select Version or App if part of Tool Suite</InputLabel>
                  <Select
                    labelId="select-version-label"
                    id="select-version"
                    label='Select Version or App if part of Tool Suite'
                    value={null}
                  >
                    {cApplication.versions && (cApplication.versions as Version[]).sort((a, b) => a.value.localeCompare(b.value)).map(version => (
                      <MenuItem 
                        key={version.id} value={version.value}
                        onClick={(e) => {e.preventDefault(); handleAddApp({ application: cApplication, version });}}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                            {version.badge && typeof version.badge !== 'number' &&
                              <GetBadge shield={selectBadgeFromArray(getBadgesFromVersions(cApplication?.versions as Version[]))} />}
                            {version.value}
                          </Box>
                          <Button startIcon={<Add />}>Add</Button>
                      </MenuItem>
                    ))}
                    {cApplication.company && <MenuItem value='requestNew' onClick={() => setNewVersionApplication(cApplication)}><Add /> Request New Version or App</MenuItem>}
                    {!cApplication.company && <MenuItem value='addNew' onClick={() => setSelectedApplication(cApplication)}><Add /> Add New Version or App</MenuItem>}
                  </Select>
                </FormControl>
              </DataCell>
            </DataRow>
          ))}
          {showNewApp && <Box sx={{ m: '1em 0', width: 'auto' }}>Not finding the application you are looking for? <Button onClick={() => setIsLicensedApplicationOpen(true)}>Add it to our directory.</Button></Box>}
          {applications && <DataPagination total={applications.count} offset={offset} limit={limit} handlePageChange={setOffset} />}
        </Box>
        {props.isCustom && <Grid item xs={12}><Divider /></Grid>}
        {props.isCustom && <Grid item xs={12}>
          <Typography>Selected Applications:</Typography>
          <Box sx={{ display: 'flex', gap: '8px', mt: 1 }}>
            {selectedApplicationVersions.map(av => (
              <ApplicationPill key={(av.application as Application).id + ' ' + (av.version as Version)?.id} application={av.application as Application} version={av.version as Version} handleRemoveSelectedApplication={() => handleApplicationDelete(av)} />
            ))}
            {!selectedApplicationVersions.length && <Typography sx={{ mt: 1 }}>None</Typography>}
          </Box>
        </Grid>}
      </Grid>
      <StandardDialogActions>
        {props.back && <Button variant='outlined' onClick={() => {
          cleanUpAndClose(true); if (props.back) props.back();
        }} startIcon={<ChevronLeft />}>Back</Button>}
        <Button variant='outlined' onClick={() => {cleanUpAndClose();}}>Cancel</Button>
        {props.isCustom && <Button variant='contained' onClick={() => {props.callBack(selectedApplicationVersions); cleanUpAndClose();}}>Save</Button>}
      </StandardDialogActions>
      <StandardDialog title='Request New Version or App' isOpen={!!versionRequestOpen} handleClose={() => {setNewVersionApplication(undefined); setNewVersionString('');}}>
        <Stack spacing={2}>
          <Typography>Please enter the version you wish to request.</Typography>
          <Typography>Please note that your name, email address, and company&apos;s name will be shared with the Application Owner for awareness.</Typography>
          <TextField fullWidth label='Version/Apps' value={newVersionString} onChange={e => setNewVersionString(e.target.value)} required />
          {newVersionError && <Alert severity='error'>Please Enter a version</Alert>}
        </Stack>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => {setNewVersionApplication(undefined); setNewVersionString('');}}>Cancel</Button>
          <Button variant='contained' onClick={() => requestNewVersion()}>Request Version</Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog title='New Version Requested' isOpen={!!versionRequestCompleteOpen} handleClose={() => setVersionRequestCompleteOpen(false)}>
        <Typography>TPN has been notified of your request for:</Typography>
        <Typography>{versionRequestCompleteOpen}</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => setVersionRequestCompleteOpen(false)}>Close</Button>
        </StandardDialogActions>
      </StandardDialog>
      <LicensedApplication application={selectedApplication} isOpen={isLicensedApplicationOpen} handleClose={() => {setSelectedApplication(undefined); setIsLicensedApplicationOpen(false);}} callBack={udpateLicensedApplicationList} />
      {props.company && <SelectSitesAndServices company={props.company} newApplication={addSelectedApplication?.application as Application} version={addSelectedApplication?.version as Version} isOpen={isSitesAndServicesOpen} handleClose={() => {setAddSelectedApplication(undefined); setIsSitesAndServicesOpen(false);}} callBack={callBack} />}
    </StandardDialog>
  );
}