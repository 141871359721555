import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function Certifications() {
  return (
    <Box>
      <hr/>
      <Typography variant='h6'>Certification Management</Typography>
      <Grid container>
      </Grid>
    </Box>
  );
}
