import axios from 'axios';

import { BestPracticeQuestionDetail } from '../interfaces/bestPractice.interface';
import getAuthSession from './auth';

export default async function getBestPractice(bestPracticeSlug: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/best-practices/${bestPracticeSlug}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as BestPracticeQuestionDetail;
}