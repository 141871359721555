import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import User, { Company, CompanySubmissionData, Contact } from '../../../interfaces/users.interface';
import { updateCompany, resetSubsidiaryCompany } from '../../../services/company';
import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import StandardDialog from '../../Modals/StandardDialog';
import { DataCell, DataRow } from '../../Tables/DataList';
import { useAppSelector } from '../../../app/hooks';
import { titleCase } from '../CompanyDirectory';
import AutoCompleteSearch from '../../Forms/AutoCompleteSearch';
import Typography from '@mui/material/Typography';
import { StandardDialogActions } from '../../Modals/StandardDialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function CompanyDataRow(props: { subsidiary: Company, parentCompany: Company, subsidiaryCallback: any, user: any }) {
  const {
    subsidiary,
    parentCompany,
    subsidiaryCallback,
    user,
  } = props;
  const [removeSubsidiaryOpen, setRemoveSubsidiaryOpen] = useState(false);
  const handleRemoveSubsidiaryClose = () => setRemoveSubsidiaryOpen(false);
  const handleRemoveSubsidiaryOpen = () => setRemoveSubsidiaryOpen(true);

  const removeSubsidiarySuccessCallback = (removedSubsidiary: Company) => {
    const companyData: CompanySubmissionData = {
      ...parentCompany,
      primaryContactUser: parentCompany.primaryContactUser?.id,
      employees: parentCompany.employees.map((employee: User) => employee.id),
      pendingEmployees: parentCompany.pendingEmployees.map((employee) => employee.id),
      locations: parentCompany.locations.map((location: Contact) => location.id),
      services: parentCompany.services.map((service: any) => service.id),
      admins: parentCompany.admins.map(item => item.id),
      subsidiaries: parentCompany.subsidiaries?.filter((sub: Company) => sub.id !== removedSubsidiary.id).map((sub: Company) => sub.id),
    };
    if (parentCompany.id) updateCompany(companyData).then((responseCompany: Company) => {
      if (removedSubsidiary?.id) {
        resetSubsidiaryCompany(removedSubsidiary.id).then(() => {
          handleRemoveSubsidiaryClose();
          subsidiaryCallback(responseCompany);
        });
      } else {
        handleRemoveSubsidiaryClose();
        subsidiaryCallback(responseCompany);
      }
    });
  };

  return (
    <DataRow>
      <StandardDialog title="Remove Related Company" handleClose={handleRemoveSubsidiaryClose} isOpen={removeSubsidiaryOpen}>
        <Typography>Are you sure you want to remove {subsidiary.name} from {parentCompany.name}?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => removeSubsidiarySuccessCallback(subsidiary)}>{`Remove ${subsidiary.name}`}</Button>
          <Button variant='outlined' color='error' onClick={() => handleRemoveSubsidiaryClose()}>Cancel</Button>
        </StandardDialogActions>
      </StandardDialog>
      <DataCell xs={3}><Link to={`/registry/${subsidiary.id}`} style={{ textDecoration: 'none', cursor: 'pointer' }}>{subsidiary.name}</Link></DataCell>
      <DataCell xs={2}>{subsidiary.type ? titleCase(subsidiary.type.replace('_', ' ')) : null}</DataCell>
      <DataCell xs={2}>{subsidiary.employees.length}</DataCell>
      <DataCell xs={2}>{subsidiary.services.length}</DataCell>
      <DataCell xs={2}>{subsidiary.locations.length}</DataCell>
      <DataCell xs={1}>
        <IconButton size='small' name='Remove Subsidiary' onClick={() => handleRemoveSubsidiaryOpen()} disabled={user?.type !== 'tpn_admin'}>
          <DeleteIcon />
        </IconButton>
      </DataCell>
    </DataRow>
  );
}

export default function CompanySubsidiariesAccordion(props: { company: Company, companyCallback: any }) {
  const {
    user: adminUser,
  } = useAppSelector(state => state.user);
  const { company } = props;
  const subsidiaryRef = useRef<any>();
  const { subsidiaries } = company;
  const [addSubsidiaryOpen, setAddSubsidiaryOpen] = useState(false);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<any | null>(null);
  const handleAddSubsidiaryOpen = () => setAddSubsidiaryOpen(true);
  const handleAddSubsidiaryClose = () => {
    setSelectedSubsidiary(null);
    setAddSubsidiaryOpen(false);
  };

  const handleAddSubsidiary = (responseCompany: Company) => {
    props.companyCallback(responseCompany);
    handleAddSubsidiaryClose();
  };

  const addSubsidiarySuccessCallback = (subsidiary: Company) => {
    const companyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: company.primaryContactUser?.id,
      employees: company.employees.map((employee: User) => employee.id),
      pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
      locations: company.locations.map((location: Contact) => location.id),
      services: company.services.map((service: any) => service.id),
      admins: company.admins.map(item => item.id),
      subsidiaries: [...company.subsidiaries?.map((sub: Company) => sub.id), subsidiary.id],
    };
    if (company.id) updateCompany(companyData).then((responseCompany: Company) => handleAddSubsidiary(responseCompany));
  };

  const selectSubsidiaryOption = (subsidiary: any) => setSelectedSubsidiary(subsidiary);
  const clearSelectedSubsidiary = () => {
    setSelectedSubsidiary(null);
    subsidiaryRef.current.clear();
  };

  const [expanded, setExpanded] = useState(false);
  const renderOption = (companyOption: any) => {
    if (!companyOption || companyOption.name.length < 1 )  return null;

    return (
      <>
        <Typography variant="overline" component='div' gutterBottom sx={{ fontSize: '.6em', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ whiteSpace: 'nowrap' }}>{companyOption.name}</Box>
        </Typography>
        <Typography>{companyOption.name}</Typography>
      </>
    );
  };

  return (
    <AccordionCollapse expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
      <AccordionCollapseSummary
        name="company-subsidiaries-header"
        title='Subsidiary Companies'
        count={subsidiaries.length}
        addItemTest={adminUser && adminUser.type == 'tpn_admin'}
        addItem={() => handleAddSubsidiaryOpen()}
        addItemLabel='Subsidiary'
      >
      </AccordionCollapseSummary>
      <AccordionDetails>
        <StandardDialog title="Add Subsidiary Company" handleClose={handleAddSubsidiaryClose} isOpen={addSubsidiaryOpen}>
          {selectedSubsidiary && <Typography>Are you sure you want to add {selectedSubsidiary.name} to {company.name}?</Typography>}
          <AutoCompleteSearch
            onChangeHandler={selectSubsidiaryOption}
            api='companies/list'
            label='For Subsidiary'
            renderOption={renderOption}
            ref={subsidiaryRef}
          />
          <StandardDialogActions>
            {selectedSubsidiary &&
              <>
                <Button variant='outlined' onClick={() => addSubsidiarySuccessCallback(selectedSubsidiary)}>{`Add ${selectedSubsidiary.name}`}</Button>
                <Button variant='outlined' color='error' onClick={clearSelectedSubsidiary}>Cancel</Button>
              </>
            }
          </StandardDialogActions>
        </StandardDialog>
        <DataRow onClick={() => {}} header>
          <DataCell xs={3}>Company</DataCell>
          <DataCell xs={2}>Type</DataCell>
          <DataCell xs={2}>Employees</DataCell>
          <DataCell xs={2}>Services</DataCell>
          <DataCell xs={2}>Locations</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {subsidiaries.map((subsidiary: Company) => <CompanyDataRow key={subsidiary.id} subsidiary={subsidiary} parentCompany={company} subsidiaryCallback={props.companyCallback} user={adminUser}/>)}
      </AccordionDetails>
    </AccordionCollapse>
  );
}
