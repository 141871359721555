import axios from 'axios';

import getAuthSession from './auth';
import { Survey } from '../interfaces/survey.interface';
import { Assessment, AssessmentSurvey, AssessorAnswer, Comment } from '../interfaces/assessment.interface';

export default async function createSurvey(survey: Survey) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/surveys/`,
    {
      ...survey,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Survey;
}

export async function createAssessmentSurvey(assessmentSurvey: AssessmentSurvey) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/assessment-surveys/`,
    {
      ...assessmentSurvey,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessmentSurvey;
}

export async function fetchAssessment(assessment: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/assessments/${assessment}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Assessment;
}

export async function updateAssessmentSingleKey(assessment: number, key: string, value: any) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/assessments/${assessment}/`,
    {
      [key]: value,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Assessment;
}

export async function fetchAssessmentSurvey(assessmentSurvey: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/assessment-surveys/${assessmentSurvey}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessmentSurvey;
}

export async function createMultipleAssessmentSurveys(assessmentSurveys: AssessmentSurvey[]) {
  const authSession = await getAuthSession();
  return Promise.all(assessmentSurveys.map(assessmentSurvey => 
    axios.post(
      `${process.env.REACT_APP_BASE_API}/assessment-surveys/`,
      { 
        survey: assessmentSurvey.survey,
        isOnPremise: assessmentSurvey.isOnPremise,
        isCloud: assessmentSurvey.isCloud,
        isInPerson: assessmentSurvey.isInPerson,
      },
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  )).then(res => {
    return res.map(assSur => assSur.data) as AssessmentSurvey[];
  });
}

export async function updateAssessmentSurvey(assessmentSurvey: AssessmentSurvey) {
  const authSession = await getAuthSession();
  delete (assessmentSurvey as any).assessorAnswers;
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/assessment-surveys/${assessmentSurvey.id}/`,
    { 
      ...assessmentSurvey,
      survey: typeof assessmentSurvey.survey === 'number' ? assessmentSurvey.survey : assessmentSurvey.survey.id,
      adminReviewer: !assessmentSurvey.adminReviewer || typeof assessmentSurvey.adminReviewer === 'number' ? assessmentSurvey.adminReviewer : assessmentSurvey.adminReviewer.id,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessmentSurvey;
}

export async function updateAssessmentSurveySimple(id: number | string, assessmentSurveyKeys: { [key: string]: any } ) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/assessment-surveys/${id}/`,
    assessmentSurveyKeys,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessmentSurvey;
}

export async function updateMultipleAssessmentSurveys(assessmentSurveys: AssessmentSurvey[]) {
  const authSession = await getAuthSession();
  return Promise.all(assessmentSurveys.map(curSur => 
    axios.patch(
      `${process.env.REACT_APP_BASE_API}/assessment-surveys/${curSur.id}/`,
      { 
        ...curSur,
        survey: typeof curSur.survey === 'number' ? curSur.survey : curSur.survey.id,
        assessorAnswers: curSur.assessorAnswers ? curSur.assessorAnswers.map(cSur => typeof cSur === 'number' ? cSur : cSur.id) : curSur.assessorAnswers,
      },
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  )).then(res => {
    return res.map(assSur => assSur.data) as AssessmentSurvey[];
  });
}

export async function deleteMultipleAssessmentSurveys(assessmentSurveys: number[]) {
  const authSession = await getAuthSession();
  return Promise.all(assessmentSurveys.map(curSur => 
    axios.delete(
      `${process.env.REACT_APP_BASE_API}/assessment-surveys/${curSur}/`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  )).then(res => {
    return res;
  });
}

export async function getSiteCloudStatus(baselineId: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/baseline-environment-site-cloud-detail/${baselineId}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function createAssessorAnswer(assessorAnswer: AssessorAnswer) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/assessor-answers/`,
    {
      ...assessorAnswer,
      question: typeof assessorAnswer.question === 'number' ? assessorAnswer.question : assessorAnswer.question.id,
      comments: !assessorAnswer.comments || assessorAnswer.comments.length === 0 ? undefined : assessorAnswer.comments.map(comment => typeof comment === 'number' ? comment : comment.id),
      contentOwnerPriority: !assessorAnswer.contentOwnerPriority || assessorAnswer.contentOwnerPriority.length === 0 ? undefined : assessorAnswer.contentOwnerPriority.map(company => typeof company === 'number' ? company : company.id),
      auditLogEntries: !assessorAnswer.auditLogEntries || assessorAnswer.auditLogEntries.length === 0 ? undefined : assessorAnswer.auditLogEntries.map(auditLogEntries => typeof auditLogEntries === 'number' ? auditLogEntries : auditLogEntries.id),
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  const newAssAnswer = response.data as AssessorAnswer;
  if (newAssAnswer.assessmentSurvey) {
    axios.patch(
      `${process.env.REACT_APP_BASE_API}/assessment-survey-assessor-answers/assessment-survey/${newAssAnswer.assessmentSurvey}/assessor-answer/${newAssAnswer.id}/`,
      {},
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );
  }
  return newAssAnswer;
}

export async function updateAssessorAnswer(assessorAnswer: AssessorAnswer) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/assessor-answers/${assessorAnswer.id}/`,
    {
      ...assessorAnswer,
      question: typeof assessorAnswer.question === 'number' ? assessorAnswer.question : assessorAnswer.question.id,
      comments: !assessorAnswer.comments || assessorAnswer.comments.length === 0 ? undefined : assessorAnswer.comments.map(comment => typeof comment === 'number' ? comment : comment.id),
      contentOwnerPriority: !assessorAnswer.contentOwnerPriority || assessorAnswer.contentOwnerPriority.length === 0 ? undefined : assessorAnswer.contentOwnerPriority.map(company => typeof company === 'number' ? company : company.id),
      auditLogEntries: !assessorAnswer.auditLogEntries || assessorAnswer.auditLogEntries.length === 0 ? undefined : assessorAnswer.auditLogEntries.map(auditLogEntries => typeof auditLogEntries === 'number' ? auditLogEntries : auditLogEntries.id),
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessorAnswer;
}

export async function getMultipleAssessorAnswers(assessorAnswers: number[]) {
  const authSession = await getAuthSession();

  return Promise.all(assessorAnswers.map(ansId => 
    axios.get(
      `${process.env.REACT_APP_BASE_API}/assessor-answers/${ansId}/`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  )).then(res => {
    return res.map(assAns => assAns.data) as AssessorAnswer[];
  });
}

export async function getMultipleComments(commentIds: number[]) {
  const authSession = await getAuthSession();

  const comments = await axios.get(
    `${process.env.REACT_APP_BASE_API}/comments/?ids=${commentIds.join(',')}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );

  return comments.data.results as Comment[];
}

function buildRequestArrays(ids: number[], baseUrl: string, batchSize: number = 15) {
  // Assuming this function splits IDs into multiple URLs each containing up to `batchSize` IDs
  let index = 0;
  const urls = [];

  while (index < ids.length) {
    const batch = ids.slice(index, index + batchSize);
    urls.push(`${baseUrl}${batch.join(',')}`);
    index += batchSize;
  }

  return urls;
}

async function fetchResources(ids: number[], resourceType: string) {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const authSession = await getAuthSession();
  const urlParts = buildRequestArrays(ids, `${baseUrl}/${resourceType}/?ids=`, 15);
  const authHeader = { headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` } };
 
  try {
    const responses = await Promise.all(urlParts.map(url => axios.get(url, authHeader)));
    return responses.map(res => res.data.results).flat();
  } catch (error) {
    console.error(`Error fetching ${resourceType}:`, error);
    return [];
  }
}

export async function getCommentsAndLogs(assessorAnswers: AssessorAnswer[]) {
  const commentIds = new Set<number>();
  const auditIds = new Set<number>();
  
  // Collect all unique comment and audit log IDs
  assessorAnswers.forEach(ans => {
    (ans.comments as number[]).forEach(id => commentIds.add(id));
    (ans.auditLogEntries as number[]).forEach(id => auditIds.add(id));
  });

  const commentsPromise = fetchResources(Array.from(commentIds), 'comments');
  const auditLogsPromise = fetchResources(Array.from(auditIds), 'audit-logs');

  const [comments, auditLogs] = await Promise.all([commentsPromise, auditLogsPromise]);
 
  return { comments, auditLogs };
}

export async function newAssessorAssessmentAlert(serivceProviderID: number, assessorID: number) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/assessor-site-assessment-request/request-company/${serivceProviderID}/assessor/${assessorID}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function assessmentRejectedAlert(serivceProviderID: number, assessorID: number) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/assessment-request-denial/request-company/${serivceProviderID}/assessor/${assessorID}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}
