import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { DataRow, DataCell, DataRowActions, DraggableItem } from '../Tables/DataList';
import { Link } from 'react-router-dom';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Topic } from '../../interfaces/topic.interface';
import { updateTopic } from '../../app/store/topicSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { BestPracticeEditForm } from './BestPracticeForm';
import { deleteBestPractice } from '../../app/store/bestPracticeSlice';

export default function BestPracticeListItem(props: { bestPractice: BestPractice, canEdit?: boolean, topic: Topic, idx: number, moveItem?: any, dropItem?: any }) {
  const [deleteBestPracticeOpen, setDeleteBestPracticeOpen] = useState(false);
  const handleDeleteBestPracticeOpen = () => setDeleteBestPracticeOpen(true);
  const handleDeleteBestPracticeClose = () => setDeleteBestPracticeOpen(false);

  const [bestPractice, setBestPractice] = useState(props.bestPractice);
  const [editBestPracticeOpen, setEditBestPracticeOpen] = useState(false);
  const handleEditBestPracticeOpen = () => setEditBestPracticeOpen(true);
  const handleEditBestPracticeClose = (newBestPractice:BestPractice) =>{
    if (newBestPractice) setBestPractice(newBestPractice);
    setEditBestPracticeOpen(false);
  };

  useEffect(() => {
    if (!props.bestPractice) return;
    setBestPractice(props.bestPractice);
  }, [props.bestPractice]);

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm();

  const dispatch = useAppDispatch();
  const onSubmit = () => {
    const { bestPractices } = props.topic;
    const bestPracticesIdsArr = bestPractices.filter(bestPracticeItem => bestPractice.id !== bestPracticeItem.id).map((bestPracticeItem) => bestPracticeItem.id);
    dispatch(updateTopic({
      ...props.topic,
      bestPractices: bestPracticesIdsArr,
      domain: typeof props.topic.domain === 'number' ? props.topic.domain : props.topic.domain.id,
      questionnaire: typeof props.topic.questionnaire === 'number' ? props.topic.questionnaire : props.topic.questionnaire.id,
    }));
    dispatch(deleteBestPractice(bestPractice.id));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleDeleteBestPracticeClose();
    }
  }, []);

  const renderRow = () => {
    return (
      <>
        <Link to={bestPractice.slug} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 87%)' }}>
          <DataRow hover>
            <DataCell xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              { props.moveItem && props.canEdit ? <DragIndicatorIcon sx={{ cursor: 'grab', mr: '.25em' }} /> : null }
              <Box>{bestPractice.title}</Box>
            </DataCell>
            <DataCell xs={4}>
              <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={bestPractice.description}>{bestPractice.description}</Box>
            </DataCell>
            <DataCell xs={2}>
              {props.idx + 1}
              {props.canEdit && (
              <DataRowActions>
                <IconButton size="small" name="Edit BestPractice" onClick={(e)=> {e.preventDefault(); handleEditBestPracticeOpen(); }}><EditIcon /></IconButton>
                <IconButton size="small" name="Delete BestPractice" onClick={(e)=> {e.preventDefault(); handleDeleteBestPracticeOpen(); }}><DeleteIcon /></IconButton>
              </DataRowActions>
              )}
            </DataCell>
          </DataRow>
        </Link>
        <StandardDialog title={`Edit Best Practice: ${bestPractice.title}`} handleClose={handleEditBestPracticeClose} isOpen={editBestPracticeOpen}>
          <BestPracticeEditForm bestPractice={bestPractice} handleClose={handleEditBestPracticeClose} />
        </StandardDialog>
        <StandardDialog title={`Remove ${bestPractice.title} from topic ${props.topic.title}`} handleClose={handleDeleteBestPracticeClose} isOpen={deleteBestPracticeOpen}>
          <Typography>Are you sure you want to remove {bestPractice.title} from topic {props.topic.title}?</Typography>
          <StandardDialogActions component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
          <Button variant="outlined" onClick={() => handleDeleteBestPracticeClose()}>Cancel</Button>
            <Button type="submit"variant="contained" color="error">Remove</Button>
          </StandardDialogActions>
        </StandardDialog>
      </>
    );
  };

  return (
    <>
      { props.moveItem && props.canEdit ?
        <DraggableItem
            key={props.idx}
            index={props.idx}
            id={props.bestPractice.id}
            moveItem={props.moveItem}
            dropItem={props.dropItem}
          >
          {renderRow()}
        </DraggableItem>
        :
        <>{renderRow()}</>
      }
    </>
  );
}