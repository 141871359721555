import React, { useState, useEffect } from 'react';
import { DataRow, DataCell, DataRowActions } from '../Tables/DataList';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';

import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { QuestionnaireEditForm } from './QuestionnaireForm';
import { questionnaireTypes } from '../../services/questionnaires';

import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { AccordionCollapse, AccordionCollapseSummary } from '../Layout/Collapsible';
import AccordionDetails from '@mui/material/AccordionDetails';
import { versionQuestionnaire, promoteQuestionnaire, deleteQuestionnaire } from '../../services/questionnaires';

function QuestionnaireListItem(props: { canEdit?: boolean, questionnaire: Questionnaire, idx:number, refresh?:any, isChild: boolean, parentQuestionnaire?: any }) {
  const { questionnaire: questionnaireResult } = useAppSelector(state => state.questionnaires);
  const [questionnaire, setQuestionnaire] = useState(props.questionnaire);
  const [editQuestionnaireOpen, setEditQuestionnaireOpen] = useState(false);
  const handleEditQuestionnaireOpen = () => setEditQuestionnaireOpen(true);
  const handleEditQuestionnaireClose = (newQuestionnaire:Questionnaire) =>{
    if (newQuestionnaire) setQuestionnaire(newQuestionnaire);
    setEditQuestionnaireOpen(false);
  };

  const [versionQuestionnaireOpen, setVersionQuestionnaireOpen] = useState(false);
  const handleVersionQuestionnaireOpen = () => setVersionQuestionnaireOpen(true);
  const handleVersionQuestionnaireClose = () => setVersionQuestionnaireOpen(false);

  const [promoteQuestionnaireOpen, setPromoteQuestionnaireOpen] = useState(false);
  const handlePromoteQuestionnaireOpen = () => setPromoteQuestionnaireOpen(true);
  const handlePromoteQuestionnaireClose = () => setPromoteQuestionnaireOpen(false);

  const [deleteQuestionnaireOpen, setDeleteQuestionnaireOpen] = useState(false);
  const handleDeleteQuestionnaireOpen = () => setDeleteQuestionnaireOpen(true);
  const handleDeleteQuestionnaireClose = () => setDeleteQuestionnaireOpen(false);

  const [duplicationInProgress, setDuplicationInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  useEffect(() => {
    if (questionnaireResult && questionnaireResult.id === props.questionnaire.id) setQuestionnaire(questionnaireResult);
  }, [questionnaireResult]);

  useEffect(() => {
    setQuestionnaire(props.questionnaire);
  }, [props.questionnaire]);

  const getTypeDisplay = (questionnaireObj: Questionnaire) => {
    if (!questionnaireObj.type) return '-';
    const qtype = questionnaireTypes.find(type => type.value === questionnaireObj.type);
    return qtype ? qtype.label : '-';
  };

  const handleVersionQuestionnaire = (questionnaireId: number) => {
    setDuplicationInProgress(true);
    versionQuestionnaire(questionnaireId).then(() => {
      setDuplicationInProgress(false);
      props.refresh();
      handleVersionQuestionnaireClose();
    }).catch((error: any) => {
      console.error(error);
      setDuplicationInProgress(false);
    });
  };

  const handlePromoteQuestionnaire = (oldParent: any, newParent: number) => {
    if (!oldParent) return;
    promoteQuestionnaire(oldParent, newParent).then(() => {
      props.refresh();
      handlePromoteQuestionnaireClose();
    });
  };

  const handleDeleteQuestionnaire = (questionnaireSlug: string) => {
    setDeleteInProgress(true);
    deleteQuestionnaire(questionnaireSlug).then(() => {
      setDeleteInProgress(false);
      props.refresh();
      handleDeleteQuestionnaireClose();
    }).catch((error: any) => {
      console.error(error);
      setDeleteInProgress(false);
    });
  };

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Link to={questionnaire.slug} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 87%)' }}>
        <DataRow hover>
          <DataCell xs={1} >v{questionnaire.version}</DataCell>
          <DataCell xs={3}>{questionnaire.title}</DataCell>
          <DataCell xs={3}><Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={questionnaire.description}>{questionnaire.description}</Box></DataCell>
          <DataCell xs={1}><Checkbox checked={questionnaire.isPublished} disabled/></DataCell>
          <DataCell xs={2}>{getTypeDisplay(questionnaire)}</DataCell>
          <DataCell xs={2}>
            {questionnaire.domains.length}
            { props.canEdit && ( <DataRowActions>
              <IconButton size="small" name="Edit Questionnaire" onClick={(e) => {e.preventDefault(); handleEditQuestionnaireOpen();}}><EditIcon /></IconButton>
              {props.isChild ?
                <IconButton size="small" name="Promote Questionnaire" onClick={(e)=> {e.preventDefault(); handlePromoteQuestionnaireOpen();}}><UpgradeOutlinedIcon /></IconButton> :
                <IconButton size="small" name="Version Questionnaire" onClick={(e) => {e.preventDefault(); handleVersionQuestionnaireOpen();}}><CopyAllOutlinedIcon /></IconButton>
              }
              <IconButton size="small" name="Delete Questionnaire" onClick={(e)=> {e.preventDefault(); handleDeleteQuestionnaireOpen();}}><DeleteIcon /></IconButton>
            </DataRowActions> )}
          </DataCell>
        </DataRow>
      </Link>
      <StandardDialog title={`Edit Questionnaire: ${questionnaire.title}`} handleClose={handleEditQuestionnaireClose} isOpen={editQuestionnaireOpen}>
        <QuestionnaireEditForm questionnaire={questionnaire} handleClose={handleEditQuestionnaireClose} refresh={props.refresh} />
      </StandardDialog>
      <StandardDialog title={`Version ${questionnaire.title} Questionnaire`} handleClose={handleVersionQuestionnaireClose} isOpen={versionQuestionnaireOpen}>
        <Typography variant='body1'>
          Versioning a questionnaire will duplicate all Domains, Topics, Best Practices, and Questions, including all configuration options.
        </Typography>
        <Typography variant='body1' mt={2} mb={2}>
          You will have the opportunity to promote this versioned questionnaire to the default for its type after duplication & review.
        </Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleVersionQuestionnaireClose()}>Close</Button>
          <LoadingButton
            variant='contained'
            onClick={() => handleVersionQuestionnaire(questionnaire.id)}
            loading={duplicationInProgress}
            disabled={duplicationInProgress}
          >Create Version</LoadingButton>
        </StandardDialogActions>
        {duplicationInProgress && <Typography variant='body2'>Questionnaire duplication can be a lengthy process. You may close this dialog at anytime.</Typography> }
      </StandardDialog>
      <StandardDialog title={`Promote ${questionnaire.title} Questionnaire`} handleClose={handlePromoteQuestionnaireClose} isOpen={promoteQuestionnaireOpen}>
        <Typography variant='body1' mb={2}>
          Promoting a questionnaire will change the state of a child version to that of its parent. The parent questionnaire, from where the child is derived, will be demoted to a child version of this questionnaire.
        </Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handlePromoteQuestionnaireClose()}>Close</Button>
          <Button variant='contained' onClick={() => handlePromoteQuestionnaire(props.parentQuestionnaire?.id, questionnaire.id)}>Promote Version</Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog title={`Delete ${questionnaire.title} Questionnaire. v${questionnaire.version}`} handleClose={handleDeleteQuestionnaireClose} isOpen={deleteQuestionnaireOpen}>
        <Typography variant='h5' mb={2}>
          Warning You Are About To Permanently Delete This Questionnaire
        </Typography>
        <Typography variant='body1' mb={2}>
          Deleting this questionnaire will remove all related Domains, Topics, Best Practices, and Questions.
        </Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleDeleteQuestionnaireClose()}>Close</Button>
          <LoadingButton
            variant='contained'
            onClick={() => handleDeleteQuestionnaire(questionnaire.slug)}
            loading={deleteInProgress}
            disabled={deleteInProgress}
          >Delete</LoadingButton>
        </StandardDialogActions>
      </StandardDialog>
      {questionnaire.versions?.length ? <AccordionCollapse expanded={expanded} onChange={() => {setExpanded(!expanded);}}>
        <AccordionCollapseSummary
          name={`${questionnaire.id}-child-versions`}
          title={`${questionnaire.title} Child Versions`}
        />
        <AccordionDetails>
          <DataRow header>
            <DataCell xs={1}>Version</DataCell>
            <DataCell xs={3}>Title</DataCell>
            <DataCell xs={3}>Description</DataCell>
            <DataCell xs={1}>Published</DataCell>
            <DataCell xs={2}>Type</DataCell>
            <DataCell xs={2}>Domains</DataCell>
          </DataRow>
          {questionnaire.versions?.map((childQuestionnaire: Questionnaire, index: number) => <QuestionnaireListItem canEdit={props.canEdit} key={index} parentQuestionnaire={questionnaire} questionnaire={childQuestionnaire} idx={index} isChild={true} refresh={props.refresh}/>)}
        </AccordionDetails>
      </AccordionCollapse> : <></>}
    </>
  );
}

QuestionnaireListItem.defaultProps = {
  isChild: false,
};

export default QuestionnaireListItem;