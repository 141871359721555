import React, { useEffect } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getHelpURL, getUserFromAuthSession } from '../../services/user';
import { updateUser } from '../../app/store/userSlice';
import { useNavigate } from 'react-router-dom';
import { HelpOutline } from '@mui/icons-material';
import { logoutUser } from '../../app/store/userSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';

const formStyles = {
  root: {
    marginTop: '3em',
    fontWeight: '100',
    display: 'flex',
    justifyContent: 'center',
  },
  circle: {
    display: 'flex',
    borderRadius: '50%',
    width: '125px',
    height: '125px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBar: {
    background: '#2F2D4F',
    textAlign: 'center',
    padding: '1em 2em',
    position: 'relative',
  },
  headerBarBG: {
    position: 'absolute',
    left: '-429px',
    top: '-546px',
    width: '716px',
    height: '823px',
    background: 'radial-gradient(42.78% 42.78% at 50% 57.22%, #8AA2C5 5.06%, rgba(100, 140, 200, 0.68) 43.23%, rgba(146, 168, 200, 0) 100%)',
    opacity: '0.5',
  },
};

export default function WaitingForApproval() {
  const { user } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const handleLogout = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const userPolling = setInterval(function () {
      getUserFromAuthSession().then(responseUser => {
        if (responseUser.companies.length > 0 || responseUser.pendingCompanies.length === 0) {
          dispatch(updateUser(responseUser));
          clearInterval(userPolling);
          if (responseUser.companies.length > 0) {
            navigate('/profile');
            return;
          }
          if (responseUser.pendingCompanies.length === 0) {
            navigate('/company-onboarding');
            return;
          }
        }
      });
    }, 5000);

    return (() => {
      clearInterval(userPolling);
    });
  }, []);

  return (
    <Box sx={{ backgroundColor: '#F3F5FB', minHeight: '100vh', pb: 2 }}>
      <Box sx={{ ...formStyles.headerBar }}><Box sx={{ ...formStyles.headerBarBG }}></Box><Box component='img' sx={{ width: '500px', maxWidth: '80%' }} src={`${process.env.PUBLIC_URL}/assets/TPN_logo.png`} alt='TPN Logo' /></Box>
      <Grid sx={formStyles.root} spacing={0} container>
        <Box sx={{ maxWidth: '900px', margin: '2em!important', p: 2 }}>
          <Typography variant='h5' component='h5'>Your request has been submitted to an admin for {user && user.pendingCompanies.length > 0 ? user.pendingCompanies[0].name : 'your company'}. You will be granted access upon approval.</Typography>
          <Box sx={{ display: 'flex', gap: '1em', justifyContent: 'center' }}>
            {!user && <Typography>Having trouble? Email <a href="mailto:support@ttpn.org" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>support@ttpn.org</a> for assistance.</Typography>}
            {user && <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer"><Button variant='contained' onClick={()=> { return false; }}><HelpOutline sx={{ mr: 1 }} />Need Support?</Button></a> }
            {user && <Button onClick={() => handleLogout()}>Sign Out</Button>}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}