import axios from 'axios';

import getAuthSession from './auth';
import { CompanyCertification, CompanyCertificationSubmitData } from '../interfaces/users.interface';

export default async function createCompanyCertification(companyCertificationData: CompanyCertificationSubmitData) {
  const authSession = await getAuthSession();
  const preResponse = await axios.post(
    `${process.env.REACT_APP_BASE_API}/company-certifications/`,
    { ...companyCertificationData },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  const newCompanyCert = { ...preResponse.data, certification: preResponse.data.certification.id, applicationVersions: companyCertificationData.applicationVersions, sites: companyCertificationData.sites };
  delete newCompanyCert.document;
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-certifications/${preResponse.data.id}/`,
    newCompanyCert,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as CompanyCertification;
}

export async function deleteCompanyCertification(companyCertification: CompanyCertification) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/company-certifications/${companyCertification.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function updateCompanyCertificationApproval(companyCertificationId: number, approved: boolean) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-certifications/${companyCertificationId}/`,
    {
      adminApproved: approved,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function updateCompanyCertificatioReviewed(companyCertificationId: number, reviewed: boolean) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-certifications/${companyCertificationId}/`,
    {
      adminReviewed: reviewed,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function getCompanyCertifications(companyId: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/company-certifications/?company=${companyId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results;
}

export async function getAllCompanyCertifications() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/company-certifications/?limit=99999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results;
}