import getAuthSession from './auth';
import axios from 'axios';

import { DocusignWaiver } from '../interfaces/users.interface';

export async function fetchWaiver(waiverId: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/docusign-waivers/${waiverId}/`,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return (response.data) as DocusignWaiver;
}

export async function createWaiver(waiver: DocusignWaiver) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/docusign-waivers/`,
    {
      ...waiver,
    },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return (response.data) as DocusignWaiver;
}