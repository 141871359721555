import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { ListSubheader } from '@mui/material';
import { Version } from '../../../interfaces/users.interface';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedServices: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selectedServices.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type MultipleSelectChipProps = { 
  options: any[];
  isApplication?: boolean;
  optionsCallback: any;
  selectedOptions: Array<any>;
  inputLabel: string;
  optionkey?: string;
  getOption?: any;
  disabled?:boolean;
  size?: 'small' | 'medium';
  margin?: string;
  sx?: any;
  selectionsLimit?: number;
};

const MultipleSelectChip = (props: MultipleSelectChipProps) => {
  const theme = useTheme();
  const {
    options,
    optionsCallback,
    selectedOptions,
    inputLabel,
    optionkey,
    getOption,
    disabled,
    size,
    margin,
    sx,
    isApplication,
    selectionsLimit,
  } = props;

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;
    optionsCallback(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getOptionName(optionId: string) {
    const curOption = options.find(option => option.id === parseInt(optionId));
    if (!curOption) return;
    if (isApplication) {
      const app = options.find(opt => 'versions' in opt && (opt.versions as Version[]).find(v => v.id === curOption.id));
      if (!app) return;
      return app.name + ' ' + curOption.value;
    }
    if (getOption) return getOption(curOption);
    return optionkey ? curOption[optionkey].name : curOption.name;
  }

  const handleDelete = (value: string) => {
    optionsCallback(selectedOptions.filter(service => service !== value));
  };

  return (
    <div>
      <FormControl sx={{ margin: margin ? margin : '.5rem 0 0 0' }} fullWidth>
        <InputLabel size={size === 'medium' ? 'normal' : size} id={inputLabel}>{inputLabel}</InputLabel>
        <Select
          labelId={inputLabel}
          id={inputLabel}
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput id={`select-multiple-${inputLabel}-chip`} label={inputLabel} />}
          disabled={disabled ? disabled : false}
          size={size}
          sx={sx ? { ...sx } : {}}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin: '-8px 0', transform: 'translateY(4px)' }}>
              {selected.map((value) => (
                <Chip key={value} label={getOptionName(value)} variant='outlined' onDelete={() => handleDelete(value)} onMouseDown={ (e) => e.stopPropagation() } />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props.options.map((option: any, idx) => {
            if (!isApplication) return <MenuItem
                key={option.id}
                value={option.id}
                disabled={selectionsLimit ? selectedOptions.length >= selectionsLimit : false}
                style={getStyles(option.name, selectedOptions, theme)}
              >
                {getOptionName(option.id)}
              </MenuItem>;
            if (isApplication && option.name) return <ListSubheader key={`${idx}_${option.id}`} sx={{ borderBottom: '1px solid rgba(100,100,100,.2)', background: 'rgba(100,100,100,.1)', position: 'relative' }}>{option.name}</ListSubheader>;
            if (isApplication && option.value) return <MenuItem
                key={`${idx}_${option.id}`}
                value={option.id}
                disabled={selectionsLimit ? selectedOptions.length >= selectionsLimit : false}
                style={getStyles(option.name, selectedOptions, theme)}
                sx={{ pl: 4 }}
              >
                {option.value}
              </MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default MultipleSelectChip;