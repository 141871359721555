import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Divider, Skeleton } from '@mui/material';
import { Paper, Avatar, Tooltip } from '@mui/material';
import { widgetStyles, blue, gold } from './widgetStyles';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { AssessmentSurveyMeta } from '../../../interfaces/metaData.interface';

interface Counts {
  inProgress: number;
  completed: number;
}

interface CountObject extends Counts {
  total: number | string
}

function LinearProgressWithLabel(props: LinearProgressProps & { countObject: CountObject, fontColor: string, title: string, mb?: number, labels?: { first: string | number, second: string | number } }) {
  const styles = widgetStyles();
  const styleObject:any = {
    width: typeof props.countObject.total === 'string' ? '100%' : ((props.countObject.completed + props.countObject.inProgress) / props.countObject.total * 100) + '%',
  };
  if (typeof props.countObject.total === 'string') styleObject.height = '25px';
  if (props.mb) styleObject.mb = props.mb + 'rem';
  return (<>
    <Tooltip title={`${props.countObject.completed + props.countObject.inProgress} total ${props.title}`}><Typography variant="h5" sx={{ display: 'flex', gap: 2, mb: '.5rem', alignItems: 'baseline' }}>
      <div>{props.title}</div>
      <Avatar sx={{ backgroundColor: props.title.toLowerCase().indexOf('gold') > -1 ? gold : blue,
        color: props.fontColor,
        width: props.countObject.completed + props.countObject.inProgress > 99 ? props.countObject.completed + props.countObject.inProgress > 999 ? 50 : 40 : 30,
        height: props.countObject.completed + props.countObject.inProgress > 99 ? props.countObject.completed + props.countObject.inProgress > 999 ? 50 : 40 : 30,
      }}>{props.countObject.completed + props.countObject.inProgress}</Avatar>
    </Typography></Tooltip>
    <Box className={styles.labelProgressBar} sx={styleObject}>
      <Box sx={{ width: '100%', height: '100%', mr: 1, position: 'relative' }}>
        <LinearProgress value={props.countObject.inProgress / (props.countObject.completed + props.countObject.inProgress) * 100} variant="determinate" {...props} />
        {props.labels && props.labels.first != 0 && <Tooltip title={`${props.labels.first} ${props.title} In Progress`}><Typography variant='h5' className={styles.labelProgressBarLabels} sx={{
          left: 0,
          width: (props.countObject.inProgress / (props.countObject.completed + props.countObject.inProgress) * 100) + '%',
          color: props.fontColor,
        }}>{props.labels.first}</Typography></Tooltip>}
        {props.labels && props.labels.second != 0 && <Tooltip title={`${props.labels.second} ${props.title} Completed`}><Typography variant='h5' className={styles.labelProgressBarLabels} sx={{
          right: 0,
          width: (100 - (props.countObject.inProgress / (props.countObject.completed + props.countObject.inProgress) * 100)) + '%',
          color: props.fontColor,
        }}>{props.labels.second}</Typography></Tooltip>}
      </Box>
    </Box>
  </>);
}

export default function SitesApplicationsOverviewWidget(props: {  assessmentSurveyMeta?: AssessmentSurveyMeta, type: 'site' | 'application' }) {
  const { type } = props;
  const styles = widgetStyles();
  const [ assessmentSurveyMeta, setAssessmentSurveyMeta ] = useState<AssessmentSurveyMeta | undefined>(undefined);
  const boxItem = useRef();

  const counts = !assessmentSurveyMeta ? undefined : 
    {
      blue: {
        inProgress: type === 'site' ? assessmentSurveyMeta.sites.siteSurveysTotalCount - assessmentSurveyMeta.sites.siteSurveysCompleteCount : assessmentSurveyMeta.applications.applicationSurveysTotalCount - assessmentSurveyMeta.applications.applicationSurveysCompleteCount,
        completed: type === 'site' ? assessmentSurveyMeta.sites.siteSurveysCompleteCount : assessmentSurveyMeta.applications.applicationSurveysCompleteCount,
      },
      gold: {
        inProgress: type === 'site' ? assessmentSurveyMeta.goldSites.siteAssessmentsTotalCount - assessmentSurveyMeta.goldSites.siteAssessmentSurveysCompleted : assessmentSurveyMeta.goldApplications.applicationAssessmentsTotalCount - assessmentSurveyMeta.goldApplications.applicationAssessmentSurveysCompleted,
        completed: type === 'site' ? assessmentSurveyMeta.goldSites.siteAssessmentSurveysCompleted : assessmentSurveyMeta.goldApplications.applicationAssessmentSurveysCompleted,
      },
    };

  const domain = !counts ? undefined : counts.blue.inProgress + counts.blue.completed > counts.gold.inProgress + counts.gold.completed ?
    counts.blue.inProgress + counts.blue.completed :
    counts.gold.inProgress + counts.gold.completed * 1;

  useEffect(() => {
    setAssessmentSurveyMeta(props.assessmentSurveyMeta);
  }, [props.assessmentSurveyMeta]);

  return (
    <Paper className={styles.widget}>
      <Box className={styles.widgetHeader} ref={boxItem}>
        <Typography variant='caption'>Last 90 days</Typography>
        <Typography variant='h4' fontSize='1.8rem' color={blue}>{props.type === 'site' ? "Site's" : "Application's"} Status</Typography>
        <Divider />
      </Box>
      {!counts && 
        <Box className={`${styles.skeletonContainer}`}>
          <div />
          <Skeleton width='100%' height='75px' animation="wave" />
          <Skeleton width='100%' height='75px' animation="wave" />
          <div />
        </Box>
      }
      {counts && 
        <Box className={`${styles.skeletonContainer}`}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <LinearProgressWithLabel
              title='TPN MPA Best Practices'
              className={styles.progressBarBlue}
              countObject={{ ...counts.blue, total: domain || 1 }}
              labels={{ first: counts.blue.inProgress, second: counts.blue.completed }}
              mb={2}
              fontColor='white'
            />
            <LinearProgressWithLabel
              title='Gold Assessments'
              className={styles.progressBarGold}
              countObject={{ ...counts.gold, total: domain || 1 }}
              labels={{ first: counts.gold.inProgress, second: counts.gold.completed }}
              fontColor='black'
            />
          </Box>
        </Box>
      }
    </Paper>
  );
}