import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { ServiceCategory } from '../../interfaces/vendor.interface';
import { ApplicationType, ApplicationVersion } from '../../interfaces/users.interface';
import { File } from '../../interfaces/questionAnswer.interface';

interface InitialServiceCategoryState {
  serviceCategories: ServiceCategory[];
  applicationTypes: ApplicationType[];
  documents: File[];
  applicationVersions: ApplicationVersion[];
  fetchingApplicationTypes: boolean;
}

const initialState = {
  serviceCategories: [],
  applicationTypes: [],
  documents: [],
  applicationVersions: [],
  fetchingApplicationTypes: false,
} as InitialServiceCategoryState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAllServiceCategories = createAsyncThunk(
  'serviceCategories/fetchAllServiceCategories',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/service-categories/?limit=99999`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as ServiceCategory[];
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchAllApplicationTypes = createAsyncThunk(
  'serviceCategories/fetchAllApplicationTypes',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/application-types/?limit=9999`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as ApplicationType[];
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchDocument = createAsyncThunk(
  'serviceCategories/fetchDocument',
  async (id:number) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/files/${id}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as File;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchApplicationVersion = createAsyncThunk(
  'serviceCategories/fetchApplicationVersion',
  async (id:number) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/application-versions/${id}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as ApplicationVersion;
    } catch (error: any) {
      return error;
    }
  },
);

const servicecategorySlice = createSlice({
  name: 'servicecategory',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAllServiceCategories.fulfilled, (state, action: PayloadAction<ServiceCategory[]>) => {
      state.serviceCategories = action.payload.sort((a, b) => a.name.localeCompare(b.name));
    });
    builder.addCase(fetchAllApplicationTypes.fulfilled, (state, action: PayloadAction<ApplicationType[]>) => {
      state.applicationTypes = action.payload.sort((a, b) => a.type.localeCompare(b.type));
      state.fetchingApplicationTypes = true;
    });
    builder.addCase(fetchAllApplicationTypes.pending, (state) => {
      state.fetchingApplicationTypes = false;
    });
    builder.addCase(fetchAllApplicationTypes.rejected, (state) => {
      state.fetchingApplicationTypes = false;
    });
    builder.addCase(fetchDocument.fulfilled, (state, action: PayloadAction<File>) => {
      const newDocs = [ ...state.documents ];
      newDocs.push(action.payload);
      state.documents = newDocs;
    });
    builder.addCase(fetchApplicationVersion.fulfilled, (state, action: PayloadAction<ApplicationVersion>) => {
      const newAppVer = [ ...state.applicationVersions ];
      newAppVer.push(action.payload);
      state.applicationVersions = newAppVer;
    });
  },
});

export default servicecategorySlice.reducer;