import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Topic } from '../../interfaces/topic.interface';
import { Domain } from '../../interfaces/domain.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateDomain } from '../../app/store/domainSlice';
import { updateTopic, createTopic, resetTopic } from '../../app/store/topicSlice';
import Grid from '@mui/material/Grid';
import { StandardDialogActions } from '../Modals/StandardDialog';

export default function TopicForm(props: { domain: Domain, handleClose: any }) {
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful, errors },
    reset,
    setError,
    clearErrors,
  } = useForm();
  const { topic: topicResult, error, targetDomainId: targetDomainId } = useAppSelector(state => state.topics);
  const [domain, setDomain] = useState(props.domain);
  const dispatch = useAppDispatch();
  const onSubmit = (data: FieldValues) => {
    const newTopic = { title: data.title, description: data.description, domain: data.targetDomainId, questionnaire: typeof domain.questionnaire === 'number' ? domain.questionnaire : domain.questionnaire.id };
    dispatch(createTopic(newTopic));
  };

  useEffect(() => {
    if (topicResult && isSubmitSuccessful) {
      reset({ title: '', description: '' });
      const { topics } = domain;
      const topicsIdsArr = topics.map((topic) => topic.id);
      topicsIdsArr.push(topicResult.id);
      dispatch(updateDomain({ ...domain, topics: topicsIdsArr, questionnaire: typeof domain.questionnaire === 'number' ? domain.questionnaire : domain.questionnaire.id }));
      dispatch(resetTopic());
      clearErrors();
      props.handleClose();
    }
    setDomain(props.domain);
  }, [formState, topicResult, props.domain]);

  useEffect(() => {
    if (error?.title && targetDomainId == domain.id) {
      setError('title', { type: 'custom', message: error.title[0] });
    }
  }, [error]);
  return (
    <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            error={!!errors.title}
            helperText={!!errors.title && errors.title.message}
            required
            id='title'
            label='Topic Title'
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='description'
            label='Description'
            rows={3}
            multiline
            sx={{ width: '100%' }}
            fullWidth
            {...register('description')}
          />
          <TextField type='hidden' hidden id='targetDomainId' {...register('targetDomainId')} value={props.domain.id} sx={{ display: 'none' }}/>
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}

export function TopicEditForm(props: { topic: Topic, handleClose: any }) {
  let [topic] = useState(props.topic);
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: { title: topic.title, description: topic.description },
  });
  const dispatch = useAppDispatch();
  const [newTopic, setNewTopic] = useState(props.topic);
  const onSubmit = async (data: FieldValues) => {
    data.id = topic.id;
    const { bestPractices } = topic;
    const bestPracticesIdsArr = bestPractices.map((bestPractice) => bestPractice.id);
    setNewTopic( { ...topic, ...data });

    const itemTopic: Topic = { ...topic, ...data, bestPractices: bestPracticesIdsArr };
    if (topic.questionnaire) itemTopic.questionnaire = typeof topic.questionnaire === 'number' ? topic.questionnaire : topic.questionnaire.id;
    if (topic.domain) itemTopic.domain = typeof topic.domain === 'number' ? topic.domain : topic.domain.id;

    dispatch(updateTopic(itemTopic));
  };
  const { error } = useAppSelector(state => state.topics);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.handleClose(newTopic);
    }
  }, [formState]);

  return (
    <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ width: '100%' }} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="title"
            label="Topic Title"
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            error={!!error?.title}
            helperText={!!error?.title && error.title[0]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            label="Description"
            multiline
            rows={3}
            sx={{ width: '100%' }}
            {...register('description')}
            error={!!error?.description}
            helperText={!!error?.description && error.description[0]}
          />
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}