import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ReportOption } from '../../interfaces/reports.interface';
import fetchReportOptions from '../../services/reports';
import { useAppSelector } from '../../app/hooks';

interface ReportSelectDropdownProps {
  onSelect: (reportOption: ReportOption) => void;
  selectedReportName: string | null;
  setSelectedReportName: (name: string | null) => void;
}

const ReportSelectDropdown: React.FC<ReportSelectDropdownProps> = ({
  onSelect,
  selectedReportName,
  setSelectedReportName,
}) => {
  const [reportOptions, setReportOptions] = useState<ReportOption[]>([]);
  const { user } = useAppSelector(state => state.user);

  useEffect(() => {
    if (reportOptions.length) return;
    fetchReportOptions(user).then((reportOptionsResponse: ReportOption[]) => setReportOptions(reportOptionsResponse));
  }, [reportOptions.length, user]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selected = reportOptions.find((option) => String(option.reportKey) === event.target.value);
    if (selected) {
      setSelectedReportName(selected.reportName);
      onSelect(selected);
    }
  };

  return (
    <FormControl variant='standard' sx={{ minWidth: 160 }}>
      <InputLabel id='download-report-label'>TPN+ Metrics Reports</InputLabel>
      <Select
        labelId='download-report-label'
        value={selectedReportName || ''}
        onChange={handleChange}
        label='TPN+ Metrics Reports'
        displayEmpty
        renderValue={() =>
          selectedReportName && selectedReportName.length > 45
            ? `${selectedReportName.substring(0, 45)}...`
            : selectedReportName || 'TPN+ Metrics Reports'
        }
      >
        {reportOptions.map((reportOption) => (
          <MenuItem key={reportOption.reportKey} value={String(reportOption.reportKey)}>
            {reportOption.reportName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportSelectDropdown;