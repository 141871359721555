import React, { useEffect } from 'react';
import { Button, Grid, Stack, Typography, Alert } from '@mui/material';
import { Application, ApplicationMeta, Version, Company, CompanyApplication, CompanyApplicationSubmitData } from '../../../../interfaces/users.interface';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import MultipleSelectChip from '../../../Vendors/Forms/MultiSelect';
import { useMemo, useState } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import createCompanyApplication, { updateCompanyApplicationSimple } from '../../../../services/companyApplication';
import { useAppSelector } from '../../../../app/hooks';

type SelectSitesAndServicesProps = {
  newApplication?: Application;
  companyApplication?: CompanyApplication;
  version?: Version;
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  back?: boolean;
  callBack?: any;
};

export default function SelectSitesAndServices(props: SelectSitesAndServicesProps) {
  const { newApplication, companyApplication, isOpen, handleClose, company, back, callBack, version } = props;
  const { companies } = useAppSelector(state => state.companyList);
  const [selectedServices, setSelectedServices] = useState<Array<number>>(companyApplication ? companyApplication.services.map(service => typeof service === 'number' ? service : service.id as number) : []);
  const [selectedSites, setSelectedSites] = useState<Array<number>>(companyApplication ? companyApplication.sites.map(site => typeof site === 'number' ? site : site.id as number) : []);
  const [formError, setFormError] = useState(false);
  const services = useMemo(() => company.services, [company]);
  const application = useMemo(() => companyApplication?.application || newApplication, [newApplication, companyApplication]);
  const title = useMemo(() => {
    if (!application) return;
    let companyName = undefined;
    if (application.company && typeof application.company !== 'number') companyName = application.company.name;
    if (application.company && typeof application.company === 'number') companyName = companies.find(co => co.id === application.company)?.name;
    if (!application.company && application.meta && !!application.meta.length) companyName = (application.meta[0] as ApplicationMeta).title;

    return `Sites and Services for ${companyName ? companyName : ''} ${application.name} ${version ? version.value : ''}`;
  }, [application, companies]);

  const saveApplication = async () => {
    setFormError(false);
    if (selectedSites.length === 0 || selectedServices.length === 0) return setFormError(true);
    let updatedCompany = undefined;
    let newCA = undefined;
    if (newApplication) {
      const companyAppData: CompanyApplicationSubmitData = {
        company: company.id!,
        application: newApplication.id as number,
        sites: selectedSites,
        services: selectedServices,
        versions: props.version ? [props.version.id!] : undefined,
      };
      newCA = await createCompanyApplication(companyAppData);
    } else if (companyApplication) {
      newCA = await updateCompanyApplicationSimple(companyApplication.id!, {
        sites: selectedSites,
        services: selectedServices,
      });
    }
    if (!newCA) return;
    let companyApps = company.companyApplications ? company.companyApplications : [];
    const caIndex = companyApplication ? companyApps.findIndex(ca => ca.id === companyApplication.id) : -1;
    if (caIndex > -1) {
      companyApps[caIndex] = newCA;
    } else {
      companyApps = [...companyApps, newCA];
    }
    updatedCompany = {
      ...company,
      companyApplications: companyApps,
    };
    if (!updatedCompany) return;
    callBack(updatedCompany);
    handleClose();
  };

  useEffect(() => {
    setSelectedServices(companyApplication ? companyApplication.services.map(service => typeof service === 'number' ? service : service.id as number) : []);
    setSelectedSites(companyApplication ? companyApplication.sites.map(site => typeof site === 'number' ? site : site.id as number) : []);
  }, [newApplication, companyApplication]);

  return (
    <StandardDialog title={title} isOpen={isOpen} handleClose={() => {handleClose(); setFormError(false);}}>
      {application && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography>Indicate which Site locations operate or host this application. (i.e. do not include cloud instances)</Typography>
            <MultipleSelectChip options={company.locations} optionsCallback={setSelectedSites} selectedOptions={selectedSites} inputLabel='Sites*' />
            <MultipleSelectChip options={services} optionsCallback={setSelectedServices} selectedOptions={selectedServices} inputLabel='Services*' />
          </Stack>
        </Grid>
      </Grid>}
      <Grid container spacing={2}>
        {formError && <Grid item xs={12}>
          <Alert severity='error'>You must select and least one Site and Service.</Alert>
        </Grid>}
      </Grid>
      <StandardDialogActions>
        {back && <Button variant='outlined' onClick={() => {handleClose(); setFormError(false);}} startIcon={<ChevronLeft />}>Back</Button>}
        {!back && <Button variant='outlined' onClick={() => {handleClose(); setFormError(false);}}>Cancel</Button>}
        <Button variant='contained' onClick={() => saveApplication()}>Save Application</Button>
      </StandardDialogActions>
    </StandardDialog>
  );
}