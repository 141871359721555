import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Skeleton, Switch, TextField, Tooltip, Typography, SelectChangeEvent } from '@mui/material';
import { ApplicationReponse, ApplicationMeta, ApplicationType, Company } from '../../interfaces/users.interface';
import { getApplicationsFull } from '../../services/applications';
import { DataListContain, DataRow, DataCell, StrippedRows } from '../Tables/DataList';
import ScrollTopButton from '../../features/Directory/ScrollTopButton';
import Layout from '../Layout/Layout';
import { debounce } from 'lodash';
import ChipsWithOverflow from '../Layout/ChipsWithOverflow';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllApplicationTypes } from '../../app/store/serviceCategories';
import ApplicationListVersion from './ApplicationVersion';
import { Star } from '@mui/icons-material';
import FilterButtonIcon from '../Directory/FilterButtonIcon';
import useOutsideDirectory from '../Directory/UseOutsideDirectory';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function ApplicationDirectory() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const { applicationTypes, fetchingApplicationTypes } = useAppSelector(state => state.serviceCategories);
  const [applications, setApplications] = useState<ApplicationReponse | undefined>(undefined);
  const [company, setCompany] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [searchCompanyType, setSearchCompanyType] = useState('');
  const [applicationType, setApplicationType] = useState<string>('');
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasHardening, setHasHardening] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [count, setCount] = useState(0);
  const limit = 25;

  const observerRef = useRef<HTMLDivElement | null>(null);
  const [ hasMore, setHasMore] = useState(true);
 
  // Handle intersection observer logic
  const handleObserver = React.useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      setOffset(prevOffset => prevOffset + limit);
    }
  }, [hasMore]);
 
  useEffect(() => {
    // Set up the IntersectionObserver to observe the target element
    const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
    if (observerRef.current) observer.observe(observerRef.current);
 
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [handleObserver]);

  const appTypeRef = useRef<HTMLSelectElement | null>(null);
  const [openCompanyTypeSelect, setOpenCompanyTypeSelect] = useState(false);
  const closeAppType = React.useCallback(() => setOpenCompanyTypeSelect(false), []);
  const handleOpenAppType = React.useCallback(() => setOpenCompanyTypeSelect(true), []);
  useOutsideDirectory(appTypeRef, openCompanyTypeSelect, closeAppType);
  const fetchApplications = async (reset = false) => {
    const tmpApplications = !reset && applications ? applications.results : [];
    setApplications(undefined);
    const appList = await getApplicationsFull({
      limit,
      search,
      offset,
      applicationType : applicationType ? parseInt(applicationType) : undefined,
      application_company_name: company,
      has_hardening_documents: hasHardening ? hasHardening : undefined,
      has_company: hasCompany ? hasCompany : undefined,
      ...(user?.type === 'tpn_admin' ? {} : {
        is_public: true,
        admin_approved: true,
      }),
    });
    setHasMore(true);
    // Concatenate the new results with the old ones
    setApplications(
      {
        count: appList.count,
        results: 
        user?.type === 'tpn_admin'
          ? [
            ...tmpApplications,
            ...appList.results,
          ]
          : [ 
            ...tmpApplications,
            ...appList.results.filter(app =>
              !app.company || !(app.company as Company).isHidden,
            )],
      },
    );
    if (appList.results.length < limit) { // If less than limit we are are the end
      setHasMore(false);
    }
    setCount(appList.count);
  };

  useEffect(() => {
    if (offset !== 0) {
      fetchApplications();
    }
  }, [offset]);

  const clearAppType = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setApplicationType('');
  }, []);

  const clearCompanyNameSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCompany('');
    setSearchCompany('');
    setOffset(0);
  };

  const clearCompanyTypeSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSearchCompanyType('');
    setSearch('');
    setOffset(0);
  };

  const debounceSetSearch = useRef(debounce((value:string) => {
    setSearch(value);
    setOffset(0);
  }, 1000)).current;

  const debounceSetCompany = useRef(debounce((value:string) => {
    setCompany(value);
    setOffset(0);
  }, 1000)).current;

  const handleAppTypeChange = (e: SelectChangeEvent<string>) => {
    setOffset(0);
    setApplicationType(e.target.value);
  };

  const handleSearchCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchCompany(value);
    debounceSetCompany(value);
  };

  const handleSearchCompanyTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchCompanyType(value);
    debounceSetSearch(value);
  };

  useEffect(() => {
    fetchApplications();
    if (!applicationTypes.length && !fetchingApplicationTypes) dispatch(fetchAllApplicationTypes());
  }, []);

  useEffect(() => {
    setOffset(0);
  }, [hasHardening, search]);

  useEffect(() => {
    fetchApplications(true);
  }, [ search, applicationType, company, hasHardening, hasCompany ]);
  
  return (
    <Layout pageTitle="Applications">
      <Box sx={{ '@media (max-width: 1635px)': {
        pt: '3em',
      } }}
      >
        <DataListContain title='Applications'>
          <Box sx={{
            position: 'absolute',
            top: '-7em',
            right: 0,
            width: 'calc(100% - 130px)',
            flexWrap: 'wrap',
            display: 'flex',
            gap: 2,
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media (min-width: 1636px)': {
              top: '-3.5em',
            },
          }}>
            <FormControlLabel control={
              <Switch
                checked={hasCompany}
                onChange={() => { setOffset(0); setHasCompany(prev => !prev);}}
                inputProps={{ 'aria-label': 'controlled' }}

              />}
              label="TPN+ Member App"
            />
            <FormControlLabel control={
              <Switch
                checked={hasHardening}
                onChange={() => { setOffset(0); setHasHardening(prev => !prev);}}
                inputProps={{ 'aria-label': 'controlled' }}

              />}
              label="Has Hardening Guidelines"
            />
            <FormControl>
              <InputLabel size='small' id="application-type-select-label">App Types</InputLabel>
              <Select
                labelId="application-type-select-label"
                id="application-type-select"
                value={applicationType}
                ref={appTypeRef}
                onChange={handleAppTypeChange}
                onClick={() => setOpenCompanyTypeSelect(true)}
                label='App Types'
                size="small"
                open={openCompanyTypeSelect}
                sx={{ width: '130px', backgroundColor: '#fff' }}
                IconComponent={() => <FilterButtonIcon openOptions={handleOpenAppType} clearMethod={clearAppType} showClear={!!applicationType} /> }
              >
                {applicationTypes.length > 0 && applicationTypes.map(sc => (
                 <MenuItem key={sc.id} value={sc.id}>{sc.type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
               size='small'
               label='Search Company Name'
               inputProps={{ sx: { background: 'white' } }}
               onChange={handleSearchCompanyChange}
               value={searchCompany}
               InputProps={{
                 endAdornment: (
                 <InputAdornment position="end">
                 {searchCompany && (
                   <IconButton
                     aria-label="clear search"
                     onClick={clearCompanyNameSearch}
                     edge="end"
                     size="small"
                     sx={{ '& svg': { fontSize: '1rem' } }} 
                   >
                     <ClearIcon />
                   </IconButton>
                 )}
                 </InputAdornment>
                 ),
                 sx: { background: 'white', minWidth: '275px' },
               }}
               />
             <TextField
             size='small'
             label="Search Application Name"
             value={searchCompanyType}
             InputProps={{
               endAdornment: (
               <InputAdornment position="end">
               {searchCompanyType && (
                 <IconButton
                   aria-label="clear search"
                   onClick={clearCompanyTypeSearch}
                   edge="end"
                   size="small"
                   sx={{ '& svg': { fontSize: '1rem' } }} 
                 >
                   <ClearIcon />
                 </IconButton>
               )}
               </InputAdornment>
               ),
	               sx: { background: 'white', minWidth: '275px' },
             }}
             inputProps={{ sx: { background: 'white' } }}
             onChange={handleSearchCompanyTypeChange}
             />
          </Box>
          <Typography variant='caption' sx={{ display: 'flex', alignItems: 'center' }}>
             <Badge
               sx={{ marginLeft: '40px' }}
               anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'left',
               }}
               badgeContent={
                 <Star 
                   color='primary' 
                   sx={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: 'translate(-12px, 8px)'  }} 
                 />
               }          
             >
               This star denotes a TPN member Company
             </Badge>
           </Typography>
          <DataRow header>
            <DataCell xs={3}>Application</DataCell>
            <DataCell xs={2}>Company</DataCell>
            <DataCell xs={4}>Description</DataCell>
            <DataCell xs={3}>Type(s)</DataCell>
          </DataRow> 
          <DataRow>
               <DataCell xs={6}>
                 <Box
                   display="flex"
                   alignItems="center"
                   sx={{ fontWeight: '400', color: 'gray', textAlign: 'left' }}
                 >
                   Showing {count} results
                 </Box>
               </DataCell>
           </DataRow> 
          {!applications && [...Array(10)].map((e, i) =>
            <DataRow key={i}>
              <DataCell xs={3}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={2}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={4}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={3}><Skeleton width='100%' height='35px' /></DataCell>
            </DataRow>,
          )}
          <ScrollTopButton />
          <StrippedRows>
            {applications && applications.results.map(app =>
              <DataRow key={app.id}>
                <DataCell xs={3}><Tooltip title={app.name}><Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{app.name}</Typography></Tooltip></DataCell>
                <DataCell xs={2}>
                  {app.company && <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    sx={{ maxWidth: '100%' }}
                    badgeContent={(app.company as Company)?.isPublished ? <Star color='primary' sx={{ width: '20px', height: '20px', transform: 'translateX(-8px)' }} /> : <></>}
                  >
                    <Tooltip title={app.company ? (app.company as Company).name : ''}><Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{app.company ? (app.company as Company).name : ''}</Typography></Tooltip>
                  </Badge>}
                  {!app.company && <Tooltip title={`${app.meta?.length ? (app.meta as ApplicationMeta[])[0].title : ''}`}><Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{app.meta?.length ? (app.meta as ApplicationMeta[])[0].title : ''}</Typography></Tooltip>}</DataCell>
                <DataCell xs={4}><Tooltip title={app.description || '-'}><Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{app.description || '-'}</Typography></Tooltip></DataCell>
                <DataCell xs={3}>{!!app.applicationTypes?.length && ChipsWithOverflow((app.applicationTypes as ApplicationType[]).map(type => type.type))}</DataCell>
                <ApplicationListVersion application={app} />
              </DataRow>,
            )}
            <div ref={observerRef} style={{ height: '100px' }}>
              { (<Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
              </Box>)}
          </div>
          </StrippedRows>
        </DataListContain>
      </Box>
    </Layout>
  );
}