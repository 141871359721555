import React, { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import throttle from 'lodash/throttle';
import getAuthSession from '../../services/auth';
import {
  TextField,
  InputAdornment,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import axios, { AxiosResponse } from 'axios';
import { QuestionSearch } from '../../interfaces/question.interface';
import { CertificationSearch } from '../../interfaces/certification.interface';

const AutoCompleteSearch =  forwardRef((props: { onChangeHandler: any, api: string, label: string, renderOption?:any, filter?: any }, ref) => {
  const { onChangeHandler } = props;
  const inputRef = useRef<any>();
  const [itemList, setItemList] = useState<QuestionSearch[] | CertificationSearch[]>([]);
  const [JWToken, setJWToken] = useState('');
  const [acValue, setAcValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  // for clearing the value using ref
  // since value is not controlled by the parent
  useImperativeHandle(ref, () => ({
    clear() {
      setInputValue('');
      setAcValue(null);
    },
  }), []);

  useEffect(() => {
    getAuthSession().then((session) => {
      setJWToken(session.getIdToken().getJwtToken());
    });
  });

  const fetchItems =  (searchText: string) => {
    if (!searchText || !JWToken) return;
    try {
      axios.get(
        `${process.env.REACT_APP_BASE_API}/${props.api}/?search=${searchText}${props.filter?.questionnaireId ? '&questionnaire=' + props.filter.questionnaireId : ''}`,
        {
          headers: { 'Authorization': `Bearer ${JWToken}` },
        },
      ).then((response: AxiosResponse) => {
        setItemList( response.data.results);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const handleOnChange = (event: any, item: any | null) => {
    if (!item) return;
    setAcValue(item);
    onChangeHandler(item);
  };

  const throttledFetchItems = throttle(fetchItems, 2500, { leading: true, trailing: true });

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    await throttledFetchItems(newValue);
  };

  return (
    <div>
      <Autocomplete
        id={`${props.label}-autocomplete`}
        fullWidth
        options={itemList}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title == value.title}
        value={acValue}
        onChange={handleOnChange}
        ref={inputRef}
        clearIcon={true}
        renderOption={(properties, option) => 
          <li {...properties} key={option.title + (option.id ? option.id : '')} style={{ display: 'block' }}>
            {props.renderOption ? props.renderOption(option) : option.title}
          </li>}
        renderInput={(params) =>
          <TextField
          {...params}
          variant="outlined"
          fullWidth
          margin="normal"
          placeholder={`Search ${props.label}`}
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
               <InputAdornment position="end">
                 <SearchIcon />
               </InputAdornment>
            ),
          }}
        /> }
      />
    </div>
  );
});

AutoCompleteSearch.displayName = 'AutoCompleteSearch';
export default AutoCompleteSearch;
