import React, { useState, useEffect } from 'react';
import { Fab, Zoom, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
 
const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
 
  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };
 
  // Set up and clean up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
 
  return (
     <Tooltip title="Scroll to Top" arrow>
     <Zoom in={isVisible}>
       <Fab
         color="primary"
         size="small"
         onClick={scrollToTop}
         style={{
           position: 'fixed',
           bottom: '2rem',
           right: '2rem',
         }}
       >
         <KeyboardArrowUpIcon />
	       </Fab>
     </Zoom>
     </Tooltip>
  );
};
 
export default ScrollTopButton;