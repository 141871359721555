import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack } from '@mui/material';

export default function StandardDialog({ title, subtitle, handleClose, isOpen, children, hideBackdrop, cantClose, maxWidth = 'md' }: any) {
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth={true}
      onClick={(e)=> e.stopPropagation()}
      hideBackdrop={ hideBackdrop ? hideBackdrop : false }
      disableEscapeKeyDown={cantClose ? cantClose : false}
    >
      <Box sx={{ p:4, overflowY: 'scroll' }}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5'>{title}</Typography>
            {!cantClose && <CloseIcon sx={{ cursor: 'pointer' }} onClick={(e)=> {e.stopPropagation(); e.preventDefault(); handleClose();}}/>}
          </Box>
          {subtitle && <Typography variant="body2" sx={{ mt: 2 }} color="textSecondary">{subtitle}</Typography>}
        </Box>
        {children && <Divider sx={{ mb: 2 }} />}
        <Box
          ref={contentRef}
          tabIndex={-1}
          sx={{
            outline: 'none',
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  );
}

export function StandardDialogActions(props: { children: any, component?: React.ElementType, method?: string, onSubmit?: any }) {
  return  (
    <Box component={props.component ? props.component : 'div'} method={props.method ? props.method : undefined} onSubmit={props.onSubmit ? props.onSubmit : undefined}>
      <Divider sx={{ mt: 2 }} />
      <Stack sx={{ mt: 2, justifyContent: 'flex-end' }} direction="row" spacing={1}>
        {props.children}
      </Stack>
    </Box>
  );
}