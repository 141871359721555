import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import {
  TopicResponse,
  Topic,
  TopicBestPracticeResponse,
  TopicErrorResponse,
} from '../../interfaces/topic.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { FieldValues } from 'react-hook-form';

interface InitialTopicState {
  count: number;
  next?: string;
  previous?: string;
  topic?: Topic;
  results: Topic[];
  isPosting: boolean;
  deletedTopicId?: number;
  error?: TopicErrorResponse;
  targetDomainId?: number;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  topic: undefined,
  results: [],
  isPosting: false,
  deletedTopicId: undefined,
  error: undefined,
  targetDomainId: undefined,
} as InitialTopicState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAll = createAsyncThunk(
  'topics/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/topics/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as TopicResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchTopic = createAsyncThunk(
  'topics/fetchTopic',
  async (topicSlug: string, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/topics/${topicSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Topic;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createTopic = createAsyncThunk(
  'topics/createTopic',
  async ({ title, description, domain, questionnaire }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/topics/`, {
          title,
          description,
          bestPractices: [],
          domain,
          questionnaire,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Topic;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteTopic = createAsyncThunk(
  'topics/deleteTopic',
  async (topicSlug: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/topics/${topicSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return topicSlug;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateTopic = createAsyncThunk(
  'topics/updateTopic',
  async ({ id, title, description, bestPractices, slug, questionnaire, domain }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/topics/${slug}/`, {
          id,
          title,
          description,
          bestPractices: bestPractices.map((bestPractice:BestPractice) => typeof bestPractice === 'number' ? bestPractice : bestPractice.id),
          questionnaire: typeof questionnaire === 'number' ? questionnaire : questionnaire.id,
          domain: typeof domain === 'number' ? domain : domain.id,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Topic;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

function updateTopicWithBestPractice(topic: Topic, topicId: number, bestPractice: BestPractice | undefined) {
  if (topic.id != topicId || !bestPractice) return topic;
  const { bestPractices } = topic;
  return { ...topic, bestPractices: [...bestPractices, topic] };
}

const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    updateTopicBestPracticeArray: {
      reducer: (state, action: PayloadAction<{ bestPractice: BestPractice }>) => {
        if (!state.topic) return;
        const bestPracticeIndex = state.topic.bestPractices.findIndex(bp => bp.id === action.payload.bestPractice.id);
        if (bestPracticeIndex !== -1) {
          const newTopic = { ...state.topic };
          newTopic.bestPractices[bestPracticeIndex] = action.payload.bestPractice;
          state.topic = newTopic;
        }
      },
      prepare: (bestPractice: BestPractice) => {
        return { payload: { bestPractice } };
      },
    },
    updateTopicWithNewBestPractice(state, action: PayloadAction<TopicBestPracticeResponse>) {
      state.results.map((topic: Topic) => {
        updateTopicWithBestPractice(topic, action.payload.topicId, action.payload.bestPractice);
      });
    },
    resetTopic: (state) => {
      state.topic = undefined;
      state.error = undefined;
      state.targetDomainId = undefined;
    },
    resetDeletedTopicId: (state) => {
      state.deletedTopicId = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<TopicResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
      state.results.map((topic) => topic.bestPractices.sort((a, b) => a.id - b.id));
      state.error = undefined;
    });
    builder.addCase(createTopic.fulfilled, (state, action: PayloadAction<Topic>) => {
      state.topic = action.payload;
      state.error = undefined;
    });
    builder.addCase(createTopic.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteTopic.fulfilled, (state, action: PayloadAction<number>) => {
      state.deletedTopicId = action.payload;
      state.results = state.results.filter((topic) => topic.id !== action.payload);
    });
    builder.addCase(updateTopic.fulfilled, (state, action: PayloadAction<Topic>) => {
      state.topic = action.payload;
      state.error = undefined;
    });
    builder.addCase(updateTopic.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchTopic.fulfilled, (state, action: PayloadAction<Topic>) => {
      state.topic = action.payload;
    });
  },
});

export const { resetTopic, resetDeletedTopicId, updateTopicBestPracticeArray } = topicSlice.actions;

export default topicSlice.reducer;
