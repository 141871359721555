import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 
import React from 'react';
 
export default function FilterButtonIcon(props: { showClear: boolean, clearMethod: (event: React.MouseEvent<HTMLButtonElement>) => void, openOptions: () => void }) {
  return props.showClear ? (
       <IconButton
         onClick={props.clearMethod}
         edge="end"
         size="small"
         sx={{  '& svg': { fontSize: '1rem' }, color: 'action.active', marginRight: '5px' }}
       >
         <ClearIcon />
       </IconButton>
  ) :  <IconButton sx={{
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'action.active', 
  }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.openOptions();}}><ArrowDropDownIcon/>
        </IconButton>;
}
