import React from 'react';
import { makeStyles } from '@mui/styles';
import { appTheme } from '../Themes/light';

const useStyles = makeStyles(() => ({
  '@keyframes bouncing-loader': {
    to: {
      opacity: '0.1',
      transform: 'translateY(-8px)',
    },
  },
  bouncingLoader: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '8px',
      height: '8px',
      margin: '3px 3px',
      borderRadius: '50%',
      background: appTheme.palette.primary.main,
      animation: '$bouncing-loader 0.6s infinite alternate',
    },
    '& > div:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& > div:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
}));

const BouncingDotsLoader = () => {
  const classes = useStyles();
  return (
      <div className={classes.bouncingLoader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default BouncingDotsLoader;