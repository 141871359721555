import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import './VendorOnboarding.css';

const formStyles = {
  root: {
    marginTop: '3em',
    fontWeight: '100',
    display: 'flex',
    justifyContent: 'center',
  },
  headerBar: {
    background: '#2F2D4F',
    textAlign: 'center',
    padding: '1em 2em',
    position: 'relative',
  },
  headerBarBG: {
    position: 'absolute',
    left: '-429px',
    top: '-546px',
    width: '716px',
    height: '823px',
    background: 'radial-gradient(42.78% 42.78% at 50% 57.22%, #8AA2C5 5.06%, rgba(100, 140, 200, 0.68) 43.23%, rgba(146, 168, 200, 0) 100%)',
    opacity: '0.5',
  },
};

export default function LoginContainer(props: { children: any, signUp?: boolean }) {
  return (
    <Box sx={{ backgroundColor: '#F3F5FB', minHeight: '100vh', position: 'relative' }}>
      <Box sx={{ ...formStyles.headerBar }}><Box sx={{ ...formStyles.headerBarBG }}></Box><Box sx={{ width: '500px', maxWidth: '80%' }} component='img' src={`${process.env.PUBLIC_URL}/assets/TPN_logo.png`} alt='TPN Logo' /></Box>
      <Grid sx={formStyles.root} spacing={0} container>
        <Stack spacing={2} alignItems='center'>
          <Typography variant='h4' sx={{ fontWeight: '100' }} mb={2}>Welcome To The Trusted Partner Network</Typography>
          { props.signUp && <>
            <Typography variant='h6'>Complete the signup process below</Typography>
            <Typography variant='body2'>Please note that TPN+, the new platform is not connected to the legacy platform. To access TPN+, you will need to sign up with a new account.</Typography>
          </> }
          <Card sx={{ padding: 4, minWidth: '800px', maxWidth: '1200px', marginTop: '2em!important' }}>
            {props.children}
          </Card>
        </Stack>
      </Grid>
    </Box>
  );
}
