import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useForm, FieldValues } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import User, { InviteUser } from '../../interfaces/users.interface';
import createUser from '../../services/user';
import { Switch, Typography } from '@mui/material';

const NewUserForm = (props: { successCallback: any, modalClose: any, userType: string }) => {
  const {
    successCallback,
    userType,
    modalClose,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const [isConsultant, setIsConsultant] = useState(false);

  const onSubmit = (data: FieldValues) => {
    const inviteUser: InviteUser = {
      email: data.email,
      username: data.email,
      type: userType,
      isActive: true,
      isConsultant: isConsultant,
    };
    createUser(inviteUser).then((newUserResponse: User) => {
      clearErrors();
      reset();
      successCallback(newUserResponse);
    }).catch((responseError: any) => {
      console.error(responseError.response.data);
      const errorData = responseError.response?.data;
      if (!errorData) {
        clearErrors();
        return;
      }
      for (const field of Object.keys(errorData)) {
        const errorMsgArr: string[] = errorData[field];
        setError(field, { type: 'manual', message: errorMsgArr[0] });
      }
    });
  };

  useEffect(() => {
    const email = watch((value, { name }) => {
      if (name === 'email' && value.email?.length > 0 && value.email !== value.email.toLowerCase()) setValue('email', value.email.toLowerCase());
    });
    return () => email.unsubscribe();
  }, [watch]);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <Grid container>
      <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Typography mb={1} mt={1}>Please add one user at a time</Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='email'
              label='Email'
              type='email'
              autoFocus
              {...register('email')}
              onFocus={() => clearErrors()}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />
          </Grid>
          <Grid item xs={9}>
            <FormGroup>
              <FormControlLabel
                sx={{ 'paddingLeft': '10px' }}
                control={<Checkbox onChange={(event, checked) => {setAcceptedTerms(checked);}}/>}
                label="By inviting this user to the platform, you agree that they will abide by all TPN terms & conditions."
              />
            </FormGroup>
          </Grid>
          <Grid item xs={3}>
            <Typography>Is this user a consultant?</Typography>
            <Switch onChange={() => setIsConsultant(prev => !prev)} checked={isConsultant} />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }} mt={2}>
          <Button disabled={!acceptedTerms} type="submit" variant="contained">Invite User</Button>
          {modalClose && <Button sx={{ mr: 1 }} variant="outlined" onClick={() => modalClose()}>Close</Button>}
        </Box>
      </Box>
    </Grid>
  );
};

export default NewUserForm;