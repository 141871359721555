import axios from 'axios';

import getAuthSession from './auth';
import { CompanyDocument, CompanyDocumentSubmitData, CompanyDocumentUpdateData } from '../interfaces/users.interface';

export default async function createCompanyDocument(companyDocument: CompanyDocumentSubmitData) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/company-documents/`,
    { ...companyDocument },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data as CompanyDocument;
}

export async function updateCompanyDocument(id: number, companyDocument: CompanyDocumentUpdateData) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-documents/${id}/`,
    { ...companyDocument },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as CompanyDocument;
}

export async function deleteCompanyDocument(companyDocument: CompanyDocument) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/company-documents/${companyDocument.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}