import React, { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Moment from 'moment';
import { CertificationControl } from '../../interfaces/certificationControl.interface';
import { Question } from '../../interfaces/question.interface';
import { DataRow, DataCell, DataRowActions } from '../Tables/DataList';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import { updateCertificationControl, deleteCertificationControl, resetCertificationControl } from '../../app/store/certificationControlSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Answer } from '../../interfaces/answer.interface';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { InputLabel } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(title: string, selectedAnswers: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selectedAnswers.indexOf(title) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CertMappingListItem(props: {
  canEdit?: boolean,
  certListItem: CertificationControl,
  questionUpdate: any,
  question: Question,
  removeCertControl: any,
  answers?: Answer[]
}) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: {
    control: props.certListItem.control,
    answers: props.certListItem.answers && props.certListItem.answers.length > 0 ?
      typeof(props.certListItem.answers[0]) === 'number' ? props.certListItem.answers : props.certListItem.answers.map(a => typeof(a) !== 'number' && a.id)
      : props.certListItem.answers,
  } });
  const dispatch = useAppDispatch();
  const { certificationControl: certificationControlResult } = useAppSelector(state => state.certificationControl);
  const [deleteMappingOpen, setDeleteMappingOpen] = useState(false);
  const theme = useTheme();
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(props.certListItem.answers ? props.certListItem.answers.map(a => typeof a === 'number' ? a.toString() : a.id.toString()) : []);
  const [answers, setAnswers] = useState<Answer[] | undefined>(props.answers);
  const [shouldReset, setShouldReset] = useState(false);
  const [curControl, setCurControl] = useState(props.certListItem.control);

  useEffect(() => {
    return (()=> {
      setAnswers(undefined);
      setSelectedAnswers([]);
    });
  }, []);

  useEffect(() => {
    setAnswers(props.answers);
  }, [props.answers]);

  useEffect(() => {
    if (!shouldReset) return;
    setShouldReset(false);
    dispatch(resetCertificationControl());
  }, [certificationControlResult]);

  useEffect(() => {
    setSelectedAnswers(props.certListItem.answers ? props.certListItem.answers.map(a => typeof a === 'number' ? a.toString() : a.id.toString()) : []);
  }, [props.certListItem]);

  const onSubmit = (data: FieldValues) => {
    if (curControl === data.control) return;
    setShouldReset(true);
    setCurControl(data.control);
    dispatch(updateCertificationControl({ ...data, certification: props.certListItem.certification.id, id:  props.certListItem.id, answers: selectedAnswers }));
  };

  const onClickOff = () => {
    const control = getValues('control');
    if (curControl === control) return;
    setCurControl(control);
    setShouldReset(true);
    dispatch(updateCertificationControl({ control, certification: props.certListItem.certification.id, id:  props.certListItem.id, answers: selectedAnswers }));
  };

  const onDeleteSubmit = () => {
    dispatch(deleteCertificationControl(props.certListItem.id));
    props.removeCertControl(props.certListItem.id);
    setDeleteMappingOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedAnswers>) => {
    const {
      target: { value },
    } = event;
    setSelectedAnswers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const control = getValues('control');
    setShouldReset(true);
    dispatch(updateCertificationControl({ control, certification: props.certListItem.certification.id, id:  props.certListItem.id, answers: typeof value === 'string' ? value.split(',') : value }));
  };

  function getAnswerName(answerId: string) {
    if (!answers) return;
    return answers.find(answer => answer.id === parseInt(answerId))?.text;
  }

  const handleDelete = (value: string) => {
    const nSelectedAnswers = selectedAnswers.filter(answer => answer !== value);
    setSelectedAnswers(nSelectedAnswers);
    const control = getValues('control');
    setShouldReset(true);
    dispatch(updateCertificationControl({ control, certification: props.certListItem.certification.id, id:  props.certListItem.id, answers: nSelectedAnswers }));
  };

  return (
    <DataRow key={`${props.certListItem.certification.title}-${props.certListItem.id}`} p={1} mb={.01}>
      <DataCell xs={3} p={1}>{`${props.certListItem.certification.title}${props.certListItem.certification.version && props.certListItem.certification.version.length > 0 ? `: ${props.certListItem.certification.version}` : ''}`}</DataCell>
      <DataCell xs={2} p={1}>
        <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
          <ClickAwayListener onClickAway={onClickOff}>
            <TextField
              error={!!errors.control}
              helperText={!!errors.control && errors.control.message}
              id='control'
              disabled={props.canEdit === false}
              label='Control'
              sx={{ width: '100%' }}
              {...register('control')}
            />
          </ClickAwayListener>
        </Box>
      </DataCell>
      <DataCell xs={2} p={1}>{props.certListItem.certification.version}</DataCell>
      <DataCell xs={2} p={1}>{props.certListItem.certification.releaseDate ? Moment(props.certListItem.certification.releaseDate).format('MM/DD/YYYY') : '-'}</DataCell>
      <DataCell xs={3} p={1}>
        <FormControl sx={{ width: 'calc(100% - 2em)' }}>
          <InputLabel id="answers">Answers</InputLabel>
          <Select
            labelId="answers"
            id="answers"
            multiple
            value={selectedAnswers}
            disabled={props.canEdit === false}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Answers" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip disabled={props.canEdit === false} key={value} label={getAnswerName(value)} variant='outlined' onDelete={() => handleDelete(value)} onMouseDown={ (e) => e.stopPropagation() } />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {answers && answers.map(answer => (
              <MenuItem
                key={answer.id}
                value={answer.id}
                style={getStyles(answer.text!, selectedAnswers, theme)}
              >
                {answer.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DataRowActions>
        { props.canEdit && <IconButton size="small" name="Delete Mapping" onClick={(e)=> {e.preventDefault(); setDeleteMappingOpen(true); }}><DeleteIcon /></IconButton> }
        </DataRowActions>
      </DataCell>
      <StandardDialog title="Remove Mapping from question" handleClose={() => setDeleteMappingOpen(false)} isOpen={deleteMappingOpen}>
        <Typography>
          Are you sure you want to remove {props.certListItem.certification.title}?
        </Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => setDeleteMappingOpen(false)}>Cancel</Button>
          <Button type="submit" sx={{ ml: 2 }} variant="contained" color="error" onClick={(e) => {e.stopPropagation(); onDeleteSubmit(); }}>Remove</Button>
        </StandardDialogActions>
      </StandardDialog>
    </DataRow>
  );
}