import React, { useEffect } from 'react';
import {
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function ProtectedRoute() {
  const { user } = useAppSelector(state => state.user);
  let navigate = useNavigate();

  const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (!user || (route !== 'authenticated' && route !== 'idle')) {
      navigate('/');
    }
  }, []);

  return (
    <Outlet />
  );
}
