import amber from '@mui/material/colors/amber';
import grey from '@mui/material/colors/grey';
import { makeStyles } from '@mui/styles';
import { appTheme } from '../../Themes/light';

export const gold = amber.A700;
export const lightGold = amber.A200;
export const blue = appTheme.palette.primary.main;
export const lightBlue = appTheme.palette.primary.light;
export const cert = grey[600];
export const member = grey[400];

export const skeletonBarProps = {
  width: '100%',
};


export const widgetStyles = makeStyles(() => ({
  widget: {
    padding: '1em',
  },
  widgetHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  skeletonContainer: {
    margin: '0 auto',
    display: 'grid',
    columnGap: '1rem',
    rowGap: '1rem',
    width: '100%',
    maxWidth: '100%',
    height: '300px',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
  },
  skeletonBarChart: {
    gridTemplateColumns: '50px 1fr 1fr 1fr 1fr 50px',
    alignItems: 'end',
    '& > span': {
      transform: 'translateY(0)',
    },
    '& > span:nth-of-type(1)': {
      height: '270px',
    },
    '& > span:nth-of-type(2)': {
      height: '50px',
    },
    '& > span:nth-of-type(3)': {
      height: '150px',
    },
    '& > span:nth-of-type(4)': {
      height: '200px',
    },
  },
  skeletonCircle: {
  },
  goldShield: {
    fill: gold + '!important',
    stroke: gold + '!important',
  },
  blueShield: {
    fill: blue + '!important',
    stroke: blue + '!important',
  },
  certShield: {
    fill: cert + '!important',
    stroke: cert + '!important',
  },
  noneShield: {
    fill: member + '!important',
    stroke: member + '!important',
  },

  labelProgressBar: {
    height: '76px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiLinearProgress-root, & .MuiLinearProgress-bar': {
      height: '100%',
    },
  },
  labelProgressBarLabels: {
    position: 'absolute',
    top: 0,
    padding: '22px 0',
    textAlign: 'center',
    '&:hover': {
      border: '3px rgba(0,0,0,.3) inset',
      padding: '19px 0',
    },
  },
  toolTipStyling: {
    background: 'rgba(0,0,0,.5)',
    color: 'white',
    padding: '.5em',
    borderRadius: appTheme.shape.borderRadius,
    fontSize: '.8em',
  },
  progressBarBlue: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: lightBlue,
    },
    '&.MuiLinearProgress-root': {
      backgroundColor: blue,
    },
  },
  progressBarGold: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: lightGold,
    },
    '&.MuiLinearProgress-root': {
      backgroundColor: gold,
    },
  },
  progressBarGrey: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: member,
    },
    '&.MuiLinearProgress-root': {
      backgroundColor: cert,
    },
  },
  fullSizeSkeleton: {
    width: '100%',
    height: '100%',
    transform: 'scale(1)',
  },
  labelCountBox: {
    display: 'flex',
    width: '50%',
    padding: '.25em',
    alignItems: 'center',
  },
}));