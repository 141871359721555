import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';

interface FileSliceState {
  whiteListedFileTypes?: string[],
}

const initialState: FileSliceState = {
  whiteListedFileTypes: [],
};

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchWhiteListedFileTypes = createAsyncThunk(
  'users/fetchWhiteListedFileTypes',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/white-listed-extensions/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results.map((bp: { id: number, extension: string }) => bp.extension);
    } catch (error: any) {
      return error;
    }
  },
);


const fileSlice = createSlice({
  name: 'fileSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWhiteListedFileTypes.fulfilled, (state, action: PayloadAction<any>) => {
      state.whiteListedFileTypes = action.payload;
    });
  },
});

export default fileSlice.reducer;
