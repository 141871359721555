import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Answer, AnswerResponse, AnswerError } from '../../interfaces/answer.interface';
import { FieldValues } from 'react-hook-form';

interface InitalAnswerState {
  count: number;
  next?: string;
  previous?: string;
  results: Answer[],
  error?: AnswerError,
  answer?: Answer
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  results: [],
  error: undefined,
  answer: undefined,
} as InitalAnswerState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAll = createAsyncThunk(
  'answers/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/answers/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as AnswerResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchAnswer = createAsyncThunk(
  'answers/fetchAnswer',
  async (answerId: string, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/answers/${answerId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Answer;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createAnswer = createAsyncThunk(
  'answer/createAnswer',
  async ({ text, boolean, completedRemediation, requiresRemediation, remediation, question, attachmentLocationUrl, isCompliant }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/answers/`, {
          text: text && text.length > 0 ? text : undefined,
          boolean: boolean ? boolean : false,
          completedRemediation: completedRemediation ? completedRemediation : false,
          requiresRemediation:	requiresRemediation ? requiresRemediation : false,
          remediation: remediation ? remediation : undefined,
          attachmentLocationUrl: attachmentLocationUrl ? attachmentLocationUrl : undefined,
          question: question,
          isCompliant:	isCompliant ? isCompliant : false,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Answer;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteAnswer = createAsyncThunk(
  'answers/deleteAnswer',
  async (answerId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/answers/${answerId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return answerId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAnswer = createAsyncThunk(
  'answers/updateAnswer',
  async ({ id, text, boolean, completedRemediation, requiresRemediation, remediation, question, attachmentLocationUrl, isCompliant }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/answers/${id}/`, {
          id: id,
          text: text && text.length > 0 ? text : null,
          boolean,
          completedRemediation,
          requiresRemediation,
          remediation,
          question,
          attachmentLocationUrl,
          isCompliant,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Answer;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    resetAnswer: (state) => {
      state.answer = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<AnswerResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
      state.error = undefined;
    });
    builder.addCase(createAnswer.fulfilled, (state, action: PayloadAction<Answer>) => {
      state.answer = action.payload;
      state.error = undefined;
    });
    builder.addCase(createAnswer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteAnswer.fulfilled, (state, action: PayloadAction<number>) => {
      state.results = state.results.filter((answer) => answer.id !== action.payload);
    });
    builder.addCase(updateAnswer.fulfilled, (state, action: PayloadAction<Answer>) => {
      state.results = state.results.filter((answer) => answer.id !== action.payload.id);
      state.results = [...state.results, action.payload].sort((a, b) => a.id - b.id);
      state.error = undefined;
    });
    builder.addCase(updateAnswer.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchAnswer.fulfilled, (state, action: PayloadAction<Answer>) => {
      state.results = [action.payload];
    });
  },
});

export const { resetAnswer } = answerSlice.actions;

export default answerSlice.reducer;
