import { fetchReport } from '../../services/reports';
import { ReportOption } from '../../interfaces/reports.interface';

export const downloadReport = async (reportOption: ReportOption, reportName: string) => {
  try {
    const data = await fetchReport(reportOption);
    const blob = new Blob([data], { type: 'text/csv' });
    const date = new Date().toISOString().split('T')[0];
    const filename = `${reportName.toLowerCase().replace(/\s+/g, '-')}-${date}.csv`;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Failed to download the report:', error);
  }
};