import axios from 'axios';

import getAuthSession from './auth';
import { Comment } from '../interfaces/assessment.interface';
import { AuditLog } from '../interfaces/auditLog.interface';

export async function createComment(newComment:Comment) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/comments/`,
    {
      ...newComment,
      user: typeof newComment.user === 'number' ? newComment.user : newComment.user.id,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Comment;
}

export async function createAuditLog(newAuditLog:AuditLog) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/audit-logs/`,
    {
      ...newAuditLog,
      user: typeof newAuditLog.user === 'number' ? newAuditLog.user : newAuditLog.user.id,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AuditLog;
}

export async function updateAuditLog(auditLog:AuditLog) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/audit-logs/${auditLog.id}/`,
    {
      ...auditLog,
      user: typeof auditLog.user === 'number' ? auditLog.user : auditLog.user.id,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AuditLog;
}

export async function getAuditLogByQuestionAnswerId(qaid:number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/audit-logs/?question_answer=${qaid}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as AuditLog[];
}