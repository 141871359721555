import { useEffect } from 'react';
 
// A hook that adds an event listener to the document
export default function useOutsideDirectory(ref: any, isOpen: boolean, closeDropdown: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDropdown();
      }
    }

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, ref, closeDropdown]);
}