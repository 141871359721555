import User, { Company } from '../interfaces/users.interface';

export default function canAdminCompany(user: User | undefined, company: Company) {
  if (!user) return false;
  if (user.type == 'tpn_admin') return true;
  if (!company.employees?.length) return false;

  const companyEmployeeIds: number[] = company.employees.map((employee: User) => employee.id);

  if (!companyEmployeeIds.includes(user.id)) return false;
  const adminUser = company.admins?.find((admin: User) => admin.id == user.id);

  return adminUser != undefined;
}

export const canStartFiveThreeSurvey = (user: User | undefined) => {
  if (!user) return false;
  return !!user?.permissions?.includes('can_start_v5_3');
};
