import React, { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';

import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import { renderOption } from './LogicListItem';
import { IncludedInLogic } from '../Questions/Question';
import { deleteInclusionConfiguration } from '../../app/store/inclusionConfigurationSlice';


export default function IncludedInLogicItem(props: { canEdit?: boolean, includedInLogic:IncludedInLogic, callback:any }) {
  const dispatch = useAppDispatch();
  const [deleteInclusionsOpen, setDeleteInclusionsOpen] = useState(false);
  const handleDeleteInclusionsOpen = () => setDeleteInclusionsOpen(true);
  const handleDeleteInclusionsClose = () => setDeleteInclusionsOpen(false);

  const deleteInclusions = () => {
    props.includedInLogic.inclusionIds?.forEach(id => {
      dispatch(deleteInclusionConfiguration(id));
    });
    props.callback(props.includedInLogic.id);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {renderOption(props.includedInLogic)}
      { props.canEdit && <IconButton sx={{ position: 'absolute', right: 2, top: '50%', transform: 'translateY(-50%)', width: 'auto' }} size="small" name="Delete Question" onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleDeleteInclusionsOpen(); }}><DeleteIcon /></IconButton> }
      <Divider />
      <StandardDialog title={`Delete all child logic related to question: ${props.includedInLogic.title}`} handleClose={handleDeleteInclusionsClose} isOpen={deleteInclusionsOpen}>
        <Typography>Are you sure you want to remove {props.includedInLogic.inclusionIds && props.includedInLogic.inclusionIds.length} logic items associated to question: {props.includedInLogic.title}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleDeleteInclusionsClose()}>Cancel</Button>
          <Button variant="contained" color="error" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteInclusions();}}>Remove</Button>
        </StandardDialogActions>
      </StandardDialog>
    </Box>
  );
}