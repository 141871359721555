import { Box, Chip, Tooltip } from '@mui/material';
import React from 'react';
import { appTheme } from '../Themes/light';

export default function ChipsWithOverflow(items: string[] | undefined, itemsToDisplay: number = 1, type: 'main' | 'app' | 'service' | 'site' | 'appType' = 'main') {
  if (!items || items.length === 0) return '-';

  const styles = {
    backgroundColor: appTheme.palette.chips?.[type] || 'main',
    color: appTheme.palette.chips!.contrastText,
    padding: '0.25rem',
    maxWidth: 125,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
  return (
    <>
      {items.map((item, index) => (<Box key={index}>
        {index + 1 <= itemsToDisplay && <Tooltip title={item}><Chip size="small" variant='filled' label={item} sx={{ ...styles, mb: 1 }} /></Tooltip>}
        {items.length === itemsToDisplay + 1 && index + 1 ===  itemsToDisplay + 1 && <Tooltip title={item}><Chip size="small" variant='filled' label={item} sx={{ ...styles, mb: 1 }} /></Tooltip>}
        {items.length > itemsToDisplay + 1 && index + 1 ===  itemsToDisplay + 1 && <Tooltip title={items.filter(curItem => items.indexOf(curItem) >= itemsToDisplay).join(', ')}><Chip size="small" variant='filled' sx={styles} label={`+${items.length - itemsToDisplay } more`} /></Tooltip>}
      </Box>))}
    </>
  );
}
