import React, { memo } from 'react';
import { Question } from '../../interfaces/question.interface';

import Box from '@mui/material/Box';

import QuestionOnQuestionnaire from './QuestionOnQuestionnaire/QuestionOnQuestionnaire';
import { QuestionStatusById } from '../../app/store/questionnaireAnswerSlice';
import '../../App.css';
interface QuestionOnQuestionnaireListProps {
  questions: Question[];
  relavantQuestionStatus?: QuestionStatusById;
}
// Custom comparison function to determine if rerender is necessary
function areEqual(prevProps: { questions: Question[] }, nextProps: { questions: Question[] }) {
  return prevProps.questions === nextProps.questions;
}

const QuestionOnQuestionnaireList = memo(({ questions, relavantQuestionStatus }: QuestionOnQuestionnaireListProps) => {
  return (
        <Box mt={1}>
          {questions.map((question:Question) => (
            <QuestionOnQuestionnaire
              relavantQuestionStatus={relavantQuestionStatus ? relavantQuestionStatus[question.id] : undefined}
              key={question.id + question.slug}
              question={question}
            />
          ))}
        </Box>
  );
}, areEqual);
 
export default QuestionOnQuestionnaireList;