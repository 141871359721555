// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// mui
import { Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Delete, DeleteOutlineOutlined, EditOutlined, MoreVert } from '@mui/icons-material';
// redux
import { useAppSelector } from '../../../../app/hooks';
// interface
import { Survey } from '../../../../interfaces/survey.interface';
import { AssessmentSurvey } from '../../../../interfaces/assessment.interface';
import User, { Company, CompanyApplication, Contact } from '../../../../interfaces/users.interface';
// services
import { fetchSurvey } from '../../../../services/surveys';
import { deleteContact } from '../../../../services/contact';
import { recentBadgeByType, fetchGoldBadgeLabel, fetchBlueBadgeLabel } from '../../../../services/badge';
import { fetchAssessmentSurvey } from '../../../../services/assessments';
import { wfHierarchy } from '../../../../services/workflow';
// components
import SiteName from './SiteName';
import GetBadge from '../../GetBadge';
import LocationForm from '../LocationForm';
import WorkflowStatus from '../../WorkflowStatus';
import WorkflowButtons from '../../WorkflowButtons';
import { DataCell, DataRow } from '../../../Tables/DataList';
import ChipsWithOverflow from '../../../Layout/ChipsWithOverflow';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';

type SiteDataRowProps = {
  company: Company;
  site: Contact;
  userCanAdminCompany: boolean;
  companySuccessCallback: any;
  onClick: (site: Contact) => void;
};

const SiteDataRow = (props: SiteDataRowProps) => {
  // props
  const { company, site, userCanAdminCompany, companySuccessCallback, onClick } = props;
  // redux
  const { user } = useAppSelector((state) => state.user);
  const { workflows: companyWorkflows } = useAppSelector((state) => state.workflow);
  // states
  const [cSite, setcSite] = useState(props.site);
  const [editSiteOpen, setEditSiteOpen] = useState(false);
  const [deleteSiteOpen, setDeleteSiteOpen] = useState(false);
  const [curSurvey, setCurSurvey] = useState<Survey | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [assessmentSurvey, setAssessmentSurvey] = useState<AssessmentSurvey | undefined>();
  // local variables
  const isMenuOpen = Boolean(anchorEl);
  const workflows = useMemo(() => {
    return companyWorkflows
      ?.filter((wf) => wf.site === site.id && wf.isActive)
      .sort((a, b) => wfHierarchy.indexOf(b.status as typeof wfHierarchy[number]) - wfHierarchy.indexOf(a.status as typeof wfHierarchy[number]));
  }, [companyWorkflows, site.id, wfHierarchy]); // sort by hierarchy aka 'highest achievement'
  const workflow = useMemo(() => (workflows && workflows.length ? workflows[0] : undefined), [workflows]);
  const mostRecentBlue = useMemo(() => workflows && recentBadgeByType(workflows, 'tpn_self_reported'), [workflows]);
  const mostRecentGold = useMemo(() => workflows && recentBadgeByType(workflows, 'tpn_assessed'), [workflows]);
  const companyCertification = useMemo(
    () => company?.companyCertifications?.find((cert) => cert.sites?.find((tsite) => tsite.id === cSite.id)),
    [company?.companyCertifications, cSite?.id],
  );
  const serviceNames = useMemo(() => cSite?.services?.map((service) => service.name), [cSite?.services]);
  const applicationNames = useMemo(
    () =>
      cSite?.companyApplications?.map((ca: CompanyApplication) => {
        if (!company.companyApplications || company.companyApplications.length === 0) return 'Name Missing';
        const companyApplication = company.companyApplications.find((compApp) => compApp.id === ca.id);
        if (!companyApplication) return 'Name Missing';
        return companyApplication.application.name;
      }),
    [company?.companyApplications, cSite?.companyApplications],
  );
  // short/simple functions
  const handleEditSiteClose = useCallback(() => setEditSiteOpen(false), []);
  const handleDeleteSiteClose = useCallback(() => setDeleteSiteOpen(false), []);

  const handleEditSiteOpen = useCallback((e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setEditSiteOpen(true);
  }, []);

  const handleDeleteSiteOpen = useCallback((e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setDeleteSiteOpen(true);
  }, []);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleEditSubmitSuccess = useCallback(
    (updatedCompany: Company) => {
      companySuccessCallback(updatedCompany);
      setEditSiteOpen(false);
    },
    [companySuccessCallback],
  );

  const handleSiteDeleteSubmit = useCallback(async () => {
    await deleteContact(cSite);
    const updatedCompany: Company = {
      ...company,
      locations: company.locations.filter((location: Contact) => location.id != cSite.id),
    };
    companySuccessCallback(updatedCompany);
    handleDeleteSiteClose();
  }, [company, companySuccessCallback]);

  const handleFetchAssessmentSurvey = useCallback(async (assessmentSurveyId: number) => {
    const assSurvey = await fetchAssessmentSurvey(assessmentSurveyId);
    if (workflow?.status !== 'Complete' ||
     (workflow?.status === 'Complete' && assSurvey.bestPracticeRemediationCount)) {
      setAssessmentSurvey(assSurvey);
    }
  }, []);

  const handleFetchSurvey = useCallback(async (surveyId: number) => {
    const surveyData = await fetchSurvey(surveyId);
    setCurSurvey(surveyData);
  }, []);

  // loads the survey?
  useEffect(() => {
    // if I have an assessment and status is in remediation, load survey
    if (workflow?.assessmentSurvey) {
      // fetch assessment survey
      handleFetchAssessmentSurvey(workflow?.assessmentSurvey);
      return;
    }

    // we have a bp survey and no assessment
    if (
      workflow?.bpSurvey &&
      !workflow?.assessment &&
      !['submittedForAssessment', 'complete'].includes(workflow?.status)
    ) {
      // fetch survey
      handleFetchSurvey(workflow?.bpSurvey);
      return;
    }
  }, [workflow]);

  // setting of site from props
  useEffect(() => {
    setcSite(site);
  }, [site]);

  const blueBadgeLabel = useMemo(() => mostRecentBlue?.badge && fetchBlueBadgeLabel([mostRecentBlue.badge], mostRecentBlue.version) || '', [mostRecentBlue]);

  return (
    <>
      <DataRow onClick={() => onClick(site)} hover>
        <DataCell xs={1} textAlign='center'>
          <GetBadge
            isActive={company.isPublished}
            goldBadgeLabel={mostRecentGold?.badge && fetchGoldBadgeLabel([mostRecentGold.badge], mostRecentGold.version)}
            blueBadgeLabel={blueBadgeLabel?.includes('Expired') && !mostRecentGold?.badge ? '' : blueBadgeLabel}
            assessmentSurvey={assessmentSurvey}
            assessor={workflow?.assignedAssessor as User | undefined}
            certification={companyCertification}
            company={company.id}
          />
        </DataCell>
        <DataCell xs={3}>
          <SiteName contact={cSite} />
        </DataCell>
        <DataCell
          xs={2}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.25rem',
            padding: '0.25rem',
          }}
        >
          {ChipsWithOverflow(applicationNames, 1, 'app')}
        </DataCell>
        <DataCell
          xs={2}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.25rem',
            padding: '0.25rem',
          }}
        >
          {ChipsWithOverflow(serviceNames, 1, 'service')}
        </DataCell>
        <DataCell xs={1}>
          <WorkflowStatus hasBlueExpired={blueBadgeLabel.includes('Expired')} workflow={workflow} company={company} survey={curSurvey} assessmentSurvey={assessmentSurvey} />
        </DataCell>
        <DataCell xs={2} textAlign='center'>
          <WorkflowButtons
            company={company}
            workflow={workflow}
            survey={curSurvey}
            assessmentSurvey={assessmentSurvey}
            site={cSite}
          />
        </DataCell>
        <DataCell xs={1}>
          {((userCanAdminCompany && !workflow?.assessmentSurvey && workflow?.status !== 'submittedForAssessment') ||
            user?.type === 'tpn_admin') && (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{ '& .MuiMenu-paper': { minWidth: 175, color: '#808080' } }}
              >
                <MenuItem onClick={handleEditSiteOpen} sx={{ columnGap: 0.625, alignItems: 'center' }}>
                  <EditOutlined />
                  <Typography>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={handleDeleteSiteOpen} sx={{ columnGap: 0.625, alignItems: 'center' }}>
                  <DeleteOutlineOutlined />
                  <Typography>Delete</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </DataCell>
      </DataRow>
      {/* Dialogs here */}
      <StandardDialog title={`Edit ${cSite.name}`} handleClose={handleEditSiteClose} isOpen={editSiteOpen}>
        <LocationForm
          successCallback={handleEditSubmitSuccess}
          submitButtonText='Update Site'
          company={company}
          location={cSite}
        />
      </StandardDialog>
      <StandardDialog title={`Delete ${cSite.name}?`} handleClose={handleDeleteSiteClose} isOpen={deleteSiteOpen}>
        <Typography>Are you sure you want to delete {cSite.name}?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleDeleteSiteClose()}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Delete />}
            onClick={() => handleSiteDeleteSubmit()}
            disabled={!userCanAdminCompany}
          >
            Delete
          </Button>
        </StandardDialogActions>
      </StandardDialog>
    </>
  );
};

export default SiteDataRow;
