import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchBestPractice, updateBestPractice, resetBestPractice } from '../../app/store/bestPracticeSlice';
import { Question } from '../../interfaces/question.interface';
import { BestPractice as BestPracticeInterface } from '../../interfaces/bestPractice.interface';
import { BestPracticeEditForm } from './BestPracticeForm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import QuestionListItem from '../Questions/QuestionListItem';
import { DataListContain, DataRow, DataCell } from '../Tables/DataList';
import HeaderDetailCard from '../Cards/HeaderDetailCard';
import StandardDialog from '../Modals/StandardDialog';
import QuestionForm from '../Questions/QuestionForm';
import QuestionDetail from '../Questions/Question';
import { buildQuestionnaireBreadCrumbs } from '../../services/breadCrumbs';

import QuestionnairePreview from '../Preview/QuestionnairePreview';
import { resetQuestion } from '../../app/store/questionSlice';

export const buildBestPracticeTitle = (bestPractice:BestPracticeInterface) => {
  if (typeof bestPractice.domain === 'number' ||
    typeof bestPractice.topic === 'number'
  ) return;

  const topicOrder = bestPractice.domain.topics.findIndex((topic) => (typeof topic === 'number' ? topic : topic.id) === (typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id)) + 1;
  const bestPracticeOrder = bestPractice.topic.bestPractices.findIndex((bp) => (typeof bp === 'number' ? bp : bp.id) === bestPractice.id);

  return `${bestPractice.domain.code}-${topicOrder}.${bestPracticeOrder} ${bestPractice.title}`;
};

export default function BestPractice() {
  const params = useParams();
  const bestPracticeSlug:string = params.bestPracticeSlug!;
  const dispatch = useAppDispatch();

  const { bestPractice } = useAppSelector(state => state.bestPractices);
  const { question: nQuestion } = useAppSelector(state => state.questions);
  const { user } = useAppSelector(state => state.user);

  useEffect(() => {
    dispatch(resetBestPractice());
    dispatch(resetQuestion());
    dispatch(fetchBestPractice(bestPracticeSlug));
    return (() => {
      dispatch(resetQuestion());
    });
  }, []);

  const [curView, setCurView] = useState('edit');
  const [activeQuestion, setActiveQuestion] = useState<Question | undefined>(undefined);

  const [newQuestionOpen, setNewQuestionOpen] = useState(false);
  const handleNewQuestionSetOpen = () => setNewQuestionOpen(true);
  const handleNewQuestionClose = () => setNewQuestionOpen(false);

  const [editBestPracticeOpen, setEditBestPracticeOpen] = useState(false);
  const handleEditBestPracticeOpen = () => setEditBestPracticeOpen(true);
  const handleEditBestPracticeClose = () => setEditBestPracticeOpen(false);

  const [questions, setQuestions] = useState(bestPractice?.questions as Question[]);
  const [questionFilter, setQuestionFilter] = useState(null);

  useEffect(() => {
    let newQuestionList = bestPractice?.questions as Question[];
    if (questionFilter) {
      if (typeof(questionFilter) === 'number') {
        newQuestionList = newQuestionList.filter(question => question.id === questionFilter);
      } else {
        newQuestionList = newQuestionList.filter(question => question.title.toLowerCase().includes(questionFilter));
      }
    }
    setQuestions(newQuestionList);
  }, [bestPractice?.questions, questionFilter]);

  useEffect(() => {
    if (nQuestion && (!activeQuestion || activeQuestion.id !== nQuestion.id)) {
      setActiveQuestion(nQuestion);
      setCurView('question');
    }
  }, [nQuestion]);

  const questionSearchClickCallback = useCallback((e: Event, value: any) => {
    e.preventDefault();
    if (value) { setQuestionFilter(typeof(value) !== 'string' ? value.question.id : value); } else { setQuestionFilter(null); }
  }, []);

  const moveQuestion = useCallback((dragIndex: number, hoverIndex: number) => {
    setQuestions((prevQuestions: Question[]) =>
      update(prevQuestions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevQuestions[dragIndex] as Question],
        ],
      }),
    );
  }, []);

  const dropQuestion = () => {
    const questionsIdsArr = questions.map((question) => typeof question === 'number' ? question : question.id);
    if (bestPractice) {
      const { inclusions } = bestPractice;
      const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
      const { excludedByCertifications } = bestPractice;
      const certificationsIdsArr = excludedByCertifications.map((certification) => typeof certification === 'number' ? certification : certification.id);
      dispatch(updateBestPractice({
        ...bestPractice,
        questions: questionsIdsArr,
        inclusions: inclusionsIdsArr,
        excludedByCertifications: certificationsIdsArr,
        questionnaire: typeof bestPractice.questionnaire === 'number' ? bestPractice.questionnaire : bestPractice.questionnaire.id,
        domain: typeof bestPractice.domain === 'number' ? bestPractice.domain : bestPractice.domain.id,
        topic: typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id,
      }));
    }
  };

  const buildQuestionSearchList = () => {
    if (!bestPractice) return false;
    return bestPractice.questions.map(question => {return { label: question.title, question: question };});
  };

  const [questionSearchList, setQuestionSearchList] = useState(buildQuestionSearchList);
  const [breadCrumbs, setBreadCrumbs] = useState<any[] | undefined>(undefined);
  
  const buildBreadCrumbs = () => {
    if (!bestPractice) return;
    if (typeof bestPractice.questionnaire === 'number') return;
    if (typeof bestPractice.domain === 'number') return;
    if (typeof bestPractice.topic === 'number') return;

    const crumbItems = [
      { ...bestPractice.questionnaire, label: 'Questionnaire' },
      { ...bestPractice.domain, label: 'Domain' },
      { ...bestPractice.topic, label: 'Topic' },
    ];
    const breadCrumbsList = buildQuestionnaireBreadCrumbs(crumbItems, '/questionnaires/');
    setBreadCrumbs(breadCrumbsList);
  };

  useEffect(() => {
    if (!bestPractice) return;
    if (bestPractice.questions) setQuestionSearchList(buildQuestionSearchList);

    buildBreadCrumbs();
  }, [bestPractice]);

  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      { bestPractice && curView === 'edit' &&
        <>
          <HeaderDetailCard  canEdit={canEdit} overTitle='Best Practice' title={buildBestPracticeTitle(bestPractice)!} detail={bestPractice.description} editClick={handleEditBestPracticeOpen} breadCrumbs={breadCrumbs} previewClick={() => setCurView('preview')}  />
          <StandardDialog title={`Edit Best Practice: ${buildBestPracticeTitle(bestPractice)!}`} handleClose={handleEditBestPracticeClose} isOpen={editBestPracticeOpen}>
            <BestPracticeEditForm bestPractice={bestPractice} handleClose={handleEditBestPracticeClose} />
          </StandardDialog>
          <StandardDialog title="New Question" handleClose={handleNewQuestionClose} isOpen={newQuestionOpen}>
            <QuestionForm bestPractice={bestPractice} handleClose={handleNewQuestionClose} />
          </StandardDialog>
          <DataListContain title="Questions" buttonTitle={canEdit ? 'Questions' : ''} buttonClick={handleNewQuestionSetOpen} searchList={questionSearchList} onChange={questionSearchClickCallback}>
            <DataRow header>
              <DataCell xs={6}>Title</DataCell>
              <DataCell xs={4}>Description</DataCell>
              <DataCell xs={2}>Order</DataCell>
            </DataRow>
            { questionFilter ?
              <>
                {questions && questions.map((question: Question) => (
                  <QuestionListItem canEdit={canEdit} key={question.id} question={question} bestPractice={bestPractice} idx={bestPractice?.questions.indexOf(question)} />
                ))}
              </>
              :
              <DndProvider backend={HTML5Backend}>
                {questions && questions.map((question: Question) => (
                  <QuestionListItem canEdit={canEdit} key={question.id} question={question} bestPractice={bestPractice} idx={bestPractice?.questions.indexOf(question)} moveItem={moveQuestion} dropItem={dropQuestion} clickHandeler={() => {setActiveQuestion(question); setCurView('question');}} />
                ))}
              </DndProvider>
            }
          </DataListContain>
        </>
      }
      { bestPractice && activeQuestion && curView === 'question' &&
        <QuestionDetail question={activeQuestion} bestPractice={bestPractice} changeView={() => {dispatch(fetchBestPractice(bestPracticeSlug)); setCurView('edit'); }} />
      }
      { bestPractice && curView === 'preview' &&
        <QuestionnairePreview
          buttonClick={() => setCurView('edit')}
          buttonText={bestPractice.title}
          bestPractice={bestPractice}
        />
      }
    </>
  );
}