import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { BestPracticeStatus, Survey } from '../../interfaces/survey.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';

interface InitialSurveyState {
  surveys?: Survey[];
  survey?: Survey;
  error?: any;
  mpa_best_practice?: number;
  site_baseline?: number;
  application_baseline?: number;
  assessor_onboarding?: number;
  content_owner_onboarding?: number;
  vendor_onboaring?: number;
}

export interface QuestionnaireType {
  questionnaire: Questionnaire;
  type: 'application_baseline' | 'mpa_best_practice' | 'site_baseline';
}

const initialState = {
  survey: undefined,
} as InitialSurveyState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAllSurveys = createAsyncThunk(
  'survey/fetchAllSurveys',
  async ({ company, service, application, site, questionnaire }: { company?:number, service?: number, application?: number, site?: number, questionnaire?: number }, { rejectWithValue }) => {
    const authSession = await getAuthSession();
    let url = `${baseUrl}/surveys/?limit=99999`;
    if (service) url += `&service=${service}`;
    if (company) url += `&company=${company}`;
    if (application) url += `&application=${application}`;
    if (site) url += `&site=${site}`;
    if (questionnaire) url += `&questionnaire=${questionnaire}`;
    try {
      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as Survey[];
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAllSurveysCombine = createAsyncThunk(
  'survey/fetchAllSurveysCombine',
  async ({ company, service, application, site }: { company?:number, service?: number, application?: number, site?: number }, { rejectWithValue }) => {
    const authSession = await getAuthSession();
    let url = `${baseUrl}/surveys/?limit=99999`;
    if (service) url += `&service=${service}`;
    if (company) url += `&company=${company}`;
    if (application) url += `&application=${application}`;
    if (site) url += `&site=${site}`;
    try {
      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as Survey[];
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSurvey = createAsyncThunk(
  'surveys/fetchSurvey',
  async (surveyId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/surveys/${surveyId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Survey;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchPublishedQuestionnaire = createAsyncThunk(
  'surveys/fetchPublishedQuestionnaire',
  async (type: string) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/questionnaires/?is_published=true&type=${type}`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return { questionnaire: response.data.results[0], type } as QuestionnaireType;
    } catch (error: any) {
      return error;
    }
  },
);

export const createSurvey = createAsyncThunk(
  'survey/createSurvey',
  async ({ company, service, application, site, questionnaire, version }: { company:number, service?: number, application?: number, site?: number, questionnaire: number, version?: string }, { rejectWithValue }) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.post(
        `${baseUrl}/surveys/`,
        {
          company,
          questionnaire,
          service,
          application,
          site,
          status: 'incomplete',
          version,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Survey;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const backgroundCreateSurvey = createAsyncThunk(
  'survey/backgroundCreateSurvey',
  async ({ company, service, application, site, questionnaire, version }: { company:number, service?: number, application?: number, site?: number, questionnaire: number, version?: string }, { rejectWithValue }) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.post(
        `${baseUrl}/surveys/`,
        {
          company,
          questionnaire,
          service,
          application,
          site,
          status: 'incomplete',
          version,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Survey;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSurvey = createAsyncThunk(
  'survey/updateSurvey',
  async ({ id, company, service, application, site, questionnaire, status, bestPracticeStatuses }: Survey, { rejectWithValue }) => {
    const authSession = await getAuthSession();
    if (!bestPracticeStatuses || (typeof bestPracticeStatuses[0] !== 'number' && bestPracticeStatuses[0].id === undefined) ) return rejectWithValue('requires Best Practice Statuses');
    try {
      const response = await axios.patch(
        `${baseUrl}/surveys/${id}/`,
        {
          company: typeof company === 'number' ? company : company.id,
          questionnaire: typeof questionnaire === 'number' ? questionnaire : questionnaire.id,
          service,
          application,
          site,
          status,
          bestPracticeStatuses: bestPracticeStatuses?.map(bps => typeof bps === 'number' ? bps : bps.id),
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Survey;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);


const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    resetSurvey: (state) => {
      state.survey = undefined;
    },
    resetSurveys: (state) => {
      state.survey = undefined;
      state.surveys = undefined;
    },
    updateBPSonCurSurvey: {
      reducer: (state, action: PayloadAction<{ bps: BestPracticeStatus }>) => {
        const newSurvey = state.survey;
        if (!newSurvey || !newSurvey.bestPracticeStatuses || newSurvey.bestPracticeStatuses.length === 0) return;
        const bpsIndex = newSurvey.bestPracticeStatuses.findIndex(nbps => typeof nbps === 'number' ? nbps === action.payload.bps.id : nbps.id === action.payload.bps.id );
        if (bpsIndex !== -1) {
          newSurvey.bestPracticeStatuses[bpsIndex] = action.payload.bps;
          state.survey = newSurvey;
        }
      },
      prepare: (bps: BestPracticeStatus) => {
        return { payload: { bps } };
      },
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPublishedQuestionnaire.fulfilled, (state, action: PayloadAction<QuestionnaireType>) => {
      if (!action.payload.questionnaire) return;
      state[action.payload.type] = action.payload.questionnaire.id;
    });
    builder.addCase(fetchAllSurveys.fulfilled, (state, action: PayloadAction<Survey[]>) => {
      state.surveys = action.payload;
      state.error = undefined;
    });
    builder.addCase(fetchAllSurveysCombine.fulfilled, (state, action: PayloadAction<Survey[]>) => {
      state.surveys = state.surveys ? [ ...state.surveys, ...action.payload ] : action.payload;
      state.error = undefined;
    });
    builder.addCase(backgroundCreateSurvey.fulfilled, (state, action: PayloadAction<Survey>) => {
      state.surveys = state.surveys ? [ ...state.surveys, action.payload ] : [ action.payload ];
      state.error = undefined;
    });
    builder.addCase(createSurvey.fulfilled, (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload;
      state.surveys = state.surveys ? [ ...state.surveys, action.payload ] : [ action.payload ];
      state.error = undefined;
    });
    builder.addCase(fetchSurvey.fulfilled, (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload;
    });
    builder.addCase(createSurvey.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.survey = undefined;
    });
    builder.addCase(backgroundCreateSurvey.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchSurvey.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.survey = undefined;
    });
    builder.addCase(updateSurvey.fulfilled, (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload;
      state.error = undefined;
    });
    builder.addCase(updateSurvey.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
  },
});

export const {
  resetSurvey,
  resetSurveys,
  updateBPSonCurSurvey,
} = surveySlice.actions;
export default surveySlice.reducer;