import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import Box from '@mui/material/Box';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Topic } from '../../interfaces/topic.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateTopic, updateTopicBestPracticeArray } from '../../app/store/topicSlice';
import { updateBestPractice, createBestPractice, resetBestPractice } from '../../app/store/bestPracticeSlice';
import Grid from '@mui/material/Grid';
import LogicMapping from '../Cards/LogicMapping';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StandardDialogActions } from '../Modals/StandardDialog';
import getIncluisionConfigurationsById from '../../services/inclusionConfiguration';

export default function BestPracticeForm(props: { topic: Topic, handleClose: any }) {
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful, errors },
    reset,
    setError,
    clearErrors,
  } = useForm();
  const { bestPractice: bestPracticeResult, error, targetTopicId: targetTopicId } = useAppSelector(state => state.bestPractices);
  const [topic, setTopic] = useState(props.topic);
  const [view, setView] = useState('mainForm');
  const dispatch = useAppDispatch();
  const onSubmit = (data: FieldValues) => {
    const newBestPractice = {
      title: data.title,
      description: data.description,
      targetTopicId: data.targetTopicId,
      domain: typeof topic.domain === 'number' ? topic.domain : topic.domain.id,
      questionnaire: typeof topic.questionnaire === 'number' ? topic.questionnaire : topic.questionnaire.id,
    };
    dispatch(createBestPractice(newBestPractice));
  };
  const [bestPractice, setBestPractice] = useState<BestPractice | undefined>(undefined);
  const [inclusionConfigurations, setInclusionConfigurations] = useState<InclusionConfiguration[]>([]);

  useEffect(() => {
    if (bestPracticeResult && isSubmitSuccessful) {
      setBestPractice(bestPracticeResult);
      reset({ title: '', description: '' });
      const { bestPractices } = topic;
      const bestPracticesIdsArr = bestPractices.map((bestPracticeItem) => bestPracticeItem.id);
      if (bestPracticesIdsArr.indexOf(bestPracticeResult.id) === -1) {
        bestPracticesIdsArr.push(bestPracticeResult.id);
        dispatch(updateTopic({
          ...topic,
          bestPractices: bestPracticesIdsArr,
          domain: typeof topic.domain === 'number' ? topic.domain : topic.domain.id,
          questionnaire: typeof topic.questionnaire === 'number' ? topic.questionnaire : topic.questionnaire.id,
        }));
        dispatch(resetBestPractice());
      }
      clearErrors();
      setView('logicForm');
    }
    setTopic(props.topic);
  }, [formState, bestPracticeResult, props.topic]);

  useEffect(() => {
    if (error?.title && targetTopicId == topic.id) {
      setError('title', { type: 'custom', message: error.title[0] });
    }
  }, [error]);

  const buildBestPractice = () => {
    if (!bestPracticeResult) return;

    const { excludedByCertifications, questions, inclusions } = bestPracticeResult;
    const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    const certificationsIdsArr = excludedByCertifications.map((cert) => typeof cert === 'number' ? cert : cert.id);
    const questionsIdsArr = questions.map((question) => typeof question === 'number' ? question : question.id);
    
    return { ...bestPracticeResult,
      questions: questionsIdsArr,
      inclusions: inclusionsIdsArr,
      excludedByCertifications: certificationsIdsArr,
      questionnaire: typeof bestPracticeResult.questionnaire === 'number' ? bestPracticeResult.questionnaire : bestPracticeResult.questionnaire.id,
      domain: typeof bestPracticeResult.domain === 'number' ? bestPracticeResult.domain : bestPracticeResult.domain.id,
      topic: typeof bestPracticeResult.topic === 'number' ? bestPracticeResult.topic : bestPracticeResult.topic.id,
    } as BestPractice;
  };

  const updateBP = (incConfs:InclusionConfiguration[]) => {
    if (!bestPracticeResult) return;
    const newBestPractice = buildBestPractice();
    if (!newBestPractice) return;

    newBestPractice.inclusions = incConfs;
    dispatch(updateBestPractice(newBestPractice));
  };

  const inclusionDeletedCallback = (inclusionConfiguration:InclusionConfiguration) => {
    if (!bestPracticeResult) return;
    const newBestPractice = buildBestPractice();
    if (!newBestPractice) return;
    const newInclusionConfigurations = inclusionConfigurations.filter(inclusion => inclusion.id !== inclusionConfiguration.id);

    const { inclusions } = bestPracticeResult;
    const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    dispatch(updateBestPractice({ ...newBestPractice, inclusions: inclusionsIdsArr.splice(inclusionsIdsArr.indexOf(inclusionConfiguration.id), 1) }));
    setInclusionConfigurations(newInclusionConfigurations);
  };


  return (
    <>{ view === 'mainForm' &&
      <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              error={!!errors.title}
              helperText={!!errors.title && errors.title.message}
              required
              id='title'
              label='Best Practice Title'
              sx={{ width: '100%' }}
              autoFocus
              {...register('title')}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='description'
              label='Description'
              rows={3}
              multiline
              sx={{ width: '100%' }}
              fullWidth
              {...register('description')}
            />
            <TextField type='hidden' hidden id='targetTopicId' {...register('targetTopicId')} value={props.topic.id} sx={{ display: 'none' }}/>
          </Grid>
        </Grid>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
          <Button type="submit" variant="contained" endIcon={<NavigateNextIcon />}>Save</Button>
        </StandardDialogActions>
      </Box>
    }
    { view === 'logicForm' &&
      <Box sx={{ width: '100%' }}>
        <LogicMapping
          description={`Best Practice: ${bestPractice?.title}`}
          updateBP={updateBP}
          inclusionDeletedCallback={inclusionDeletedCallback}
          inclusions={inclusionConfigurations}
        />
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
          <Button type="submit" variant="contained" onClick={() => props.handleClose()}>Save</Button>
        </StandardDialogActions>
      </Box>
    }</>
  );
}

export function BestPracticeEditForm(props: { bestPractice: BestPractice, handleClose: any }) {
  const [bestPractice, setBestPractice] = useState(props.bestPractice);
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: { title: bestPractice.title, description: bestPractice.description },
  });
  const dispatch = useAppDispatch();
  const [view, setView] = useState('mainForm');

  const [inclusionConfigurations, setInclusionConfigurations] = useState<InclusionConfiguration[]>([]);
  useEffect(() => {
    setBestPractice(props.bestPractice);
    if (props.bestPractice.inclusions.length > 0) {
      getIncluisionConfigurationsById((props.bestPractice.inclusions as number[])).then(inclusionResults => setInclusionConfigurations(inclusionResults.results));
    } else {
      setInclusionConfigurations([]);
    }
    return (() => {
      setInclusionConfigurations([]);
    });
  }, [props.bestPractice]);

  const buildBestPractice = () => {
    const { inclusions, excludedByCertifications, questions } = bestPractice;
    const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    const certificationsIdsArr = excludedByCertifications.map((cert) => typeof cert === 'number' ? cert : cert.id);
    const questionsIdsArr = questions.map((question) => typeof question === 'number' ? question : question.id);
    return {
      ...bestPractice,
      questions: questionsIdsArr,
      inclusions: inclusionsIdsArr,
      excludedByCertifications: certificationsIdsArr,
      questionnaire: typeof bestPractice.questionnaire === 'number' ? bestPractice.questionnaire : bestPractice.questionnaire.id,
      domain: typeof bestPractice.domain === 'number' ? bestPractice.domain : bestPractice.domain.id,
      topic: typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id,
    } as BestPractice;
  };

  const onSubmit = async (data: FieldValues) => {
    if (props.bestPractice.title === data.title && props.bestPractice.description === data.description) {
      setView('logicForm');
    } else {
      const newBestPractice = buildBestPractice();
      dispatch(updateBestPractice({ ...newBestPractice, ...data })); 
    }
  };
  const { error, bestPractice: bestPracticeResult } = useAppSelector(state => state.bestPractices);

  useEffect(() => {
    if (bestPracticeResult && bestPracticeResult.id === props.bestPractice.id) {
      setBestPractice(bestPracticeResult);
      if (bestPracticeResult.inclusions.length) {
        getIncluisionConfigurationsById((bestPracticeResult.inclusions as number[])).then(inclusionResults => setInclusionConfigurations(inclusionResults.results));
      } else {
        setInclusionConfigurations([]);
      }
    }
  }, [bestPracticeResult]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setView('logicForm');
    }
  }, [formState]);

  const updateBP = (incConfs:{ inclusions: InclusionConfiguration[] }) => {
    if (!bestPracticeResult) return;
    const newBestPractice = buildBestPractice();
    if (!newBestPractice) return;

    newBestPractice.inclusions = incConfs.inclusions.map(inc => inc.id);
    dispatch(updateBestPractice(newBestPractice));
  };

  const inclusionDeletedCallback = (inclusionId:number) => {
    const { inclusions } = bestPractice;
    const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    if (inclusionsIdsArr.indexOf(inclusionId) !== undefined) inclusionsIdsArr.splice(inclusionsIdsArr.indexOf(inclusionId), 1);
    const newInclusionConfigurations = inclusionConfigurations.filter(inclusion => inclusion.id !== inclusionId);
    
    const newBestPractice = buildBestPractice();
    dispatch(updateBestPractice({ ...newBestPractice, inclusions: inclusionsIdsArr }));
    setInclusionConfigurations(newInclusionConfigurations);
  };

  const closeModal = () => {
    dispatch(updateTopicBestPracticeArray(bestPractice));
    props.handleClose();
  };

  return (
    <>{ view === 'mainForm' &&
      <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ width: '100%' }} rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              label="BestPractice Title"
              sx={{ width: '100%' }}
              autoFocus
              {...register('title')}
              error={!!error?.title}
              helperText={!!error?.title && error.title[0]}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label="Description"
              multiline
              rows={3}
              sx={{ width: '100%' }}
              {...register('description')}
              error={!!error?.description}
              helperText={!!error?.description && error.description[0]}
            />
          </Grid>
        </Grid>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => closeModal()}>Cancel</Button>
          <Button type="submit" variant="contained" endIcon={<NavigateNextIcon />}>Save</Button>
        </StandardDialogActions>
      </Box>
    }
    { view === 'logicForm' &&
      <Box sx={{ width: '100%' }}>
        <LogicMapping
          updateBP={updateBP}
          inclusionDeletedCallback={inclusionDeletedCallback}
          inclusions={inclusionConfigurations}
        />
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => closeModal()}>Cancel</Button>
          <Button type="submit" variant="contained" onClick={() => closeModal()}>Save</Button>
        </StandardDialogActions>
      </Box>
    }</>
  );
}