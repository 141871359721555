import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridOverlay, GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Moment from 'moment/moment';

import { SKU } from '../../interfaces/billing.interface';
import getSKUs, { deleteSKU } from '../../services/skus';
import { Card, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import SKUForm from '../Billing/SKUForm';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'item', headerName: 'Item', flex: 3 },
  { field: 'itemTotal', headerName: 'Item Total', flex: 2 },
  { field: 'description', headerName: 'Description', flex: 3 },
  { field: 'createdAt', headerName: 'Created At', flex: 2, renderCell: params => (<span>{Moment(params.row.createdAt).format('MM/DD/YYYY')}</span>) },
  { field: 'updatedAt', headerName: 'Updated At', flex: 2, renderCell: params => (<span>{Moment(params.row.updatedAt).format('MM/DD/YYYY')}</span>) },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: () => (
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    ),
  },
];

interface RowActionsMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: any;
  currentRow: any;
  setRows: any;
  rows: any[];
}

function RowActionsMenu({ anchorEl, handleClose, currentRow, setRows, rows }: RowActionsMenuProps) {
  const [sku, setSKU] = useState<SKU>();
  const open = Boolean(anchorEl);
  const [deleteSKUOpen, setDeleteSKUOpen] = useState(false);
  const [editSKUOpen, setEditSKUOpen] = useState(false);

  const handleEditSKUSubmit = (editedSKU: SKU) => {
    console.log(editedSKU);
    setRows(rows.map((row) => row.id === editedSKU.id ? editedSKU : row));
    setEditSKUOpen(false);
    handleClose();
  };

  const handleSKUDeleteSubmit = () => {
    if (!sku) return;
    deleteSKU(sku).then(() => {
      setRows(rows.filter((row) => row.id !== sku.id));
      setDeleteSKUOpen(false);
      handleClose();
    });
  };

  useEffect(() => {
    if (currentRow) setSKU(currentRow);
  }, [currentRow]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >{sku &&
      <div>
        <StandardDialog title={`Delete ${sku.item}?`} handleClose={() => setDeleteSKUOpen(false)} isOpen={deleteSKUOpen}>
          <Typography>Are you sure you want delete {sku.item}?</Typography>
            <Alert severity="warning">Warning. This action will remove this SKU from existing invoices. Invoice totals will remain the same.</Alert>
          <StandardDialogActions>
            <Button variant="outlined" onClick={() => setDeleteSKUOpen(false)}>Cancel</Button>
            <Button variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => handleSKUDeleteSubmit()}>Delete</Button>
          </StandardDialogActions>
        </StandardDialog>
        <StandardDialog title={`Edit ${sku.item}?`} handleClose={() => setEditSKUOpen(false)} isOpen={editSKUOpen}>
          <Stack direction="row" spacing={2}>
            <SKUForm callBack={handleEditSKUSubmit} sku={sku} handleClose={() => setEditSKUOpen(false)} />
          </Stack>
        </StandardDialog>
      </div>}
      <MenuItem onClick={() => setEditSKUOpen(true)}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setDeleteSKUOpen(true)}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
}

function CustomToolbar(props: any) {
  const [openCreateSKU, setOpenCreateSKU] = useState(false);
  const handCreateSKUSuccess = (newSKU: SKU) => {
    props.onCreateSuccessHandler(newSKU);
    setOpenCreateSKU(false);
  };
  return (
    <GridToolbarContainer>
      <StandardDialog title='Create New SKU' handleClose={() => setOpenCreateSKU(false)} isOpen={openCreateSKU}>
        <SKUForm sku={null} callBack={handCreateSKUSuccess} handleClose={() => setOpenCreateSKU(false)} />
      </StandardDialog>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{
        fileName: `tpn_skus_${new Date().toLocaleDateString()}`,
        fields: ['id', 'item', 'itemTotal', 'description', 'createdAt', 'updatedAt'],
      }}/>
      <Button
        variant='text'
        size='small'
        startIcon={<AddOutlinedIcon />}
        onClick={() => setOpenCreateSKU(true)}>
        Create New SKU
      </Button>
    </GridToolbarContainer>
  );
}

export default function SKUsAdminPanel() {
  const [rows, setRows] = useState<SKU[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentRow, setCurrentRow] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    getSKUs().then((skus: SKU[]) => {
      setRows(skus);
      setIsLoading(false);
    });
  }, []);

  const addNewSKU = (newSKU: SKU) => {
    setRows([...rows, newSKU]);
  };

  const handleMenuClose = (event: any) => {
    event.preventDefault();
    console.log('handling menu close');
    setMenuAnchorEl(null);
    setCurrentRow(null);
  };

  function handleRowClick(params: GridRowParams, event: any) {
    setCurrentRow(params.row);
    setMenuAnchorEl(event.currentTarget);
  }

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        onRowClick={handleRowClick}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
        componentsProps={{
          toolbar: { onCreateSuccessHandler: addNewSKU },
        }}
      />
      <RowActionsMenu
        anchorEl={menuAnchorEl}
        handleClose={(e: any) => handleMenuClose(e)}
        currentRow={currentRow}
        setRows={setRows}
        rows={rows}
      />
    </Card>
  );
}
