import axios from 'axios';

import getAuthSession from './auth';
import { BestPracticeStatus } from '../interfaces/survey.interface';

export default async function createBestPracticeStatus(bestPracticeStatus: BestPracticeStatus) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/best-practice-statuses/`,
    {
      bestPractice: typeof bestPracticeStatus.bestPractice === 'number' ? bestPracticeStatus.bestPractice : bestPracticeStatus.bestPractice.id,
      color: bestPracticeStatus.color,
      questionsAnsweredCount: bestPracticeStatus.questionsAnsweredCount,
      visibleQuestionsCount: bestPracticeStatus.visibleQuestionsCount,
      status: bestPracticeStatus.status,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as BestPracticeStatus;
}

export async function updateBestPracticeStatus(bestPracticeStatus: BestPracticeStatus) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/best-practice-statuses/${bestPracticeStatus.id}/`,
    {
      id: bestPracticeStatus.id,
      bestPractice: typeof bestPracticeStatus.bestPractice === 'number' ? bestPracticeStatus.bestPractice : bestPracticeStatus.bestPractice.id,
      color: bestPracticeStatus.color,
      questionsAnsweredCount: bestPracticeStatus.questionsAnsweredCount,
      visibleQuestionsCount: bestPracticeStatus.visibleQuestionsCount,
      status: bestPracticeStatus.status,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as BestPracticeStatus;
}

export async function deleteBestPracticeStatus(bestPracticeStatus: BestPracticeStatus) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/best-practice-statuses/${bestPracticeStatus.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export const createMultipleBestPracticeStatuses = async (bestPracticeStatuses: BestPracticeStatus[]) => {
  const authSession = await getAuthSession();
  return Promise
    .all(bestPracticeStatuses.map(bestPracticeStatus => 
      axios.post(
        `${process.env.REACT_APP_BASE_API}/best-practice-statuses/`,
        { 
          bestPractice: typeof bestPracticeStatus.bestPractice === 'number' ? bestPracticeStatus.bestPractice : bestPracticeStatus.bestPractice.id,
          color: bestPracticeStatus.color,
          questionsAnsweredCount: bestPracticeStatus.questionsAnsweredCount,
          visibleQuestionsCount: bestPracticeStatus.visibleQuestionsCount,
          status: bestPracticeStatus.status,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      ),
    ))
    .then(res => {
      return res.map(bpStatus => bpStatus.data) as BestPracticeStatus[];
    });
};