import React, { useCallback } from 'react';
import { Collapse, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CollapsibleListItem = React.memo((properties: {
  item: any, 
  setActive: any, 
  active: any, 
  children?: any, 
  cantCollapse?: boolean, 
  idx: number, 
  onClick?: any, 
  pl?: number, 
  pr?: number,
  titleOverride?: string 
  typographyProps: any
  bpStatus: any
}) => {
  const handleClick = useCallback(() => {
    properties.setActive(properties.item.id);
    if (properties.onClick) {
      properties.onClick();
    }
  }, [properties.setActive, properties.item.id, properties.onClick]);
  
  return (
      <>
        <ListItemButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleClick(); }}>
          <ListItemText
            primaryTypographyProps={properties.cantCollapse ? properties.typographyProps(properties.item.id) : {}}
            sx={{ pl: properties.pl, pr: properties.pr }}
            primary={properties.titleOverride ? properties.titleOverride : (properties.item.hasOwnProperty('code') ? properties.item.code : properties.idx) + '. ' + properties.item.title}
          />
          {!properties.cantCollapse ? properties.active === properties.item.id ? <ExpandLess /> : <ExpandMoreIcon /> : null}
          {properties.cantCollapse && !properties.titleOverride && properties.bpStatus[properties.item.id] ? <Typography sx={properties.typographyProps(properties.item.id)}>{properties.bpStatus[properties.item.id].questionsAnsweredCount + '/' + properties.bpStatus[properties.item.id].visibleQuestionsCount}</Typography> : null}
        </ListItemButton>
        {!properties.cantCollapse &&
          <Collapse in={properties.active === properties.item.id} timeout="auto" unmountOnExit>
            {properties.children}
          </Collapse>
        }
      </>
  );
}, (prevProps, nextProps) => {
  return prevProps.item.id === nextProps.item.id &&
           prevProps.active === nextProps.active &&
           prevProps.children === nextProps.children &&
           prevProps.cantCollapse === nextProps.cantCollapse &&
           prevProps.idx === nextProps.idx &&
           prevProps.pl === nextProps.pl &&
           prevProps.titleOverride === nextProps.titleOverride;
});

export default CollapsibleListItem;