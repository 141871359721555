import React, { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { Question } from '../../interfaces/question.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { deleteInclusionConfiguration } from '../../app/store/inclusionConfigurationSlice';

import IconButton from '@mui/material/IconButton';
import { DataRow, DataCell, DataRowActions } from '../Tables/DataList';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import Button from '@mui/material/Button';

export const buildQuestionBPTitle = (question:Question) => {
  if (typeof question.domain === 'number' ||
    typeof question.topic === 'number' ||
    typeof question.questionnaire === 'number' ||
    typeof question.bestPractice === 'number'
  ) return;

  const topicOrder = question.domain.topics.findIndex((topic) => topic === (typeof question.topic === 'number' ? question.topic : question.topic.id)) + 1;
  const bestPracticeOrder = question.topic.bestPractices.findIndex((bp) => bp === (typeof question.bestPractice === 'number' ? question.bestPractice : question.bestPractice.id));

  return `${question.questionnaire.title}: ${question.domain.code}-${topicOrder}.${bestPracticeOrder} ${question.bestPractice.title}`;
};

export const renderOption = (question:Question) => {
  if (!question || question.title.length < 1) return null;
  return (
    <>
      <Typography variant="overline" component='div' gutterBottom sx={{ fontSize: '.6em', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ whiteSpace: 'nowrap' }}>{buildQuestionBPTitle(question)}</Box>
      </Typography>
      <Typography>{question.title}</Typography>
    </>
  );
};

export default function LogicListItem(props: {
  canEdit?: boolean,
  inclusion: InclusionConfiguration,
  questionUpdate: any,
  question: Question,
  removeInclusion: any,
  hideAction?: Boolean,
}) {
  const dispatch = useAppDispatch();
  const [deleteLogicOpen, setDeleteLogicOpen] = useState(false);

  const onDeleteSubmit = () => {
    dispatch(deleteInclusionConfiguration(props.inclusion.id));
    props.removeInclusion(props.inclusion.id);
    setDeleteLogicOpen(false);
  };

  return (
    <DataRow key={`${typeof props.inclusion.answer !== 'number' && props.inclusion.answer.text}-${props.inclusion.id}`}>
      <DataCell xs={8}>{typeof props.inclusion.question !== 'number' && renderOption(props.inclusion.question)}</DataCell>
      <DataCell xs={4}>{typeof props.inclusion.answer !== 'number' && props.inclusion.answer.text}
        {!props.hideAction && props.canEdit && (
          <DataRowActions>
            <IconButton size="small" name="Delete Logic" onClick={(e)=> {e.preventDefault(); setDeleteLogicOpen(true); }}><DeleteIcon /></IconButton>
          </DataRowActions>
        )}
      </DataCell>
      <StandardDialog title="Remove Logic from question" handleClose={() => setDeleteLogicOpen(false)} isOpen={deleteLogicOpen}>
        <Typography>
          Are you sure you want to remove {typeof props.inclusion.question !== 'number' && props.inclusion.question.title}: {typeof props.inclusion.answer !== 'number' && props.inclusion.answer.text}?
        </Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => setDeleteLogicOpen(false)}>Cancel</Button>
          <Button type="submit" variant="contained" color="error" onClick={(e) => {e.preventDefault(); e.stopPropagation(); onDeleteSubmit();}}>Remove</Button>
        </StandardDialogActions>
      </StandardDialog>
    </DataRow>
  );
}