import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Typography, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
  />
))(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: '0px',
  marginBottom: theme.spacing(2),
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  alignItems: 'center',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '8px!important',
    alignItems: 'center',
  },
  '$::before': {
    display: 'none',
  },
}));



export function AccordionCollapse(props: { expanded: boolean, onChange: any, children: any }) {
  return (
    <Accordion expanded={props.expanded} onChange={() => {props.onChange();}}>
      {props.children}
    </Accordion>
  );
}

export function AccordionCollapseSummary(props: { name: string, title: any, children?: any, addItemTest?: boolean, addItem?: any, addItemLabel?:string, count?: number }) {
  return (
    <AccordionSummary
        aria-controls={props.name}
        id={props.name}
      > 
        <Typography component="div" fontWeight={500}>{props.title}</Typography>
        {(props.count || props.count === 0) && <Typography component="div" ml='.25em' fontWeight={100}>{`(${props.count})`}</Typography> }
        { props.addItem && (props.addItemTest === undefined || props.addItemTest) && <Button variant="contained" startIcon={<AddIcon />} sx={{ marginLeft: 'auto', minWidth: '200px' }} onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.addItem();}}>{props.addItemLabel}</Button> }
        {props.children}
      </AccordionSummary>
  );
}