// react and external-libs
import React, { useCallback, useMemo, useState } from 'react';
// MUI
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
// interface
import { Application, Company, CompanyApplication, Version } from '../../../../../interfaces/users.interface';
// components
import VersionSidebar from './VersionSidebar';
import InhouseAppDataRow from './InhouseAppDataRow';
import { DataCell, DataRow } from '../../../../Tables/DataList';

type InhouseApplicationsProps = {
  company: Company;
  userCanAdminCompany: boolean;
  companyCallback: any;
};

const InhouseApplications = (props: InhouseApplicationsProps) => {
  const { company, userCanAdminCompany, companyCallback } = props;
  // states
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCompanyApp, setSelectedCompanyApp] = useState<CompanyApplication | undefined>(undefined);
  const [selectedApp, setSelectedApp] = useState<Application | undefined>(undefined);
  const [selectedVersion, setSelectedVersion] = useState<Version | undefined>(undefined);

  const handleVersionRowClick = useCallback((companyApp: CompanyApplication, app: Application, version: Version) => {
    setSelectedCompanyApp(companyApp);
    setSelectedApp(app);
    setSelectedVersion(version);
    setIsSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setIsSidebarOpen(false);
    setSelectedApp(undefined);
    setSelectedVersion(undefined);
  }, []);

  const sortedCompanyApplications = useMemo(
    () =>
      company?.companyApplications
        ?.filter((ca) =>
          ca.application.company && typeof ca.application.company === 'number'
            ? ca.application.company === company.id
            : typeof ca.application.company !== 'number' && ca.application.company?.id === company.id,
        )
        .sort((a, b) =>
          a.application.name && b.application.name ? a.application.name.localeCompare(b.application.name) : 0,
        ),
    [company?.companyApplications],
  );

  return (
    <>
      <Typography variant='h6' sx={{ m: '0 -16px 0px -16px', background: grey[300], padding: '.5em 1.5em' }}>
        In-house Developed Application
      </Typography>
      <DataRow header>
        <DataCell xs={1}>&nbsp;</DataCell>
        <DataCell xs={1} textAlign='center'>
          Shield
        </DataCell>
        <DataCell xs={3}>Name</DataCell>
        <DataCell xs={2}>Sites</DataCell>
        <DataCell xs={2}>Services</DataCell>
        <DataCell xs={2}>Application Types</DataCell>
        <DataCell xs={1}>&nbsp;</DataCell>
      </DataRow>
      {sortedCompanyApplications?.map((companyApplication: CompanyApplication) => (
        <InhouseAppDataRow
          key={companyApplication.id}
          company={company}
          companyApplication={companyApplication}
          userCanAdminCompany={userCanAdminCompany}
          companySuccessCallback={companyCallback}
          onVersionRowClick={handleVersionRowClick}
        />
      ))}
      <VersionSidebar
        isOpen={isSidebarOpen}
        company={company}
        companyApp={selectedCompanyApp}
        application={selectedApp}
        version={selectedVersion}
        onClose={handleSidebarClose}
      />
    </>
  );
};

export default InhouseApplications;
