import getAuthSession from './auth';
import axios from 'axios';
import { AssessorLevel } from '../interfaces/users.interface';

export const fetchAssessorLevels = async () => {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/assessor-levels/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as AssessorLevel[];
};