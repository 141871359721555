import React, { useEffect } from 'react';
import { MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
 
export default function BooleanFilterComponent(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
 
  const handleFilterChange = (event: any) => {
    applyValue({ ...item, value: event.target.value === 'Yes' });
  };
 
  // Set initial value on mount
  useEffect(() => {
    applyValue({ ...item, value: item.value ?? true });
  }, []);
 
  return (
     <FormControl fullWidth size="small" sx={{
       marginTop: '7px',
     }}>
       <InputLabel shrink>Value</InputLabel>
       <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%', marginTop: '5px' }}>
         <Select
           value={item.value != null ? (item.value ? 'Yes' : 'No') : 'Yes'}
           onChange={handleFilterChange}
           label="Value"
           sx={{
             width: '100%',
             border: 'none',
             '& .MuiOutlinedInput-notchedOutline': {
               border: 'none',
             },
             '&:hover .MuiOutlinedInput-notchedOutline': {
               border: 'none',
             },
             '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
               border: 'none',
               boxShadow: 'none',
             },
           }}
         >
           <MenuItem value="Yes">Yes</MenuItem>
           <MenuItem value="No">No</MenuItem>
         </Select>
       </Box>
     </FormControl>
  );
}