import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Domain } from '../../interfaces/domain.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateQuestionnaire } from '../../app/store/questionnaireSlice';
import { updateDomain, createDomain, resetDomain } from '../../app/store/domainSlice';
import Grid from '@mui/material/Grid';
import { StandardDialogActions } from '../Modals/StandardDialog';

export default function DomainForm(props: { questionnaire: Questionnaire, handleClose: any }) {
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful, errors },
    reset,
    setError,
    clearErrors,
  } = useForm();
  const { domain: domainResult, error, targetQuestionnaireId: targetQuestionnaireId } = useAppSelector(state => state.domains);
  const [questionnaire, setQuestionnaire] = useState(props.questionnaire);
  const dispatch = useAppDispatch();
  const onSubmit = (data: FieldValues) => {
    const newDomain = { title: data.title, description: data.description, targetQuestionnaireId: data.targetQuestionnaireId, code: data.code };
    dispatch(createDomain(newDomain));
  };

  useEffect(() => {
    if (domainResult && isSubmitSuccessful) {
      reset({ title: '', description: '' });
      const { domains } = questionnaire;
      const domainsIdsArr = domains.map((domain) => domain.id);
      domainsIdsArr.push(domainResult.id);
      dispatch(updateQuestionnaire({ ...questionnaire, domains: domainsIdsArr }));
      dispatch(resetDomain());
      clearErrors();
      props.handleClose();
    }
    setQuestionnaire(props.questionnaire);
  }, [formState, domainResult, props.questionnaire]);

  useEffect(() => {
    if (error?.title && targetQuestionnaireId == questionnaire.id) {
      setError('title', { type: 'custom', message: error.title[0] });
    }
  }, [error]);
  return (
    <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={9}>
          <TextField
            error={!!errors.title}
            helperText={!!errors.title && errors.title.message}
            required
            id='title'
            label='Domain Title'
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            required
            id="code"
            label="Code"
            sx={{ width: '100%' }}
            autoFocus
            {...register('code')}
            error={!!errors?.code}
            helperText={!!errors?.code && errors.code[0]}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='description'
            label='Description'
            rows={3}
            multiline
            sx={{ width: '100%' }}
            fullWidth
            {...register('description')}
          />
          <TextField type='hidden' hidden id='targetQuestionnaireId' {...register('targetQuestionnaireId')} value={props.questionnaire.id} sx={{ display: 'none' }}/>
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}

export function DomainEditForm(props: { domain: Domain, handleClose: any }) {
  let [domain] = useState(props.domain);
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: { title: domain.title, description: domain.description, code: domain.code },
  });
  const dispatch = useAppDispatch();
  const [updatedDomain, setUpdateDomain] = useState(props.domain);
  const onSubmit = async (data: FieldValues) => {
    data.id = domain.id;
    const { topics } = domain;
    const topicsIdsArr = topics.map((topic) => topic.id);
    setUpdateDomain({ ...domain, ...data });

    const newDomain: Domain = { ...domain, ...data, topics: topicsIdsArr };

    if (newDomain.questionnaire) newDomain.questionnaire = typeof domain.questionnaire === 'number' ? domain.questionnaire : domain.questionnaire.id;

    dispatch(updateDomain(newDomain));
  };
  const { error } = useAppSelector(state => state.domains);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.handleClose(updatedDomain);
    }
  }, [formState]);

  return (
    <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ width: '100%' }} spacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            required
            id="title"
            label="Domain Title"
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            error={!!error?.title}
            helperText={!!error?.title && error.title[0]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            required
            id="code"
            label="Code"
            sx={{ width: '100%' }}
            autoFocus
            {...register('code')}
            error={!!error?.code}
            helperText={!!error?.code && error.code[0]}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            label="Description"
            multiline
            rows={3}
            sx={{ width: '100%' }}
            {...register('description')}
            error={!!error?.description}
            helperText={!!error?.description && error.description[0]}
          />
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}