import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Moment from 'moment/moment';

import getSKUs from '../../services/skus';
import { Invoice, BillingRecord, InvoicePatchPostObj, SKU } from '../../interfaces/billing.interface';
import { BillingFrequency, billingFrequencyOptions } from '../../interfaces/billing.interface';
import { createInvoice, patchInvoice } from '../../services/invoices';
import { StandardDialogActions } from '../Modals/StandardDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert } from '@mui/material';
// import { Add } from '@mui/icons-material';
import { sendDocusignWaiver } from '../../services/company';
import { Company, DocusignWaiver } from '../../interfaces/users.interface';

export default function InvoiceForm(props: { invoice: Invoice | null, billingRecord: BillingRecord, callBack: any, handleClose: any, company: Company }) {
  const {
    invoice,
    billingRecord,
    callBack,
    handleClose,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    clearErrors,
    setValue,
    getValues,
  } = useForm({ defaultValues: invoice ? { ...invoice } : undefined });

  const [waiverSent, setWavierSent] = useState<boolean | undefined | DocusignWaiver>(invoice?.waiver);

  const [skus, setSkus] = useState<SKU[]>([]);
  const [selectedSKUId, setSelectedSKUId] = useState(null);
  useEffect(() => {
    getSKUs().then((fetchedSKUs: SKU[]) => setSkus(fetchedSKUs));
  }, []);
  const availableOptions = ['', 'void', 'already_invoiced', 'ready_for_invoice', 'needs_validation', 'paid'];
  const selectedStatus = getValues('status') || ''; // Use empty string if getValues returns undefined
  const validatedStatus = availableOptions.includes(selectedStatus) ? selectedStatus : '';

  const onSubmit = (data: FieldValues) => {
    if (!billingRecord?.id) {
      console.log('No billing record found');
      return;
    }
    if (!selectedSKUId && !invoice?.sku.id) {
      console.log('No SKU selected');
      return;
    }

    const invoiceData: InvoicePatchPostObj = {
      id: invoice?.id ? invoice.id : null,
      startDate: Moment(data.startDate, 'YYYY-MM-DD HH:mm:ssZZ').toDate(),
      endDate: Moment(data.endDate, 'YYYY-MM-DD HH:mm:ssZZ').toDate(),
      sku: selectedSKUId ? selectedSKUId : invoice?.sku.id,
      discountPercent: data.discountPercent,
      discountAmount: data.discountAmount,
      poNumber: data.poNumber,
      notes: data.notes,
      invoiceTotal: data.invoiceTotal,
      billingRecord: billingRecord.id,
      billingFrequencyCaptured: data.billingFrequencyCaptured,
      isPaid: data.isPaid,
      paidDate: data.isPaid == 'true' ? new Date() : null,
      status: data.status,
      updatedAt: new Date(),
      createdAt: invoice?.createdAt ? invoice.createdAt : new Date(),
    };

    clearErrors();
    reset();

    if (invoice) {
      patchInvoice(invoiceData).then((updatedInvoice: Invoice) => {
        callBack(updatedInvoice);
        handleClose();
      });
    } else {
      createInvoice(invoiceData).then((createdInvoice: Invoice) => {
        callBack(createdInvoice, true);
        handleClose();
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }} component="form" method={invoice ? 'patch' : 'post'} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Alert severity='info'>Changing invoice status is descriptive only and does not affect the billing record or renewals.</Alert>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} mb={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="YYYY-MM-DD"
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} {...register('startDate')}/>}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="YYYY-MM-DD"
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} {...register('endDate')} />}
                  />
                )}
              />
            </LocalizationProvider>
            {(!invoice || !invoice.isPaid) && <Button variant='outlined' disabled={!!waiverSent} onClick={() => {sendDocusignWaiver(props.company as Company, invoice ? invoice.id as number : undefined); setWavierSent(true);}}>{waiverSent ? 'New Waiver Sent' : 'Send New Waiver'}</Button>}
          </Stack>
          <Grid container spacing={2}>
             <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="sku">SKU</InputLabel>
            <Select
              labelId="sku"
              id="sku-select"
              label="SKU"
              onChange={(event: any, selectedSKU: any | null) => {
                event.preventDefault();
                if (!selectedSKU.props.value) return;
                const foundSKU = skus.find((sku: SKU) => sku.id == selectedSKU.props.value);
                if (foundSKU) setValue('invoiceTotal', foundSKU.itemTotal);
                setSelectedSKUId(selectedSKU.props.value);
              }}
              defaultValue={invoice?.sku && invoice.sku.id}
            >
              {skus && skus.map((sku: SKU, idx: number) => {
                if (!sku?.id) return;
                return <MenuItem key={idx} value={sku?.id} selected={invoice?.sku.id === sku.id}>{sku.item} {sku.itemTotal}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="sku">Invoice Status</InputLabel>
            <Select
              labelId="invoiceStatus"
              id="invoice-status-select"
              label="Invoice Status"
              {...register('status')}
              defaultValue={validatedStatus}
              onChange={(e) => {
                setValue('status', e.target.value);
              }}
            >
              <MenuItem value='' disabled></MenuItem>
              <MenuItem value='undefined' disabled></MenuItem>
              <MenuItem value='void'>Void</MenuItem>
              <MenuItem value='already_invoiced'>Already Invoiced</MenuItem>
              <MenuItem value='ready_for_invoice'>Ready For Invoice</MenuItem>
              <MenuItem value='needs_validation'>Needs Validation</MenuItem>
              <MenuItem value='paid'>Paid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
               <FormControl fullWidth>
                 <InputLabel id="captured-billing-frequency">Captured Billing Frequency</InputLabel>
                 <Select
                   labelId="captured-billing-frequency"
                   id="captured-billing-frequency-select"
                   label="Captured Billing Frequency"
                   {...register('billingFrequencyCaptured')}
                   defaultValue={invoice?.billingFrequencyCaptured && invoice.billingFrequencyCaptured}
                   onChange={(e) => {
                     setValue('billingFrequencyCaptured', e.target.value as BillingFrequency);
                   }}
                 >
                 {billingFrequencyOptions.map((option) => (
                   <MenuItem key={option.key} value={option.value}>
                     {option.key}
                   </MenuItem>
                 ))}
                 </Select>
               </FormControl>
             </Grid>
           </Grid>
         </Grid>        
        <Grid item xs={12}>
          <TextField
            error={!!errors.discountAmount}
            helperText={!!errors.discountAmount && errors.discountAmount.message}
            id='discountAmount'
            label='Discount Amount'
            sx={{ width: '100%' }}
            autoFocus
            onFocus={() => clearErrors('discountAmount')}
            {...register('discountAmount')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!errors.discountPercent}
            helperText={!!errors.discountPercent && errors.discountPercent.message}
            id='discountPercent'
            label='Discount Percent'
            sx={{ width: '100%' }}
            autoFocus
            {...register('discountPercent')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!errors.invoiceTotal}
            helperText={!!errors.invoiceTotal && errors.invoiceTotal.message}
            required
            InputLabelProps={{ shrink: true }}
            id='invoiceTotal'
            label='Invoice Total'
            sx={{ width: '100%' }}
            autoFocus
            {...register('invoiceTotal')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='poNumber'
            label='PO Number'
            rows={3}
            sx={{ width: '100%' }}
            fullWidth
            {...register('poNumber')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='notes'
            label='Notes'
            rows={3}
            multiline
            sx={{ width: '100%' }}
            fullWidth
            {...register('notes')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Is Paid?</FormLabel>
            <RadioGroup row aria-label="isPaid" defaultValue={invoice?.isPaid ? invoice.isPaid : 'false'} disabled={(!!invoice && invoice.isPaid)} {...register('isPaid')}>
              <FormControlLabel value={true} control={<Radio />} label="Yes" {...register('isPaid')} />
              <FormControlLabel value={false} control={<Radio />} label="No" {...register('isPaid')} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>{(!invoice || !invoice.isPaid) ? 'Cancel' : 'Close'}</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}
