import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';

import getBestPractice from '../../../services/bestPractice';
import { BestPracticeQuestionDetail } from '../../../interfaces/bestPractice.interface';
import { Question } from '../../../interfaces/question.interface';
import { Answer } from '../../../interfaces/answer.interface';
import { QuestionAnswer } from '../../../interfaces/questionAnswer.interface';
import createQuestionAnswer from '../../../services/questionAnswer';
import { OnboardingContainer } from './OnboardingForm';
import { useAppSelector } from '../../../app/hooks';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getQuestion } from '../../../services/question';

const QuestionDetail = (props: { initialQuestion: Question, display: boolean, setDisplayIndex: any, questions: Question[], currentIndex: number }) => {
  const {
    user,
  } = useAppSelector(state => state.user);
  const { initialQuestion, display, questions, setDisplayIndex, currentIndex } = props;
  const [question, setQuestion] = useState<Question | null>(null);
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [newQuestionAnswer, setNewQuestionAnswer] = useState<QuestionAnswer | null>(null);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showFinishButton, setShowFinishButton] = useState(false);

  useState(() => {
    if (!initialQuestion) return;
    getQuestion(initialQuestion.id).then((questionResponse: Question) => setQuestion(questionResponse));
  });

  const createQuestionAnswerObject = (answerId: string) => {
    setShowSubmitButton(true);
    if (!user?.vendorAdminProfile?.vendor?.id || !question) {
      console.error('Error creating QuestionAnswer object. \nUser does not have an associated vendor id.');
      return;
    }
    setNewQuestionAnswer({ survey: -1, question: question.id, answer: [parseInt(answerId)], questionnaire: typeof(question.questionnaire) === 'number' ? question.questionnaire : question.questionnaire.id });
  };

  const handleButtonDisplay = () => {
    if (questions[currentIndex + 1]) {
      setDisplayIndex(currentIndex + 1);
    } else {
      setShowFinishButton(true);
      setContinueEnabled(false);
      setContinueEnabled(false);
    }
  };
  const handleSubmitOnClick = () => {
    if (!newQuestionAnswer) {
      console.error('Attempted to submit new QuestionAnswer object when one did not exist.');
      return;
    }
    createQuestionAnswer(newQuestionAnswer).then(() => {
      setContinueEnabled(true);
      handleButtonDisplay();
      setShowSubmitButton(false);
    });
  };

  return (
    <Box sx={{ display: `${display ? 'block' : 'none'}` }}>
      {question &&
        <>
          <Typography variant='h6' align='center'>{question.title}</Typography>
          <FormControl>
              <RadioGroup name={question.slug} onChange={(event: React.ChangeEvent<HTMLInputElement>) => createQuestionAnswerObject(event.target.value)}>
                  <Grid container mt={5}>
                    {question.answers.map((answer: Answer, index: number) =>
                      <Grid key={index} item xs={6}>
                        <FormControlLabel value={answer.id} control={<Radio/>} label={answer.text}/>
                      </Grid>,
                    )}
                  </Grid>
              </RadioGroup>
          </FormControl>
          <Grid container mt={5} justifyContent="right">
              <Grid item>
                  <Stack spacing={2} direction='row'>
                    {showSubmitButton && <Button variant='contained' onClick={() => handleSubmitOnClick()}>Submit</Button>}
                    {continueEnabled && <Button variant='contained' onClick={() => handleButtonDisplay()} endIcon={<NavigateNextIcon />}>Continue</Button>}
                    {showFinishButton && <Link to='/company-onboarding/payments' style={{ textDecoration: 'none' }}><Button variant='contained' endIcon={<NavigateNextIcon />}>Continue</Button></Link>}
                  </Stack>
              </Grid>
          </Grid>
        </>
      }
    </Box>
  );
};

const ProgressDisplay = (props: { displayIndex: number, questionnaireLength: number }) => {
  const { displayIndex, questionnaireLength } = props;
  return (
    <Box mb={2}>
      <Typography align='center' variant='body1'>Question {displayIndex + 1} of {questionnaireLength}</Typography>
      <LinearProgress variant='determinate' value={((displayIndex + 1) / questionnaireLength) * 100} />
    </Box>
  );
};

const OnBoardingQuestionnaire = () => {
  const [bestPractice, setBestPractice] = useState<BestPracticeQuestionDetail | null>(null);
  const [displayIndex, setDisplayIndex] = useState(0);

  useEffect(() => {
    if (bestPractice) return;
    getBestPractice('vendor-onboarding').then((bestPracticeResponse: BestPracticeQuestionDetail) => {
      setBestPractice(bestPracticeResponse);
    }).catch((errors: any) => {
      console.log(errors);
    });
  }, []);

  return (
    <OnboardingContainer>
      {bestPractice?.questions && <ProgressDisplay displayIndex={displayIndex} questionnaireLength={bestPractice.questions.length} />}
      {bestPractice?.questions && bestPractice.questions.map((ListQuestion: Question, index: number) =>
        <QuestionDetail
          key={index}
          initialQuestion={ListQuestion}
          display={index == displayIndex}
          setDisplayIndex={setDisplayIndex}
          questions={bestPractice.questions}
          currentIndex={index}
        />,
      )}
    </OnboardingContainer>
  );
};

export default OnBoardingQuestionnaire;