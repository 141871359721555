import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { DataRow, DataCell } from '../../Tables/DataList';
import { Domain } from '../../../interfaces/domain.interface';
import { Box } from '@mui/material';
import { resetQuestion } from '../../../app/store/questionSlice';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { postExclusionConfigurations, deleteExclusionConfigurations } from '../../../services/inclusionConfiguration';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { fetchQuestionnaire, fetchTopics, fetchBestPractices } from '../../../services/questionnaires';
import { BestPractice } from '../../../interfaces/bestPractice.interface';
import { IncludedInLogic } from '../../Questions/Question';
import { Answer } from '../../../interfaces/answer.interface';
import { getQuestion } from '../../../services/question';
import { Question } from '../../../interfaces/question.interface';

export default function ScopeModal({ answers, includedInLogic, answer, isOpen, closeModal, inclusionQuestionId, inclusionAnswerId }: {
  answer?: string;
  includedInLogic: IncludedInLogic[];
  isOpen: boolean;
  closeModal: () => void;
  answers: Answer[];
  inclusionQuestionId: number;
  inclusionAnswerId: number;
}) {
  const dispatch = useAppDispatch();
  const { results: questionnaires, questionnaire } = useAppSelector(state => state.questionnaires);

  const [questionnaireSlug, setQuestionnaireSlug] = useState<number | string | undefined>(questionnaire?.id);
  const [selectedDomainSlug, setSelectedDomainSlug] = useState<string | undefined>();
  const [selectedQuestions, setSelectedQuestions] = useState<{ [key: number]: boolean }>({});
  const [originalSelectedQuestions, setOriginalSelectedQuestions] = useState<{ [key: number]: boolean }>({});
  const [bpSlug, setBpSlug] = useState<string>('');

  const [domains, setDomains] = useState<Domain[] | null>(null);
  const [topics, setTopics] = useState<Domain | null>(null);
  const [bestPractice, setBestPractice] = useState<BestPractice[] | string | null>(null); 
  const [bestPracticeQuestions, setBestPracticeQuestions] = useState<BestPractice | null>(null);

  const handleFetchDomains = useCallback(async (slug: string) => {
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const resp = await fetchQuestionnaire(slug);
    setDomains(resp.domains);
  }, [questionnaireSlug]);

  const handleAddScopeClose = useCallback(() => {
    setSelectedDomainSlug(undefined);
    setQuestionnaireSlug(undefined);
    setBestPracticeQuestions(null);
    setSelectedQuestions({});
    setBpSlug('');
    setBestPractice(null);
    setDomains(null);
    setTopics(null);
    closeModal();
  }, []);

  const handleFetchTopics = useCallback(async (slug: string) => {
    setSelectedDomainSlug(slug);
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const fetchedTopics = await fetchTopics(slug);
    setTopics(fetchedTopics);
  }, []);

  const handleFetchBestPracticeQuestions = useCallback(async (slug: string) => {
    setBpSlug(slug);
    const fetchedQuestions = await fetchBestPractices(slug);
    setBestPracticeQuestions(fetchedQuestions);
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuestions({
      ...selectedQuestions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleQuestionnaireSelect = (slug: string) => {
    setQuestionnaireSlug(slug);
    handleFetchDomains(slug);
    setTopics(null);
    setBestPracticeQuestions(null);
    setBestPractice(null);
  };

  const handleQuestionExclusion = async (question: Question, incQuestionId: number, incAnswerId: number, inLogicArray: IncludedInLogic[]) => {
    try {
      if (!selectedQuestions[question.id]) {
        // Delete inclusion if it exists
        await deleteExclusionConfigurations(question.id, [], incQuestionId);
        return;
      }

      if (inLogicArray.some((inc) => inc.id === question.id)) {
        // Question hidden by some other answer, delete answer inclusion and create new one(s)
        const validInclusion = inLogicArray.find((inc) => inc.id === question.id);
        if (!validInclusion) return;

        const questionRelatedInclusions = validInclusion.inclusions.filter((inc) => typeof inc.question !== 'number' && inc.question.id === incQuestionId);
        const inclusionToNotDelete = questionRelatedInclusions
          .filter((inc) => typeof inc.answer !== 'number' && inc.answer.id !== incAnswerId)
          .map((inc) => (inc.answer as Answer).id);
        await deleteExclusionConfigurations(question.id, inclusionToNotDelete, incQuestionId);
        await postExclusionConfigurations(question.id, inclusionToNotDelete.concat(incAnswerId), incQuestionId);
        return;
      }

      // Simple addition pass questionId and incAnswerId to new service
      await postExclusionConfigurations(question.id, [incAnswerId], incQuestionId);
      return;
    } catch (error) {
      console.error(`Error processing question ID ${question.id}:`, error);
    }
  };

  const handleSaveScopes = useCallback(async () => {
    if (!bestPracticeQuestions?.questions) return;

    try {
      const promises = bestPracticeQuestions.questions
        .filter((question) => originalSelectedQuestions[question.id] !== selectedQuestions[question.id])
        .map((question) => handleQuestionExclusion(question, inclusionQuestionId, inclusionAnswerId, includedInLogic));

      await Promise.all(promises);
      dispatch(resetQuestion());
      handleAddScopeClose();
    } catch (error) {
      console.error('Error updating questions:', error);
    }
  }, [selectedQuestions, bestPracticeQuestions?.questions, includedInLogic, inclusionAnswerId, dispatch, handleAddScopeClose]);

  useEffect(() => {
    if (!bestPracticeQuestions) return;

    const fetchInitialSelectedQuestions = async () => {
      const initialSelectedQuestions: { [key: number]: boolean } = {};

      for (const question of bestPracticeQuestions.questions) {
        const detailedQuestion = await getQuestion(question.id);
        const allButSelected = answers.filter((ans) => ans.id !== inclusionAnswerId);

        const isSelected =
          question.inclusions.length > 0 &&
          allButSelected.every((ans) =>
            detailedQuestion.inclusions.some(
              (inclusion) => String((inclusion.answer as Answer)?.id) === String(ans.id),
            ),
          );

        initialSelectedQuestions[question.id] = isSelected;
      }

      setSelectedQuestions(initialSelectedQuestions);
      setOriginalSelectedQuestions(initialSelectedQuestions);
    };

    fetchInitialSelectedQuestions();
  }, [bestPracticeQuestions, answers, inclusionAnswerId]);

  const filteredQuestionnaires = React.useMemo(() => questionnaires?.filter(q => q.type === 'mpa_best_practice'), [questionnaires]);  

  return (
        <StandardDialog title={'Questionnaire Scope Reducer'} handleClose={handleAddScopeClose} isOpen={isOpen}>
        <Typography variant='h6' sx={{ marginBottom: '15px' }}>
            Answer: {answer}</Typography>
        {!!filteredQuestionnaires?.length && <>
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
        <Select
            labelId="questionnaire-label"
            id="questionnaire"
            value={questionnaireSlug || ''}
            label="Questionnaire"
            onChange={(e) => handleQuestionnaireSelect(e.target.value as string)}
        >
            {filteredQuestionnaires.map(q=> <MenuItem key={q.slug} value={q.slug}>{q.title} {q.version}</MenuItem>)}
        </Select>
        </FormControl>
        </>}
        {domains && domains.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="domain-label">Domain</InputLabel>
        <Select
            labelId="domain-label"
            id="domain"
            value={selectedDomainSlug || ''}
            onChange={(e) => handleFetchTopics(e.target.value)}
            label="Domain"
        >
            {domains.map((domain, idx) => <MenuItem key={idx} value={domain.slug}>{domain.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        {topics && topics.topics.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="topic-label">Topic</InputLabel>
        <Select
            labelId="topic-label"
            id="topic"
            value={bestPractice || ''}
            onChange={(e) => { setBestPractice(e.target.value); setBestPracticeQuestions(null);}}
            label="Topic"
        >
            {topics.topics.map((topic: any, idx: number) => <MenuItem key={idx} value={topic?.bestPractices}>{topic.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        {bestPractice && Array.isArray(bestPractice) && bestPractice.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="best-practice-label">Best Practice</InputLabel>
        <Select
            labelId="best-practice-label"
            id="best-practice"
            value={bpSlug}
            onChange={(e) => handleFetchBestPracticeQuestions(e.target.value as string)}
            label="Best Practice"
        >
            {bestPractice.map((bp: BestPractice, idx: number) => <MenuItem key={idx} value={bp.slug}>{bp.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        { bestPracticeQuestions && bestPracticeQuestions.questions.length > 0 && (
        <FormControl component="fieldset" fullWidth sx={{ marginBottom: '10px' }}>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>Best Practice Questions</Typography>
        <DataRow header>
        <DataCell xs={9}>Question</DataCell>
        <DataCell xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>De-scope
          <Tooltip
            title={
              <Box
                sx={{
                  padding: 1,
                  width: '300px',
                }}
              >
                <Typography variant="h6" gutterBottom> If Baseline Answer is &apos;x&apos;, hide Best Practice Question &apos;y&apos;</Typography>
                <Typography variant="caption">
                  Select this checkbox to narrow the Questionnaire Scope by hiding the selected
                  Best Practice question below when the specified Baseline Answer above is chosen.
                </Typography>
              </Box>
            }
            arrow
            placement="top"
          >
            <IconButton size="small" sx={{ marginLeft: 0 }}>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </DataCell>
        </DataRow>
        {bestPracticeQuestions.questions.map((bpq) => (
        <DataRow key={bpq.id}>
            <DataCell xs={9}>{bpq.title}</DataCell>
            <DataCell xs={3}>
                <Checkbox
                    checked={selectedQuestions[bpq.id] || false}
                    onChange={handleCheckboxChange}
                    name={bpq.id.toString()}
                />
            </DataCell>
        </DataRow>
        ))}
        </FormControl>
        )}
        <StandardDialogActions>
          <Button variant="outlined" onClick={handleAddScopeClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveScopes}color='primary'>Save</Button>
        </StandardDialogActions>
        </StandardDialog>
  );
}