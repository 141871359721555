import axios from 'axios';

import getAuthSession from './auth';
import { Evidence, File as FileTPN, QuestionAnswer } from '../interfaces/questionAnswer.interface';
import User from '../interfaces/users.interface';
import { FileUpload } from '../features/Forms/FileUpload';

export default async function createQuestionAnswer(questionAnswer: QuestionAnswer) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/question-answers/`,
    {
      ...questionAnswer,
      comment: questionAnswer.comment && questionAnswer.comment.length > 0 ? questionAnswer.comment : undefined,
      text: questionAnswer.text && questionAnswer.text.length > 0 ? questionAnswer.text : undefined,
    },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  const qa = response.data as QuestionAnswer;
  if (qa.id && qa.survey > 0) {
    axios.patch(
      `${process.env.REACT_APP_BASE_API}/survey-question-answers/survey/${qa.survey}/question-answer/${qa.id}/`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
  }
  return qa;
}

export async function updateQuestionAnswer(questionAnswer: QuestionAnswer) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/question-answers/${questionAnswer.id}/`,
    {
      ...questionAnswer,
      text: questionAnswer.text && questionAnswer.text.length > 0 ? questionAnswer.text : undefined,
    },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as QuestionAnswer;
}

export async function createFile(file: File) {
  const authSession = await getAuthSession();

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/files/`,
    { document: file },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data as FileTPN;
}

export async function createEvidence(file: FileTPN, fileUpload: FileUpload, user:User) {
  const authSession = await getAuthSession();

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/evidence/`,
    { 
      file: file.id, 
      createdBy: user.id, 
      company: typeof user.companies[0] === 'number' ? user.companies[0] : user.companies[0].id, 
      isPublic: fileUpload.isPublic, 
    },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as Evidence;
}

export async function createEvidenceFlow(file: FileUpload, user: User) {
  const nFile = await createFile(file.file as File);
  const evidence = await createEvidence(nFile, file, user);
  return evidence;
}

export async function uploadMultipleAttachments(files: FileUpload[], user:User) {
  const evidence:Evidence[] = await Promise.all(files.map(file => createEvidenceFlow(file, user)));
  return evidence;
}

export async function createMultipleFiles(files:File[]) {
  try {
    return await Promise.all(files.map(file => createFile(file)));
  } catch (error) {
    return [];
  }
}

export async function getManyEvidence(evidenceIds:number[]) {
  const authSession = await getAuthSession();

  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/evidence/?ids=${evidenceIds.join(',')}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );

  return response.data.results as Evidence[];
}

export async function updateSingleEvidence(evidence: FileUpload) {
  const authSession = await getAuthSession();

  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/evidence/${evidence.id}/`,
    { isPublic: evidence.isPublic },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );

  return response.data as Evidence;
}

export async function updateEvidences(evidences: FileUpload[]) {
  const updatedEvidences: Evidence[] = await Promise.all(evidences.map(ev => updateSingleEvidence(ev)));
  return updatedEvidences;
}


export async function deleteEvidence(id: number) {
  const authSession = await getAuthSession();

  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/evidence/${id}/`,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as Evidence;
}