import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Assessment, AssessmentSurvey, AssessorAnswer } from '../../interfaces/assessment.interface';
import { Survey } from '../../interfaces/survey.interface';
import { fetchAssessment } from '../../services/assessments';
import { fetchSurvey } from '../../services/surveys';
import { buildFullQuestionnaireObject } from '../../services/questionnaires';
import { fetchQuestionDependantObjects, initialBuildOutQuestionnaire } from '../../services/buildQuestionnaire';
import { Questionnaire, ReportVersion } from '../../interfaces/questionnaire.interface';
import { Question } from '../../interfaces/question.interface';
import { getCompany } from '../../services/company';
import User, { Company, CompanyApplication, Contact } from '../../interfaces/users.interface';
import { QuestionAnswer } from '../../interfaces/questionAnswer.interface';
import moment from 'moment';
import { appTheme } from '../Themes/light';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Answer } from '../../interfaces/answer.interface';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useAppSelector } from '../../app/hooks';
import BouncingDotsLoader from '../Forms/Bouncing3dot';
import { Topic } from '../../interfaces/topic.interface';
import { fetchWorkflows } from '../../services/workflow';

export default function AssessmentReport(props: { assessmentId?: number | null, surveyId?: number }) {
  const { assessmentId, surveyId } = props;
  const { user } = useAppSelector(state => state.user);
  const [reportLoading, setReportLoading] = useState(false);
  const [assessment, setAssessment] = useState<Assessment | undefined>(undefined);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [baseline, setBaseline] = useState<Survey | undefined>(undefined);
  const [assessmentSurvey, setAssessmentSurvey] = useState<AssessmentSurvey | undefined>(undefined);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | undefined>(undefined);
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [domainRollUps, setDomainRollUp] = useState<any>(undefined);
  const [bpDisplay, setBPDisplay] = useState<any>(undefined);
  const [reportReady, setReportReady] = useState(false);
  const [site, setSite] = useState<Contact | undefined>(undefined);
  const [application, setApplication] = useState<CompanyApplication | undefined>(undefined);
  const [employeeCount, setEmployeeCount] = useState<Answer | undefined>(undefined);
  const [workFromHome, setWorkFromHome] = useState<Answer | undefined>(undefined);
  const [byod, setBYOD] = useState<Answer | undefined>(undefined);
  const [thirdParty, setThirdParty] = useState<Answer | undefined>(undefined);
  const [contentTypes, setContentTypes] = useState<Answer[] | undefined>(undefined);
  const [physicalContent, setPhysicalContent] = useState<Answer | undefined>(undefined);
  const [timeframes, setTimeframes] = useState<Answer[] | undefined>(undefined);
  const [environments, setEnvironments] = useState<Answer[] | undefined>(undefined);
  const [dataCenter, setDataCenter] = useState<Answer | undefined>(undefined);
  const [replication, setReplication] = useState<Answer | undefined>(undefined);
  const [software, setSoftware] = useState<Answer | undefined>(undefined);
  const [reportVersion, setReportVersion] = useState<ReportVersion>(ReportVersion.v5_2);
  const [countTotals, setCountTotals] = useState({
    bpCounts: {
      fi: 0,
      pi: 0,
      ni: 0,
      na: 0,
    },
    igCounts: {
      fi: 0,
      pi: 0,
      ni: 0,
      na: 0,
    },
  });

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const buildReport = async () => {
    if (!survey || !survey.questionnaire) {
      setReportLoading(false);
      return;
    }

    setReportLoading(true);
    const surQuestionnaire = survey.questionnaire as Questionnaire;

    getCompany(typeof survey.company === 'number' ? survey.company.toString() : survey.company.id!.toString()).then(comp => setCompany(comp));
    const questionnaireObjects = await buildFullQuestionnaireObject(surQuestionnaire.id);
    const builtQuestionnaire = initialBuildOutQuestionnaire({ ...questionnaireObjects, questionnaire: surQuestionnaire });
    setQuestionnaire(builtQuestionnaire.questionnaire);

    const fetchQuestions = await fetchQuestionDependantObjects(false, builtQuestionnaire.allQuestions);
    setQuestions(fetchQuestions.questions);
  };

  const bpHasPIandNI = (bp: BestPractice, assessorAnswers: AssessorAnswer[]) => {
    let found = false;

    (bp.questions as unknown[] as number[]).forEach(questionId => {
      const assessorAnswer = assessorAnswers.find(aa => aa.question === questionId);
      if (!assessorAnswer) return;
      if (['Partially Implemented', 'Not Implemented'].includes(assessorAnswer.answer as string)) {
        found = true;
      }
    });

    return found;
  };

  const loopQuestionnaire = () => {
    if (!survey || !assessment || !company || !questionnaire || !questions || questions.length === 0) {
      setReportLoading(false);
      return;
    }

    const questionAnswers = survey.questionAnswers;
    const curAssessment = (assessment.surveys as AssessmentSurvey[]).find(assSur => typeof assSur.survey === 'number' ? assSur.survey === survey.id : assSur.survey.id === survey.id);
    const assessorAnswers = curAssessment?.assessorAnswers as AssessorAnswer[];

    if (!questionAnswers || questionAnswers.length === 0 || !curAssessment || !assessorAnswers || assessorAnswers.length === 0) {
      setReportLoading(false);
      return;
    }
    setAssessmentSurvey(curAssessment);

    const rollups: any[] = [];
    const bps: any[] = [];
    const totalCounts = {
      bpCounts: {
        fi: 0,
        pi: 0,
        ni: 0,
        na: 0,
      },
      igCounts: {
        fi: 0,
        pi: 0,
        ni: 0,
        na: 0,
      },
    };

    questionnaire.domains.forEach(domain => {
      const domainRollUp: any = { name: domain.title, bpCounts: { fi: 0, pi: 0, ni: 0, na: 0 }, igCounts: { fi: 0, pi: 0, ni: 0, na: 0 } };
      domain.topics.forEach(topic => {
        topic.bestPractices.forEach(bp => {
          const curBP = { bp, domain, topic, questions: ([] as any[]) };
          (bp.questions as unknown as number[]).forEach(questionId => {
            const question = questions.find(q => q.id === questionId);
            const answer = (survey.questionAnswers as QuestionAnswer[]).find(qa => qa.question === questionId);
            const assessorAnswer = assessorAnswers.find(aa => aa.question === questionId);
            if (!question || !assessorAnswer) return;

            if (bpHasPIandNI(bp, assessorAnswers)) {
              curBP.questions.push({ question, answer, assessorAnswer });
            }

            switch (assessorAnswer.answer) {
              case 'Fully Implemented':
                if (question.isBestPractice) {
                  domainRollUp.bpCounts.fi++;
                  totalCounts.bpCounts.fi++;
                }
                if (!question.isBestPractice) {
                  domainRollUp.igCounts.fi++;
                  totalCounts.igCounts.fi++;
                }
                break;
              case 'Partially Implemented':
                if (question.isBestPractice) {
                  domainRollUp.bpCounts.pi++;
                  totalCounts.bpCounts.pi++;
                }
                if (!question.isBestPractice) {
                  domainRollUp.igCounts.pi++;
                  totalCounts.igCounts.pi++;
                }
                break;
              case 'Not Implemented':
                if (question.isBestPractice) {
                  domainRollUp.bpCounts.ni++;
                  totalCounts.bpCounts.ni++;
                }
                if (!question.isBestPractice) {
                  domainRollUp.igCounts.ni++;
                  totalCounts.igCounts.ni++;
                }
                break;
              case 'Not Applicable':
                if (question.isBestPractice) {
                  domainRollUp.bpCounts.na++;
                  totalCounts.bpCounts.na++;
                }
                if (!question.isBestPractice) {
                  domainRollUp.igCounts.na++;
                  totalCounts.igCounts.na++;
                }
                break;
            }

          });
          if (curBP.questions.length > 0) bps.push(curBP);
        });
      });
      rollups.push(domainRollUp);
    });
    setDomainRollUp(rollups);
    setBPDisplay(bps);
    setCountTotals(totalCounts);
    if (employeeCount) {
      setReportLoading(false);
      setReportReady(true);
    }
  };

  const setSiteOrApplication = () => {
    if (!company || !survey) return;

    const csite = survey.site ? company.locations.find(location => location.id === survey.site) : undefined;
    const capplication = survey.application ? company.companyApplications?.find(companyApplication => companyApplication.application.id === survey.application) : undefined;

    setSite(csite);
    setApplication(capplication);
  };

  useEffect(() => {
    if (!survey) return;
    buildReport();
  }, [survey]);

  useEffect(() => {
    if (!questions || !company || !questionnaire) return;
    loopQuestionnaire();
    setSiteOrApplication();
  }, [questions, company, questionnaire]);

  const fetchBaselineQuestions = async () => {
    if ((!site && !application) || !company || !baseline) return;

    const getBaselineQuestions = await fetchQuestionDependantObjects(true, (baseline.questionAnswers as QuestionAnswer[]).map(qa => qa.question));
    const baselineQuestions = getBaselineQuestions.questions;
    if (!baselineQuestions.length || !baseline.questionAnswers?.length) return;

    const employeeCountQuestion = baselineQuestions.find(question => question.title.toLowerCase().indexOf('number') !== -1 && question.title.toLowerCase().indexOf('employees') !== -1);
    if (employeeCountQuestion) {
      const questionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === employeeCountQuestion.id);
      if (questionAnswer && questionAnswer.answer?.length) setEmployeeCount(employeeCountQuestion.answers.find(answer => answer.id === questionAnswer.answer![0]));
    }

    const wfhQuestion = baselineQuestions.find(question => question.title.includes('Work From Home/Remote'));
    if (wfhQuestion) {
      const wfhQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === wfhQuestion.id);
      if (wfhQuestionAnswer && wfhQuestionAnswer.answer?.length) {
        setWorkFromHome(wfhQuestion.answers.find(answer => answer.id === wfhQuestionAnswer.answer![0]));
      }
    }

    const byodQuestion = baselineQuestions.find(question => question.title === 'Bring Your Own Device');
    if (byodQuestion) {
      const byodQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === byodQuestion.id);
      if (byodQuestionAnswer && byodQuestionAnswer.answer?.length) setBYOD(byodQuestion.answers.find(answer => answer.id === byodQuestionAnswer.answer![0]));
    }

    const thirdPartyQuestion = baselineQuestions.find(question => question.title === 'Subcontract to Third-Party Service Providers');
    if (thirdPartyQuestion) {
      const thirdPartyQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === thirdPartyQuestion.id);
      if (thirdPartyQuestionAnswer && thirdPartyQuestionAnswer.answer?.length) setThirdParty(thirdPartyQuestion.answers.find(answer => answer.id === thirdPartyQuestionAnswer.answer![0]));
    }

    const contentTypeQuestion = baselineQuestions.find(question => question.title === 'Content Types');
    if (contentTypeQuestion) {
      const contentTypeQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === contentTypeQuestion.id);
      if (contentTypeQuestionAnswer && contentTypeQuestionAnswer.answer?.length) setContentTypes(contentTypeQuestion.answers.filter(answer => contentTypeQuestionAnswer.answer!.indexOf(answer.id) !== -1));
    }

    const timeframeQuestion = baselineQuestions.find(question => question.title === 'Workflow Timeframes');
    if (timeframeQuestion) {
      const timeframeQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === timeframeQuestion.id);
      if (timeframeQuestionAnswer && timeframeQuestionAnswer.answer?.length) setTimeframes(timeframeQuestion.answers.filter(answer => timeframeQuestionAnswer.answer!.indexOf(answer.id) !== -1));
    }

    const physicalContentQuestion = baselineQuestions.find(question => question.title === 'Physical Content Assets');
    if (physicalContentQuestion) {
      const physicalContentQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === physicalContentQuestion.id);
      if (physicalContentQuestionAnswer && physicalContentQuestionAnswer.answer?.length) setPhysicalContent(physicalContentQuestion.answers.find(answer => answer.id === physicalContentQuestionAnswer.answer![0]));
    }

    const environmentQuestion = baselineQuestions.find(question => question.title === 'Environments');
    if (environmentQuestion) {
      const environmentQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === environmentQuestion.id);
      if (environmentQuestionAnswer && environmentQuestionAnswer.answer?.length) setEnvironments(environmentQuestion.answers.filter(answer => environmentQuestionAnswer.answer!.indexOf(answer.id) !== -1));
    }

    const dataCenterQuestion = baselineQuestions.find(question => question.title === 'Data Center & Co-locations');
    if (dataCenterQuestion) {
      const dataCenterQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === dataCenterQuestion.id);
      if (dataCenterQuestionAnswer && dataCenterQuestionAnswer.answer?.length) setDataCenter(dataCenterQuestion.answers.find(answer => answer.id === dataCenterQuestionAnswer.answer![0]));
    }

    const replicationQuestion = baselineQuestions.find(question => question.title === 'Replication Facilities');
    if (replicationQuestion) {
      const replicationQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === replicationQuestion.id);
      if (replicationQuestionAnswer && replicationQuestionAnswer.answer?.length) setReplication(replicationQuestion.answers.find(answer => answer.id === replicationQuestionAnswer.answer![0]));
    }

    const softwareQuestion = baselineQuestions.find(question => question.title === 'Software Development');
    if (softwareQuestion) {
      const softwareQuestionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === softwareQuestion.id);
      if (softwareQuestionAnswer && softwareQuestionAnswer.answer?.length) setSoftware(softwareQuestion.answers.find(answer => answer.id === softwareQuestionAnswer.answer![0]));
    }
    if (bpDisplay) {
      setReportLoading(false);
      setReportReady(true);
    }
  };

  useEffect(() => {
    if ((!site && !application) || !company || !baseline) return;
    fetchBaselineQuestions();
  }, [baseline, site, application, company]);

  const requestBuildReport = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (reportLoading) return;
    if (!assessmentId || !surveyId) {
      setReportLoading(false);
      return;
    }

    setReportLoading(true);
    fetchAssessment(assessmentId).then(ass => setAssessment(ass));
    fetchSurvey(surveyId).then(sur => setSurvey(sur));
    fetchWorkflows({ 'bp_survey': surveyId, assessment: assessmentId }).then(wf => {
      if (wf.length > 0) {
        fetchSurvey(wf[0].baseline!).then((bas) => {
          setBaseline(bas);
          if (typeof bas.questionnaire !== 'number' && !bas.questionnaire.title.includes(ReportVersion.v5_2)) {
            setReportVersion(ReportVersion.v5_1);
          }
        });
      }
    });
  };

  const outputOverviewBox = () => {
    if (!assessmentSurvey || !company) return;
    const output = {
      margin: [0, 0, 0, 50],
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
          [{
            border: [true, false, true, true],
            stack: [
              { text: [{ text: 'Assessment Scope:', bold: true }, ` ${assessmentSurvey.isOnPremise ? survey?.site ? 'Site' : 'On-Prem' : ''}${assessmentSurvey.isOnPremise && assessmentSurvey.isCloud ? ', ' : ''}${assessmentSurvey.isCloud ? 'Cloud' : ''}`] },
              { text: [{ text: 'Assessment Type:', bold: true }, ` ${assessmentSurvey.isInPerson ? 'On Site' : 'Remote'}`] },
            ],
          }],
        ],
      },
    };

    if (site) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facility:', bold: true }, ` ${site.name}`] });
    if ((application && application.sites?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facilities:', bold: true }, ` ${application.sites.map(csite => csite.name).join(', ')}`] });
    if ((site && site.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${site.services.map(service => service.name).join(', ')}`] });
    if ((application && application.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${application.services.map(service => service.name).join(', ')}`] });
    if (employeeCount?.text) output.table.body[0][0].stack.push({ text: [{ text: 'Number of Employees:', bold: true }, ` ${employeeCount.text}`] });
    const ownedApps = company?.companyApplications
      ?.filter(
        (ca) =>
          ca.application.company &&
            (typeof ca.application.company === 'number' && ca.application.company === company.id) ||
            (typeof ca.application.company !== 'number' && ca.application.company?.id === company.id),
      );
    if (site && ownedApps?.length) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Applications:', bold: true }, ` ${ownedApps.map(app => app.application.name).join(', ')}`] });
    if (application) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Application:', bold: true }, application.application.name ] });
    const thirdPartyApps = company?.companyApplications
      ?.filter(
        (ca) =>
          !ca.application.company ||
          (typeof ca.application.company === 'number' && ca.application.company !== company.id) ||
          (typeof ca.application.company !== 'number' && ca.application.company.id !== company.id),
      );
    if (site && thirdPartyApps?.length) output.table.body[0][0].stack.push({ text: [{ text: '3rd Party Licensed Applications:', bold: true }, ` ${thirdPartyApps.map(app => app.application.name).join(', ')}`] });
    return output;
  };

  const summaryOutputBox = () => {
    if (!assessmentSurvey || !company) return;
    const output = {
      margin: [0, 0, 0, 50],
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
          [{
            border: [true, false, true, true],
            stack: [
              { text: ' ' },
              { text: 'Site and Assessment Information:', bold: true },
              { text: `${company.name} is located in ${company.contact?.country} and currently has ${employeeCount?.text} supporting the ${site ? site.name + ' site' : application?.application.name + ' application'} being assessed. ${site ? 'The following services are in scope for this assessment: ' + site.services?.map(service => service.name).join(', ') + '.' : ''} ${ application ? 'The following in-house developed applications are in scope for this assessment: ' + application.application.name + '.' : ''}` },
              { text: ' ' },
              { text: 'Organizational and Employee Information:', bold: true },
              { text: `At the time of this assessment, ${company.name} ${workFromHome?.text === 'Yes' ? 'does' : 'does not'} support Work From Home or Remote Workers. ${reportVersion === ReportVersion.v5_2 ? `${company.name} ${byod?.text === 'Yes' ? 'does' : 'does not'} support a Bring Your Own Device policy. ${company.name} currently ${thirdParty?.text ? 'does' : 'does not'} support Third-Party Service Providers.` : ''}` },
              { text: ' ' },
              { text: 'Content Assets and Workflow Information:', bold: true },
              { text: `At the time of this assessment, ${company.name} handles the following types of content: ${contentTypes?.map(answer => answer.text).join(', ')} with the following Workflow Timeframes: ${timeframes?.map(answer => answer.text).join(', ')}. ${reportVersion === ReportVersion.v5_2 ? `${company.name} ${physicalContent?.text === 'Yes' ? 'Does' : 'Does not'} handle physical content assets.` : ''}` },
            ],
          }],
        ] },
    };

    if (reportVersion === ReportVersion.v5_2) {
      output.table.body[0][0].stack.push(
        { text: ' ' },
        { text: 'Environments and Facility Information:', bold: true },
        { text: `At the time of this assessment, ${company.name} provides services in the following environments: ${environments?.map(answer => answer.text).join(', ')} and ${dataCenter?.text === 'Yes' ? 'does' : 'does not'} utilize an owned & operated Data-Center or Co-location facility` },
        { text: ' ' },
        { text: 'Additional Services Provided:', bold: true },
        { text: `At the time of this assessment, ${company.name} ${replication?.text === 'Yes' ? 'does' : 'does not'} provide replication services.  ${company.name} ${software?.text === 'Yes' ? 'does' : 'does not'} develop software in-house.` },
      );
    }

    return output;
  };

  const assessorSummaryOutputBox = () => {
    if (!assessmentSurvey || !assessmentSurvey.assessorComment) return;
    const output = {
      margin: [0, 0, 0, 50],
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
          [{
            border: [true, false, true, true],
            stack: [
              { text: ' ' },
              { text: assessmentSurvey.assessorComment },
            ],
          }],
        ] },
    };

    return output;
  };

  const outputDomainRollUps = () => {
    const rollUpArray:any = [
      [ { text: '' },
        { fillColor: '#ccc', text: 'Best Practice', colSpan: 4 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }, 
        { fillColor: '#ccc', text: 'Implementation Guidance', colSpan: 4 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { fillColor: '#ccc', text: 'Legend' },
      ],
      [ { fillColor: '#ccc', text: 'Security Domains', alignment: 'left' },
        { fillColor: appTheme.palette.success.main, text: 'FI', color: 'white' },
        { fillColor: appTheme.palette.nonCompliant.main, text: 'PI' },
        { fillColor: appTheme.palette.error.main, text: 'NI', color: 'white' },
        { fillColor: '#ccc', text: 'NA' },
        { text: '' },
        { fillColor: appTheme.palette.success.main, text: 'FI', color: 'white' },
        { fillColor: appTheme.palette.nonCompliant.main, text: 'PI' },
        { fillColor: appTheme.palette.error.main, text: 'NI', color: 'white' },
        { fillColor: '#ccc', text: 'NA' },
        { text: '' },
        { text: '' },
      ],
    ];
    domainRollUps.forEach((rollUp: any, i: number) => {
      const row = [
        { text: rollUp.name, alignment: 'left' },
        rollUp.bpCounts.fi,
        rollUp.bpCounts.pi,
        rollUp.bpCounts.ni,
        rollUp.bpCounts.na,
        '',
        rollUp.igCounts.fi,
        rollUp.igCounts.pi,
        rollUp.igCounts.ni,
        rollUp.igCounts.na,
        '',
      ];
      if (i === 0) row.push({ text: 'FI: Fully Implemented', alignment: 'left' });
      if (i === 1) row.push({ text: 'PI: Partially Implemented', alignment: 'left' });
      if (i === 2) row.push({ text: 'NI: Not Implemented', alignment: 'left' });
      if (i === 3) row.push({ text: 'NA: Not Applicable', alignment: 'left' });
      if (i > 3) row.push('');
      rollUpArray.push(row);
    });
    
    rollUpArray.push([
      { text: 'Totals', alignment: 'left' },
      countTotals.bpCounts.fi,
      countTotals.bpCounts.pi,
      countTotals.bpCounts.ni,
      countTotals.bpCounts.na,
      '',
      countTotals.igCounts.fi,
      countTotals.igCounts.pi,
      countTotals.igCounts.ni,
      countTotals.igCounts.na,
      '',
      '',
    ]);

    return rollUpArray;
  };

  const outputBPItems = () => {
    const bpItems:any = bpDisplay.map((bpitem: any, index: number) => {
      const item:any = [
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.code}: ${bpitem.domain.title} - ${bpitem.topic.title}`,
              } ],
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.topics.findIndex((top: Topic) => bpitem.topic.id === top.id) + 1}.${bpitem.topic.bestPractices.findIndex((bp: BestPractice) => bpitem.bp.id === bp.id)} ${bpitem.bp.title}`,
              } ],
            ],
          },
        },
        { text: bpitem.bp.description, margin: [0, 5, 0, 10] },
      ];
      bpitem.questions.map((qContain: any) => {
        const questionArray:any = [
          { text: (qContain.question.isBestPractice ? 'Best Practice' : 'Implementation Guidance'), fontSize: 16, margin: [0, 0, 0, 20], alignment: 'center' },
          { text: qContain.question.title, bold: true, margin: [0, 0, 0, 10] },
          { text: qContain.question.description, margin: [0, 0, 0, 5] },
        ];
        if (qContain && qContain.answer && qContain.answer.text) { questionArray.push(qContain.answer.text); } else {
          const answers = qContain.question.answers.map((answer: Answer) => {
            const answered = qContain.answer && qContain.answer.answer.findIndex((ans: number) => ans === answer.id) > -1;
            return {
              columns: [
                { width: 'auto', text: answered ? '√' : 'x' },
                { width: 'auto', text: answer.text },
              ],
              bold: answered,
              columnGap: 5,
            };
          });
          questionArray.push(...answers);
        }
        questionArray.push(...[
          qContain.answer?.comment && { text: `Service Provider Comment: ${qContain.answer?.comment}` },
          {
            table: {
              widths: '*',
              body: [
                [
                  {
                    border: [false, false, false, false],
                    fillColor: qContain.assessorAnswer.answer === 'Not Implemented' ? appTheme.palette.error.main : qContain.assessorAnswer.answer === 'Partially Implemented' ? appTheme.palette.nonCompliant.main : appTheme.palette.success.main,
                    color: qContain.assessorAnswer.answer === 'Partially Implemented' ? 'black' : 'white',
                    text: qContain.assessorAnswer.answer,
                  },
                ],
              ],
            },
            margin: [0, 10, 0, 5],
          },
          { text: 'Assessor Finding:', bold: true, margin: [0, 10, 0, 0] },
          qContain.assessorAnswer.assessorFinding,
        ]);
        if (qContain.assessorAnswer.vendorStatus) {
          questionArray.push(...[
            { text: 'Service Provider Remediation Plan:', bold: true, margin: [0, 10, 0, 0] },
            {
              ul: [
                { text: [{ text: 'Remediation Plan: ', bold: true }, `${qContain.assessorAnswer.vendorStatus}`], margin: [0, 5, 0, 0] },
                qContain.assessorAnswer.vendorStatus === 'Will Remediate Later'
                  ? { text: [{ text: 'Future Remediation Date: ', bold: true }, `${qContain.assessorAnswer.remediationDate ? moment(qContain.assessorAnswer.remediationDate).format('MMM D, YYYY') : 'Not available' }`], margin: [0, 5, 0, 0] }
                  : '',
                (qContain.assessorAnswer.vendorStatus === 'Remediated' || qContain.assessorAnswer.vendorStatus === 'Will not Remediate')
                  ? { text: [{ text: 'Remediation Date: ', bold: true }, `${qContain.assessorAnswer.remediationDate ? moment(qContain.assessorAnswer.remediationDate).format('MMM D, YYYY') : 'Not available' }`], margin: [0, 5, 0, 0] }
                  : '',
                { text: [{ text: 'Remediation Comment: ', bold: true }, `${qContain.assessorAnswer.remediationComment || 'Not available'}`], margin: [0, 5, 0, 0] },
              ].filter(Boolean),
            },
          ]);
        }
        item.push({
          table: {
            headerRows: 0,
            widths: ['*'],
            body: [
              [{
                stack: questionArray,
              }],
            ],
          },
          margin: [0, 20, 0, 0],
        });
      });
      if (index + 1 !== bpDisplay.length) return { stack: item, pageBreak: 'after' };
      return { stack: item };
    });

    return bpItems;
  };

  const generatePDF = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!(reportReady && assessmentSurvey && bpDisplay && domainRollUps && company && questionnaire && user)) return;

    const curDate = moment();

    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
      MaterialSymbolsOutlined: {
        normal: 'https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2',
      },
    };

    const docDefinition: any = {
      content: [
        {
          stack: [
            { image: 'TPN', fit: [400, 400], margin: [0, 130, 0, 20] },
            { text: 'GOLD CONTENT SECURITY ASSESSMENT', fontSize: 25 },
            ...(site ?
              [
                {
                  stack: [
                    company.name,
                    site.name,
                    site.address,
                    site.address2,
                    site.address3,
                    `${site.city}, ${site.state} ${site.zipcode}`,
                    site.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${site.primaryContact?.firstName} ${site.primaryContact?.lastName}`,
                site.primaryContact?.email,
                site.primaryContact?.contact?.phoneNumber,
              ]
              :
              [
                {
                  stack: [
                    company.name,
                    company.contact?.address,
                    company.contact?.address2,
                    company.contact?.address3,
                    `${company.contact?.city}, ${company.contact?.state} ${company.contact?.zipcode}`,
                    company.contact?.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${company.primaryContactUser?.firstName} ${company.primaryContactUser?.lastName}`,
                company.primaryContactUser?.email,
                company.primaryContactUser?.contact?.phoneNumber,
              ]
            ),
            {
              stack: [
                `Assessment Date: ${moment(assessmentSurvey.reportIssueDate).format('YYYY-MM-DD')}`,
                `Report Generated: ${moment().format('YYYY-MM-DD')}`,
                `Expiration Date: ${moment(assessmentSurvey.expirationDate).format('YYYY-MM-DD')}`,
                `TPN Assessor: ${(assessment?.assignedAssessor as User).firstName} ${(assessment?.assignedAssessor as User).lastName}`,
              ], fontSize: 12, margin: [0, 40, 0, 0],
            },
            { text: questionnaire.title, margin: [0, 50, 0, 0] },
          ], alignment: 'center', pageBreak: 'after',
        },
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Overview',
              }],
            ],
          },
        },
        outputOverviewBox(),
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Baseline Summary',
              }],
            ],
          },
        },
        summaryOutputBox(),
        assessmentSurvey.assessorComment && {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Assessor Summary',
              }],
            ] },
        },
        assessmentSurvey.assessorComment && assessorSummaryOutputBox(),
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Assessment Dashboard',
              }],
            ],
          },
        },
        {
          table: {
            headerRows: 1,
            widths: [80, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 76],
            body: outputDomainRollUps(),
          },
          pageBreak: 'after',
        },
        {
          stack: outputBPItems(),
        },
      ],
      images: {
        TPN: `${window.location.origin}/assets/TPN_GoldLogoWhiteBkgrnd_1280wide.png`,
      },
      styles: {
        headerBG: {
          fontSize: 16,
          color: 'white',
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
      info: {
        title: `TPN-Gold_Assessment-${encodeURIComponent(company.name.replace(/\s+/g, '-'))}-${curDate.format('YYYY-MM-DD')}`,
        author: 'Trusted Partner Network',
      },
      watermark: { text: `TPN Assessment - ${user.email} - ${curDate.format('MMMM DD YYYY')}`, color: 'blue', opacity: 0.2, bold: true, italics: false },
      footer: function (currentPage: number, pageCount: number) { return { text: currentPage.toString() + ' of ' + pageCount, alignment: 'center', margin: [0, 10, 0, 0 ] }; },
      header: { text: `TPN Gold Assessment: ${company.name} ${curDate.format('MMMM DD YYYY')}`, alignment: 'center', margin: [0, 5, 0, 5] },
    };
    pdfMake.createPdf(docDefinition).download(`TPN-Gold_Assessment-${encodeURIComponent(company.name.replace(/\s+/g, '-'))}-${curDate.format('YYYY-MM-DD')}.pdf`);
  };

  return (<>
    {(!assessmentId || !surveyId) && <Typography>Could not find assessment</Typography>}
    {assessmentId && surveyId && !reportReady && <Button sx={{ maxWidth: '100%', width: '205px' }} size='small' variant='outlined' onClick={(e) => requestBuildReport(e)}>Generate Report{reportLoading && <BouncingDotsLoader />}</Button>}
    {reportReady &&
      <Button sx={{ maxWidth: '100%', width: '205px' }} size='small' variant='contained' color='success' onClick={(e) => generatePDF(e)}>Download Report</Button>
    }
  </>);
}