import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { resetQuestionnaireAnswer, setCompanyCertifications, setPreview, setSubmitted } from '../../app/store/questionnaireAnswerSlice';

import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { Domain } from '../../interfaces/domain.interface';
import { Topic } from '../../interfaces/topic.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';

import { fetchQuestionnaire } from '../../app/store/questionnaireSlice';
import { fetchDomain } from '../../app/store/domainSlice';
import { fetchTopic } from '../../app/store/topicSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import QuestionnnaireAnswer from '../Questionnaires/QuestionnnaireAnswer';
import { Certification } from '../../interfaces/certification.interface';
import { CompanyCertification } from '../../interfaces/users.interface';

export default function QuestionnairePreview(props: {
  buttonClick: any,
  buttonText: string,
  questionnaire?: Questionnaire,
  domain?: Domain,
  topic?: Topic,
  bestPractice?: BestPractice
}) {
  const dispatch = useAppDispatch();
  const [questionnaire, setQuestionnaire] = useState(props.questionnaire);
  const [domain, setDomain] = useState(props.domain);
  const [topic, setTopic] = useState(props.topic);

  const { allCertifications } = useAppSelector(state => state.questionnaireAnswer);

  const { questionnaire: questionnaireResult } = useAppSelector(state => state.questionnaires);
  const { domain: domainResult } = useAppSelector(state => state.domains); 
  const { topic: topicResult } = useAppSelector(state => state.topics);
  const { submitted } = useAppSelector(state => state.questionnaireAnswer);

  useEffect(() => {
    if (props.bestPractice) {
      if (typeof(props.bestPractice.questionnaire) !== 'number') dispatch(fetchQuestionnaire(props.bestPractice.questionnaire.slug));
      if (typeof(props.bestPractice.domain) !== 'number') dispatch(fetchDomain(props.bestPractice.domain.slug));
      if (typeof(props.bestPractice.topic) !== 'number') dispatch(fetchTopic(props.bestPractice.topic.slug));
    }
    if (props.topic) {
      if (typeof(props.topic.questionnaire) !== 'number') dispatch(fetchQuestionnaire(props.topic.questionnaire.slug));
      if (typeof(props.topic.domain) !== 'number') dispatch(fetchDomain(props.topic.domain.slug));
    }
    if (props.domain) {
      if (typeof(props.domain.questionnaire) !== 'number') dispatch(fetchQuestionnaire(props.domain.questionnaire.slug));
    }
    dispatch(setPreview(true));
    return (() => {
      dispatch(resetQuestionnaireAnswer());
    });
  }, []);

  useEffect(() => {
    if (!questionnaireResult) return;
    setQuestionnaire(questionnaireResult);
  }, [questionnaireResult]);

  useEffect(() => {
    if (!domainResult) return;
    setDomain(domainResult);
  }, [domainResult]);

  useEffect(() => {
    if (!topicResult) return;
    setTopic(topicResult);
  }, [topicResult]);

  const testDisplay = () => {
    if (props.bestPractice) return topic && domain && questionnaire;
    if (props.topic) return domain && questionnaire;
    return questionnaire;
  };

  const setCompCerts = (value:Certification[]) => {
    const companyCert = {
      company: 1,
      issueDate: moment().format('MM/DD/YYYY'),
      expirationDate: moment().format('MM/DD/YYYY'),
      document: 'thing',
      sites: [],
      applications: [],
      createdAt: moment().format('MM/DD/YYYY'),
    };
    let companyCerts:CompanyCertification[] = [];
    value.forEach(certification => {
      companyCerts.push({ ...companyCert, certification });
    });
    dispatch(setCompanyCertifications(companyCerts));
  };

  const setSubmittedForAssessment = () => {
    dispatch(setSubmitted(!submitted));
  };

  return (
    <>
      {testDisplay() && <>
        <Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={() => props.buttonClick()}>Close Questionnaire Preview</Button>
            {allCertifications &&
              <Autocomplete
                multiple
                id="select-certifications"
                options={allCertifications}
                sx={{ minWidth: '300px' }}
                getOptionLabel={(option) => option.title + ': ' + option.version}
                onChange={(e, value) => setCompCerts(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Certifications"
                    placeholder="Select Certifications"
                  />
                )}
              />
            }
            <Button variant="outlined" onClick={() => setSubmittedForAssessment()}>{submitted ? 'Revert Submission' : 'Set Submitted'}</Button>
          </Box>
        </Box>
        <Divider sx={{ margin: '1em 0 2em 0' }} />
        <QuestionnnaireAnswer
          questionnaire={questionnaire!}
          domain={domain}
          topic={topic}
          bestPractice={props.bestPractice}
        />
      </>}
    </>
  );
}