// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
// MUI
import { Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Typography } from '@mui/material';
import { Delete, DeleteOutlineOutlined, EditOutlined, ExpandLess, ExpandMore, MoreVert } from '@mui/icons-material';
// redux
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { fetchAllSurveys } from '../../../../../app/store/surveySlice';
// interface
import {
  Application,
  ApplicationType,
  Company,
  CompanyApplication,
  Version,
} from '../../../../../interfaces/users.interface';
// service
import { patchSurvey } from '../../../../../services/surveys';
import { recentBadgeByType, fetchGoldBadgeLabel, fetchBlueBadgeLabel } from '../../../../../services/badge';
import { deleteCompanyApplication } from '../../../../../services/companyApplication';
import { createBadge, updateVersionBadge } from '../../../../../services/applications';
// components
import GetBadge from '../../../GetBadge';
import NewCustomApp from '../../ApplicationModals/NewCustomApp';
import { DataCell, DataRow } from '../../../../Tables/DataList';
import ChipsWithOverflow from '../../../../Layout/ChipsWithOverflow';
import VersionsAccordion from './VersionsAccordion/VersionsAccordion';
import StandardDialog, { StandardDialogActions } from '../../../../Modals/StandardDialog';

type InhouseAppDataRowProps = {
  company: Company;
  companyApplication: CompanyApplication;
  userCanAdminCompany: boolean;
  companySuccessCallback: any;
  onVersionRowClick: (companyApp: CompanyApplication, app: Application, version: Version) => void;
};

const InhouseAppDataRow = (props: InhouseAppDataRowProps) => {
  const { company, companyApplication, userCanAdminCompany, companySuccessCallback, onVersionRowClick } = props;
  // redux
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector((state) => state.surveys);
  const { workflows: companyWorkflows } = useAppSelector((state) => state.workflow);
  // states
  const [versionExpanded, setVersionExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cApplication, setcApplication] = useState(companyApplication);
  const [editApplicationOpen, setEditApplicationOpen] = useState(false);
  const [deleteApplicationOpen, setDeleteApplicationOpen] = useState(false);
  // local variables
  const isMenuOpen = Boolean(anchorEl);
  const noVerSurveys = useMemo(
    () =>
      surveys?.filter(
        (cursur) =>
          (cursur.company as Company).id === props.company.id &&
          cApplication.application.id === cursur.application &&
          !cursur.version &&
          cursur.questionnaire,
      ),
    [surveys],
  );
  const companyCert = useMemo(
    () =>
      company.companyCertifications?.find((cert) =>
        cert.applications?.find((app) => app.id === cApplication.application.id),
      ),
    [company.companyCertifications, cApplication.application.id],
  );
  const siteNames = useMemo(() => cApplication?.sites?.map((site) => site.name), [cApplication?.sites]);
  const serviceNames = useMemo(() => cApplication?.services?.map((service) => service.name), [cApplication?.services]);
  const applicationTypes = useMemo(
    () => (cApplication?.application?.applicationTypes as ApplicationType[])?.map((app) => app?.type),
    [cApplication?.application?.applicationTypes],
  );
  const sortedVersionsList = useMemo(
    () => ([...cApplication.application.versions as Version[]])?.sort((a, b) => a?.value && b?.value ? a.value.localeCompare(b.value) : 0),
    [cApplication.application.versions],
  );
  const chipsCellStyle = useMemo(
    () => ({ display: 'flex', flexWrap: 'wrap', columnGap: '0.25rem', padding: '0.25rem' }),
    [],
  );
  const appWorkflows = useMemo(
    () => companyWorkflows?.filter((w) => (w.application as Application)?.id === cApplication.application.id),
    [companyWorkflows, cApplication],
  );
  const mostRecentBlue = useMemo(() => appWorkflows && recentBadgeByType(appWorkflows, 'tpn_self_reported'), [appWorkflows]);
  const mostRecentGold = useMemo(() => appWorkflows && recentBadgeByType(appWorkflows, 'tpn_assessed'), [appWorkflows]);
  const blueBadgeLabel = useMemo(() => {
    if (!mostRecentBlue?.badge) return '';
    const label = fetchBlueBadgeLabel([mostRecentBlue.badge], mostRecentBlue.version);
    return label?.includes('Expired') && !mostRecentGold?.badge ? '' : label;
  }, [mostRecentBlue]);

  // short/simple functions
  const handleMenuClose = useCallback(() => setAnchorEl(null), []);
  const handleEditApplicationClose = useCallback(() => setEditApplicationOpen(false), []);
  const handleDeleteApplicationClose = useCallback(() => setDeleteApplicationOpen(false), []);
  const handleMenuClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget), []);

  const handleDeleteApplicationOpen = useCallback(() => {
    handleMenuClose();
    setDeleteApplicationOpen(true);
  }, []);

  const handleEditApplicationOpen = useCallback(() => {
    handleMenuClose();
    setEditApplicationOpen(true);
  }, []);

  const handleEditApplicationSubmit = useCallback(
    (companyApplicationUpdated: CompanyApplication) => {
      const newCompanyApps = [...company.companyApplications!];
      const caIndex = newCompanyApps.findIndex((ca) => ca.id === companyApplicationUpdated.id);
      if (caIndex !== -1) newCompanyApps[caIndex] = companyApplicationUpdated;
      const updatedCompany: Company = {
        ...company,
        companyApplications: newCompanyApps,
      };
      companySuccessCallback(updatedCompany);
      handleEditApplicationClose();
    },
    [company],
  );

  const handleDeleteApplicationSubmit = useCallback(async () => {
    await deleteCompanyApplication(cApplication);
    const updatedCompany: Company = {
      ...company,
      companyApplications: company.companyApplications?.filter(
        (companyApp: CompanyApplication) => companyApp.id != cApplication.id,
      ),
    };
    companySuccessCallback(updatedCompany);
    handleDeleteApplicationClose();
  }, [cApplication, company]);

  const updateSurveys = useCallback(
    async (version: string) => {
      if (!noVerSurveys || !noVerSurveys.length) return;

      if (
        companyApplication.badge &&
        ['non_tpn_self_reported', 'tpn_self_reported', 'tpn_assessed'].indexOf(companyApplication.badge) !== -1
      ) {
        const date =
          companyApplication.goldShieldDate || companyApplication.blueShieldDate || moment().format('YYYY-MM-DD');
        const newBadge = await createBadge({
          title: companyApplication.badge as 'non_tpn_self_reported' | 'tpn_self_reported' | 'tpn_assessed',
          awardedDate: date,
          expirationDate: moment(date).add(2, 'years').format('YYYY-MM-DD'),
        });

        const appVersion = (companyApplication.application.versions as Version[])?.find((v) => v.value === version);
        if (!appVersion) return;

        const newVersion = await updateVersionBadge(appVersion.id!, newBadge.id!);
        const newVersions = [...(companyApplication.application.versions as Version[])!];
        const avIndex = newVersions.findIndex((nav) => nav.id === newVersion.id);
        if (avIndex > -1) {
          newVersions[avIndex] = newVersion;
          const companyApplicationUpdated = {
            ...cApplication,
            application: { ...cApplication.application, versions: newVersions },
          };
          const newCompanyApps = [...company.companyApplications!];

          const caIndex = newCompanyApps.findIndex((ca) => ca.id === companyApplicationUpdated.id);
          if (caIndex !== -1) {
            newCompanyApps[caIndex] = companyApplicationUpdated;
          }

          const updatedCompany: Company = {
            ...company,
            companyApplications: newCompanyApps,
          };
          companySuccessCallback(updatedCompany);
        }
      }

      await Promise.all(noVerSurveys.map((sur) => patchSurvey(sur.id, version)));
      dispatch(fetchAllSurveys({ company: company.id }));
    },
    [noVerSurveys, companyApplication, cApplication, company, dispatch],
  );

  // set company application props to a local state
  useEffect(() => {
    setcApplication(companyApplication);
  }, [companyApplication]);

  return (
    <>
      <DataRow>
        <DataCell xs={1}>
          {cApplication.application.versions?.length ? (
            <IconButton size='small' onClick={() => setVersionExpanded(!versionExpanded)}>
              {versionExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : null}
        </DataCell>
        <DataCell xs={1} textAlign='center'>
          <GetBadge
            isActive={company.isPublished}
            certification={companyCert}
            goldBadgeLabel={mostRecentGold?.badge && fetchGoldBadgeLabel([mostRecentGold.badge], mostRecentGold.version)}
            blueBadgeLabel={blueBadgeLabel}
            company={company.id}
          />
        </DataCell>
        <DataCell xs={3}>
          <Typography sx={{ fontSize: '1.2em' }}>{cApplication.application.name}</Typography>
        </DataCell>
        <DataCell xs={2} sx={chipsCellStyle}>
          {ChipsWithOverflow(siteNames, 1, 'site')}
        </DataCell>
        <DataCell xs={2} sx={chipsCellStyle}>
          {ChipsWithOverflow(serviceNames, 1, 'service')}
        </DataCell>
        <DataCell xs={2} sx={chipsCellStyle}>
          {ChipsWithOverflow(applicationTypes, 1, 'appType')}
        </DataCell>
        <DataCell xs={1} textAlign='center'>
          {userCanAdminCompany && (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{ '& .MuiMenu-paper': { minWidth: 175, color: '#808080' } }}
              >
                <MenuItem onClick={handleEditApplicationOpen} sx={{ columnGap: 0.625, alignItems: 'center' }}>
                  <EditOutlined />
                  <Typography>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={handleDeleteApplicationOpen} sx={{ columnGap: 0.625, alignItems: 'center' }}>
                  <DeleteOutlineOutlined />
                  <Typography>Delete</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </DataCell>
        <DataCell xs={12} sx={{ ml: '4%' }}>
          {noVerSurveys && !!noVerSurveys.length && !!cApplication.application.versions?.length && (
            <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
              <Typography>
                You have questionnaires for this application with no version, what version/apps would you like to
                associate with that questionnaire?
              </Typography>
              <FormControl fullWidth sx={{ maxWidth: '250px' }}>
                <InputLabel id='version-label'>Version or Application</InputLabel>
                <Select
                  label='Version or Application'
                  labelId='version-label'
                  onChange={(e) => e.target.value && updateSurveys(e.target.value as string)}
                >
                  {sortedVersionsList.map((av) => (
                    <MenuItem key={av.id} value={av.value}>
                      {av.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DataCell>
      </DataRow>
      <VersionsAccordion
        isExpanded={versionExpanded}
        company={company}
        companyApp={cApplication}
        application={cApplication.application}
        versions={sortedVersionsList}
        onVersionRowClick={onVersionRowClick}
      />
      {/* Dialogs */}
      <StandardDialog
        isOpen={deleteApplicationOpen}
        title={`Delete ${cApplication.application.name} ?`}
        handleClose={handleDeleteApplicationClose}
      >
        <Typography>Are you sure you want to delete {cApplication.application.name}?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={handleDeleteApplicationClose}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Delete />}
            disabled={!userCanAdminCompany}
            onClick={handleDeleteApplicationSubmit}
          >
            Delete
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <NewCustomApp
        isOpen={editApplicationOpen}
        company={company}
        companyApplication={cApplication}
        callBack={handleEditApplicationSubmit}
        handleClose={handleEditApplicationClose}
      />
    </>
  );
};

export default InhouseAppDataRow;
