import axios from 'axios';
import getAuthSession from './auth';
import { SKU } from '../interfaces/billing.interface';

export default async function getSKUs() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/skus/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as SKU[];
}

export async function createSKU(sku: SKU) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/skus/`,
    { ...sku },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as SKU;
}

export async function patchSKU(sku: SKU) {
  if (!sku.id) throw new Error('SKU ID is required');
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/skus/${sku.id}/`,
    { ...sku },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as SKU;
}

export async function deleteSKU(sku: SKU) {
  if (!sku.id) throw new Error('SKU ID is required');
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/skus/${sku.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}