import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { Button, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { FieldValues, useForm } from 'react-hook-form';

import { deleteApplicationType, patchApplicationType, postApplicationType } from '../../services/applications';
import { ApplicationType } from '../../interfaces/users.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllApplicationTypes } from '../../app/store/serviceCategories';

interface ApplicationTypeRow {
  id: any;
  type: string;
  fullObject: ApplicationType;
}

function actionIcons(appRow: ApplicationTypeRow) {
  const apiRef = useGridApiContext();
  const [deleteApplicationTypeOpen, setDeleteApplicationTypeOpen] = useState(false);
  const handleDeleteApplicationTypeOpen = () => setDeleteApplicationTypeOpen(true);
  const handleDeleteApplicationTypeClose = () => setDeleteApplicationTypeOpen(false);

  const handleAppDelete = () => {
    deleteApplicationType(appRow.id).then(() => {
      apiRef.current.updateRows([{ id: appRow.id, _action: 'delete' }]);
      handleDeleteApplicationTypeClose();
    });
  };

  return (
    <Stack direction='column' gap={2}>
      <IconButton size='small' name='Delete ApplicationType' onClick={() => handleDeleteApplicationTypeOpen()}><DeleteIcon /></IconButton>
      <StandardDialog title='Delete Confirmation' handleClose={handleDeleteApplicationTypeClose} isOpen={deleteApplicationTypeOpen}>
        <Typography>Are you sure you want to delete {appRow.type}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleDeleteApplicationTypeClose()}>Cancel</Button>
          <Button variant="contained" color='error' onClick={() => handleAppDelete()}>Delete</Button>
        </StandardDialogActions>
      </StandardDialog>
    </Stack>
  );
}

function editStringRow(appRow: ApplicationTypeRow, field: keyof ApplicationTypeRow) {
  const [value, setValue] = useState(appRow[field]);
  const [fieldValue, setFieldValue] = useState(appRow[field]);
  const [view, setView] = useState<'static' | 'edit'>('static');

  const saveApp = () => {
    patchApplicationType(appRow.id, fieldValue).then(() => {
      setValue(fieldValue);
      setView('static');
    });
  };

  return (<>
    {view === 'static' && value}
    {view === 'edit' && <TextField value={fieldValue} title={field} onChange={(e) => setFieldValue(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />}
    <Stack direction='row' gap={1} ml={1} sx={{ opacity: '.5' }}>
      {view === 'static' && <IconButton size='small' name='Edit ApplicationType' onClick={() => {setFieldValue(value); setView('edit');}}><EditIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Save ApplicationType' onClick={() => saveApp()}><SaveIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Cancel Edit' onClick={() => setView('static')}><CancelIcon /></IconButton>}
    </Stack>
  </>);
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'type', headerName: 'Title', width: 550, renderCell: params => editStringRow(params.row, 'type') },
  { field: 'action', headerName: 'Action', width: 130, renderCell: params => actionIcons(params.row) },
];

function createApplicationTypeRow(type: ApplicationType) {
  return {
    id: type.id,
    type: type.type,
    fullObject: type,
  };
}

export default function ApplicationTypesAdminPanel() {
  const {
    handleSubmit,
    register,
    reset,
  } = useForm();
  const dispatch = useAppDispatch();
  const { applicationTypes, fetchingApplicationTypes } = useAppSelector(state => state.serviceCategories);
  const [rows, setRows] = useState<ApplicationTypeRow[]>([]);
  const [newApplicationTypeOpen, setNewApplicationTypeOpen] = useState(false);
  const handleNewApplicationTypeOpen = () => setNewApplicationTypeOpen(true);
  const handleNewApplicationTypeClose = () => setNewApplicationTypeOpen(false);

  const handleAddRow = (capp: ApplicationType) => {
    setRows((curRows) => [
      ...curRows,
      createApplicationTypeRow(capp),
    ].sort((a, b) => a.type.localeCompare(b.type)));
  };

  const onSubmit = async (data: FieldValues) => {
    const napp = await postApplicationType(data.type);

    handleAddRow(napp);
    reset();
    handleNewApplicationTypeClose();
  };

  useEffect(() => {
    if (!applicationTypes.length && !fetchingApplicationTypes) dispatch(fetchAllApplicationTypes());
  }, []);

  useEffect(() => {
    setRows(applicationTypes.map((app) => createApplicationTypeRow(app)));
  }, [applicationTypes]);

  return (
    <Card sx={{ padding: '2em' }}>
      <Stack direction="row-reverse" spacing={1} mb={2}>
        <Button variant='contained' size="small" startIcon={<AddIcon />} onClick={() => handleNewApplicationTypeOpen()}>
          Add New Application Type
        </Button>
      </Stack>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
      />
      <StandardDialog title='Add New ApplicationType' handleClose={handleNewApplicationTypeClose} isOpen={newApplicationTypeOpen}><Box component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
          <TextField
            required
            id='type'
            label='Type'
            sx={{ flexGrow: 2 }}
            {...register('type')}
          />
        </Box>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleNewApplicationTypeClose()}>Cancel</Button>
          <Button type='submit' variant="contained">Add New</Button>
        </StandardDialogActions></Box>
      </StandardDialog>
    </Card>
  );
}