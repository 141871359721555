import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { StandardDialogActions } from '../Modals/StandardDialog';
import { RequestToken } from '../../interfaces/requestToken.interface';
import { createRequestToken, patchRequestToken } from '../../services/requestTokens';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppSelector } from '../../app/hooks';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ReportOption } from '../../interfaces/reports.interface';
import fetchReportOptions from '../../services/reports';

export default function RequestTokenForm(props: { token: any | null, callBack: any, handleClose: any }) {
  const {
    token,
    callBack,
    handleClose,
  } = props;
  const { user } = useAppSelector(state => state.user);

  const [selectedReport, setSelectedReport] = useState<ReportOption | undefined>();
  const [ReportOptions, setReportOptions] = useState<ReportOption[]>([]);

  useEffect(() => {
    if (token?.context?.report) setSelectedReport(token.context.report);
  }, []);

  useEffect(() => {
    if (ReportOptions.length) return;
    fetchReportOptions(user).then((reportOptions: ReportOption[]) => setReportOptions(reportOptions));
  }, []);

  const {
    register,
    handleSubmit,
    // formState: { errors },
    reset,
    clearErrors,
    control,
    setValue,
  } = useForm({ defaultValues: token ? token : undefined });

  const onSubmit = (data: FieldValues) => {
    if (!selectedReport) return;
    const tokenData = {
      id: token?.id ? token.id : undefined,
      context: {
        recipientEmail: data.recipientEmail,
        report: selectedReport,
      },
      updatedAt: new Date(),
      expiresAt: data.expiresAt,
      isActive: data.isActive,
      createdAt: token?.createdAt ? token.createdAt : new Date(),
      issuedBy: user?.id,
    };

    clearErrors();
    reset();

    if (token) {
      patchRequestToken(tokenData).then((updatedToken: RequestToken) => {
        callBack(updatedToken);
        handleClose();
      });
    } else {
      createRequestToken(tokenData).then((createdToken: RequestToken) => {
        callBack(createdToken);
        handleClose();
      });
    }
  };

  const handleReportSelect = (event: any) => {
    // use the report title to find the report object in ReportOptions
    const reportOptionIndex = ReportOptions.findIndex((reportOption) => reportOption.reportName === event.target.value);
    if (reportOptionIndex === -1) return;
    setValue('report', ReportOptions[reportOptionIndex]);
    setSelectedReport(ReportOptions[reportOptionIndex]);
  };

  return (
    <Box sx={{ width: '100%' }} component="form" method={token ? 'patch' : 'post'} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            required
            id='recipientEmail'
            label='Recipient Email'
            sx={{ width: '100%' }}
            autoFocus
            defaultValue={token?.context?.recipientEmail}
            {...register('recipientEmail', { required: true })}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="report">Shared Report</InputLabel>
            <Select
              labelId="report-select-label"
              id="report-select"
              defaultValue={token?.context?.report?.reportName}
              {...register('report', { required: true })}
              label="Shared Report"
              onChange={handleReportSelect}
            >
              {ReportOptions.map((reportOption, idx) => <MenuItem key={idx} value={reportOption.reportName} selected={selectedReport?.reportName == reportOption.reportName}>{reportOption.reportName}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              control={control}
              name="expiresAt"
              defaultValue={moment().add(365, 'days').toDate()}
              render={({ field: { onChange, value } }) => (
                <DesktopDatePicker
                  label="Expiration Date"
                  inputFormat="MM/DD/yyyy"
                  value={value}
                  onChange={(newValue) => {onChange(newValue);}}
                  renderInput={(params) => <TextField {...params} {...register('expiresAt')} />}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}