import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useForm, FieldValues, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Question } from '../../interfaces/question.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateBestPractice } from '../../app/store/bestPracticeSlice';
import { createQuestion, updateQuestion, fetchQuestion } from '../../app/store/questionSlice';
import Grid from '@mui/material/Grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { QuestionTypes } from '../Questions/QuestionTypes';
import AutocompleteSearch from '../Forms/AutoCompleteSearch';

import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

export default function QuestionForm(props: { bestPractice: BestPractice, handleClose: any, question?: Question }) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
    setError,
    clearErrors,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      title: props.question ? props.question.title : undefined,
      description: props.question ? props.question.description : undefined,
      type: props.question ? props.question.type : 'single_select',
      includeComment: props.question ? props.question.includeComment : false,
      commentLabel: props.question ? props.question.commentLabel : undefined,
      includeAttachmentForm: props.question ? props.question.includeAttachmentForm : false,
      attachmentFormLabel: props.question ? props.question.attachmentFormLabel : undefined,
      isBestPractice: props.question ? props.question.isBestPractice : true,
    },
  });
  const dispatch = useAppDispatch();

  const { question: questionResult, error, targetBestPracticeId: targetBestPracticeId } = useAppSelector(state => state.questions);
  const [bestPractice, setBestPractice] = useState(props.bestPractice);
  const [questionData, setQuestionData] = useState<FieldValues | null>(null);
  const [shouldCloseForm, setShouldCloseForm] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const [newQuestionText, setnewQuestionText] = useState('');
  const [curView, setCurView] = useState(props.question ? 'questionForm' : 'newSearch');
  const handleSetCurView = (newView:string) => {
    setIsPosting(false);
    setCurView(newView);
  };
  const [dupQuestion, setDupQuestion] = useState<Question | undefined>(undefined);

  const [answerWarningOpen, setAnswerWarningOpen] = useState(false);
  const handleAnswerWarningOpen = () => setAnswerWarningOpen(true);
  const handleAnswerWarningClose = () => setAnswerWarningOpen(false);

  useEffect(() => {
    if (!answerWarningOpen) setQuestionData(null);
  }, [answerWarningOpen]);

  useEffect(() => {
    if (props.question && questionResult?.id !== props.question.id) dispatch(fetchQuestion(props.question.id));
    if (props.question && questionResult?.id === props.question.id) setDupQuestion(questionResult);
  }, []);

  const onNewQuestionSearchChange = (question:Question) => {
    setDupQuestion(question);
    setValue('title', question.title);
    setValue('description', question.description);
    setValue('type', question.type);
    handleSetCurView('questionForm');
  };

  const newQuestionSubmit = () => {
    setValue('title', newQuestionText);
    handleSetCurView('questionForm');
  };

  const kvCompare = (obj1:any, obj2:any, key:any) => {
    return obj1[key] === obj2[key];
  };

  const buildQuestion = (data: FieldValues) => {
    let newQuestion = {
      ...dupQuestion,
      title: data.title,
      description: data.description && data.description.length > 0 ? data.description : null,
      type: data.type,
      includeComment: data.includeComment,
      commentLabel: data.commentLabel,
      includeAttachmentForm: data.includeAttachmentForm,
      attachmentFormLabel: data.attachmentFormLabel,
      isBestPractice: data.isBestPractice,
      questionnaire: typeof bestPractice.questionnaire === 'number' ? bestPractice.questionnaire : bestPractice.questionnaire.id,
      domain: typeof bestPractice.domain === 'number' ? bestPractice.domain : bestPractice.domain.id,
      topic: typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id,
      bestPractice: bestPractice.id,
    };

    if (dupQuestion) {
      const { inclusions } = dupQuestion;
      const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
      const { excludedByCertifications } = dupQuestion;
      const certificationsIdsArr = excludedByCertifications.map((cert) => typeof cert === 'number' ? cert : cert.id);

      newQuestion = { ...newQuestion, inclusions: inclusionsIdsArr, excludedByCertifications: certificationsIdsArr };
    }

    return newQuestion;
  };

  const shouldShowAnswerWarning = (data: FieldValues) => {
    if (!dupQuestion || (dupQuestion.answers && dupQuestion.answers.length === 0) ) return false;

    return (
      dupQuestion &&
      (
        ( dupQuestion.type?.includes('text') && !data.type.includes('text') ) ||
        ( dupQuestion.type?.includes('select') && !data.type.includes('select') )
      )
    );
  };

  const updateQuestionRemoveAnswers = () => {
    if (!questionData) return;

    dispatch(updateQuestion({ ...questionData, answers: [] }));
    handleAnswerWarningClose();
  };

  const questionPost = (data: FieldValues) => {
    let newQuestion = buildQuestion(data);
    if (props.question &&
      (
        kvCompare(props.question, newQuestion, 'title') &&
        kvCompare(props.question, newQuestion, 'description') &&
        kvCompare(props.question, newQuestion, 'type') &&
        kvCompare(props.question, newQuestion, 'includeComment') &&
        kvCompare(props.question, newQuestion, 'commentLabel') &&
        kvCompare(props.question, newQuestion, 'includeAttachmentForm') &&
        kvCompare(props.question, newQuestion, 'attachmentFormLabel') &&
        kvCompare(props.question, newQuestion, 'isBestPractice')
      ) || (questionResult &&
      (
        kvCompare(newQuestion, questionResult, 'title') &&
        kvCompare(newQuestion, questionResult, 'description') &&
        kvCompare(newQuestion, questionResult, 'type') &&
        kvCompare(newQuestion, questionResult, 'includeComment') &&
        kvCompare(newQuestion, questionResult, 'commentLabel') &&
        kvCompare(newQuestion, questionResult, 'includeAttachmentForm') &&
        kvCompare(newQuestion, questionResult, 'attachmentFormLabel') &&
        kvCompare(newQuestion, questionResult, 'isBestPractice')
      ))
    ) {
      props.handleClose();
    } else if (props.question) {
      if (dupQuestion) {
        const { answers } = dupQuestion;
        const answersIdsArr = answers.map((answer) => typeof answer === 'number' ? answer : answer.id);
        if (shouldShowAnswerWarning(data)) {
          setQuestionData({ ...newQuestion, answers: answersIdsArr });
          handleAnswerWarningOpen();
        } else {
          dispatch(updateQuestion({ ...newQuestion, answers: answersIdsArr }));
        }
      }
    } else {
      dispatch(createQuestion({ ...newQuestion }));
    }
  };

  const onSubmit = (data: FieldValues) => {
    if (isPosting) return;

    setIsPosting(true);
    questionPost(data);
  };

  useEffect(() => {
    if (!props.question) {
      setDupQuestion(undefined);
    }
  }, [props.question]);

  useEffect(() => {
    setIsPosting(false);
    if (questionResult && isSubmitSuccessful) {
      const { questions } = bestPractice;
      const questionsIdsArr = questions.map((question) => question.id);
      const { inclusions } = bestPractice;
      const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
      const { excludedByCertifications } = bestPractice;
      const certificationsIdsArr = excludedByCertifications.map((certification) => typeof certification === 'number' ? certification : certification.id);
      if (questionsIdsArr.indexOf(questionResult.id) === -1) {
        questionsIdsArr.push(questionResult.id);
        dispatch(updateBestPractice({
          ...bestPractice,
          questions: questionsIdsArr,
          inclusions: inclusionsIdsArr,
          excludedByCertifications: certificationsIdsArr,
          questionnaire: typeof bestPractice.questionnaire === 'number' ? bestPractice.questionnaire : bestPractice.questionnaire.id,
          domain: typeof bestPractice.domain === 'number' ? bestPractice.domain : bestPractice.domain.id,
          topic: typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id,
        }));
      }
      setDupQuestion(questionResult);
      clearErrors();
      if (curView === 'logicForm') {
        if (shouldCloseForm) {
          dispatch(updateBestPractice({
            ...bestPractice,
            questions: questionsIdsArr,
            inclusions: inclusionsIdsArr,
            excludedByCertifications: certificationsIdsArr,
            questionnaire: typeof bestPractice.questionnaire === 'number' ? bestPractice.questionnaire : bestPractice.questionnaire.id,
            domain: typeof bestPractice.domain === 'number' ? bestPractice.domain : bestPractice.domain.id,
            topic: typeof bestPractice.topic === 'number' ? bestPractice.topic : bestPractice.topic.id,
          }));
          props.handleClose();
        }
      } else {
        props.handleClose();
      }
    }
    if (props.question && props.question.id === questionResult?.id) setDupQuestion(questionResult);
    setBestPractice(props.bestPractice);
  }, [questionResult]);

  useEffect(() => {
    setIsPosting(false);
    setShouldCloseForm(false);
    if (error?.title && targetBestPracticeId == bestPractice.id) {
      setError('title', { type: 'custom', message: error.title[0] });
    }
  }, [error]);

  return (
    <>
      { curView === 'newSearch' &&
        <Box sx={{ width: '100%' }} component="form" onSubmit={handleSubmit(newQuestionSubmit)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <AutocompleteSearch 
                onChangeHandler={onNewQuestionSearchChange}
                api='questions/orphans'
                label='Questions'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!errors.title}
                helperText={!!errors.title && errors.title.message}
                required
                id='newQuestionTitle'
                label='Question Title'
                onChange={(e) => {setnewQuestionText(e.target.value);}}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4 }}>
            <Button type="submit" sx={{ ml: 2 }} variant="contained" endIcon={<NavigateNextIcon />}>Continue</Button>
            <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
          </Box>
        </Box>
      }
      { curView === 'questionForm' && 
        <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                error={!!errors.title}
                helperText={!!errors.title && errors.title.message}
                required
                id='title'
                label='Question Title'
                multiline
                rows={1}
                sx={{ width: '100%' }}
                autoFocus
                {...register('title')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='description'
                label='Description'
                rows={3}
                multiline
                sx={{ width: '100%' }}
                fullWidth
                {...register('description')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset" sx={{ display: 'block' }}>
                <FormGroup aria-label="position" row>
                  <Controller
                    control={control}
                    name="includeComment"
                    render={({ field: { value } }) => (
                      <FormControlLabel
                        control={<Checkbox checked={value} onChange={(e) => {setValue('includeComment', e.target.checked);}} />}
                        label="Show Comment Box"
                      />
                    )}
                  />
                  <TextField
                    error={!!errors.commentLabel}
                    helperText={!!errors.commentLabel && errors.commentLabel.message}
                    id='commentLabel'
                    label='Comment Label'
                    sx={{ width: '100%' }}
                    {...register('commentLabel')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset" sx={{ display: 'block' }}>
                <FormGroup aria-label="position" row>
                  <Controller
                    control={control}
                    name="includeAttachmentForm"
                    render={({ field: { value } }) => (
                      <FormControlLabel
                        control={<Checkbox checked={value} onChange={(e) => {setValue('includeAttachmentForm', e.target.checked);}} />}
                        label="Show Attachment Box"
                      />
                    )}
                  />
                  <TextField
                    error={!!errors.attachmentFormLabel}
                    helperText={!!errors.attachmentFormLabel && errors.attachmentFormLabel.message}
                    id='attachmentFormLabel'
                    label='Attachment Form Label'
                    sx={{ width: '100%' }}
                    {...register('attachmentFormLabel')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="type-label">Type</FormLabel>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      aria-labelledby="type-label"
                      id="type"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {QuestionTypes.map((qType: any) => (
                        <FormControlLabel {...register('type')} name="type" key={qType.value} value={qType.value} control={<Radio />} label={qType.label} />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="isBestPractice-label">Is Best Practice Question:</FormLabel>
                <Controller
                  control={control}
                  name="isBestPractice"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      aria-labelledby="isBestPractice-label"
                      id="bestPracticeQuestion"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <FormControlLabel {...register('isBestPractice')} name="isBestPractice" value={true} control={<Radio />} label="True" />
                      <FormControlLabel {...register('isBestPractice')} name="isBestPractice" value={false} control={<Radio />} label="False" /> 
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4 }}>
            <Button type="submit" sx={{ ml: 2 }} variant="contained">{ !isPosting ? 'Save' : <CircularProgress color="info" /> }</Button>
            <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
          </Box>
          <StandardDialog title={`Remove answers from ${questionData?.title}`} handleClose={handleAnswerWarningClose} isOpen={answerWarningOpen}>
            <Typography>Changing the question type will delete answers currently associated with this question and any answer responses will be lost. Are you sure  you wish to continue?</Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleAnswerWarningClose()}>Cancel</Button>
              <Button variant="contained" color="error" onClick={() => {updateQuestionRemoveAnswers();}}>Delete Answers</Button>
            </StandardDialogActions>
          </StandardDialog>
        </Box>
      }
    </>
    
  );
}