import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { DataRow, DataCell, DataRowActions, DraggableItem } from '../Tables/DataList';
import { Question } from '../../interfaces/question.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { updateBestPractice } from '../../app/store/bestPracticeSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { deleteQuestion } from '../../app/store/questionSlice';

export default function QuestionListItem(props: { canEdit?: boolean, question: Question, bestPractice: BestPractice, idx: number, moveItem?: any, dropItem?: any, clickHandeler?: any }) {
  const dispatch = useAppDispatch();

  const [deleteQuestionOpen, setDeleteQuestionOpen] = useState(false);
  const handleDeleteQuestionOpen = () => setDeleteQuestionOpen(true);
  const handleDeleteQuestionClose = () => setDeleteQuestionOpen(false);

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm();

  const onSubmit = () => {
    const { questions, inclusions, excludedByCertifications } = props.bestPractice;
    const questionsIdsArr = questions.filter(question => question.id !== props.question.id).map((question) => question.id);
    const inclusionsIdsArr = inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    const certificationsIdsArr = excludedByCertifications.map((certification) => typeof certification === 'number' ? certification : certification.id);
    dispatch(updateBestPractice({
      ...props.bestPractice,
      questions: questionsIdsArr,
      inclusions: inclusionsIdsArr,
      excludedByCertifications: certificationsIdsArr,
      questionnaire: typeof props.bestPractice.questionnaire === 'number' ? props.bestPractice.questionnaire : props.bestPractice.questionnaire.id,
      domain: typeof props.bestPractice.domain === 'number' ? props.bestPractice.domain : props.bestPractice.domain.id,
      topic: typeof props.bestPractice.topic === 'number' ? props.bestPractice.topic : props.bestPractice.topic.id,
    }));
    dispatch(deleteQuestion(props.question.id));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleDeleteQuestionClose();
    }
  }, []);

  const handleQuestionClick = () => {
    if (props.clickHandeler) {
      props.clickHandeler();
    }
  };

  const renderRow = () => {
    return (
      <>
        <DataRow onClick={(e:any)=> {e.preventDefault(); e.stopPropagation(); handleQuestionClick(); }} hover>
          <DataCell xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            { props.moveItem && props.canEdit ? <DragIndicatorIcon sx={{ cursor: 'grab', mr: '.25em' }} /> : null }
            { props.question.isBestPractice && <WorkspacePremiumIcon color="primary" sx={{ mr: '.25em' }} /> }
            <Box>{props.question.title}</Box>
          </DataCell>
          <DataCell xs={4}><Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={props.question.description}>{props.question.description}</Box></DataCell>
          <DataCell xs={2}>
            {props.idx + 1}
            {props.canEdit && (<DataRowActions>
              <IconButton size="small" name="Edit Question"><EditIcon /></IconButton>
              <IconButton size="small" name="Delete Question" onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleDeleteQuestionOpen(); }}><DeleteIcon /></IconButton>
            </DataRowActions>)}
          </DataCell>
        </DataRow>
        <StandardDialog title={`Remove ${props.question.title} from Best Practice: ${props.bestPractice.title}`} handleClose={handleDeleteQuestionClose} isOpen={deleteQuestionOpen}>
          <Typography>Are you sure you want to remove {props.question.title} from Best Practice {props.bestPractice.title}?</Typography>
          <StandardDialogActions component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Button variant="outlined" onClick={() => handleDeleteQuestionClose()}>Cancel</Button>
            <Button type="submit" variant="contained" color="error">Remove</Button>
          </StandardDialogActions>
        </StandardDialog>
      </>
    );
  };

  return (
    <>
      { props.moveItem && props.canEdit ?
        <DraggableItem
            key={props.idx}
            index={props.idx}
            id={props.question.id}
            moveItem={props.moveItem}
            dropItem={props.dropItem}
          >
          {renderRow()}
        </DraggableItem>
        :
        <>{renderRow()}</>
      }
    </>
  );
}