export interface QuestionType {
  label: string,
  value: string,
}

export const QuestionTypes:Array<QuestionType> = [
  {
    label: 'Single Select',
    value: 'single_select',
  },
  {
    label: 'Multi Select',
    value: 'multi_select',
  },
  {
    label: 'Text',
    value: 'text',
  },
];