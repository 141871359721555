// react and external-libs
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// MUI
import { AccordionDetails } from '@mui/material';
// redux
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// interface
import { Company, Application } from '../../../../interfaces/users.interface';
import { HardeningDocument } from '../../../../interfaces/hardeningDocuments';
// service
import { usePrevious } from '../../../../services/stateHelpers';
import { fetchDocument } from '../../../../app/store/serviceCategories';
// components
import NewApplication from '../ApplicationModals/NewApplication';
import InhouseApplications from './InhouseApplications/InhouseApplications';
import LicensedApplications from './LicensedApplications/LicensedApplications';
import { AccordionCollapse, AccordionCollapseSummary } from '../../../Layout/Collapsible';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    nonCompliant: true;
    assessorApproved: true;
  }
}

export const CompanyApplicationsAccordion = (props: {
  company: Company;
  userCanAdminCompany: boolean;
  companyCallback: any;
}) => {
  const { company, companyCallback, userCanAdminCompany } = props;
  // router & redux
  const { profileSection } = useParams();
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector((state) => state.serviceCategories);
  // states
  const [expanded, setExpanded] = useState(false);
  const [addApplicationOpen, setAddApplicationOpen] = useState(false);
  // local variables
  const prevApps = usePrevious(company.companyApplications);
  // short/simple functions
  const handleAddApplicationOpen = useCallback(() => setAddApplicationOpen(true), []);
  const handleAddApplicationClose = useCallback(() => setAddApplicationOpen(false), []);

  // expands accordion
  useEffect(() => {
    if (
      !prevApps ||
      !company.companyApplications ||
      (prevApps as Application[]).length === company.companyApplications.length
    )
      return;
    setExpanded(true);
  }, [company.companyApplications]);

  // expands accordion if in "applications" url
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'applications');
  }, [profileSection]);

  // fetch company documents
  useEffect(() => {
    const cawdocs = company.companyApplications?.filter(
      (ca) => ca.application.hardeningDocuments && ca.application.hardeningDocuments.length > 0,
    );
    if (!cawdocs) return;
    cawdocs.forEach((ca) =>
      (ca.application.hardeningDocuments as HardeningDocument[])?.map((hd) => {
        if (!documents.find((doc) => doc.id === hd.document)) dispatch(fetchDocument(hd.document));
      }),
    );
  }, []);

  return (
    <AccordionCollapse
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionCollapseSummary
        name='apps-header'
        title='Apps'
        count={company?.companyApplications?.length}
        addItemLabel='App'
        addItem={handleAddApplicationOpen}
        addItemTest={userCanAdminCompany}
      >
        <NewApplication
          isOpen={addApplicationOpen}
          company={company}
          callBack={(co: Company) => companyCallback(co)}
          handleClose={handleAddApplicationClose}
        />
      </AccordionCollapseSummary>
      <AccordionDetails>
        <InhouseApplications
          company={company}
          userCanAdminCompany={userCanAdminCompany}
          companyCallback={companyCallback}
        />
        <LicensedApplications
          company={company}
          userCanAdminCompany={userCanAdminCompany}
          companyCallback={companyCallback}
        />
      </AccordionDetails>
    </AccordionCollapse>
  );
};

export default CompanyApplicationsAccordion;
