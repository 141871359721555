import React, { ChangeEvent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay, useGridApiContext } from '@mui/x-data-grid';
import Moment from 'moment/moment';
import { Autocomplete, Button, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import getCertifications, { createCertification, deleteCertification, patchCertification } from '../../services/certifications';
import { Certification } from '../../interfaces/certification.interface';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { Company } from '../../interfaces/users.interface';
import { throttle } from 'lodash';
import getAuthSession from '../../services/auth';
import axios, { AxiosResponse } from 'axios';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import Box from '@mui/material/Box';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getCompanies } from '../../services/company';
import CircularProgress from '@mui/material/CircularProgress';

let companies:Company[] = [];

interface CertificationRow {
  id: any;
  title: string;
  version?: string;
  releaseDate: string;
  company: number | Company | string;
  action: string;
}

function actionIcons(certRow: CertificationRow) {
  const apiRef = useGridApiContext();
  const [deleteCertificateOpen, setDeleteCertificateOpen] = useState(false);
  const handleDeleteCertificateOpen = () => setDeleteCertificateOpen(true);
  const handleDeleteCertificateClose = () => setDeleteCertificateOpen(false);

  const handleCertDelete = () => {
    deleteCertification(certRow.id).then(() => {
      apiRef.current.updateRows([{ id: certRow.id, _action: 'delete' }]);
      handleDeleteCertificateClose();
    });
  };

  return (
    <Stack direction='column' gap={2}>
      <IconButton size='small' name='Delete Certificate' onClick={() => handleDeleteCertificateOpen()}><DeleteIcon /></IconButton>
      <StandardDialog title='Delete Confirmation' handleClose={handleDeleteCertificateClose} isOpen={deleteCertificateOpen}>
        <Typography>Are you sure you want to delete {certRow.title}?</Typography>
        <Typography color='error'>Warning! Any Mappings or company certificates associated to this cert will also be deleted!!!</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleDeleteCertificateClose()}>Cancel</Button>
          <Button variant="contained" color='error' onClick={() => handleCertDelete()}>Delete</Button>
        </StandardDialogActions>
      </StandardDialog>
    </Stack>
  );
}

function editStringRow(certRow: CertificationRow, field: keyof CertificationRow) {
  if (field === 'action') return;
  const [value, setValue] = useState(certRow[field]);
  const [fieldValue, setFieldValue] = useState(certRow[field]);
  const [view, setView] = useState<'static' | 'edit'>('static');

  const saveCert = () => {
    patchCertification(certRow.id, field, fieldValue).then(() => {
      setValue(fieldValue);
      setView('static');
    });
  };

  return (<>
    {view === 'static' && value}
    {view === 'edit' && <TextField value={fieldValue} title={field} onChange={(e) => setFieldValue(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />}
    <Stack direction='row' gap={1} ml={1} sx={{ opacity: '.5' }}>
      {view === 'static' && <IconButton size='small' name='Edit Certificate' onClick={() => {setFieldValue(value); setView('edit');}}><EditIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Save Certificate' onClick={() => saveCert()}><SaveIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Cancel Edit' onClick={() => setView('static')}><CancelIcon /></IconButton>}
    </Stack>
  </>);
}

function editCompanyRow(certRow: CertificationRow) {
  const [value, setValue] = useState<string | number | Company>(typeof certRow.company === 'number' || typeof certRow.company === 'string' ? certRow.company : certRow.company.name);
  const [view, setView] = useState<'static' | 'edit'>('static');
  const [itemList, setItemList] = useState<Company[]>([]);

  const saveCert = (company?: Company) => {
    patchCertification(certRow.id, 'company', company ? company.id : null).then(() => {
      setValue(company ? company.name : '-');
      setView('static');
    });
  };

  const fetchItems = async (searchText: string) => {
    if (!searchText) return;
    const authSession = await getAuthSession();
    try {
      axios.get(
        `${process.env.REACT_APP_BASE_API}/companies/list/?search=${searchText}`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      ).then((response: AxiosResponse) => {
        setItemList(response.data.results);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const handleCompanyChange = (event: any, item: Company | null) => {
    if (!item) return;
    saveCert(item);
  };

  const throttledFetchItems = throttle(fetchItems, 2500, { leading: true, trailing: true });

  const handleInputChange = async (event: ChangeEvent<{ value: unknown }>) => {
    await throttledFetchItems(event.target.value as string);
  };

  useEffect(() => {
    if (!companies.length || typeof value !== 'number') return;
    const selectedCompany = companies.find(co => co.id === value);
    if (selectedCompany) setValue(selectedCompany.name);
  }, [companies, value]);

  return (<>
    {view === 'static' && value}
    {view === 'edit' && 
      <Autocomplete
        id='company'
        fullWidth
        options={itemList}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, cvalue) => option.id == cvalue.id}
        onChange={handleCompanyChange}
        defaultValue={typeof certRow.company === 'string' || typeof certRow.company === 'number' ? undefined : certRow.company}
        onKeyDown={(e) => e.stopPropagation()}
        renderInput={(params) =>
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
            placeholder='Search Companies'
            InputProps={{
              ...params.InputProps,
              endAdornment: (<>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>),
            }}
          />
        }
      />
    }
    <Stack direction='row' gap={1} ml={1} sx={{ opacity: '.5' }}>
      {view === 'static' && <IconButton size='small' name='Edit Certificate' onClick={() => setView('edit')}><EditIcon /></IconButton>}
      {view === 'static' && value !== '-' && <IconButton size='small' name='Delete Certificate' onClick={() => saveCert()}><DeleteIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Cancel Edit' onClick={() => setView('static')}><CancelIcon /></IconButton>}
    </Stack>
  </>);
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'title', headerName: 'Title', width: 350, renderCell: params => editStringRow(params.row, 'title') },
  { field: 'version', headerName: 'Version', width: 200, renderCell: params => editStringRow(params.row, 'version') },
  { field: 'releaseDate', headerName: 'Release Date', width: 130 },
  { field: 'company', headerName: 'Company', width: 350, renderCell: params => editCompanyRow(params.row)  },
  { field: 'action', headerName: 'Action', width: 130, renderCell: params => actionIcons(params.row) },
];

function createCertificationRow(cert: Certification) {
  return {
    id: cert.id,
    title: cert.title,
    version: cert.version ? cert.version : '-',
    releaseDate: cert.releaseDate ? Moment(cert.releaseDate).format('MM/DD/YYYY') : '-',
    company: cert.company ? cert.company : '-',
    action: 'edit',
  };
}

export default function CertificationsAdminPanel() {
  const {
    handleSubmit,
    register,
    control,
    reset,
  } = useForm();
  const [rows, setRows] = useState<CertificationRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newCertificateOpen, setNewCertificateOpen] = useState(false);
  const handleNewCertificateOpen = () => setNewCertificateOpen(true);
  const handleNewCertificateClose = () => setNewCertificateOpen(false);

  const handleAddRow = (ccert: Certification) => {
    setRows((curRows) => [
      ...curRows,
      createCertificationRow(ccert),
    ].sort((a, b) => a.title.localeCompare(b.title)));
  };

  const onSubmit = async (data: FieldValues) => {
    const releaseDate = Moment(data.releaseDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
    const ncert = await createCertification({ title: data.title, version: data.version, releaseDate: releaseDate });

    handleAddRow(ncert);
    reset();
    handleNewCertificateClose();
  };

  useEffect(() => {
    getCertifications().then((certs: Certification[]) => {
      setRows(certs.sort((a, b) => a.title.localeCompare(b.title)).map((cert) => createCertificationRow(cert)));
      setIsLoading(false);
    });
    getCompanies('?limit=9999').then(comp => companies = comp);
  }, []);

  return (
    <Card sx={{ padding: '2em' }}>
      <Stack direction="row-reverse" spacing={1} mb={2}>
        <Button variant='contained' size="small" startIcon={<AddIcon />} onClick={() => handleNewCertificateOpen()}>
          Add New Certification
        </Button>
      </Stack>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        components={{
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
      />
      <StandardDialog title='Add New Certification' handleClose={handleNewCertificateClose} isOpen={newCertificateOpen}><Box component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
          <TextField
            required
            id='title'
            label='Title'
            sx={{ flexGrow: 2 }}
            {...register('title')}
          />
          <TextField
            required
            id='version'
            label='Version'
            sx={{ flexGrow: 0 }}
            {...register('version')}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              control={control}
              name="releaseDate"
              render={({ field: { onChange, value } }) => (
                <DesktopDatePicker
                  label="Release Date"
                  inputFormat="MM/DD/yyyy"
                  value={value}
                  onChange={(newValue) => {onChange(newValue);}}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleNewCertificateClose()}>Cancel</Button>
          <Button type='submit' variant="contained">Add New</Button>
        </StandardDialogActions></Box>
      </StandardDialog>
    </Card>
  );
}
