import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function SelectInput(props: { options: any[], selectCallback: any, inputLabel: string, onChangeCallback: any }) {
  const {
    options,
    selectCallback,
    inputLabel,
    onChangeCallback,
  } = props;
  const [selectedOption, setSelectedOption] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedOption(value);
    selectCallback(value);
    onChangeCallback(event);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`select-${inputLabel}-label`}>{inputLabel}</InputLabel>
        <Select
          labelId={`select-${inputLabel}-label`}
          id={`select-${inputLabel}-label`}
          value={selectedOption}
          label={inputLabel}
          onChange={handleChange}
        >
          {options.map((option: any, index: number) => <MenuItem key={index} value={option.id}>{option.name ? option.name : option.title}{option.version && option.version.length > 0 ? `: ${option.version}` : ''}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

SelectInput.defaultProps = {
  onChangeCallback: () => {},
};

export default SelectInput;
