import { useCallback } from 'react';

const useFontLoader = (fontFamily: string, weight: string | number = 'normal') => {
  const loadFont = useCallback(async () => {
    const fontLoadDiv = document.createElement('div');
    fontLoadDiv.style.fontFamily = `${fontFamily}, sans-serif`;
    fontLoadDiv.style.fontWeight = `${weight}`;
    fontLoadDiv.style.fontSize = '16px';
    fontLoadDiv.style.visibility = 'hidden';
    fontLoadDiv.textContent = 'Loading Font';
    document.body.appendChild(fontLoadDiv);

    await new Promise(resolve => setTimeout(resolve, 300));

    // Wait for fonts to be ready
    await document.fonts.ready;

    if (document.fonts.check(`${weight} 60px ${fontFamily}`)) {
      document.body.removeChild(fontLoadDiv);
      return true;
    } else {
      console.error(`${fontFamily} font is not loaded.`);
      document.body.removeChild(fontLoadDiv);
      return false; // Font failed to load
    }
  }, [fontFamily, weight]);

  return loadFont;
};

export default useFontLoader;
