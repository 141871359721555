// react and external-libs
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
// MUI
import { AccordionDetails } from '@mui/material';
// interface
import { Company, Contact } from '../../../../interfaces/users.interface';
// service
import { usePrevious } from '../../../../services/stateHelpers';
// components
import SiteDataRow from './SiteDataRow';
import SiteSidebar from './SiteSidebar';
import LocationForm from '../LocationForm';
import StandardDialog from '../../../Modals/StandardDialog';
import { DataCell, DataRow } from '../../../Tables/DataList';
import { AccordionCollapse, AccordionCollapseSummary } from '../../../Layout/Collapsible';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    nonCompliant: true;
    assessorApproved: true;
  }
}

type SitesAccordionProps = {
  company: Company;
  companyCallback: any;
  userCanAdminCompany: boolean;
};

export default function SitesAccordion(props: SitesAccordionProps) {
  const { company, companyCallback, userCanAdminCompany } = props;
  // external lib and redux
  const { profileSection } = useParams();
  const prevLocations = usePrevious(company.locations);
  // states
  const [expanded, setExpanded] = useState(false);
  const [createSiteOpen, setCreateSiteOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState<Contact | null>(null);
  // short/simple functions
  const handleCreateSiteOpen = useCallback(() => setCreateSiteOpen(true), []);
  const handleCreateSiteClose = useCallback(() => setCreateSiteOpen(false), []);

  const handleRowClick = useCallback((site: Contact) => {
    setSelectedSite(site);
    setIsSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setIsSidebarOpen(false);
    setSelectedSite(null);
  }, []);

  const handleSiteCreation = useCallback(
    (companyResponse: Company) => {
      companyCallback(companyResponse);
      handleCreateSiteClose();
    },
    [companyCallback],
  );

  const sortedSites = useMemo(
    () => [...company.locations].sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0)),
    [company.locations],
  );

  // if url params profile section is "sites", expand the accordion
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'sites');
  }, [profileSection]);

  useEffect(() => {
    if (!prevLocations || !company.locations || (prevLocations as Contact[]).length === company.locations.length)
      return;
    setExpanded(true);
  }, [company.locations]);

  return (
    <>
      <AccordionCollapse
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionCollapseSummary
          name='sites-header'
          title='Sites'
          count={company?.locations.length}
          addItem={() => handleCreateSiteOpen()}
          addItemLabel='Site'
          addItemTest={userCanAdminCompany}
        >
          <StandardDialog title='Add New Site' handleClose={handleCreateSiteClose} isOpen={createSiteOpen}>
            <LocationForm
              submitButtonText='Add Site'
              company={company}
              successCallback={handleSiteCreation}
              modalClose={() => handleCreateSiteClose()}
            />
          </StandardDialog>
        </AccordionCollapseSummary>
        <AccordionDetails>
          <DataRow header>
            <DataCell xs={1} textAlign='center'>
              Shield
            </DataCell>
            <DataCell xs={3}>Name</DataCell>
            <DataCell xs={2}>Applications</DataCell>
            <DataCell xs={2}>Services</DataCell>
            <DataCell xs={1}>Status</DataCell>
            <DataCell xs={2} textAlign='center'>
              Actions
            </DataCell>
            <DataCell xs={1}>&nbsp;</DataCell>
          </DataRow>
          {!!company.locations.length &&
            typeof company.locations[0] !== 'number' &&
            sortedSites.map((site: Contact) => (
              <SiteDataRow
                key={site.id}
                company={company}
                site={site}
                userCanAdminCompany={userCanAdminCompany}
                companySuccessCallback={companyCallback}
                onClick={handleRowClick}
              />
            ))}
        </AccordionDetails>
      </AccordionCollapse>
      <SiteSidebar
        isOpen={isSidebarOpen}
        company={company}
        site={selectedSite}
        onClose={handleSidebarClose}
      />
    </>
  );
}
