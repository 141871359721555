import axios from 'axios';

import getAuthSession from './auth';
import { Survey } from '../interfaces/survey.interface';
import { AssessmentSurveyMeta } from '../interfaces/metaData.interface';
import { ApplicationVersion } from '../interfaces/users.interface';

export default async function createSurvey(survey: Survey) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/surveys/`,
    {
      ...survey,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Survey;
}

export async function fetchSurvey(survey: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/surveys/${survey}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Survey;
}

export async function patchSurvey(id: number, version?: string) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/surveys/${id}/`,
    {
      version,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Survey;
}

export interface SurveyFilters {questionnaire?:string, company?:number, application?: number, site?: number, status?: number, search?: number, limit?: number, offset?: number, version?: string}

export async function fetchSurveys(filters?:SurveyFilters) {
  const authSession = await getAuthSession();
  const filtersFromObject = filters && Object.keys(filters).length > 0 ?
    Object.keys(filters).map((filter, index: number) => {
      let filterString = index === 0 ? '?' : '';
      return filterString += `${filter}=${filters[filter as keyof SurveyFilters]}`;
    }) : undefined;
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/surveys/${filtersFromObject && filtersFromObject.join('&')}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Survey[];
}

export async function fetchSurveyMeta() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/survey-badge/meta-data/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as AssessmentSurveyMeta;
}

function isAppVersion(data: any): data is ApplicationVersion {
  return data.version !== undefined;
}

export async function fetchBaselineSurvey(questionnaireId: number, siteOrAppId: number, version?: ApplicationVersion | number) {
  const authSession = await getAuthSession();

  let url = `${process.env.REACT_APP_BASE_API}/surveys/?questionnaire=${questionnaireId}`;
  if (version) {
    url += `&application=${siteOrAppId}&version=${isAppVersion(version) ? version.id : version}`;
  } else {
    url += `&site=${siteOrAppId}`;
  }

  const response = await axios.get(
    url,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Survey[];
}
