import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import { Card, FormControl, Link, MenuItem, Select, Switch } from '@mui/material';

import User, { AssessorLevel, Company } from '../../interfaces/users.interface';
import { updateCompanyBoolean } from '../../services/company';
import { getUsersByType } from '../../services/user';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchAssessorLevels } from '../../services/assessorLevels';

interface AssessorRow {
  id: number;
  company?: Company;
  assessorLevel: AssessorLevel | null;
  email: string;
  name: string;
  canAssessPrem: boolean;
  canAssessCloud: boolean;
  assessorLevelOptions?: AssessorLevel[];
}

function createRowSwitch(assessorRow: AssessorRow, field: string) {
  if (!assessorRow.company) return '-';

  const handleChange = (event: any) => {
    updateCompanyBoolean({
      id: assessorRow.company!.id as number,
      [event.target.name]: event.target.checked,
    });
  };
  const fieldName: string = field as string;
  const fieldValue = assessorRow[fieldName as keyof typeof assessorRow];
  return (
    <Switch
      id={assessorRow.id.toString()}
      defaultChecked={typeof fieldValue === 'boolean' ? fieldValue : false}
      onChange={(event) => handleChange(event)}
      name={field}
    />
  );
}

function createSelect(assessorRow: AssessorRow) {
  const handleChange = (event: any) => {
    const selectedValue = event.target.value as number;
    updateCompanyBoolean({
      id: assessorRow?.company!.id as number,
      assessorLevel: selectedValue !== 0 ? selectedValue : null,
    });
  };

  return (
    <FormControl size='small'>
      <Select
        id="assessor-level"
        defaultValue={assessorRow.assessorLevel?.id !== 0 ? assessorRow.assessorLevel : 0}
        onChange={handleChange}
      >
        {assessorRow.assessorLevelOptions?.map((assessorLevel: AssessorLevel) => (
          <MenuItem key={assessorLevel.id} value={assessorLevel.id}>
            {assessorLevel.descriptor}
          </MenuItem>
        ))}
        <MenuItem value={0}>Null</MenuItem>
      </Select>
    </FormControl>
  );
}

const columns: GridColDef[] = [
  { field: 'assessorLevel', headerName: 'Level', width: 300, renderCell: params => createSelect(params.row) },
  { field: 'email', headerName: 'Email', width: 300, renderCell: (params) => <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link> },
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'canAssessPrem', headerName: 'Can Assess Site', width: 150, renderCell: params => createRowSwitch(params.row, 'canAssessPrem') },
  { field: 'canAssessCloud', headerName: 'Can Assess Cloud', width: 150, renderCell: params => createRowSwitch(params.row, 'canAssessCloud') },
];

function createAssessorRow(assessor: User, assessorLevelOptions: AssessorLevel[] = []) {
  if (assessor.companies.length === 0) {
    return {
      id: assessor.id!,
      assessorLevel: { id: 0, descriptor: 'Null' },
      email: assessor.email,
      name: assessor.firstName + ' ' + assessor.lastName,
      canAssessPrem: false,
      canAssessCloud: false,
      assessorLevelOptions,
    };
  }

  return {
    id: assessor.id!,
    company: assessor.companies[0],
    assessorLevel: assessor.companies[0].assessorLevel ? assessor.companies[0].assessorLevel : { id: 0, descriptor: 'Null' },
    email: assessor.email,
    name: assessor.firstName + ' ' + assessor.lastName,
    canAssessPrem: assessor.companies[0].canAssessPrem ? assessor.companies[0].canAssessPrem : false,
    canAssessCloud: assessor.companies[0].canAssessCloud ? assessor.companies[0].canAssessCloud : false,
    assessorLevelOptions,
  };
}

export default function AssessorAdminPanel() {
  const [rows, setRows] = useState<Array<AssessorRow>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getUsersByType('assessor').then(response => {
      const assessors = response.results as User[];
      fetchAssessorLevels().then((assessorLevelsResponse: AssessorLevel[]) => {
        setRows(assessors.sort((a, b) => a.email.localeCompare(b.email)).map(assessor => createAssessorRow(assessor, assessorLevelsResponse)));
        setIsLoading(false);
      });
    });

    return (() => {
      setRows([]);
    });
  }, []);

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        components={{
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
      />
    </Card>
  );
}
