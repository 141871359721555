import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchQuestionnaire, updateQuestionnaire, resetQuestionnaire } from '../../app/store/questionnaireSlice';
import { Domain } from '../../interfaces/domain.interface';
import { QuestionnaireEditForm } from './QuestionnaireForm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import DomainListItem from '../Domains/DomainListItem';
import { DataListContain, DataRow, DataCell } from '../Tables/DataList';
import HeaderDetailCard from '../Cards/HeaderDetailCard';
import StandardDialog from '../Modals/StandardDialog';
import DomainForm from '../Domains/DomainForm';

import { resetDomain } from '../../app/store/domainSlice';
import { resetTopic } from '../../app/store/topicSlice';
import { resetBestPractice } from '../../app/store/bestPracticeSlice';

import QuestionnairePreview from '../Preview/QuestionnairePreview';

export default function Questionnaire() {
  const params = useParams();
  const questionnaireSlug:string = params.questionnaireSlug!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetQuestionnaire());
    dispatch(fetchQuestionnaire(questionnaireSlug));
    dispatch(resetDomain());
    dispatch(resetTopic());
    dispatch(resetBestPractice());
  }, []);

  const { user } = useAppSelector(state => state.user);
  const { questionnaire } = useAppSelector(state => state.questionnaires);
  const [curView, setCurView] = useState('edit');
  const [newDomainOpen, setNewDomainOpen] = useState(false);
  const handleNewDomainSetOpen = () => setNewDomainOpen(true);
  const handleNewDomainClose = () => setNewDomainOpen(false);

  const [editQuestionnaireOpen, setEditQuestionnaireOpen] = useState(false);
  const handleEditQuestionnaireOpen = () => setEditQuestionnaireOpen(true);
  const handleEditQuestionnaireClose = () => setEditQuestionnaireOpen(false);

  const [domainFilter, setDomainFilter] = useState(null);
  const [domains, setDomains] = useState(questionnaire?.domains as Domain[]);

  useEffect(() => {
    let newDomainList = questionnaire?.domains as Domain[];
    if (domainFilter) {
      if (typeof(domainFilter) === 'number') {
        newDomainList = newDomainList.filter(domain => domain.id === domainFilter);
      } else {
        newDomainList = newDomainList.filter(domain => domain.title.toLowerCase().includes(domainFilter));
      }
    }
    setDomains(newDomainList);
  }, [questionnaire?.domains, domainFilter]);

  const domainSearchClickCallback = useCallback((e: Event, value: any) => {
    e.preventDefault();
    if (value) { setDomainFilter(typeof(value) !== 'string' ? value.domain.id : value); } else { setDomainFilter(null); }
  }, []);

  const moveDomain = useCallback((dragIndex: number, hoverIndex: number) => {
    setDomains((prevDomains: Domain[]) =>
      update(prevDomains, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevDomains[dragIndex] as Domain],
        ],
      }),
    );
  }, []);

  const dropDomain = () => {
    const domainsIdsArr = domains.map((domain) => domain.id);
    dispatch(updateQuestionnaire({ ...questionnaire, domains: domainsIdsArr }));
  };

  const buildDomainSearchList = () => {
    if (!questionnaire) return false;
    return questionnaire.domains.map(domain => {return { label: domain.title, domain: domain };});
  };

  const [domainSearchList, setDomainSearchList] = useState(buildDomainSearchList);
  useEffect(() => {
    setDomainSearchList(buildDomainSearchList);
  }, [questionnaire?.domains]);

  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      { curView === 'edit' && questionnaire && questionnaire.slug === questionnaireSlug ? 
        <>
          <HeaderDetailCard canEdit={canEdit} overTitle='Questionnaire' title={questionnaire.title} detail={questionnaire.description} editClick={handleEditQuestionnaireOpen} previewClick={() => setCurView('preview')} />
          <StandardDialog title={`Edit Questionnaire: ${questionnaire.title}`} handleClose={handleEditQuestionnaireClose} isOpen={editQuestionnaireOpen}>
            <QuestionnaireEditForm questionnaire={questionnaire} handleClose={handleEditQuestionnaireClose} />
          </StandardDialog>
          <StandardDialog title="New Domain" handleClose={handleNewDomainClose} isOpen={newDomainOpen}>
            <DomainForm questionnaire={questionnaire} handleClose={handleNewDomainClose} />
          </StandardDialog>
          <DataListContain title="Domains" buttonTitle={canEdit ? 'Domains' : '' } buttonClick={handleNewDomainSetOpen} searchList={domainSearchList} onChange={domainSearchClickCallback}>
            <DataRow header>
              <DataCell xs={6}>Title</DataCell>
              <DataCell xs={4}>Description</DataCell>
              <DataCell xs={2}>Order</DataCell>
            </DataRow>
            { domainFilter ?
                <>
                  { domains && domains.map((domain: Domain) => (
                    <DomainListItem canEdit={canEdit} key={domain.id} domain={domain} questionnaire={questionnaire} idx={questionnaire?.domains.indexOf(domain)} />
                  ))}
                </>
              :
              <DndProvider backend={HTML5Backend}>
                {domains && domains.map((domain: Domain) => (
                  <DomainListItem canEdit={canEdit} key={domain.id} domain={domain} questionnaire={questionnaire} idx={questionnaire?.domains.indexOf(domain)} moveItem={moveDomain} dropItem={dropDomain} />
                ))}
              </DndProvider>
            }
          </DataListContain>
        </>
        :
        <></>
      }
      { curView === 'preview' && questionnaire &&
        <QuestionnairePreview
          buttonClick={() => setCurView('edit')}
          buttonText={questionnaire.title}
          questionnaire={questionnaire}
        />
      }
    </>
  );
}