import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Certification, CertificationResultArray } from '../../interfaces/certification.interface';
import { FieldValues } from 'react-hook-form';

interface InitialCertificationsState {
  count: number;
  next?: string;
  previous?: string;
  certification?: Certification;
  results: Certification[];
  deletedCertificationId?: number;
  error?: any;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  certification: undefined,
  results: [],
  deletedCertificationId: undefined,
  error: undefined,
} as InitialCertificationsState;

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAll = createAsyncThunk(
  'certifications/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/certifications/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as CertificationResultArray;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchCertification = createAsyncThunk(
  'certifications/fetchCertification',
  async (certificationId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/certifications/${certificationId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Certification;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createCertification = createAsyncThunk(
  'certifications/createCertification',
  async ({ title, releaseDate, version }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/certifications/`, {
          title,
          releaseDate,
          version,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as Certification;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCertification = createAsyncThunk(
  'certifications/deleteCertification',
  async (certificationId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/certifications/${certificationId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return certificationId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCertification = createAsyncThunk(
  'certifications/updateCertification',
  async ({ id, title, date, version }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/certifications/${id}/`, {
          id,
          title,
          date,
          version,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Certification;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const certificationSlice = createSlice({
  name: 'certifications',
  initialState,
  reducers: {
    resetCertification: (state) => {
      state.certification = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<CertificationResultArray>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
    });
    builder.addCase(createCertification.fulfilled, (state, action: PayloadAction<Certification>) => {
      state.results = [...state.results, action.payload];
      state.certification = action.payload;
    });
    builder.addCase(createCertification.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteCertification.fulfilled, (state, action: PayloadAction<number>) => {
      state.error = null;
      state.deletedCertificationId = action.payload;
      state.results = state.results.filter((certification: Certification) => certification.id !== action.payload);
    });
    builder.addCase(updateCertification.fulfilled, (state, action: PayloadAction<Certification>) => {
      state.results = state.results.filter((certification: Certification) => certification.id !== action.payload.id);
      state.results = [...state.results, action.payload].sort((a, b) => a.id - b.id);
      state.error = null;
      state.certification = action.payload;
    });
    builder.addCase(updateCertification.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchCertification.fulfilled, (state, action: PayloadAction<Certification>) => {
      state.certification = action.payload;
    });
  },
});

export const { resetCertification } = certificationSlice.actions;

export default certificationSlice.reducer;