import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  getCertificateControlsById,
  setAllQuestionStatus,
  setAllCertifications,
  setReadOnly,
  setAssessmentStatus,
  QuestionStatusById,
  setBPStatus,
} from '../../app/store/questionnaireAnswerSlice';
import { REMEDIATION_OPTIONS } from '../../services/surveyHelpers';

import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { Domain } from '../../interfaces/domain.interface';
import { Topic } from '../../interfaces/topic.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Certification } from '../../interfaces/certification.interface';
import { generateGoldShield } from '../../services/badge';
import useFontLoader from '../Directory/UseFontLoader';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import QuestionOnQuestionnaireList from '../Questions/QuestionOnQuestionnaireList';
import QuestionnaireSidebar from '../Sidebar/QuestionnaireSidebar';
import BestPracticeHeader from '../BestPractices/BestPracticeHeader';
import { Button } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { resetSurvey, updateSurvey } from '../../app/store/surveySlice';
import { Company, Version } from '../../interfaces/users.interface';
import { Survey } from '../../interfaces/survey.interface';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import { updateAssessmentSurveySimple } from '../../services/assessments';
import { updateAssessment } from '../../app/store/assessmentSlice';
import { updateCompanyBoolean } from '../../services/company';
import { updateContact, updateContactShort } from '../../services/contact';
import { updateCompanyApplication, updateCompanyApplicationShort } from '../../services/companyApplication';
import QuestionnaireAnswerLoader from './QuestionnaireAnswerLoader';
import moment from 'moment';
import { debounce } from 'lodash';
import BouncingDotsLoader from '../Forms/Bouncing3dot';
import { createBadge, updateVersionBadge } from '../../services/applications';
import { fetchBaselineSurvey, fetchWorkflow, updateWorkflow } from '../../app/store/workflowSlice';
import createSurvey from '../../services/surveys';
import { Workflow } from '../../interfaces/workflow.interface';
import { getAssessmentQuestionsAndAnswersCount } from '../../services/surveyHelpers';
import { FileDownload } from '@mui/icons-material';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { canStartFiveThreeSurvey } from '../../services/adminUtils';
import { WorkflowStatus } from '../../services/workflow';

export default function QuestionnaireAnswer(props: {
  questionnaire: Questionnaire,
  domain?: Domain,
  topic?: Topic,
  bestPractice?: BestPractice,
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    questionnaire,
    domain,
    topic,
    bestPractice,
    certificationControlIds,
    certControlsById,
    questions,
    allCertifications,
    questionStatus,
    assessmentStatus,
    assessmentQuestionsStatus,
    company,
    bpStatus,
    readOnly,
  } = useAppSelector(state => state.questionnaireAnswer);
  const {
    survey,
    mpa_best_practice: mpaBestPractice,
  } = useAppSelector(state => state.surveys);
  const { assessment } = useAppSelector(state => state.assessments);
  const { user } = useAppSelector(state => state.user);
  const { baseline, workflow } = useAppSelector(state => state.workflow);
  const [showSurveySubmitButton, setShowSurveySubmitButton] = useState(false);
  const [questionnaireComplete, setQuestionnaireCompleteOpen] = useState(false);
  const handleQuestionnaireCompleteOpen = () => setQuestionnaireCompleteOpen(true);
  const handleQuestionnaireCompleteClose = () => setQuestionnaireCompleteOpen(false);
  const [questionnaireSubmitted, setQuestionnaireSubmittedOpen] = useState(false);
  const handleQuestionnaireSubmittedOpen = () => setQuestionnaireSubmittedOpen(true);
  const handleQuestionnaireSubmittedClose = () => setQuestionnaireSubmittedOpen(false);
  const [questionnaireSubmittedConfirm, setQuestionnaireSubmittedConfirmOpen] = useState(false);
  const handleQuestionnaireSubmittedConfirmOpen = () => setQuestionnaireSubmittedConfirmOpen(true);
  const handleQuestionnaireSubmittedConfirmClose = () => setQuestionnaireSubmittedConfirmOpen(false);

  const [showAssessmentSubmitButton, setShowAssessmentSubmitButton] = useState(false);
  const [assessmentComplete, setAssessmentCompleteOpen] = useState(false);
  const handleAssessmentCompleteOpen = () => setAssessmentCompleteOpen(true);
  const handleAssessmentCompleteClose = () => setAssessmentCompleteOpen(false);
  const [assessmentSubmitted, setAssessmentSubmittedOpen] = useState(false);
  const handleAssessmentSubmittedOpen = () => setAssessmentSubmittedOpen(true);
  const handleAssessmentSubmittedClose = () => setAssessmentSubmittedOpen(false);
  const [remediationCompleted, setRemediationCompletedOpen] = useState(false);
  const handleRemediationCompletedOpen = () => setRemediationCompletedOpen(true);
  const handleRemediationCompletedClose = () => setRemediationCompletedOpen(false);
  const [siteOrApplicationName, setSiteOrApplicationName] = useState<string | undefined>(undefined);
  const [shouldOpenModal, setShouldOpenModal] = useState(true);
  const [shouldOpenQuestionnaireModal, setShouldOpenQuestionnaireModal] = useState(user?.type === 'vendor_admin');
  const [newSurveyId, setnewSurveyId] = useState<number | undefined>(undefined);
  const questionnaires: Questionnaire[] = useAppSelector(state => state.questionnaires.results);
  const canStartFiveDotThree = useMemo(() => canStartFiveThreeSurvey(user), [user]);

  useEffect(() => {
    dispatch(fetchAll(false));
  }, []);

  useEffect(() => {
    if (!company || !survey || (!survey.application && !survey.site)) return;

    const site = survey.site;
    const application = survey.application;

    if (site) setSiteOrApplicationName(company.locations?.find(loc => loc.id === site)?.name);
    if (application) setSiteOrApplicationName(company.companyApplications?.find(app => app.application.id === application)?.application.name);
  }, [company, survey]);

  // load OR update workflow object if there's a survey
  /* KYLE: in this function we need to determine how to get the workflow. There are 3 possibilities:
    1. This is a baseline survey. URL will be /questionnaire/{id}/ and survey.quetsionnaire.type will be either application_baseline or site_baseline. Get workflow via /workflows/?baseline={survey.id}
    2. This is a best practice survey. URL will be /qustionnaire/{id}/ and survey.questionnaire.type will be mpa_best_practice. Get workflow via /workflows/?bpSurvey={survey.id}
    3. This is an assessment. URL will be /assessment/{id}/questionnaire/{id}/. Get workflow via /workflows/?bpSurvey={survey.id}&assessment={assessment.id}
  */
  useEffect(() => {
    if (survey && (!workflow || (workflow.bpSurvey !== survey.id && workflow.baseline !== survey.id && (!assessment || workflow.assessment !== assessment.id)))) dispatch(fetchWorkflow({ survey, assessment }));
  }, [survey, assessment]);

  // fetch baseline survey if there's a survey, questionnaire and a workflow
  // KYLE: We only need to fetch the baseline if this is a best practice questionnaire or assessment. Probably best to wait until we have the workflow here then just request the baseline via the baseline id in the workflow if needed. 
  useEffect(() => {
    if (survey && questionnaire && questionnaire.type === 'mpa_best_practice' && workflow && (!baseline || workflow.baseline !== baseline.id)) {
      dispatch(fetchBaselineSurvey({
        id: workflow.baseline!,
      }));
    }
    //KYLE: at this point we should have everything we had before and the flow can continue as normal
  }, [survey, questionnaire, workflow]);

  useEffect(() => {
    if (!certControlsById) return;
  
    let certs:Certification[] = [];
    Object.keys(certControlsById).forEach(certControlId => {
      const index = certs.findIndex(cert => cert.id === certControlsById[certControlId].certification.id);
      if (index === -1) certs.push(certControlsById[certControlId].certification);
    });
    dispatch(setAllCertifications(certs));
  }, [certControlsById]);

  useEffect(() => {
    if (!allCertifications || !questions || !questionnaire) return;
    const questionStatusById:QuestionStatusById = {};
    if (survey && (survey.questionnaire as Questionnaire).type === 'mpa_best_practice') {
      // instead of filter, fetch baseline based on workflow
      // KYLE: lets make sure if we between survey, assessment, and questionnaireAnswer we are only fetching the baseline once and everywhere else references that object
      // const baseline = await fetchBaselineSurvey(questionnaire.id, (survey.site || survey.application) as number, workflow.applicationVersion);
      // T: stored the baseline (Survey[]) inside the workflow slice, instead of fetching it here since it's going to be referenced somewhere else in the app
      const addQAs = (array:Survey) => {
        array.questionAnswers.forEach(qa => {
          if (typeof qa === 'number') return;
          questionStatusById[qa.question] = {
            status: 'offSurvey',
            questionAnswer: qa,
            question: {
              title: 'none',
              slug: 'none',
              version: 'none',
              excludedByTrainings: [],
              questionnaire: 0,
              domain: 0,
              topic: 0,
              bestPractice:0,
              includeAttachmentForm: false,
              includeComment: false,
              excludedByCertifications: [],
              inclusions: [],
              answers: [],
              isBestPractice: false,
            },
          };
        });
      };

      if (baseline) addQAs(baseline);
    }
    dispatch(setAllQuestionStatus(questionStatusById));
  }, [allCertifications, questions, baseline]);

  const isDev = () => {
    //if (process.env.REACT_APP_ENVIRONMENT === 'dev' || window.location.host.indexOf('qa-tpn') > -1) return true;
    return false;
  };

  useEffect(() => {
    if (!survey) return;

    if ( user?.type !== 'vendor_admin' ) {
      dispatch(setReadOnly(true));
      return;
    }

    if (survey.status === 'submittedForAssessment' && (!assessmentStatus || assessmentStatus !== 'Pre-Assessment') && questionnaire && !readOnly) {
      handleQuestionnaireSubmittedOpen();
      dispatch(setReadOnly(true));
    }

    if ((['submittedForAssessment', 'priority'].indexOf(survey.status) > -1 || survey.status.indexOf('remediation') > -1) &&
      (!assessmentStatus || assessmentStatus !== 'Pre-Assessment')
    ) dispatch(setReadOnly(true));

    if (!questionnaire || questionnaire.type !== 'mpa_best_practice') return;

    if (isDev() && questionnaire && questionnaire.type === 'mpa_best_practice' && !assessmentStatus && survey.status !== 'submittedForAssessment') {setShowSurveySubmitButton(true); return;}
    if (!isDev() && survey.status === 'complete' && questionnaire && questionnaire.type === 'mpa_best_practice') {
      if (shouldOpenQuestionnaireModal) {
        setShouldOpenQuestionnaireModal(false);
        handleQuestionnaireCompleteOpen();
      }
      setShowSurveySubmitButton(true); return;
    }

    setShowSurveySubmitButton(false);
  }, [survey, questionnaire, mpaBestPractice]);

  useEffect(() => {
    if (assessmentStatus && assessmentStatus === 'Pre-Assessment' && readOnly) dispatch(setReadOnly(false));
    if (assessmentStatus && assessmentStatus !== 'Pre-Assessment' && !readOnly) dispatch(setReadOnly(true));
  }, [assessmentStatus]);

  useEffect(() => {
    if (!survey || !assessment || user?.type !== 'assessor' || !questionnaire || !assessmentQuestionsStatus) return;
    
    const curSurvey = (assessment.surveys as AssessmentSurvey[]).find(assSur => typeof assSur.survey === 'number' ? assSur.survey === survey.id : assSur.survey.id === survey.id);
    if (!curSurvey) {
      setShowAssessmentSubmitButton(false);
      return;
    }

    const { hasQuestion, hasBP, actualAssessmentAnswers, isComplete } = getAssessmentQuestionsAndAnswersCount(bpStatus, assessmentQuestionsStatus, questionnaire, workflow?.status as WorkflowStatus);
    if (!hasQuestion || !hasBP) return;
    if (isDev() && actualAssessmentAnswers?.length && assessmentStatus === 'Assessing') {setShowAssessmentSubmitButton(true); return;}
    if (!isDev() && isComplete && assessmentStatus === 'Assessing') {
      if (shouldOpenModal) {
        setShouldOpenModal(false);
        handleAssessmentCompleteOpen();
      }
      setShowAssessmentSubmitButton(true);
      return;
    }

    setShowAssessmentSubmitButton(false);
  }, [assessment, survey, assessmentStatus, bpStatus, assessmentQuestionsStatus]);

  useEffect(() => {
    if (questionnaire && certificationControlIds) dispatch(getCertificateControlsById(certificationControlIds));
  }, [certificationControlIds]);

  const submitForAssessment = async () => {
    handleQuestionnaireSubmittedConfirmClose();
    handleQuestionnaireSubmittedOpen();
    dispatch(setReadOnly(true));
    if (!survey) return;
    await dispatch(updateSurvey({ ...survey, status: 'submittedForAssessment' }));
    if (workflow) await dispatch(updateWorkflow({ id: workflow.id, status: 'submittedForAssessment' }));
    if ((!survey.application && !survey.site) || !company) return;
    const newDate = moment().utc();
    if (survey.site) {
      const site = company.locations.find(location => location.id === survey.site);
      const date = moment().format('YYYY-MM-DD');
      if (site && site.badge !== 'tpn_assessed') updateContact({ ...site, primaryContact: site.primaryContact ? site.primaryContact.id : undefined, badge: 'tpn_self_reported', blueShieldDate: date });
      if (workflow) {
        createBadge({
          title: 'tpn_self_reported',
          awardedDate: date,
          expirationDate: moment(date).add(1, 'years').format('YYYY-MM-DD'),
        }).then(async newBadge => {
          await dispatch(updateSurvey({ ...survey, status: 'submittedForAssessment' }));
          const badges = workflow.badges ? workflow?.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
          dispatch(updateWorkflow({ id: workflow.id, badges: [...badges, newBadge.id!] }));
        });
      }
    }
    if (survey.application && survey.version) {
      const application = company.companyApplications?.find(capplication => capplication.application.id === survey.application);
      if (application) {
        const version = (application.application.versions as Version[]).find(v => v.value === survey.version);
        if (version) {
          const date = moment().format('YYYY-MM-DD');
          createBadge({
            title: 'tpn_self_reported',
            awardedDate: date,
            expirationDate: moment(date).add(1, 'years').format('YYYY-MM-DD'),
          }).then(newBadge => {
            updateVersionBadge(version.id!, newBadge.id!);
            if (workflow) {
              const badges = workflow.badges ? workflow?.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
              dispatch(updateWorkflow({ id: workflow.id, badges: [...badges, newBadge.id!] }));
            }
          });
        }

        if (application.badge !== 'tpn_assessed') updateCompanyApplication({
          company: application.company,
          id: application.id,
          application: application.application.id!,
          sites: application.sites ? application.sites.map(site => site.id!) : undefined,
          services: application.services.map(service => service.id),
          badge: 'tpn_self_reported',
          blueShieldDate: newDate.format('YYYY-MM-DD'),
        });
      }
    }
    if (company.displayShieldType !== 'tpn_assessed'
    ) {
      updateCompanyBoolean({
        id: company.id, 
        displayShieldType: 'tpn_self_reported',
        blueShieldDate: newDate.format('YYYY-MM-DD'),
      });
    }
  };

  const submitForApproval = () => {
    handleAssessmentCompleteClose();
    handleAssessmentSubmittedOpen();

    if (!assessment || !survey) return;
    const curSurvey = (assessment.surveys as AssessmentSurvey[]).find(assSur => typeof assSur.survey === 'number' ? assSur.survey === survey.id : assSur.survey.id === survey.id);
    if (!curSurvey) return;
    updateAssessmentSurveySimple( curSurvey.id!, { status: 'Submitted', submittedDate: moment().format('YYYY-MM-DDTHH:mm:ssZZ') }).then(nAssSur => {
      const assessmentStatuses = ['Pre-Assessment', 'Assessing', 'Submitted'];
      let newStatus = 2;
      (assessment.surveys as AssessmentSurvey[]).forEach(assSur => {
        const curIndex = assSur.id === nAssSur.id ? 2 : assessmentStatuses.indexOf(assSur.status);
        if (curIndex !== -1 && curIndex < newStatus) newStatus = curIndex;
      });
      dispatch(updateAssessment({ ...assessment, status: assessmentStatuses[newStatus] }));
      if (workflow) dispatch(updateWorkflow({ id: workflow.id, status: assessmentStatuses[newStatus] }));
      dispatch(setAssessmentStatus('Submitted'));
    });
  };

  const loadFont = useFontLoader('Montserrat', 700);
  const handleGenerateGoldShield = useCallback( async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!workflow || !company) return;
    const isFontLoaded = await loadFont();
    if (!isFontLoaded) {
      return;
    }

    generateGoldShield({
      company,
      site: company.locations?.find(loc => loc.id ===  workflow?.site),
      workflow,
    });
  }, [workflow, company]);

  useEffect(() => {
    dispatch(setBPStatus());

    if (!assessmentQuestionsStatus || !assessmentStatus || assessmentStatus !== 'Remediation' || !assessment || !survey) return;
    const assQKeys = Object.keys(assessmentQuestionsStatus);
    if (assQKeys.length === 0) return;

    let complete = true;
    for (let i = 0; i < assQKeys.length; i++) {
      const currAssessment = assessmentQuestionsStatus[assQKeys[i]];
	    const nonBlockingRemediation = ['submitted', 'remediation', 'priority'].includes(currAssessment?.status || '') && [REMEDIATION_OPTIONS.willNotRemediate, REMEDIATION_OPTIONS.willRemediateLater].includes(currAssessment.vendorStatus || '');
      if ((currAssessment.status?.toLowerCase() !== 'remediation' && currAssessment.status?.toLowerCase() !== 'priority') || nonBlockingRemediation) continue;
      complete = false;
      break;
    }

    if (!complete) return;
    const curSurvey = (assessment.surveys as AssessmentSurvey[]).find(assSur => typeof assSur.survey === 'number' ? assSur.survey === survey.id : assSur.survey.id === survey.id);
    if (!curSurvey) return;
    const date = curSurvey.reportIssueDate ? moment(curSurvey.reportIssueDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    updateAssessmentSurveySimple( curSurvey.id!, { status: 'Complete' }).then(nAssSur => {
      const assessmentStatuses = ['Pre-Assessment', 'Assessing', 'Submitted', 'Remediation', 'Complete'];
      let newStatus = 4;
      (assessment.surveys as AssessmentSurvey[]).forEach(assSur => {
        const curIndex = assSur.id === nAssSur.id ? 4 : assessmentStatuses.indexOf(assSur.status);
        if (curIndex !== -1 && curIndex < newStatus) newStatus = curIndex;
      });

      if (company) updateCompanyBoolean({ id: company.id, displayShieldType: 'tpn_assessed', goldShieldDate: date });
      if ((curSurvey.survey as Survey).site && workflow?.status !== 'Complete') {
        updateContactShort({ id: (curSurvey.survey as Survey).site, badge: 'tpn_assessed', goldShieldDate: date });
        if (workflow) {
          createBadge({
            title: 'tpn_assessed',
            awardedDate: date,
            expirationDate: moment(date).add(2, 'years').format('YYYY-MM-DD'),
          }).then(newBadge => {
            const badges = workflow.badges ? workflow.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
            dispatch(updateWorkflow({ id: workflow.id, badges: [...badges, newBadge.id!], status: 'Complete' }));
            handleRemediationCompletedOpen();
          });
        }
      }
      if ((curSurvey.survey as Survey).application && workflow?.status !== 'Complete') {
        const companyApplication = company?.companyApplications?.find(ca => ca.application.id === (curSurvey.survey as Survey).application);
        if (!companyApplication) return;

        const version = (companyApplication.application.versions as Version[]).find(v => v.value === (curSurvey.survey as Survey).version);
        if (version) {
          createBadge({
            title: 'tpn_assessed',
            awardedDate: date,
            expirationDate: moment(date).add(2, 'years').format('YYYY-MM-DD'),
          }).then(newBadge => {
            updateVersionBadge(version.id!, newBadge.id!);
            if (workflow) {
              const badges = workflow.badges ? workflow.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
              dispatch(updateWorkflow({ id: workflow.id, badges: [...badges, newBadge.id!], status: 'Complete' }));
            }
          });
        }

        if (companyApplication.badge !== 'tpn_assessed') updateCompanyApplicationShort({ id: companyApplication.id, badge: 'tpn_assessed', goldShieldDate: date });

        dispatch(updateAssessment({ ...assessment, status: assessmentStatuses[newStatus] }));
        dispatch(setAssessmentStatus('Complete'));
        handleRemediationCompletedOpen();
      }
    });
  }, [questionStatus, assessmentQuestionsStatus, assessmentStatus, assessment, survey]);

  const callBackgroundCreateSurvey = async (dsurvey:Survey, dmpaBestPractice:number, dworkflow:Workflow) => {
    if (!dsurvey || !dmpaBestPractice || !dworkflow || dworkflow.bpSurvey) return;
    const fiveThreeBPId =  questionnaires.filter(q => q.type === 'mpa_best_practice').sort((a, b) =>  b.id - a.id)[0].id;
    const bpSurvey = await createSurvey({
      questionnaire: canStartFiveDotThree ? fiveThreeBPId : dmpaBestPractice,
      status: 'incomplete',
      company: (dsurvey.company as Company).id!,
      questionAnswers: [],
      site: dsurvey.site,
      application: dsurvey.application,
      version: dsurvey.version,
    });
    dispatch(updateWorkflow({ id: dworkflow.id, status: 'incomplete', bpSurvey: bpSurvey.id! }));
    setnewSurveyId(bpSurvey.id);
  };

  const debounceBackgroundCreateSurvey = useCallback(debounce((dsurvey, dmpaBestPractice, dworkflow) => {
    callBackgroundCreateSurvey(dsurvey, dmpaBestPractice, dworkflow);
  }, 1000), []);

  useEffect(() => {
    if (!Object.keys(questionStatus).length || !bpStatus || !questionnaire) return;
    let surveyComplete = true;
    let hasBP = false;
    let hasQuestion = false;

    questionnaire.domains.forEach(d => {
      d.topics.forEach(t => {
        t.bestPractices.forEach(bp => {
          if (!bpStatus[bp.id] || bpStatus[bp.id].status === 'hide') return;
          if (bpStatus[bp.id].status !== 'hide') hasBP = true;
          bp.questions.forEach(q => {
            if (!questionStatus[q.id] || questionStatus[q.id].status === 'incomplete' || (questionStatus[q.id].questionAnswer && !questionStatus[q.id].questionAnswer?.id )) surveyComplete = false;
            hasQuestion = true;
          });
        });
      });
    });
    if (!surveyComplete || !hasBP || !hasQuestion) return;
    if (survey && ((survey.questionnaire as Questionnaire).type === 'site_baseline' || (survey.questionnaire as Questionnaire).type === 'application_baseline')) {
      if (!mpaBestPractice || !workflow) return;
      if (!workflow.bpSurvey) debounceBackgroundCreateSurvey(survey, mpaBestPractice, workflow);
      if (survey?.status === 'complete' && (
        (survey.site && survey.site === workflow?.site) ||
        (survey.application && survey.application === workflow?.application)
      )) return;
    }
    if (shouldOpenQuestionnaireModal && !assessment && survey?.status !== 'submittedForAssessment') {
      setShouldOpenQuestionnaireModal(false);
      handleQuestionnaireCompleteOpen();
    }
  }, [questionStatus, survey, mpaBestPractice, workflow]);

  useEffect(() => {
    if (!questionnaire || questionnaire.type !== 'mpa_best_practice' || !survey || !company) return;
    const cert = company.companyCertifications?.find(tcert => {
      if (tcert.certification.company) return;
      if (survey.application) return tcert.applications.find(app => app.id === survey.application);
      if (survey.site) return tcert.sites.find(site => site.id === survey.site);
    });
    if (!cert) return;
    if (survey.site) {
      const site = company.locations.find(location => location.id === survey.site);
      if (site && !site.badge) updateContact({ ...site, primaryContact: site.primaryContact ? site.primaryContact.id : undefined, badge: 'non_tpn_self_reported' });
    }
    if (survey.application) {
      const application = company.companyApplications?.find(capplication => capplication.application.id === survey.application);
      if (application) {
        const version = (application.application.versions as Version[]).find(v => v.value === survey.version);
        if (version && !version.badge) {
          const date = moment().format('YYYY-MM-DD');
          createBadge({
            title: 'non_tpn_self_reported',
            awardedDate: date,
            expirationDate: moment(date).add(2, 'years').format('YYYY-MM-DD'),
          }).then(newBadge => {
            updateVersionBadge(version.id!, newBadge.id!);
          });
        }

        if (!application.badge) {
          updateCompanyApplication({
            company: application.company,
            id: application.id,
            application: application.application.id!,
            sites: application.sites ? application.sites.map(site => site.id!) : undefined,
            services: application.services.map(service => service.id),
            badge: 'non_tpn_self_reported',
          });
        }
      }
    }
    if (!company.displayShieldType ||
      company.displayShieldType === '' ||
      company.displayShieldType === 'tpn_member'
    ) {
      updateCompanyBoolean({
        id: company.id,
        displayShieldType: 'non_tpn_self_reported',
      });
    }
  }, [company?.companyCertifications]);

  const relavantQuestionStatus = useMemo(() => {
    if (!bestPractice || bestPractice.questions.length === 0) {
      return {};
    }
  
    return bestPractice.questions.reduce((acc, question) => {
      acc[question.id] = questionStatus[question.id];
      return acc;
    }, {} as QuestionStatusById);
  }, [bestPractice?.questions, questionStatus]);  

  const [hideLoader, setHideLoader] = useState(false);
  const navigateAndReload = useCallback(() => {
    navigate(`/questionnaire/${newSurveyId}/`);
    dispatch(resetSurvey());
    setHideLoader(false);
  }, [newSurveyId]);

  if (!hideLoader || !questionnaire) return  <QuestionnaireAnswerLoader { ...props} setHideLoader={setHideLoader} />;
  return (
        <Grid container sx={{ maxWidth: '100%' }} spacing={1} mt={3} mb={3}>
          <Grid item xs={12} md={questionnaire!.type === 'mpa_best_practice' ? 8 : 12}>
             { domain && topic && bestPractice && questionnaire && <BestPracticeHeader bestPractice={{ ...bestPractice, domain, topic, questionnaire }} /> }
             { bestPractice && <QuestionOnQuestionnaireList questions={bestPractice.questions} relavantQuestionStatus={relavantQuestionStatus}/> }
          </Grid>
          {questionnaire.type === 'mpa_best_practice' && <Grid item xs={12} md={4}>
            <QuestionnaireSidebar />
          </Grid>}
          {showSurveySubmitButton && <Button variant='contained' sx={{ position: 'absolute', right: '2.5rem', top: '1rem' }} onClick={() => handleQuestionnaireCompleteOpen()}>Submit For Assessment</Button>}
          {showAssessmentSubmitButton && <Button variant='contained' sx={{ position: 'absolute', right: '2.5rem', top: '1rem' }} onClick={() => handleAssessmentCompleteOpen()}>Submit Assessment</Button>}
          <StandardDialog title={`${questionnaire && questionnaire.title } Complete`} handleClose={handleQuestionnaireCompleteClose} isOpen={questionnaireComplete}>
            <Typography>You have answered all questions on the {questionnaire && questionnaire.title}.
              {questionnaire && questionnaire.type === 'mpa_best_practice' && ' Save to continue editing later or submit and complete to finalize. Once submitted, you will no longer be able to edit your responses. Are you sure you want to submit and complete?'}
              {questionnaire && (questionnaire.type === 'site_baseline' || questionnaire.type === 'application_baseline') && ` Your next step is to complete the TPN Best Practices Questionnaire for this ${survey?.application ? 'app' : 'site'}. You may also upload accepted non-TPN certifications as self-attestation for ${siteOrApplicationName ? ` ${siteOrApplicationName}` : 'this entitiy'}` }
              {questionnaire && !questionnaire.type && ' Are you sure you want to proceed with submission? If so, you will be taken back to your profile dashboard.'}
            </Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleQuestionnaireCompleteClose()}>Continue Editing</Button>
              {questionnaire && (questionnaire.type === 'site_baseline' || questionnaire.type === 'application_baseline') && <>
              {newSurveyId && <Button
                variant="contained"
                color="primary"
                onClick={navigateAndReload}
              >
                Submit and Continue to Best Practice
              </Button> }
                {!newSurveyId && <Button variant="contained" color="primary" disabled><BouncingDotsLoader /></Button>}
                <Link to='/profile/'><Button variant="contained" color="primary">Submit</Button></Link>
              </>}
              {questionnaire && questionnaire.type === 'mpa_best_practice' && <Button variant="contained" color="primary" onClick={() => {handleQuestionnaireCompleteClose(); handleQuestionnaireSubmittedConfirmOpen(); }}>Submit</Button>}
              {questionnaire && ['site_baseline', 'application_baseline', 'mpa_best_practice'].indexOf(questionnaire.type!) === -1 && <Link to='/profile/'><Button variant="contained" color="primary">Return to Profile</Button></Link>}
            </StandardDialogActions>
          </StandardDialog>
          <StandardDialog title={`${questionnaire ? questionnaire.title : 'TPN Best Practices Questionnaire'} Submit Confirmation`} handleClose={handleQuestionnaireSubmittedConfirmClose} isOpen={questionnaireSubmittedConfirm}>
            <Typography>Are you sure you want to submit your {questionnaire ? questionnaire.title : 'TPN Best Practices Questionnaire'}? Once submitted, you will receive your TPN Blue Shield and you can no longer update your answers, add additional information, or upload evidence until you proceed with a TPN Gold Assessment.</Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleQuestionnaireSubmittedConfirmClose()}>Continue Editing</Button>
              <Button variant="contained" onClick={() => submitForAssessment()}>Submit and Complete</Button>
            </StandardDialogActions>
          </StandardDialog>
          <StandardDialog title={`${questionnaire ? questionnaire.title : 'TPN Best Practices Questionnaire'} Submitted`} handleClose={handleQuestionnaireSubmittedClose} isOpen={questionnaireSubmitted} hideBackdrop={true}>
            <Typography mb={1}>Congratulations! You have earned a TPN Blue Shield for {siteOrApplicationName && ` ${siteOrApplicationName}`}. You are now able to download the TPN Blue Shield logo by clicking on the icon in your TPN+ profile.</Typography>
            <Typography>PLEASE NOTE: you must update your TPN Questionnaire on an annual basis to continue use of the TPN Blue Shield logo for {siteOrApplicationName && ` ${siteOrApplicationName}`}.</Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleQuestionnaireSubmittedClose()}>Close</Button>
              <Link to='/profile/'><Button variant="contained" color="primary">Back to Profile</Button></Link>
            </StandardDialogActions>
          </StandardDialog>

          <StandardDialog title="TPN Assessment Complete" handleClose={handleAssessmentCompleteClose} isOpen={assessmentComplete}>
            <Typography>You have completed this assessment. Would you like to submit it for approval?</Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleAssessmentCompleteClose()}>Close</Button>
              <Button variant="contained" color="primary" onClick={() => submitForApproval()}>Submit</Button>
            </StandardDialogActions>
          </StandardDialog>
          <StandardDialog title="TPN Assessment Submitted" handleClose={handleAssessmentSubmittedClose} isOpen={assessmentSubmitted}>
            <Typography>You have Submitted this assessment, please return to your profile page.</Typography>
            <StandardDialogActions>
              <Button variant="outlined" onClick={() => handleAssessmentSubmittedClose()}>Close</Button>
              <Link to='/profile/'><Button variant="contained" color="primary">Profile</Button></Link>
            </StandardDialogActions>
          </StandardDialog>
          <StandardDialog title="Remediation Updates Provided" handleClose={handleRemediationCompletedClose} isOpen={remediationCompleted && user?.type === 'vendor_admin'}>
            <Typography>Congratulations! You&apos;ve earned a TPN Gold Shield for {siteOrApplicationName && ` ${siteOrApplicationName}`}. If you submitted a future remediation plan, ensure you meet or manage the date if delays occur. Your profile has been updated and you may download the TPN Gold Shield PNG and PDF from your TPN+ profile.</Typography>
            <StandardDialogActions>
                  <Box sx={{ flexGrow: 1 }}>
                      <Button variant="outlined" onClick={() => handleRemediationCompletedClose()}>Close</Button>
                  </Box>
                  <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<FileDownload />}
                      onClick={handleGenerateGoldShield}
                  >
                    Download TPN Gold Status PDF
                  </Button>
                  <Link to='/profile/'>
                      <Button variant="contained" color="primary">Profile</Button>
                  </Link>
            </StandardDialogActions>
          </StandardDialog>
          { (assessmentSubmitted || (remediationCompleted && user?.type === 'vendor_admin')) && <Confetti />}
        </Grid>
  );
}