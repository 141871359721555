import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import User, { BaseUser, Contact, ContactSubmissionData } from '../../interfaces/users.interface';
import createUser, { getUser, updateUser } from '../../services/user';
import createContact, { updateContact } from '../../services/contact';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Skeleton } from '@mui/material';

function UserForm(props: { successCallback: any, userType: string, user: (User | undefined), modalClose?: any }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    setError,
    setValue,
    clearErrors,
    control,
  } = useForm();

  const [user, setUser] = useState<User | undefined>(undefined);
  const [userFetchComplete, setUserFetchComplete] = useState(false);

  const userFetch = async () => {
    if (!props.user) {
      setUserFetchComplete(true);
      return;
    }
    const userRes = await getUser(props.user.id);
    setUserFetchComplete(true);
    if (!userRes) return;
    setUser(userRes);
  };

  useEffect(() => {
    userFetch();
  }, []);

  useEffect(() => {
    if (!user?.contact) return;
    setValue('name', user.contact.name);
    setValue('phoneNumber', user.contact.phoneNumber);
    setValue('address', user.contact.address);
    setValue('zipcode', user.contact.zipcode);
    setValue('isPrimary', user.contact.isPrimary);
    setValue('country', user.contact.country);
    setValue('state', user.contact.state);
    setValue('city', user.contact.city);
  }, [user]);

  const onSubmit = (data: FieldValues) => {
    const newContact: Contact = {
      name: `${data.firstName} ${data.lastName} primary contact`,
      phoneNumber: data.phoneNumber,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      isPrimary: true,
    };

    const newUser: BaseUser = {
      username: data.email,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      type: props.userType,
      isActive: true,
      contact: newContact,
      isConsultant: false,
    };
    if (user && user.contact) {
      newContact.id = typeof user.contact === 'number' ? user.contact : user.contact.id;
      const contactSubmissionData: ContactSubmissionData = {
        ...newContact,
        primaryContact: undefined,
      };
      updateContact(contactSubmissionData).then((updatedContact: Contact) => {
        updatedContact.primaryContact = undefined;
        newUser.contact = updatedContact;
        newUser.id = user.id;
        updateUser(newUser).then((updatedUserResponse: User) => {
          props.successCallback(updatedUserResponse);
          clearErrors();
        }).catch((responseError: any) => {
          console.log(responseError.response.data);
          const errorData = responseError.response?.data;
          if (!errorData) {
            clearErrors();
            return;
          }
          for (const field of Object.keys(errorData)) {
            const errorMsgArr: string[] = errorData[field];
            setError(field, { type: 'manual', message: errorMsgArr[0] });
          }
          resetField('username');
        });
      });
    } else if (user && !user.contact) {
      const contactSubmissionData: ContactSubmissionData = {
        ...newContact,
        primaryContact: undefined,
      };
      createContact(contactSubmissionData).then((updatedContact: Contact) => {
        updatedContact.primaryContact = undefined;
        newUser.contact = updatedContact;
        newUser.id = user.id;
        updateUser(newUser).then((updatedUserResponse: User) => {
          props.successCallback(updatedUserResponse);
          clearErrors();
        }).catch((responseError: any) => {
          console.log(responseError.response.data);
          const errorData = responseError.response?.data;
          if (!errorData) {
            clearErrors();
            return;
          }
          for (const field of Object.keys(errorData)) {
            const errorMsgArr: string[] = errorData[field];
            setError(field, { type: 'manual', message: errorMsgArr[0] });
          }
          resetField('username');
        });
      });
    } else {
      createUser(newUser).then((newUserResponse: User) => {
        clearErrors();
        reset();
        props.successCallback(newUserResponse);
      }).catch((responseError: any) => {
        console.log(responseError.response.data);
        const errorData = responseError.response?.data;
        if (!errorData) {
          clearErrors();
          return;
        }
        for (const field of Object.keys(errorData)) {
          const errorMsgArr: string[] = errorData[field];
          setError(field, { type: 'manual', message: errorMsgArr[0] });
        }
        resetField('username');
      });
    }
  };

  return (<>
    { !userFetchComplete && <Grid container spacing={2}>
      <Grid item xs={12}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12} md={6}><Skeleton sx={{ width: '100%', height: '56px' }} /></Grid>
      <Grid item xs={12}><Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Skeleton width='200px' height='56px' />
        {props.modalClose && <Skeleton sx={{ mr: 1 }} width='200px' height='56px' />}
      </Box></Grid>
    </Grid> }
    { userFetchComplete && <Grid container>
      <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='email'
              label='Email'
              autoFocus
              disabled={!!user}
              value={user ? user.email : null}
              {...register('email')}
              onFocus={() => clearErrors()}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='firstName'
              label='First Name'
              defaultValue={user ? user.firstName : null}
              onFocus={() => clearErrors()}
              autoFocus
              {...register('firstName')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='lastName'
              label='Last Name'
              defaultValue={user ? user.lastName : null}
              onFocus={() => clearErrors()}
              autoFocus
              {...register('lastName')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='address'
              label='Address'
              onFocus={() => clearErrors()}
              autoFocus
              {...register('address')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='country'
              label='Country'
              onFocus={() => clearErrors()}
              autoFocus
              {...register('country')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='state'
              label='State'
              onFocus={() => clearErrors()}
              autoFocus
              {...register('state')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='city'
              label='City'
              onFocus={() => clearErrors()}
              autoFocus
              {...register('city')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='zipcode'
              label='Postal Code'
              autoFocus
              {...register('zipcode')}
              onFocus={() => clearErrors()}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Controller
              render={({ field, fieldState: { error } }) => (<MuiPhoneNumber
                value={field.value}
                required
                fullWidth
                margin='normal'
                id='phoneNumber'
                label='Phone Number'
                InputLabelProps={{ shrink: true }}
                defaultCountry={'us'}
                error={!!error}
                helperText={error ? error.message : ' '}
                variant='outlined'
                onChange={field.onChange}
                onFocus={() => clearErrors()}
              />)}
              rules={{
                required: 'Phone number is required',
                validate: value => value.replace(/\D/g, '').length >= 10 || 'Phone number must be at least 10 digits',
              }}
              name={'phoneNumber'}
              control={control}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button type="submit" variant="contained" endIcon={<NavigateNextIcon />}>Submit</Button>
          {props.modalClose && <Button sx={{ mr: 1 }} variant="outlined" onClick={() => props.modalClose()}>Close</Button>}
        </Box>
      </Box>
    </Grid>
  }</>);
}

UserForm.defaultProps = {
  user: undefined,
};

export default UserForm;