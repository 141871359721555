import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Company, CompanySubmissionData, Contact, Service } from '../../../interfaces/users.interface';
import { updateCompany } from '../../../services/company';

import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import { useEffect, useState } from 'react';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, FormHelperText, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { fetchAllServiceCategories } from '../../../app/store/serviceCategories';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { InputLabel } from '@mui/material';
import { ServiceCategory } from '../../../interfaces/vendor.interface';
import { DataCell, DataRow } from '../../Tables/DataList';
import { useParams } from 'react-router-dom';
import { updateContactShort } from '../../../services/contact';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(title: string, selectedServices: readonly string[], theme: Theme) {
  if (!selectedServices || typeof selectedServices !== 'object') return;
  return {
    fontWeight:
      selectedServices.indexOf(title) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ServiceDataRow = (props: { service: Service }) => {
  const {
    service,
  } = props;
  return (
    <DataRow>
      <DataCell xs={6}>{service.name}</DataCell>
      <DataCell xs={6}>{service?.serviceCategories?.map((serviceCat, index) =><span key={serviceCat.id + service.name + index}>{serviceCat.name}{service.serviceCategories.length - 1 != index ? ', ' : ''}</span>)}</DataCell>
    </DataRow>
  );
};

export default function ServicesAccordion(props: { company: Company, companyCallback: any, userCanAdminCompany: boolean }) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm();
  const {
    company,
    companyCallback,
  } = props;
  const { profileSection } = useParams();
  const dispatch = useAppDispatch();
  const [editServicesOpen, setEditServicesOpen] = useState(false);
  const [activeServices, setActiveServices] = useState(props.company.services ? props.company.services.map(service => service.id) : []);
  const { serviceCategories } = useAppSelector(state => state.serviceCategories);
  const [view, setView] = useState('categories');
  const [errors, setErrors] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const theme = useTheme();

  const handleEditServicesClose = (reset: boolean = false) => {
    setEditServicesOpen(false);
    setErrors([]);

    // reset selected categories and services on close
    if (reset) {
      // for categories
      if (serviceCategories.length <= 0 || !company) return;
      let selectedServiceCategories:number[] = [];
      company.services.forEach(service => {
        serviceCategories.forEach(category => {
          const existsInCat = category.services.find(s => s.id === service.id);
          if (existsInCat !== undefined) selectedServiceCategories.push(category.id);
        });
      });
      setValue('serviceCategories', [...new Set(selectedServiceCategories)]);

      // for services
      setActiveServices(props.company.services ? props.company.services.map(service => service.id) : []);
    }
  };

  useEffect(() => {
    if (serviceCategories && serviceCategories.length > 0) return;
    dispatch(fetchAllServiceCategories());
  }, []);

  useEffect(() => {
    if (serviceCategories.length <= 0 || !company) return;
    let selectedServiceCategories:number[] = [];
    company.services.forEach(service => {
      serviceCategories.forEach(category => {
        const existsInCat = category.services.find(s => s.id === service.id);
        if (existsInCat !== undefined) selectedServiceCategories.push(category.id);
      });
    });
    setValue(
      'serviceCategories',
      getValues('serviceCategories')
        ? [...new Set(selectedServiceCategories.concat(getValues('serviceCategories')))]
        : [...new Set(selectedServiceCategories)],
    );
  }, [serviceCategories, company]);

  useEffect(() => {
    companyCallback(props.company);
    setActiveServices(props.company.services ? props.company.services.map(service => service.id) : []);
  }, [props.company]);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'services');
  }, [profileSection]);

  const isActiveCat = (serviceCategory: ServiceCategory) => {
    const serviceCats = getValues('serviceCategories') as string[];
    const isActive = serviceCats.find(cat => parseInt(cat) === serviceCategory.id);
    return isActive !== undefined;
  };

  const onSubmit = async () => {
    setErrors([]);
    const newErrors:number[] = [];

    serviceCategories.forEach(sc => {
      if (!sc.services.length || !isActiveCat(sc)) return;
      const hasService = sc.services.filter(ser => activeServices.includes(ser.id!));
      if (!hasService || hasService.length === 0) newErrors.push(sc.id);
    });

    if (newErrors.length) {
      setErrors(newErrors);
      return;
    }

    setIsSaving(true);
    
    const siteUpdatePromises: Promise<Contact>[] = [];
    const activeServicesSet = new Set(activeServices);
    // find which site(s) are affected by updating the Company Services
    company.locations.forEach(loc => {
      if (!loc?.services || loc?.services.length === 0) {
        return;
      }
      
      const oldServices = loc.services.map(service => service.id).sort();
      // this filters the old services that still exists in the new selected services  
      const newServices = oldServices.filter(service => activeServicesSet.has(service));
      // tricky way to compare if array is equal or not, since we're sure that it's just array of ID's
      // can sort and check if the string equivalent is the same
      if (newServices.toString() === oldServices.toString()) {
        return;
      }

      siteUpdatePromises.push(updateContactShort({ id: loc.id!, services: newServices }));
    });
    // update them all at once
    if (siteUpdatePromises.length > 0) {
      await Promise.all(siteUpdatePromises);
    }


    const companyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: company.primaryContactUser?.id,
      employees: company.employees.map((employee: any) => employee.id),
      pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
      services: activeServices,
      locations: company.locations.map((location: Contact) => location.id).filter((item): item is number => !!item),
      admins: company.admins.map(item => item.id),
      subsidiaries: company.subsidiaries.map(item => item.id),
    };

    const updatedCompany = await updateCompany(companyData);
    companyCallback(updatedCompany);
    setIsSaving(false);
    handleEditServicesClose();
    setView('categories');
  };

  const checkboxChecked = (id:number, value:number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const handleCheckboxCheck = (id:string, value:number | number[] | undefined) => {
    const idNum = parseInt(id);
    if (value === undefined || typeof value === 'number') { 
      setValue('serviceCategories', [idNum]); 
      return; 
    }
    if (value.indexOf(idNum) === -1) {
      setValue('serviceCategories', [ ...value, idNum ]); 
      return;
    }
    const newValue = [ ...value ];
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('serviceCategories', newValue);

    const serviceCategory = serviceCategories.find((sc) => sc.id === +id);
    if (serviceCategory) {
      const toRemoveServices = serviceCategory.services.map((srv) => srv.id!);
      const newActiveServices = activeServices.filter((asID) => !toRemoveServices.includes(asID));
      setActiveServices(newActiveServices);
    }
  };

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setActiveServices(typeof value === 'string' ? value.split(',').map(v => parseInt(v)) : value);
  };

  const handleDelete = (value: number) => {
    const nselectedServices = activeServices.filter(service => service !== value);
    setActiveServices(nselectedServices);
  };

  const getServiceName = (serviceId: number, serviceCategory: ServiceCategory) => {
    return serviceCategory.services.find(service => service.id === serviceId)?.name;
  };

  const serviceInCat = (value:number, serviceCategory: ServiceCategory) => {
    const isActive = serviceCategory.services.find(cat => cat.id === value);
    return isActive !== undefined;
  };

  return (
    <AccordionCollapse expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
      <AccordionCollapseSummary
        name="services-header"
        title="Services"
        count={company?.services.length}
        addItem={() => { setEditServicesOpen(true); }}
        addItemLabel='Service'
        addItemTest={props.userCanAdminCompany}
      />
      <AccordionDetails>
        <DataRow header>
          <DataCell xs={6}>Service</DataCell>
          <DataCell xs={6}>Category</DataCell>
        </DataRow>
        {company.services.map((service: Service) => <ServiceDataRow key={service.id + service.name} service={service} />)}
      </AccordionDetails>
      <StandardDialog title="Edit Services" handleClose={() => { handleEditServicesClose(true);setView('categories'); }} isOpen={editServicesOpen}>
        <Box component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
          {view === 'categories' && <>
            {serviceCategories &&
              <FormGroup>
                <Controller
                  control={control}
                  name="serviceCategories"
                  render={({ field: { value } }) => (<Grid container>
                    { serviceCategories.map(serviceCategory => (
                      <Grid item xs={4} key={serviceCategory.id}>
                        <FormControlLabel
                          label={serviceCategory.name}
                          control={
                            <Checkbox checked={checkboxChecked(serviceCategory.id, value)} value={serviceCategory.id} onChange={(e) => handleCheckboxCheck(e.target.value, value)} />
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>)}
                />
              </FormGroup>
            }
            <StandardDialogActions>
              <Button variant='outlined' onClick={() => { handleEditServicesClose(true);setView('categories'); }}>Close</Button>
              <Button variant="contained" onClick={() => { setView('services'); }}>Set Service Categories</Button>
            </StandardDialogActions>
          </>}
          {view === 'services' && <>
            <Stack spacing={2}>
              {serviceCategories.map(serviceCategory => isActiveCat(serviceCategory) && (
                <FormControl required={serviceCategory.services.length > 0} key={serviceCategory.id} error={errors.indexOf(serviceCategory.id) !== -1}>
                  <InputLabel id={serviceCategory.name}>{serviceCategory.name}</InputLabel>
                  <Select
                    labelId={serviceCategory.name}
                    id={serviceCategory.name}
                    multiple
                    value={activeServices}
                    onChange={(e) => handleChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label={serviceCategory.name} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => serviceInCat(value, serviceCategory) && (
                          <Chip key={value} label={getServiceName(value, serviceCategory)} variant='outlined' onDelete={() => handleDelete(value)} onMouseDown={ (e) => e.stopPropagation() } />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {serviceCategory.services.map(service => (
                      <MenuItem
                        key={service.id}
                        value={service.id}
                        style={getStyles(service.name!, getValues(serviceCategory.name), theme)}
                      >
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.indexOf(serviceCategory.id) !== -1 && <FormHelperText>You Must Select at least one {serviceCategory.name} service</FormHelperText>}
                </FormControl>
              ))}
            </Stack>
            <Stack direction="row-reverse" spacing={2} mt={2} alignItems=''>
              <LoadingButton type='submit' variant='contained' loading={isSaving}>Save Services</LoadingButton>
              {/* <Button type='submit' variant='contained'>Save Services</Button> */}
              <Button variant='outlined' onClick={() => { handleEditServicesClose(true);setView('categories'); }}>Close</Button>
            </Stack>
          </>}
        </Box>
      </StandardDialog>
    </AccordionCollapse>
  );
}

