import React, { useState, useEffect } from 'react';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText, Paper, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowDown } from '@mui/icons-material';

import { useAppSelector } from '../../app/hooks';
import { Survey } from '../../interfaces/survey.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import { assessmentSteps } from '../../services/surveyHelpers';
import SurveyAssessmentProgress from './SurveyAssessmentProgress';

type ProgressCardProps = {
  mt?: number;
  onProgressBarClick: () => void;
};

export default function ProgressCard({ mt, onProgressBarClick }: ProgressCardProps) {
  const theme = useTheme();
  const { survey } = useAppSelector((state) => state.surveys);
  const { assessment } = useAppSelector((state) => state.assessments);
  const { assessmentStatus } = useAppSelector((state) => state.questionnaireAnswer);
  const [expanded, setExpanded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const typographyProps = (index: number) => {
    let color = '#ccc';
    if (index === currentStep) {
      color = theme.palette.primary.main;
    }
    if (index < currentStep) color = theme.palette.success.main;

    return { color, fontWeight: 'bold', textAlign: 'center' };
  };

  useEffect(() => {
    if (!survey && !assessment) return;

    if (survey && !assessment) {
      if (
        (survey.questionnaire as Questionnaire).type === 'site_baseline' ||
        (survey.questionnaire as Questionnaire).type === 'application_baseline'
      )
        setCurrentStep(survey.status.toLowerCase() === 'complete' ? 1 : 0);
      if ((survey.questionnaire as Questionnaire).type === 'mpa_best_practice') {
        setCurrentStep(2);
        if (survey.status === 'complete' || survey.status === 'submittedForAssessment') setCurrentStep(3);
      }
    }

    if (assessment && survey) {
      setCurrentStep(4);
      const curSurvey = (assessment.surveys as AssessmentSurvey[]).find(
        (assSur) => (assSur.survey as Survey).id === survey.id,
      );
      if (curSurvey === undefined || curSurvey.status === undefined || !curSurvey.status) return;
      if (curSurvey.status.toLowerCase() === 'pre-assessment' || assessmentStatus === 'Pre-Assessment')
        setCurrentStep(5);
      if (curSurvey.status.toLowerCase() === 'assessing' || assessmentStatus === 'Assessing') setCurrentStep(6);
      if (curSurvey.status.toLowerCase() === 'submitted' || assessmentStatus === 'Submitted') setCurrentStep(7);
      if (curSurvey.status.toLowerCase() === 'remediation' || assessmentStatus === 'Remediation') setCurrentStep(8);
      if (curSurvey.status.toLowerCase() === 'complete' || assessmentStatus === 'Complete') setCurrentStep(9);
    }
  }, [assessment, survey, assessmentStatus]);

  return (
    <Paper sx={{ mt: mt || 0, mb: 1 }}>
      <List>
        <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemText 
   primary={
     <span style={{ color: theme.palette.customThemeColors?.darkGrey }}>
       <strong>Current Step:</strong>
       {` ${assessmentSteps[currentStep]}`}
     </span>
   }
 />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {!expanded && <SurveyAssessmentProgress onClick={onProgressBarClick} />}
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <List disablePadding>
            {assessmentSteps.map((step, x) => (
              <div key={`${step}-${x}`}>
                <ListItem>
                  <ListItemText primary={step} primaryTypographyProps={{ sx: typographyProps(x) }} />
                </ListItem>
                {x !== assessmentSteps.length - 1 && (
                  <Divider>
                    <KeyboardArrowDown sx={{ transform: 'translateY(5px)' }} />
                  </Divider>
                )}
              </div>
            ))}
          </List>
        </Collapse>
        {expanded && <SurveyAssessmentProgress onClick={onProgressBarClick} />}
      </List>
    </Paper>
  );
}
