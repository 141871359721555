import { createTheme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    nonCompliant: Palette['primary'];
    customThemeColors?: {
      darkGrey?: string;
      lightGray?: string;
      // Other custom colors
    };
    assessorApproved: Palette['primary'];
    chips?: Palette['primary'];
  }
  interface PaletteOptions {
    nonCompliant: PaletteOptions['primary'];
    assessorApproved: PaletteOptions['primary'];
    customThemeColors?: {
      darkGrey?: string;
      lightGray?: string;
      // Other custom colors
    };
    chips?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    app?: string;
    service?: string;
    site?: string;
    appType?: string;
  }
  interface SimplePaletteColorOptions {
    app?: string;
    service?: string;
    site?: string;
    appType?: string;
  }
}

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    customThemeColors: {
      darkGrey: '#424242', // Your reusable color
      lightGray: '#8c8c8c',
    },
    nonCompliant: {
      light: yellow[500],
      main: yellow[700],
      dark: yellow[900],
      contrastText: '#000',
    },
    assessorApproved: {
      light: '#f6d9eb',
      main: '#f994d3',
      dark: '#fb5abe',
      contrastText: '#000',
    },
    chips: {
      main: '#344C7B',
      app: '#344C7B',
      service: '#55762B',
      site: '#344C7B',
      appType: '#80688d',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 100,
        },
        h5: {
          fontWeight: 100,
        },
      },
    },
  },
});
