import React from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function PageNotFound() {
  return (
    <Container maxWidth="sm">
      <Typography mt={40} variant="h5">Page Not found</Typography>
      <Link href="/">
        <Typography variant="body1">Return home</Typography>
      </Link>
    </Container>
  );
}
