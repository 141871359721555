import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AuditLog } from '../../interfaces/auditLog.interface';
import { DataCell, DataRow } from '../Tables/DataList';
import User from '../../interfaces/users.interface';
import moment from 'moment';

export default function AuditLogModal(props: { auditLogs?: AuditLog[], handleClose: any }) {

  const [auditLogs, setAuditLogs] = useState(props.auditLogs);

  useEffect(() => {
    setAuditLogs(props.auditLogs);
  }, [props.auditLogs]);

  return (
    <Box sx={{ width: '100%', maxHeight: '750px', overflow: 'auto' }}>
      <DataRow header>
        <DataCell xs={3}>User</DataCell>
        <DataCell xs={3}>Date</DataCell>
        <DataCell xs={3}>Action</DataCell>
        <DataCell xs={3}>Description</DataCell>
      </DataRow>
      {auditLogs?.map(log => (
        <DataRow key={log.id}>
          <DataCell xs={3}>{(log.user as User).firstName} {(log.user as User).lastName}</DataCell>
          <DataCell xs={3}>{log.createdAt && moment(log.createdAt).format('MM/DD/YYYY hh:mm')}</DataCell>
          <DataCell xs={3}>{log.event}</DataCell>
          <DataCell xs={3}>{log.description}</DataCell>
        </DataRow>
      ))}
    </Box>
  );
}