import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Question, QuestionResponse, QuestionErrorResponse } from '../../interfaces/question.interface';
import { FieldValues } from 'react-hook-form';

interface InitialQuestionState {
  count: number;
  next?: string;
  previous?: string;
  question?: Question;
  results: Question[];
  isPosting: boolean;
  deletedQuestionId?: number;
  error?: QuestionErrorResponse;
  targetBestPracticeId?: number;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  question: undefined,
  results: [],
  isPosting: false,
  deletedQuestionId: undefined,
  error: undefined,
  targetBestPracticeId: undefined,
} as InitialQuestionState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAll = createAsyncThunk(
  'questions/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/questions/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as QuestionResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const search = createAsyncThunk(
  'questions/search',
  async (searchTerm:string) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/questions/?search=${searchTerm}`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as QuestionResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchQuestion = createAsyncThunk(
  'questions/fetchQuestion',
  async (id: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/questions/${id}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Question;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createQuestion = createAsyncThunk(
  'question/createQuestion',
  async (
    {
      title,
      description,
      type,
      questionnaire,
      domain,
      topic,
      bestPractice,
      answers,
      inclusions,
      isBestPractice,
      includeAttachmentForm,
      includeComment,
      commentLabel,
      attachmentFormLabel,
    }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/questions/`, {
          title,
          description: description && description.length > 0 ? description : '',
          type: type && type.length > 0 ? type : undefined,
          version: '0.0.1',
          scope: null,
          isBestPractice,
          excludedByTrainings:	[],
          excludedByCertifications: [],
          answers: answers && answers.length > 0 ? answers.map((answer: any) => typeof answer == 'number' ? answer : answer.id) : [],
          questionnaire,
          domain,
          topic,
          bestPractice,
          includeAttachmentForm,
          includeComment,
          inclusions: inclusions && inclusions.length > 0 ? inclusions : [],
          ...(commentLabel && { commentLabel }),
          ...(attachmentFormLabel && { attachmentFormLabel }),
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Question;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  'questions/deleteQuestion',
  async (questionId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/questions/${questionId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return questionId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateQuestion = createAsyncThunk(
  'questions/updateQuestion',
  async (
    {
      id,
      title,
      slug,
      description,
      type,
      version,
      scope,
      excludedByTrainings,
      excludedByCertifications,
      questionnaire,
      domain,
      topic,
      bestPractice,
      includeAttachmentForm,
      attachmentFormLabel,
      includeComment,
      commentLabel,
      answers,
      inclusions,
      isBestPractice,
    }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/questions/${id}/`, {
          id,
          title,
          slug,
          description: description && description.length > 0 ? description : '',
          type,
          version,
          scope,
          excludedByTrainings,
          excludedByCertifications,
          questionnaire,
          domain,
          topic,
          bestPractice,
          includeAttachmentForm,
          attachmentFormLabel,
          includeComment,
          commentLabel,
          answers,
          inclusions,
          isBestPractice,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Question;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    resetQuestion: (state) => {
      state.question = undefined;
      state.error = undefined;
      state.targetBestPracticeId = undefined;
    },
    resetDeletedQuestionId: (state) => {
      state.deletedQuestionId = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<QuestionResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
      state.error = undefined;
    });
    builder.addCase(search.fulfilled, (state, action: PayloadAction<QuestionResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
      state.error = undefined;
    });
    builder.addCase(createQuestion.fulfilled, (state, action: PayloadAction<Question>) => {
      state.question = action.payload;
      state.error = undefined;
    });
    builder.addCase(createQuestion.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteQuestion.fulfilled, (state, action: PayloadAction<number>) => {
      state.results = state.results.filter((question) => question.id !== action.payload);
    });
    builder.addCase(updateQuestion.fulfilled, (state, action: PayloadAction<Question>) => {
      state.question = action.payload;
      state.error = undefined;
    });
    builder.addCase(updateQuestion.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchQuestion.fulfilled, (state, action: PayloadAction<Question>) => {
      state.question = action.payload;
    });
  },
});

export const { resetQuestion, resetDeletedQuestionId } = questionSlice.actions;

export default questionSlice.reducer;
