import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ReportSelectDropdown from './ReportSelectDropdown';
import { downloadReport } from './downloadUtils';
import { ReportOption } from '../../interfaces/reports.interface';

const DownloadReportSelect = () => {
  const [selectedReportOption, setSelectedReportOption] = useState<ReportOption | undefined>();
  const [selectedReportName, setSelectedReportName] = useState<string | null>(null);

  const handleDownload = () => {
    if (selectedReportOption && selectedReportName) {
      downloadReport(selectedReportOption, selectedReportName);
    }
  };

  return (
    <Box position='relative' marginY={2} marginTop={1.5}>
      <Box display='flex' alignItems='center'>
        <ReportSelectDropdown
          onSelect={setSelectedReportOption}
          selectedReportName={selectedReportName}
          setSelectedReportName={setSelectedReportName}
        />
        {selectedReportOption && (
          <IconButton
            size='large'
            onClick={handleDownload}
            sx={{ marginLeft: 0.5, padding: 0, marginTop: 3 }}
          >
            <DownloadIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default DownloadReportSelect;