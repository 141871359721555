import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Link, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Login from '../../Forms/Login';
import './VendorOnboarding.css';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { titleCase } from '../../Directory/CompanyDirectory';
import { HelpOutline } from '@mui/icons-material';
import { getHelpURL } from '../../../services/user';
import Copyright from '../../Layout/Copyright';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { logoutUser } from '../../../app/store/userSlice';
import '../../../App.css';

const formStyles = {
  root: {
    marginTop: '3em',
    fontWeight: '100',
    display: 'flex',
    justifyContent: 'center',
  },
  circle: {
    display: 'flex',
    borderRadius: '50%',
    width: '125px',
    height: '125px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBar: {
    background: '#2F2D4F',
    textAlign: 'center',
    padding: '1em 2em',
    position: 'relative',
  },
  headerBarBG: {
    position: 'absolute',
    left: '-429px',
    top: '-546px',
    width: '716px',
    height: '823px',
    background: 'radial-gradient(42.78% 42.78% at 50% 57.22%, #8AA2C5 5.06%, rgba(100, 140, 200, 0.68) 43.23%, rgba(146, 168, 200, 0) 100%)',
    opacity: '0.5',
  },
};

export function OnboardingContainer({ children }: any) {
  const {
    user,
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const handleLogout = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
  };
  return (
    <Box sx={{ backgroundColor: '#F3F5FB', minHeight: '100vh', pb: 2 }}>
      <Box sx={{ ...formStyles.headerBar }}><Box sx={{ ...formStyles.headerBarBG }}></Box><Box component='img' sx={{ width: '500px', maxWidth: '80%' }} src={`${process.env.PUBLIC_URL}/assets/TPN_logo.png`} alt='TPN Logo' /></Box>
      <Grid sx={formStyles.root} spacing={0} container>
        <Stack spacing={2} alignItems='center'>
          <Typography variant='h4' sx={{ fontWeight: '100' }} mb={1}>Welcome To The Trusted Partner Network</Typography>
          <Typography variant='h6'>Complete the signup process below</Typography>
          <Typography variant='body2'>Please note that TPN+, the new platform is not connected to the legacy platform. To access TPN+, you will need to sign up with a new account.</Typography>
          <Card sx={{ minWidth: '100%', maxWidth: '900px', marginTop: '2em!important', p: 2 }}>
            {children}
            <Box sx={{ display: 'flex', gap: '1em', justifyContent: 'center' }}>
              {!user && <Typography>Having trouble? Email <a href="mailto:support@ttpn.org" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>support@ttpn.org</a> for assistance.</Typography>}
              {user && <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer"><Button variant='contained' onClick={()=> { return false; }}><HelpOutline sx={{ mr: 1 }} />Need Support?</Button></a> }
              {user && <Button onClick={() => handleLogout()}>Sign Out</Button>}
            </Box>
            <Copyright sx={{ mt: 6 }} />
          </Card>
        </Stack>
      </Grid>
    </Box>
  );
}

export default function OnboardForm() {
  const [userType, setUserType] = useState({ type: 'vendor_admin', display: 'Service Provider' });
  const {
    user,
  } = useAppSelector(state => state.user);
  const nav = useNavigate();
  useEffect(() => {
    if (!user) return;
    if (user && user.type === 'tpn_admin') {
      nav('/registry', { replace: true });
      return;
    }
    if (user.companies.length > 0) {
      nav('/profile', { replace: true });
      return;
    }
    if (user.pendingCompanies.length > 0) nav('/waiting-for-Approval', { replace: true });

    if (user && user.type) setUserType({ type: user.type, display: titleCase(user.type.replace('_', ' ')) });
  }, [user, nav]);

  return (
    <OnboardingContainer>
      <Typography variant='h6' align='center' mb={2}>NEW {userType.display} TPN+ Signup</Typography>
      <Login userType={userType.type} initialState='signUp' />
      <Typography variant='body1' style={{ fontWeight: '100' }} mt={6} align='center'>Already a user? <Link to='/'>Login</Link></Typography>
    </OnboardingContainer>
  );
}
