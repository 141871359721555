import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

const AssessorAssessmentComments = ({ canEdit, originalComment, handleAssessmentComment, setShowAssessmentComments, showAssessmentComments }: { canEdit?: boolean, originalComment: string, handleAssessmentComment: (comment: string) => void,  setShowAssessmentComments: any, showAssessmentComments: boolean }) => {
  const [comment, setComment] = useState('');
  const commentsRef = useRef<HTMLInputElement | null>(null);
  const [dialogEntered, setDialogEntered] = useState(false);

  useEffect(() => {
    setComment(originalComment);

    if (showAssessmentComments) {
      setDialogEntered(true);
    } else {
      setDialogEntered(false);
    }
  }, [showAssessmentComments, originalComment]);

  useEffect(() => {
    if (dialogEntered && commentsRef.current) {
      commentsRef.current.focus();
    }
  }, [dialogEntered]);

  const handleClose = () => {
    setComment('');
    setShowAssessmentComments(false);
  };

  const handleSave = () => {
    handleAssessmentComment(comment);
    handleClose();
  };
    

  return (
    <StandardDialog
       maxWidth="sm"
       handleClose={handleClose}
       isOpen={showAssessmentComments}
       onEntered={() => setDialogEntered(true)}
       subtitle="Add or view overall assessment comments"
       title="Assessor Summary">
          <TextField
              id="assessor-assessment-comment"
              multiline
              inputRef={commentsRef}
              rows={5}
              label="Assessor Summary"
              fullWidth
              disabled={!canEdit}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              sx={{ mt: 2 }}
              inputProps={{ readOnly: false, sx: { overflowY: 'scroll' } }}
          />
          <StandardDialogActions>
            {canEdit && (
            <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSave}>
                Continue
            </Button>
            )}
            <Button
                onClick={handleClose}
                color="primary"
                variant="outlined">
                {canEdit ? 'Cancel' : 'Close'}
            </Button>
          </StandardDialogActions>
     </StandardDialog>
  );
};

export default AssessorAssessmentComments;