// react and external-libs
import React, { useMemo } from 'react';
// MUI
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
// interface
import { Company, CompanyApplication } from '../../../../../interfaces/users.interface';
// components
import { DataCell, DataRow } from '../../../../Tables/DataList';
import LicensedApplicationDataRow from './LicensedApplicationDataRow';

type LicensedApplicationsProps = {
  company: Company;
  userCanAdminCompany: boolean;
  companyCallback: any;
};

const LicensedApplications = (props: LicensedApplicationsProps) => {
  const { company, userCanAdminCompany, companyCallback } = props;
  const sortedCompanyApplications = useMemo(
    () =>
      company?.companyApplications
        ?.filter(
          (ca) =>
            !ca.application.company ||
            (typeof ca.application.company === 'number' && ca.application.company !== company.id) ||
            (typeof ca.application.company !== 'number' && ca.application.company.id !== company.id),
        )
        .sort((a, b) =>
          a.application.name && b.application.name ? a.application.name.localeCompare(b.application.name) : 0,
        ),
    [company?.companyApplications],
  );

  return (
    <>
      <Typography variant='h6' sx={{ m: '2em -16px 0px -16px', background: grey[300], padding: '.5em 1.5em' }}>
        3rd Party Licensed Application
      </Typography>
      <DataRow header sx={{ mt: 1 }}>
        <DataCell xs={1} textAlign='center'>
          Shield
        </DataCell>
        <DataCell xs={2}>Name</DataCell>
        <DataCell xs={1}>Version / Apps</DataCell>
        <DataCell xs={1.5}>Sites</DataCell>
        <DataCell xs={2}>Services</DataCell>
        <DataCell xs={1.5}>Hardening Guidelines</DataCell>
        <DataCell xs={1.5}>Application Types</DataCell>
        <DataCell xs={1}>&nbsp;</DataCell>
      </DataRow>
      {sortedCompanyApplications?.map((companyApplication: CompanyApplication) => (
        <LicensedApplicationDataRow
          key={companyApplication.id}
          company={company}
          companyApplication={companyApplication}
          companySuccessCallback={companyCallback}
          userCanAdminCompany={userCanAdminCompany}
        />
      ))}
    </>
  );
};

export default LicensedApplications;
