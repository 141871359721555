import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { CertificationControl, CertificationControlResultArray } from '../../interfaces/certificationControl.interface';
import { FieldValues } from 'react-hook-form';

interface InitialCertificationControlsState {
  count: number;
  next?: string;
  previous?: string;
  certificationControl?: CertificationControl;
  results: CertificationControl[];
  deletedCertificationControlId?: number;
  error?: any;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  certificationControl: undefined,
  results: [],
  deletedCertificationControlId: undefined,
  error: undefined,
} as InitialCertificationControlsState;

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAll = createAsyncThunk(
  'certificationControls/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/certification-controls/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as CertificationControlResultArray;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchCertificationControl = createAsyncThunk(
  'certificationControls/fetchCertificationControl',
  async (certificationControlId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/certification-controls/${certificationControlId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as CertificationControl;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createCertificationControl = createAsyncThunk(
  'certificationControls/createCertificationControl',
  async (certificationId:number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/certification-controls/`, {
          certification: certificationId,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as CertificationControl;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCertificationControl = createAsyncThunk(
  'certificationControls/deleteCertificationControl',
  async (certificationControlId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/certification-controls/${certificationControlId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return certificationControlId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCertificationControl = createAsyncThunk(
  'certificationControls/updateCertificationControl',
  async ({ id, certification, control, answers }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/certification-controls/${id}/`, {
          id,
          certification,
          control,
          answers: answers ? answers : undefined,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as CertificationControl;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const certificationControlSlice = createSlice({
  name: 'certificationControl',
  initialState,
  reducers: {
    resetCertificationControl: (state) => {
      state.certificationControl = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<CertificationControlResultArray>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
    });
    builder.addCase(createCertificationControl.fulfilled, (state, action: PayloadAction<CertificationControl>) => {
      state.results = [...state.results, action.payload];
      state.certificationControl = action.payload;
    });
    builder.addCase(createCertificationControl.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteCertificationControl.fulfilled, (state, action: PayloadAction<number>) => {
      state.error = null;
      state.deletedCertificationControlId = action.payload;
      state.results = state.results.filter((certificationControl: CertificationControl) => certificationControl.id !== action.payload);
    });
    builder.addCase(updateCertificationControl.fulfilled, (state, action: PayloadAction<CertificationControl>) => {
      state.results = state.results.filter((certificationControl: CertificationControl) => certificationControl.id !== action.payload.id);
      state.results = [...state.results, action.payload].sort((a, b) => a.id - b.id);
      state.error = null;
      state.certificationControl = action.payload;
    });
    builder.addCase(updateCertificationControl.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchCertificationControl.fulfilled, (state, action: PayloadAction<CertificationControl>) => {
      state.certificationControl = action.payload;
    });
  },
});

export const { resetCertificationControl } = certificationControlSlice.actions;

export default certificationControlSlice.reducer;