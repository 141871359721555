import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';


import createContact, { updateContact } from '../../../services/contact';
import { updateCompany } from '../../../services/company';
import User, { Company, Contact, CompanySubmissionData, ContactSubmissionData } from '../../../interfaces/users.interface';
import MultipleSelectChip from '../../Vendors/Forms/MultiSelect';
import { Service } from '../../../interfaces/vendor.interface';
import { StandardDialogActions } from '../../Modals/StandardDialog';

const LocationForm = (props: { successCallback: any, submitButtonText: string, company: Company, location: (undefined | Contact), isPrimaryContact: boolean, modalClose?: any }) => {
  const {
    successCallback,
    submitButtonText,
    company,
    location,
    isPrimaryContact,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
    control,
  } = useForm();
  const [services, setServices] = useState<Service[]>(company.services ? company.services : []);
  const [selectedServices, setSelectedServices] = useState<Array<number>>([]);
  const [selectedSitePrimaryContact, setSelectedSitePrimaryContact] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!location) return;
    setValue('name', location.name);
    setValue('phoneNumber', location.phoneNumber);
    setValue('address', location.address);
    setValue('zipcode', location.zipcode);
    setValue('isPrimary', location.isPrimary);
    setValue('country', location.country);
    setValue('city', location.city);
    setValue('state', location.state);
    if (location.services) setSelectedServices(location.services.map(service => service.id));
    if (location.primaryContact) setSelectedSitePrimaryContact(location.primaryContact.id);
  }, []);

  useEffect(() => {
    setServices(company.services ? company.services : []);
  }, [company.services]);

  const update = (contact: ContactSubmissionData) => {
    updateContact(contact).then((responseContact: Contact) => {
      clearErrors();
      if (isPrimaryContact) {
        const updatedCompany: Company = {
          ...company,
          contact: responseContact,
        };
        return successCallback(updatedCompany);
      }
      const updatedCompanyData: CompanySubmissionData = {
        ...company,
        primaryContactUser: company.primaryContactUser?.id,
        employees: company.employees.map((employee) => employee.id),
        pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
        services: company.services.map((service) => service.id),
        locations: [...company.locations.map((contactLocation: Contact) => contactLocation.id), responseContact.id],
        admins: company.admins.map(item => item.id),
        subsidiaries: company.subsidiaries.map(item => item.id),
      };
      updateCompany(updatedCompanyData).then((companyResponse: Company) => successCallback(companyResponse));
    }).catch((responseError: any) => {
      const errorData = responseError.response?.data;
      if (!errorData) {
        clearErrors();
        return;
      }
      for (const field of Object.keys(errorData)) {
        const errorMsgArr: string[] = errorData[field];
        setError(field, { type: 'manual', message: errorMsgArr[0] });
      }
    });
  };

  const create = (contact: ContactSubmissionData) => {
    createContact(contact).then((responseContact: Contact) => {
      clearErrors();
      reset();
      const updatedCompany: CompanySubmissionData = {
        ...company,
        primaryContactUser: company.primaryContactUser?.id,
        employees: company.employees.map((employee) => employee.id),
        pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
        services: company.services.map((service) => service.id),
        locations: [...company.locations.map((contactLocation: Contact) => contactLocation.id), responseContact.id],
        admins: company.admins.map(item => item.id),
        subsidiaries: company.subsidiaries.map(item => item.id),
      };
      updateCompany(updatedCompany).then((companyResponse: Company) => successCallback(companyResponse));
    }).catch((responseError: any) => {
      const errorData = responseError.response?.data;
      if (!errorData) {
        clearErrors();
        return;
      }
      for (const field of Object.keys(errorData)) {
        const errorMsgArr: string[] = errorData[field];
        setError(field, { type: 'manual', message: errorMsgArr[0] });
      }
    });
  };

  const onSubmit = (data: FieldValues) => {
    if (selectedServices.length === 0) {
      setErrorMessage('You must select at least 1 service.');
      return;
    }
    setErrorMessage('');
    const newContact: ContactSubmissionData = {
      name: data.name,
      phoneNumber: data.phoneNumber,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      isPrimary: false,
      primaryContact: selectedSitePrimaryContact ? selectedSitePrimaryContact : undefined,
      services: selectedServices,
    };
    if (location) {
      newContact.id = location.id;
      update(newContact);
    } else {
      create(newContact);
    }
  };

  return (
    <Grid container>
      <Box sx={{ width: '100%' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='name'
              label='Location Name'
              autoFocus
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='address'
              label='Address'
              autoFocus
              {...register('address')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='country'
              label='Country'
              autoFocus
              {...register('country')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='state'
              label='State'
              autoFocus
              {...register('state')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='city'
              label='City'
              autoFocus
              {...register('city')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='zipcode'
              label='Postal Code'
              autoFocus
              {...register('zipcode')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              render={({ field }) => (<MuiPhoneNumber
                value={field.value}
                required
                fullWidth
                margin='normal'
                id='phoneNumber'
                label='Phone Number'
                InputLabelProps={{ shrink: true }}
                defaultCountry={'us'}
                variant='outlined'
                onChange={field.onChange}
              />)}
              name={'phoneNumber'}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="location-primary-contact">Primary Contact</InputLabel>
              <Select
                labelId="location-primary-contact"
                id="location-primary-contact-select"
                label="Primary Contact"
                defaultValue={location?.primaryContact ? location.primaryContact.id : ''}
                onChange={(event: any, selectedEmployee: any | null) => {
                  event.preventDefault();
                  setSelectedSitePrimaryContact(selectedEmployee.props.value);
                }}
              >
                {company.employees.map((employee: User) => <MenuItem key={employee.id} value={employee.id} selected={location?.primaryContact?.id == employee.id}>{employee.email}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleSelectChip options={services} optionsCallback={setSelectedServices} selectedOptions={selectedServices} inputLabel='Services' />
            {errorMessage.length > 0 && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
          </Grid>
        </Grid>
        <StandardDialogActions>
          {props.modalClose && <Button sx={{ mr: 1 }} variant="outlined" onClick={() => props.modalClose()}>Close</Button>}
          <Button type="submit" variant="contained" endIcon={<NavigateNextIcon />}>{submitButtonText}</Button>
        </StandardDialogActions>
      </Box>
    </Grid>
  );
};

LocationForm.defaultProps = {
  location: undefined,
  isPrimaryContact: false,
};

export default LocationForm;