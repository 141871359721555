import React, { useState, useEffect } from 'react';

import { DataRow, DataCell } from '../Tables/DataList';
import { Question } from '../../interfaces/question.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import LogicListItem from './LogicListItem';

export default function LogicMapping(props: {
  canEdit?: boolean,
  inclusions: InclusionConfiguration[],
  questionUpdate: any,
  question: Question,
  hideAction?: Boolean,
}) {
  const [inclusions, setInclusions] = useState<InclusionConfiguration[] | undefined>(undefined);

  useEffect(() => {
    setInclusions(props.inclusions.filter(inc => inc.question && typeof inc.question !== 'number'));
  }, [props.inclusions]);

  const removeInclusion = (inclusionId:number) => {
    if (!inclusions) return;
    const inclusionIndex = inclusions.findIndex(inclusion => inclusion.id === inclusionId);
    let newinclusionList = [ ...inclusions ];
    newinclusionList.splice(inclusionIndex, 1);
    props.questionUpdate({ inclusions: newinclusionList });
  };

  return (
    <>
      <DataRow header>
        <DataCell xs={8} p={1}>Question</DataCell>
        <DataCell xs={4} p={1}>Answer</DataCell>
      </DataRow>
      { inclusions?.map((inclusion: InclusionConfiguration) => (
        <LogicListItem
          canEdit={props.canEdit}
          key={inclusion.id}
          inclusion={inclusion}
          questionUpdate={props.questionUpdate}
          question={props.question}
          removeInclusion={removeInclusion}
          hideAction={props.hideAction}
        />
      ))}
    </>
  );
}