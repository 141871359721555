import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { DomainResponse, Domain, DomainErrorResponse } from '../../interfaces/domain.interface';
import { FieldValues } from 'react-hook-form';
import { Topic } from '../../interfaces/topic.interface';

interface InitialDomainState {
  count: number;
  next?: string;
  previous?: string;
  domain?: Domain;
  results: Domain[];
  isPosting: boolean;
  deletedDomainId?: number;
  error?: DomainErrorResponse;
  targetQuestionnaireId?: number;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  domain: undefined,
  results: [],
  isPosting: false,
  deletedDomainId: undefined,
  error: undefined,
  targetQuestionnaireId: undefined,
} as InitialDomainState;
const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAll = createAsyncThunk(
  'domains/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/domains/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as DomainResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchDomain = createAsyncThunk(
  'domains/fetchDomain',
  async (domainSlug: string, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/domains/${domainSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Domain;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createDomain = createAsyncThunk(
  'domains/createDomain',
  async ({ title, description, targetQuestionnaireId, code }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/domains/`, {
          title,
          description,
          topics: [],
          questionnaire: targetQuestionnaireId,
          code,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as Domain;
    } catch (error: any) {
      error.response.data.targetQuestionnaireId = targetQuestionnaireId;
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteDomain = createAsyncThunk(
  'domains/deleteDomain',
  async (domainSlug: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/domains/${domainSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return domainSlug;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateDomain = createAsyncThunk(
  'domains/updateDomain',
  async ({ id, title, description, topics, slug, questionnaire, code }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/domains/${slug}/`, {
          id,
          title,
          description,
          topics: topics.map((topic:Topic) => typeof topic === 'number' ? topic : topic.id),
          questionnaire: typeof questionnaire === 'number' ? questionnaire : questionnaire.id,
          code,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Domain;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const domainSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    resetDomain: (state) => {
      state.domain = undefined;
      state.error = undefined;
      state.targetQuestionnaireId = undefined;
    },
    resetDeletedDomainId: (state) => {
      state.deletedDomainId = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<DomainResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
    });
    builder.addCase(createDomain.fulfilled, (state, action: PayloadAction<Domain>) => {
      state.domain = action.payload;
    });
    builder.addCase(createDomain.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.targetQuestionnaireId = action.payload.targetQuestionnaireId;
    });
    builder.addCase(deleteDomain.fulfilled, (state, action: PayloadAction<number>) => {
      state.deletedDomainId = action.payload;
      state.results = state.results.filter((domain) => domain.id !== action.payload);
    });
    builder.addCase(updateDomain.fulfilled, (state, action: PayloadAction<Domain>) => {
      state.domain = action.payload;
      state.error = undefined;
    });
    builder.addCase(updateDomain.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchDomain.fulfilled, (state, action: PayloadAction<Domain>) => {
      state.domain = action.payload;
    });
  },
});

export const { resetDomain, resetDeletedDomainId } = domainSlice.actions;

export default domainSlice.reducer;
