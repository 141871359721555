import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { OnboardingContainer } from './OnboardingForm';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const QuestionnaireComplete = (props: { stepDisplayHandler: any, toggleNextStepHandler: any }) => {
  const handleOnClick = () => {
    props.stepDisplayHandler(false);
    props.toggleNextStepHandler(true);
  };
  return (
    <OnboardingContainer>
      <Typography variant='h5' mb={5} align='center'>All Done!</Typography>
      <Grid container>
        <Typography variant='h6' align='center'>You have completed the TPN Vendor Onboarding Questionnaire.</Typography>
      </Grid>
      <Box textAlign='right' mt={6}>
        <Button variant='contained' onClick={handleOnClick} endIcon={<NavigateNextIcon />}>Continue</Button>
      </Box>
    </OnboardingContainer>
  );
};

const InvoiceGenerated = () => {
  return (
    <OnboardingContainer>
      <Grid container>
        <Typography variant='h6' align='center'>An invoice is being generated and sent to your e-mail address.</Typography>
      </Grid>
      <Box textAlign='right' mt={6}>
        <Typography variant='body1' mb={4}>
          Please note, an unpaid account will be unlisted on TPN platform until payment is current.
        </Typography>
      </Box>
      <Box textAlign='right' mt={6}>
        <Link to='/profile' style={{ textDecoration: 'none' }}>
          <Button variant='contained' endIcon={<NavigateNextIcon />}>Continue</Button>
        </Link>
      </Box>
    </OnboardingContainer>
  );
};

const PaymentWorkFlow = () => {
  const [showQuestionnaireComplete, setShowQuestionnaireComplete] = useState(true);
  const [showInvoiceGeneration, setShowInvoiceGeneration] = useState(false);

  return (
    <>
      {showQuestionnaireComplete && <QuestionnaireComplete stepDisplayHandler={setShowQuestionnaireComplete} toggleNextStepHandler={setShowInvoiceGeneration} />}
      {showInvoiceGeneration && <InvoiceGenerated />}
    </>
  );
};

export default PaymentWorkFlow;