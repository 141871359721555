import React from 'react';
import { Avatar, Stack, Tooltip } from '@mui/material';
import { appTheme } from '../Themes/light';

type BPRemediationCirclesProps = {
  BPRemediationCount: number;
  IGRemediationCount: number;
};

const BPRemediationCircles = (props: BPRemediationCirclesProps) => {
  const { BPRemediationCount, IGRemediationCount } = props;

  return (
    <Stack
      direction={IGRemediationCount && BPRemediationCount ? 'row-reverse' : 'row'}
      justifyContent='space-around'
      alignItems='center'
      spacing={'5px'}
    >
      {IGRemediationCount !== 0 && (
        <Tooltip
          title={`${IGRemediationCount} Additional Recommendation question${
            IGRemediationCount > 1 ? 's' : ''
          } in remediation`}
        >
          <Avatar
            sx={{
              height: '30px',
              width: '30px',
              color: appTheme.palette.nonCompliant.contrastText,
              backgroundColor: appTheme.palette.nonCompliant.main,
              fontSize: '.8em',
            }}
            color='warning'
          >
            {IGRemediationCount}
          </Avatar>
        </Tooltip>
      )}
      {BPRemediationCount !== 0 && (
        <Tooltip
          title={`${BPRemediationCount} Best Practice question${BPRemediationCount > 1 ? 's' : ''} in remediation`}
        >
          <Avatar
            sx={{
              height: '30px',
              width: '30px',
              color: appTheme.palette.error.contrastText,
              backgroundColor: appTheme.palette.error.main,
              fontSize: '.8em',
            }}
            color='error'
          >
            {BPRemediationCount}
          </Avatar>
        </Tooltip>
      )}
    </Stack>
  );
};

export default BPRemediationCircles;
