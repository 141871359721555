import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { AssessmentResponse, Assessment, AssessmentErrorResponse, AssessmentSurvey, AssessorAnswer } from '../../interfaces/assessment.interface';
import { FieldValues } from 'react-hook-form';
import { AuditLog } from '../../interfaces/auditLog.interface';
import moment from 'moment';

interface AssessmentAuditLog {
  assessmentSurvey: number | undefined,
  auditLogs: AuditLog[],
}

interface AssessmentLocalStorage {
  assessmentSurvey: number,
  assessment: number,
  date: string
}

interface InitialAssessmentState {
  count: number;
  next?: string;
  previous?: string;
  assessment?: Assessment;
  results: Assessment[];
  isPosting: boolean;
  deletedAssessmentId?: number;
  error?: AssessmentErrorResponse;
  assessmentAuditLog: AssessmentAuditLog[];
  assessmentLocalStorageItems: AssessmentLocalStorage[];
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  assessment: undefined,
  results: [],
  isPosting: false,
  deletedAssessmentId: undefined,
  error: undefined,
  assessmentAuditLog: [],
  assessmentLocalStorageItems: [],
} as InitialAssessmentState;
const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAllAssessments = createAsyncThunk(
  'assessments/fetchAllAssessments',
  async ({ company, assessor }: { company?: number, assessor?: number }) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/assessments/?limit=9999${company || assessor ? `&${company ? `company=${company}` : ''}${assessor ? `assessor=${assessor}` : ''}`  : ''}`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as AssessmentResponse;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchAssessment = createAsyncThunk(
  'assessments/fetchAssessment',
  async (assessmentId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/assessments/${assessmentId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Assessment;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createAssessment = createAsyncThunk(
  'assessments/createAssessment',
  async ({ assessor, company, surveys, status, assignedAssessor }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/assessments/`, {
          assessor: typeof assessor === 'number' ? assessor : assessor.id,
          company: typeof company === 'number' ? company : company.id,
          surveys: (surveys as AssessmentSurvey[] | number[]).map(sur => typeof sur === 'number' ? sur : sur.id),
          status,
          assignedAssessor: typeof assignedAssessor === 'number' ? assignedAssessor : assignedAssessor.id,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as Assessment;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteAssessment = createAsyncThunk(
  'assessments/deleteAssessment',
  async (assessmentId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/assessments/${assessmentId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return assessmentId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAssessment = createAsyncThunk(
  'assessments/updateAssessment',
  async ({ id, assessor, company, surveys, status, assignedAssessor, dateAccepted }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/assessments/${id}/`, {
          id,
          assessor: typeof assessor === 'number' ? assessor : assessor.id,
          company: typeof company === 'number' ? company : company.id,
          surveys: (surveys as AssessmentSurvey[] | number[]).map(sur => typeof sur === 'number' ? sur : sur.id),
          status,
          assignedAssessor: typeof assignedAssessor === 'number' ? assignedAssessor : assignedAssessor.id,
          dateAccepted: dateAccepted,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Assessment;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAssessmentAuditLog = createAsyncThunk(
  'assessments/fetchAssessmentAuditLog',
  async ({ assessmentSurvey, date }: { assessmentSurvey: number, date?: Date | string }) => {
    const postDate = date ? moment(date).format('YYYY-MM-DDTHH:mm:ssZZ') : moment().subtract(10, 'y').format('YYYY-MM-DDTHH:mm:ssZZ');
    const authSession = await getAuthSession();
    const response = await axios.get(
      `${baseUrl}/audit-logs/?limit=999999&assessment_survey=${assessmentSurvey}&created_at_range_before=${moment().format('YYYY-MM-DDTHH:mm:ssZZ')}&created_at_range_after=${postDate}`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );
    return { assessmentSurvey: assessmentSurvey, auditLogs: response.data.results as AuditLog[] };
  },
);

const assessmentSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setAssessmentLocalStorageInit: (state) => {
      const localLog = localStorage.getItem('assessmentAccessLog');
      if (!localLog) return;
      state.assessmentLocalStorageItems = JSON.parse(localLog);
    },
    setAssessmentLocalStorage: {
      reducer: (state, action: PayloadAction<{ assessmentSurvey: number, assessment: number, date?: string }>) => {
        const curLog = [ ...state.assessmentLocalStorageItems ];
        const newLogItem = { assessmentSurvey: action.payload.assessmentSurvey, date: action.payload.date ? action.payload.date : moment().format('YYYY-MM-DDTHH:mm:ssZZ'), assessment: action.payload.assessment };
        const itemIndex = curLog.findIndex(log => log.assessmentSurvey === action.payload.assessmentSurvey);
        if (itemIndex !== -1) {
          curLog[itemIndex] = newLogItem;
        } else {
          curLog.push(newLogItem);
        }
        state.assessmentLocalStorageItems = curLog;
        localStorage.setItem('assessmentAccessLog', JSON.stringify(curLog));
      },
      prepare: (props: { assessmentSurvey: number, assessment: number, date?: string }) => {
        return { payload: { assessmentSurvey: props.assessmentSurvey, assessment: props.assessment, date: props.date } };
      },
    },
    resetAssessment: (state) => {
      state.assessment = undefined;
      state.error = undefined;
    },
    resetAssessments: (state) => {
      state.results = [];
      state.error = undefined;
    },
    resetDeletedAssessmentId: (state) => {
      state.deletedAssessmentId = undefined;
    },
    updateAssessmentAssessmentSurvey: {
      reducer: (state, action: PayloadAction<{ assessmentSurvey: AssessmentSurvey }>) => {
        if (!state.assessment) return;

        const assessmentSurveyIndex = state.assessment.surveys.findIndex(assessmentSurvey => typeof assessmentSurvey === 'number' ? assessmentSurvey === action.payload.assessmentSurvey.id : assessmentSurvey.id === action.payload.assessmentSurvey.id);
        if (assessmentSurveyIndex !== -1) {
          const newAssessment = state.assessment;
          newAssessment.surveys[assessmentSurveyIndex] = action.payload.assessmentSurvey;
          state.assessment = newAssessment;
        }
      },
      prepare: (assessmentSurvey: AssessmentSurvey) => {
        return { payload: { assessmentSurvey } };
      },
    },
    updateAssessmentArrayAssessmentSurvey: {
      reducer: (state, action: PayloadAction<{ assessmentSurvey: AssessmentSurvey }>) => {
        const assessmentIndex = state.results.findIndex(assessment => (assessment.surveys as AssessmentSurvey[]).filter(assessmentSurvey => assessmentSurvey.id === action.payload.assessmentSurvey.id).length > 0);
        if (assessmentIndex !== -1) {
          const newAssessments = state.results;
          const assessmentSurveyIndex = (newAssessments[assessmentIndex].surveys as AssessmentSurvey[]).findIndex(assessmentSurvey => assessmentSurvey.id === action.payload.assessmentSurvey.id);
          newAssessments[assessmentIndex].surveys[assessmentSurveyIndex] = action.payload.assessmentSurvey;
          state.results = newAssessments;
        }
      },
      prepare: (assessmentSurvey: AssessmentSurvey) => {
        return { payload: { assessmentSurvey } };
      },
    },
    updateAssessmentSurveyArrayAssessorAnswer: {
      reducer: (state, action: PayloadAction<{ assessorAnswer: AssessorAnswer }>) => {
        if (!state.assessment) return;
        let surveyIndex = -1;
        let answerIndex = -1;
        const newAssessment:Assessment = { ...state.assessment };
        (newAssessment.surveys as AssessmentSurvey[]).forEach((sur, surIndex) => {
          sur.assessorAnswers.forEach((ans, ansIndex) => {
            if (
              (typeof ans === 'number' && ans === action.payload.assessorAnswer.id) ||
              (typeof ans !== 'number' && ans.id === action.payload.assessorAnswer.id)
            ) {
              surveyIndex = surIndex;
              answerIndex = ansIndex;
            }
          });
        });
        if (surveyIndex === -1 || answerIndex === -1) return;

        (newAssessment.surveys as AssessmentSurvey[])[surveyIndex].assessorAnswers[answerIndex] = action.payload.assessorAnswer;
        state.assessment = { ...newAssessment };

        if (!state.results) return;
        const newAssessments = state.results;
        const assessmentIndex = state.results.findIndex(assessment => (assessment.surveys as AssessmentSurvey[]).filter(assessmentSurvey => assessmentSurvey.id === newAssessment.id).length > 0);
        if (assessmentIndex !== -1) {
          newAssessments[assessmentIndex] = newAssessment;
          state.results = newAssessments;
        }
      },
      prepare: (assessorAnswer: AssessorAnswer) => {
        return { payload: { assessorAnswer } };
      },
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAllAssessments.fulfilled, (state, action: PayloadAction<AssessmentResponse>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => typeof a.id === 'number' && typeof b.id === 'number' ? a.id - b.id : 0);
    });
    builder.addCase(createAssessment.fulfilled, (state, action: PayloadAction<Assessment>) => {
      state.assessment = action.payload;
      state.results = [ ...state.results, action.payload ];
    });
    builder.addCase(createAssessment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteAssessment.fulfilled, (state, action: PayloadAction<number>) => {
      state.deletedAssessmentId = action.payload;
      state.results = state.results.filter((assessment) => assessment.id !== action.payload);
    });
    builder.addCase(updateAssessment.fulfilled, (state, action: PayloadAction<Assessment>) => {
      state.assessment = action.payload;
      const index = state.results.findIndex(assess => assess.id === action.payload.id);
      if (index !== -1) {
        let newResults = [ ...state.results ];
        newResults[index] = action.payload;
        state.results = newResults;
      } else {
        state.results = [ ...state.results, action.payload ];
      }
      state.error = undefined;
    });
    builder.addCase(updateAssessment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchAssessment.fulfilled, (state, action: PayloadAction<Assessment>) => {
      state.assessment = action.payload;
    });
    builder.addCase(fetchAssessmentAuditLog.fulfilled, (state, action: PayloadAction<AssessmentAuditLog>) => {
      const curAssessmentAuditLog = [ ...state.assessmentAuditLog ];
      const curIndex = curAssessmentAuditLog.findIndex(log => log.assessmentSurvey === action.payload.assessmentSurvey);
      if (curIndex !== -1) {
        curAssessmentAuditLog[curIndex] = action.payload;
      } else {
        curAssessmentAuditLog.push(action.payload);
      }
      state.assessmentAuditLog = curAssessmentAuditLog;
    });
  },
});

export const { resetAssessment, resetAssessments, resetDeletedAssessmentId, updateAssessmentArrayAssessmentSurvey, updateAssessmentSurveyArrayAssessorAnswer, updateAssessmentAssessmentSurvey, setAssessmentLocalStorageInit, setAssessmentLocalStorage } = assessmentSlice.actions;

export default assessmentSlice.reducer;
