import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Palette, PaletteColor } from '@mui/material';

import { buildRequestArrays } from '../../services/buildQuestionnaire';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { Domain } from '../../interfaces/domain.interface';
import { Topic } from '../../interfaces/topic.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Question, QuestionResponse } from '../../interfaces/question.interface';
import { Evidence, QuestionAnswer } from '../../interfaces/questionAnswer.interface';
import { CertificationControl } from '../../interfaces/certificationControl.interface';
import { Certification } from '../../interfaces/certification.interface';
import { setQuestionStatuses, setQuestionStatus as importedSetQuestionStatus, setBPStatus as importedSetBPStatus } from '../../services/questionnaireQuestionStatus';
import { Company, CompanyCertification } from '../../interfaces/users.interface';
import { InclusionConfiguration, InclusionConfigurationResultArray } from '../../interfaces/inclusionConfiguration.interface';
import { BestPracticeStatus } from '../../interfaces/survey.interface';
import { AssessorAnswer, Comment } from '../../interfaces/assessment.interface';
import { AuditLog } from '../../interfaces/auditLog.interface';

export interface CertControlById {
  [id: string]: CertificationControl;
}
export interface QuestionStatusById {
  [id: string]: {
    status: string;
    questionAnswer?: QuestionAnswer;
    satisfiedInclusions?: number[];
    question: Question;
    fakeAnswers?: number[];
    auditLogsFetched?: boolean;
  }
}
export interface AssessmentQuestionStatusById {
  [id: string]: AssessorAnswer
}
export interface QuestionStatus {
  status: string;
  questionAnswer?: QuestionAnswer;
  satisfiedInclusions?: number[];
  question: Question;
  fakeAnswers?: number[];
  auditLogsFetched?: boolean;
}
export interface CommentsById {
  [id: string]: Comment
}
export interface AuditLogsById {
  [id: string]: AuditLog
}
export interface EvidenceById {
  [id: string]: Evidence
}
export type PaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor ? Key : never]: true;
};
export interface BPStatusById {
  [id:string]: {
    id?: number;
    status: string;
    questionsAnsweredCount: number;
    visibleQuestionsCount: number;
    color: PaletteKey;
    lastQuestion: number;
    firstUnanswered?: Question;
    bestPractice: BestPractice;
    satisfiedInclusions: number[];
  }
}

export interface InclusionConfigsById {
  [id:string]: InclusionConfiguration;
}

interface QuestionnaireState {
  questionnaire?: Questionnaire;
  domain?: Domain;
  topic?: Topic;
  bestPractice?: BestPractice;
  question?: Question;
  questionStatus: QuestionStatusById;
  preview: boolean;
  readOnly: boolean;
  submitted: boolean;
  certificationControlIds?: number[];
  certControlsById?: CertControlById;
  questions?: Question[];
  companyCerts?: CompanyCertification[];
  allCertifications?: Certification[];
  bpStatus: BPStatusById;
  inclusionConfigsById: InclusionConfigsById;
  assessmentStatus?: string;
  assessmentQuestionsStatus?: AssessmentQuestionStatusById;
  comments: CommentsById;
  auditLogs: AuditLogsById;
  evidenceById: EvidenceById;
  company?: Company;
  totalQuestionsCount: number;
}

const initialState: QuestionnaireState = {
  questionStatus: {},
  bpStatus: {},
  preview: false,
  readOnly: false,
  submitted: false,
  inclusionConfigsById: {},
  assessmentQuestionsStatus: {},
  comments: {},
  auditLogs: {},
  evidenceById: {},
  totalQuestionsCount: 0,
};

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchQuestionnaireQuestions = createAsyncThunk(
  'questionnaireAnswer/fetchQuestionnaireQuestions',
  async (questionnaireId:number) => {
    const authSession = await getAuthSession();
    const response = await axios.get(
      `${baseUrl}/questions/?questionnaire=${questionnaireId}&limit=99999`,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return (await response.data) as QuestionResponse;
  },
);

export const fetchSurveyCompany = createAsyncThunk(
  'questionnaireAnswer/fetchSurveyCompany',
  async (companyId:number) => {
    const authSession = await getAuthSession();
    const response = await axios.get(
      `${baseUrl}/companies/${companyId}/`,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return (await response.data) as Company;
  },
);

export const getBPInclusionsById = createAsyncThunk(
  'questionnaireAnswer/getBPInclusionsById',
  async (inclusionConfigIds:number[]) => {
    const authSession = await getAuthSession();
    const response = await axios.get(
      `${baseUrl}/inclusion-configurations/?ids=${inclusionConfigIds.join(',')}`,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return (await response.data) as InclusionConfigurationResultArray;
  },
);

export const getCertificateControlsById = createAsyncThunk(
  'questionnaireAnswer/getCertificateControlsById',
  async (certControlIds:number[]) => {
    const authSession = await getAuthSession();
    const promises:Array<any> = [];

    const certControlUrls = buildRequestArrays(certControlIds, 15, `${process.env.REACT_APP_BASE_API}/certification-controls/?ids=`);
    certControlUrls.forEach(url => {
      promises.push(axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
          },
        },
      ));
    });
    const data = await Promise.all(promises);
    return data.map(d => d.data.results).flat(1) as CertificationControl[];
  },
);

const questionnaireAnswerSlice = createSlice({
  name: 'questionnaireAnswer',
  initialState,
  reducers: {
    resetQuestionnaireAnswer: {
      reducer: (state, action: PayloadAction<{ company?: Company | undefined }>) => {
        state.company = action.payload.company;
        state.questionnaire = undefined;
        state.domain = undefined;
        state.topic = undefined;
        state.bestPractice = undefined;
        state.question = undefined;
        state.questionStatus = {};
        state.certificationControlIds = undefined;
        state.certControlsById = undefined;
        state.questions = undefined;
        state.companyCerts = undefined;
        state.allCertifications = undefined;
        state.preview = false;
        state.submitted = false;
        state.readOnly = false;
        state.bpStatus = {};
        state.inclusionConfigsById = {};
        state.assessmentStatus = undefined;
        state.assessmentQuestionsStatus = {};
        state.comments = {};
        state.auditLogs = {};
        state.evidenceById = {};
        state.totalQuestionsCount = 0;
      },
      prepare: (data?: { company?: Company }) => {
      // The prepare function allows for the 'company' property to be optional
      // within the action payload. It's useful for when you want to dispatch
      // the action with or without a 'company'.
        return { payload: data ?? {} }; // Default to an empty object if data is undefined
      },
    },
    setQuestionnaire: {
      reducer: (state, action: PayloadAction<{ questionnaire: Questionnaire }>) => {
        state.questionnaire = action.payload.questionnaire;
      },
      prepare: (questionnaire: Questionnaire) => {
        return { payload: { questionnaire } };
      },
    },
    setTotalQuestionCount: {
      reducer: (state, action: PayloadAction<{ totalQuestionsCount: number }>) => {
        state.totalQuestionsCount = action.payload.totalQuestionsCount;
      },
      prepare: (totalQuestionsCount: number) => {
        return { payload: { totalQuestionsCount } };
      },
    },
    setCertificationControlIds: {
      reducer: (state, action: PayloadAction<{ certificationControlIds: number[] }>) => {
        state.certificationControlIds = action.payload.certificationControlIds;
        if (action.payload.certificationControlIds.length === 0) state.certControlsById = {};
      },
      prepare: (certificationControlIds: number[]) => {
        return { payload: { certificationControlIds } };
      },
    },
    setAssessmentStatus: {
      reducer: (state, action: PayloadAction<{ status: string }>) => {
        state.assessmentStatus = action.payload.status;
      },
      prepare: (status: string) => {
        return { payload: { status } };
      },
    },
    setAllAssessmentQuestionStatus: {
      reducer: (state, action: PayloadAction<{ questionStatus: AssessmentQuestionStatusById }>) => {
        state.assessmentQuestionsStatus = action.payload.questionStatus;
      },
      prepare: (questionStatus: AssessmentQuestionStatusById) => {
        return { payload: { questionStatus } };
      },
    },
    setComments: {
      reducer: (state, action: PayloadAction<{ comments: Comment[] }>) => {
        const newComments = { ...state.comments };
        action.payload.comments.forEach(comment => {
          newComments[comment.id as number] = comment;
        });
        state.comments = newComments;
      },
      prepare: (comments: Comment[]) => {
        return { payload: { comments } };
      },
    },
    setAuditLogs: {
      reducer: (state, action: PayloadAction<{ auditLogs: AuditLog[] }>) => {
        const newAuditLogs = { ...state.auditLogs };
        action.payload.auditLogs.forEach(auditLog => {
          newAuditLogs[auditLog.id as number] = auditLog;
        });
        state.auditLogs = newAuditLogs;
      },
      prepare: (auditLogs: AuditLog[]) => {
        return { payload: { auditLogs } };
      },
    },
    setEvidence: {
      reducer: (state, action: PayloadAction<{ evidence: Evidence[] }>) => {
        const newEvidence = { ...state.evidenceById };
        action.payload.evidence.forEach(ev => {
          newEvidence[ev.id as number] = ev;
        });
        state.evidenceById = newEvidence;
      },
      prepare: (evidence: Evidence[]) => {
        return { payload: { evidence } };
      },
    },
    setAllQuestionStatus: {
      reducer: (state, action: PayloadAction<{ questionStatus: QuestionStatusById }>) => {
        const newQStatus = Object.keys(state.questionStatus).length > 0 ? { ...state.questionStatus, ...action.payload.questionStatus } : { ...action.payload.questionStatus };
        const setQStatus = setQuestionStatuses(
          newQStatus,
          state.companyCerts,
          state.certControlsById,
        );
        state.questionStatus = setQStatus;
      },
      prepare: (questionStatus: QuestionStatusById) => {
        return { payload: { questionStatus } };
      },
    },
    setDomain: {
      reducer: (state, action: PayloadAction<{ domain: Domain }>) => {
        state.domain = action.payload.domain;
      },
      prepare: (domain: Domain) => {
        return { payload: { domain } };
      },
    },
    setTopic: {
      reducer: (state, action: PayloadAction<{ topic: Topic }>) => {
        state.topic = action.payload.topic;
        if (!action.payload.topic || !state.domain || !state.questionnaire) return;

        if (state.domain.id !== action.payload.topic.domain) {
          state.questionnaire.domains.every(domain => {
            if (domain.id === action.payload.topic?.domain) {
              state.domain = domain;
              return false;
            }
          });
        }
      },
      prepare: (topic: Topic) => {
        return { payload: { topic } };
      },
    },
    setBestPractice: {
      reducer: (state, action: PayloadAction<{ bestPractice: BestPractice }>) => {
        state.bestPractice = action.payload.bestPractice;

        if (!action.payload.bestPractice || !state.domain || !state.questionnaire || !state.topic) return;

        let updated = false;
        if (state.topic.id !== action.payload.bestPractice.topic) {
          state.questionnaire.domains.forEach(domain => {
            if (updated) return false;
            domain.topics.forEach(topic => {
              if (updated) return false;
              if (topic.id === action.payload.bestPractice?.topic) {
                state.topic = topic;
                state.domain = domain;
                updated = true;
                return false;
              }
            });
          });
        }
      },
      prepare: (bestPractice: BestPractice) => {
        return { payload: { bestPractice } };
      },
    },
    setQuestion: {
      reducer: (state, action: PayloadAction<{ question: Question }>) => {
        state.question = action.payload.question;
      },
      prepare: (question: Question) => {
        return { payload: { question } };
      },
    },
    setBPStatus: (state) => {
      if (!state.questionnaire) return;
      state.bpStatus = importedSetBPStatus(
        state.questionnaire,
        state.questionStatus,
        state.bpStatus,
        state.inclusionConfigsById,
        state.assessmentQuestionsStatus,
        state.assessmentStatus,
      );
    },
    setBPStatusIds: {
      reducer: (state, action: PayloadAction<{ bestPraciticeStatuses: BestPracticeStatus[] }>) => {
        const newBPStatus = state.bpStatus;
        action.payload.bestPraciticeStatuses.forEach(bps => {
          const bpid = typeof bps.bestPractice === 'number' ? bps.bestPractice : bps.bestPractice.id;
          if (!newBPStatus[bpid]) {
            let curBP:BestPractice | undefined = undefined;
            if (!state.questionnaire) return;
            state.questionnaire.domains.forEach(domain => domain.topics.forEach(topic => topic.bestPractices.forEach(bestPractice => { if (bestPractice.id === bpid) curBP = bestPractice; })));
            if (!curBP) return;
            newBPStatus[bpid] = {
              ...newBPStatus[bpid],
              ...bps,
              bestPractice: curBP,
            };
          } else {
            newBPStatus[bpid].id = bps.id;
          }
        });
        state.bpStatus = newBPStatus;
      },
      prepare: (bestPraciticeStatuses: BestPracticeStatus[]) => {
        return { payload: { bestPraciticeStatuses } };
      },
    },
    setCompanyCertifications: {
      reducer: (state, action: PayloadAction<{ companyCerts: CompanyCertification[] }>) => {
        const newQStatus = { ...state.questionStatus };
        const setQStatus = setQuestionStatuses(
          newQStatus,
          action.payload.companyCerts,
          state.certControlsById,
        );
        state.questionStatus = setQStatus;
        state.companyCerts = action.payload.companyCerts;
      },
      prepare: (companyCerts: CompanyCertification[]) => {
        return { payload: { companyCerts } };
      },
    },
    setAllCertifications: {
      reducer: (state, action: PayloadAction<{ allCertifications: Certification[] }>) => {
        state.allCertifications = action.payload.allCertifications;
      },
      prepare: (allCertifications: Certification[]) => {
        return { payload: { allCertifications } };
      },
    },
    setQuestionStatus: {
      reducer: (state, action: PayloadAction<{ questionId: string, status?: string, question?:Question }>) => {
        if (!state.questionStatus) return;
        const isMissingQ =  state.questionStatus[action.payload.questionId] ? false : { [action.payload.questionId]: { status: action.payload.status!, question: action.payload.question! } };

        const newQStatus = isMissingQ ? { ...state.questionStatus, ...isMissingQ } : { ...state.questionStatus };

        const nQStatus = importedSetQuestionStatus(
          newQStatus,
          action.payload.questionId,
          state.companyCerts,
          state.certControlsById,
          action.payload.status,
        );
        
        state.questionStatus[action.payload.questionId] = nQStatus;
      },
      prepare: (questionId: string, status?: string, question?:Question) => {
        return { payload: { questionId, status, question } };
      },
    },
    setAssessmentQuestionStatusId: {
      reducer: (state, action: PayloadAction<{ assessorAnswer: AssessorAnswer }>) => {
        if (!state.assessmentQuestionsStatus || !state.assessmentQuestionsStatus[(action.payload.assessorAnswer.question as Question).id]) return;

        const questionStatus:AssessorAnswer = { ...state.assessmentQuestionsStatus[(action.payload.assessorAnswer.question as Question).id] };
        questionStatus.id = action.payload.assessorAnswer.id;

        const newQStatus = { ...state.assessmentQuestionsStatus, ...{
          [(action.payload.assessorAnswer.question as Question).id]: questionStatus,
        } };
        
        state.assessmentQuestionsStatus = newQStatus;
      },
      prepare: (assessorAnswer:AssessorAnswer) => {
        return { payload: { assessorAnswer } };
      },
    },
    setAssessmentQuestionStatusFromPoll: {
      reducer: (state, action: PayloadAction<{ comments?: Comment[] | number[], auditLogEntries?: AuditLog[] | number[], id: number, question: Question, status: string, comment?: Comment, auditLog?: AuditLog, assessorFinding?: string, assessorAnswer?: string, vendorStatus?: string, remediationComment?: string, remediationDate?: string, contentOwnerPriority?: number[], attachments?: number[], assessmentSurvey?: number }>) => {
        if (!state.assessmentQuestionsStatus || state.assessmentQuestionsStatus[action.payload.question.id]) return;
        const questionStatus:AssessorAnswer = state.assessmentQuestionsStatus[action.payload.question.id] ? state.assessmentQuestionsStatus[action.payload.question.id] : { question: action.payload.question };
        questionStatus.id = action.payload.id;
        if (action.payload.comment) questionStatus.comments = [ ...((questionStatus.comments as Comment[]) || []), ...[action.payload.comment] ];
        if (action.payload.auditLog) questionStatus.auditLogEntries = [ ...((questionStatus.auditLogEntries as AuditLog[]) || []), ...[action.payload.auditLog] ];
        if (action.payload.comments) questionStatus.comments = action.payload.comments;
        if (action.payload.auditLogEntries) questionStatus.auditLogEntries = action.payload.auditLogEntries;
        if (action.payload.assessorFinding) questionStatus.assessorFinding = action.payload.assessorFinding;
        if (action.payload.assessorAnswer) questionStatus.answer = action.payload.assessorAnswer;
        if (action.payload.vendorStatus) questionStatus.vendorStatus = action.payload.vendorStatus;
        if (action.payload.remediationComment) questionStatus.remediationComment = action.payload.remediationComment;
        if (action.payload.remediationDate) questionStatus.remediationDate = action.payload.remediationDate;
        if (action.payload.contentOwnerPriority) questionStatus.contentOwnerPriority = action.payload.contentOwnerPriority.length === 0 ? undefined : action.payload.contentOwnerPriority;
        if (action.payload.attachments) questionStatus.attachments = action.payload.attachments;
        if (action.payload.assessmentSurvey) questionStatus.assessmentSurvey = action.payload.assessmentSurvey;
        questionStatus.status = action.payload.status;

        const newQStatus = { ...state.assessmentQuestionsStatus, ...{
          [action.payload.question.id]: questionStatus,
        } };
        
        state.assessmentQuestionsStatus = newQStatus;
        if (action.payload.comment) state.comments = { ...state.comments, [action.payload.comment.id as number]: action.payload.comment };
        if (action.payload.auditLog) state.auditLogs = { ...state.auditLogs, [action.payload.auditLog.id as number]: action.payload.auditLog };
      },
      prepare: (props: { comments?: Comment[] | number[], auditLogEntries?: AuditLog[] | number[], id: number, question: Question, status: string, comment?: Comment, auditLog?: AuditLog, assessorFinding?: string, assessorAnswer?: string, vendorStatus?: string, remediationComment?: string, remediationDate?: string, contentOwnerPriority?: number[], attachments?: number[], assessmentSurvey?: number }) => {
        const { comments, auditLogEntries, id, question, status, comment, auditLog, assessorFinding, assessorAnswer, vendorStatus, remediationComment, remediationDate, contentOwnerPriority, attachments, assessmentSurvey } = props;
        return { payload: { comments, auditLogEntries, id, question, status, comment, auditLog, assessorFinding, assessorAnswer, vendorStatus, remediationComment, remediationDate, contentOwnerPriority, attachments, assessmentSurvey } };
      },
    },
    setAssessmentQuestionStatus: {
      reducer: (state, action: PayloadAction<{ comments?: Comment[] | number[], auditLogEntries?: AuditLog[] | number[], id?: number, question: Question, status: string, comment?: Comment, auditLog?: AuditLog, assessorFinding?: string, assessorAnswer?: string, vendorStatus?: string, remediationComment?: string, remediationDate?: string, contentOwnerPriority?: number[], attachments?: number[], assessmentSurvey?: number }>) => {
        if (!state.assessmentQuestionsStatus) return;
        const questionStatus:AssessorAnswer = state.assessmentQuestionsStatus[action.payload.question.id] ? state.assessmentQuestionsStatus[action.payload.question.id] : { question: action.payload.question };
        if (action.payload.id) questionStatus.id = action.payload.id;
        if (action.payload.comment) questionStatus.comments = [ ...((questionStatus.comments as Comment[]) || []), ...[action.payload.comment] ];
        if (action.payload.auditLog) questionStatus.auditLogEntries = [ ...((questionStatus.auditLogEntries as AuditLog[]) || []), ...[action.payload.auditLog] ];
        if (action.payload.comments) questionStatus.comments = action.payload.comments;
        if (action.payload.auditLogEntries) questionStatus.auditLogEntries = action.payload.auditLogEntries;
        if (action.payload.assessorFinding) questionStatus.assessorFinding = action.payload.assessorFinding;
        if (action.payload.assessorAnswer) questionStatus.answer = action.payload.assessorAnswer;
        if (action.payload.vendorStatus) questionStatus.vendorStatus = action.payload.vendorStatus;
        if (action.payload.remediationComment) questionStatus.remediationComment = action.payload.remediationComment;
        if (action.payload.remediationDate) questionStatus.remediationDate = action.payload.remediationDate;
        if (action.payload.contentOwnerPriority) questionStatus.contentOwnerPriority = action.payload.contentOwnerPriority.length === 0 ? undefined : action.payload.contentOwnerPriority;
        if (action.payload.attachments) questionStatus.attachments = action.payload.attachments;
        if (action.payload.assessmentSurvey) questionStatus.assessmentSurvey = action.payload.assessmentSurvey;
        questionStatus.status = action.payload.status;

        const newQStatus = { ...state.assessmentQuestionsStatus, ...{
          [action.payload.question.id]: questionStatus,
        } };
        
        state.assessmentQuestionsStatus = newQStatus;
        if (action.payload.comment) state.comments = { ...state.comments, [action.payload.comment.id as number]: action.payload.comment };
        if (action.payload.auditLog) state.auditLogs = { ...state.auditLogs, [action.payload.auditLog.id as number]: action.payload.auditLog };
      },
      prepare: (props: { comments?: Comment[] | number[], auditLogEntries?: AuditLog[] | number[], id?: number, question: Question, status: string, comment?: Comment, auditLog?: AuditLog, assessorFinding?: string, assessorAnswer?: string, vendorStatus?: string, remediationComment?: string, remediationDate?: string, contentOwnerPriority?: number[], attachments?: number[], assessmentSurvey?: number }) => {
        const { comments, auditLogEntries, id, question, status, comment, auditLog, assessorFinding, assessorAnswer, vendorStatus, remediationComment, remediationDate, contentOwnerPriority, attachments, assessmentSurvey } = props;
        return { payload: { comments, auditLogEntries, id, question, status, comment, auditLog, assessorFinding, assessorAnswer, vendorStatus, remediationComment, remediationDate, contentOwnerPriority, attachments, assessmentSurvey } };
      },
    },
    setQuestionAnswerFromPoll: {
      reducer: (state, action: PayloadAction<{ questionId: string, questionAnswer: QuestionAnswer }>) => {
        if (!state.questionStatus || (state.questionStatus[action.payload.questionId] && state.questionStatus[action.payload.questionId].questionAnswer?.id)) return;
        let newQStatus = { ...state.questionStatus };
        newQStatus[action.payload.questionId] = { ...state.questionStatus[action.payload.questionId], questionAnswer: action.payload.questionAnswer };

        const setQStatus = setQuestionStatuses(
          newQStatus,
          state.companyCerts,
          state.certControlsById,
        );
        state.questionStatus = setQStatus;
      },
      prepare: (questionId: string, questionAnswer: QuestionAnswer) => {
        return { payload: { questionId, questionAnswer } };
      },
    },
    setQuestionAnswer: {
      reducer: (state, action: PayloadAction<{ questionId: string, questionAnswer: QuestionAnswer }>) => {
        if (!state.questionStatus) return;
        let newQStatus = { ...state.questionStatus };
        newQStatus[action.payload.questionId] = { ...state.questionStatus[action.payload.questionId], questionAnswer: action.payload.questionAnswer };

        const setQStatus = setQuestionStatuses(
          newQStatus,
          state.companyCerts,
          state.certControlsById,
        );
        state.questionStatus = setQStatus;
      },
      prepare: (questionId: string, questionAnswer: QuestionAnswer) => {
        return { payload: { questionId, questionAnswer } };
      },
    },
    setQuestionAuditLogFetched: {
      reducer: (state, action: PayloadAction<{ questionId: string }>) => {
        if (!state.questionStatus) return;
        let newQStatus = { ...state.questionStatus };
        newQStatus[action.payload.questionId] = { ...state.questionStatus[action.payload.questionId], auditLogsFetched: true };

        const setQStatus = setQuestionStatuses(
          newQStatus,
          state.companyCerts,
          state.certControlsById,
        );
        state.questionStatus = setQStatus;
      },
      prepare: (questionId: string) => {
        return { payload: { questionId } };
      },
    },
    setCertControlsById: {
      reducer: (state, action: PayloadAction<{ certList: CertControlById }>) => {
        state.certControlsById = action.payload.certList ? action.payload.certList : {};
      },
      prepare: (certList: CertControlById) => {
        return { payload: { certList } };
      },
    },
    setQuestions: {
      reducer: (state, action: PayloadAction<{ questions: Question[] }>) => {
        if (!state.questions || state.questions.length === 0) {
          state.questions = action.payload.questions;
          return;
        }
        const newQuestions = [ ...state.questions ];
        action.payload.questions.forEach(question => {
          if (newQuestions.findIndex(q => q.id === question.id) === -1) newQuestions.push(question);
        });
        state.questions = newQuestions;
      },
      prepare: (questions: Question[]) => {
        return { payload: { questions } };
      },
    },
    setPreview: {
      reducer: (state, action: PayloadAction<{ preview: boolean }>) => {
        state.preview = action.payload.preview;
      },
      prepare: (preview: boolean) => {
        return { payload: { preview } };
      },
    },
    setCompanyFromResponse: {
      reducer: (state, action: PayloadAction<{ company: Company }>) => {
        state.company = action.payload.company;
      },
      prepare: (company: Company) => {
        return { payload: { company } };
      },
    },
    setSubmitted: {
      reducer: (state, action: PayloadAction<{ submitted: boolean }>) => {
        state.submitted = action.payload.submitted;
        state.readOnly = action.payload.submitted;
      },
      prepare: (submitted: boolean) => {
        return { payload: { submitted } };
      },
    },
    setReadOnly: {
      reducer: (state, action: PayloadAction<{ readOnly: boolean }>) => {
        state.readOnly = action.payload.readOnly;
      },
      prepare: (readOnly: boolean) => {
        return { payload: { readOnly } };
      },
    },
    setInclusionConfigsById: {
      reducer: (state, action: PayloadAction<{ inclusionConfigurations: InclusionConfiguration[] }>) => {
        const newInclusionConfigs = { ...state.inclusionConfigsById };

        action.payload.inclusionConfigurations.forEach(inclusion => {
          newInclusionConfigs[inclusion.id] = inclusion;
        });
        state.inclusionConfigsById = newInclusionConfigs;
      },
      prepare: (inclusionConfigurations: InclusionConfiguration[]) => {
        return { payload: { inclusionConfigurations } };
      },
    },
    nextQuestion: (state) => {
      if (!state.question || !state.bestPractice || !state.topic || !state.domain || !state.questionnaire || !state.questionStatus) return;
      let curQuestionnaire = state.questionnaire;
      let curBP = state.bestPractice;
      let curTopic = state.topic;
      let curDomain = state.domain;
      let curQuestion = state.question;
      let shouldUpdate = false;
      if (state.bpStatus[curBP.id].visibleQuestionsCount === state.bpStatus[curBP.id].questionsAnsweredCount) curQuestion = curBP.questions[curBP.questions.length - 1];

      const getNextQuestion = () => {
        const curQuestionIndex = curBP.questions.findIndex(question => typeof question === 'number' ? question === curQuestion : question.id === curQuestion?.id);
        if (curQuestionIndex > -1 && curQuestionIndex + 1 !== curBP.questions.length) {
          curQuestion = curBP.questions[curQuestionIndex + 1];
          return;
        }
  
        const curBestPracticeIndex = curTopic.bestPractices.findIndex(bestPractice => bestPractice.id === curBP?.id);
        if (curBestPracticeIndex > -1 && curBestPracticeIndex + 1 !== curTopic.bestPractices.length) {
          curBP = curTopic.bestPractices[curBestPracticeIndex + 1];
          curQuestion = curTopic.bestPractices[curBestPracticeIndex + 1].questions[0];
          return;
        }
  
        const curTopicIndex = curDomain.topics.findIndex(topic => topic.id === curTopic?.id);
        if (curTopicIndex > -1 && curTopicIndex + 1 !== curDomain.topics.length) {
          curTopic = curDomain.topics[curTopicIndex + 1];
          curBP = curDomain.topics[curTopicIndex + 1].bestPractices[0];
          curQuestion = curDomain.topics[curTopicIndex + 1].bestPractices[0].questions[0];
          return;
        }
  
        const curDomainIndex = curQuestionnaire.domains.findIndex(domain => domain.id === curDomain?.id);
        if (curDomainIndex > -1 && curDomainIndex + 1 !== curQuestionnaire.domains.length) {
          curDomain = curQuestionnaire.domains[curDomainIndex + 1];
          curTopic = curQuestionnaire.domains[curDomainIndex + 1].topics[0];
          curBP = curQuestionnaire.domains[curDomainIndex + 1].topics[0].bestPractices[0];
          curQuestion = curQuestionnaire.domains[curDomainIndex + 1].topics[0].bestPractices[0].questions[0];
          return;
        }
      };

      while (!shouldUpdate) {
        getNextQuestion();
        if (typeof curQuestion !== 'number' && state.questionStatus[curQuestion.id] && state.questionStatus[curQuestion.id].status !== 'cert' && state.questionStatus[curQuestion.id].status !== 'hide' && state.bpStatus[curBP.id].status !== 'hide') {
          shouldUpdate = true;
        } else if (
          curQuestionnaire.domains[curQuestionnaire.domains.length - 1].id === curDomain.id &&
          curDomain.topics[curDomain.topics.length - 1].id === curTopic.id &&
          curTopic.bestPractices[curTopic.bestPractices.length - 1].id === curBP.id &&
          ((typeof curQuestion === 'number' && curBP.questions[curBP.questions.length - 1] === curQuestion) ||
          (curBP.questions[curBP.questions.length - 1].id === curQuestion.id))
        ) {
          shouldUpdate = true;
          curDomain = state.domain;
          curTopic = state.topic;
          curBP = state.bestPractice;
          curQuestion = state.question;
        }
      }
      if (state.domain.id !== curDomain.id) state.domain = curDomain;
      if (state.topic.id !== curTopic.id) state.topic = curTopic;
      if (state.bestPractice.id !== curBP.id) state.bestPractice = curBP;
      state.question = curQuestion;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchQuestionnaireQuestions.fulfilled, (state, action: PayloadAction<QuestionResponse>) => {
      if (!state.questions || state.questions.length === 0) {
        state.questions = action.payload.results;
        return;
      }
      state.questions = [ ...state.questions, ...action.payload.results ];
    });
    builder.addCase(getBPInclusionsById.fulfilled, (state, action: PayloadAction<InclusionConfigurationResultArray>) => {
      const newInclusionsById:InclusionConfigsById = {};
      action.payload.results.forEach(inclusion => { newInclusionsById[inclusion.id] = inclusion; });
      state.inclusionConfigsById = newInclusionsById;
    });
    builder.addCase(fetchSurveyCompany.fulfilled, (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    });
    builder.addCase(getCertificateControlsById.fulfilled, (state, action: PayloadAction<CertificationControl[]>) => {
      let certList:CertControlById = {};

      action.payload.forEach(newCert => {
        certList[newCert.id] = newCert;
      });
      state.certControlsById = certList;
    });
  },
});

export const {
  setQuestionnaire,
  setCertificationControlIds,
  setAllQuestionStatus,
  setDomain,
  setTopic,
  setBestPractice,
  setQuestion,
  resetQuestionnaireAnswer,
  nextQuestion,
  setQuestionStatus,
  setQuestionAnswer,
  setCompanyCertifications,
  setAllCertifications,
  setCertControlsById,
  setPreview,
  setSubmitted,
  setReadOnly,
  setBPStatus,
  setAssessmentStatus,
  setAllAssessmentQuestionStatus,
  setAssessmentQuestionStatusId,
  setAssessmentQuestionStatus,
  setBPStatusIds,
  setQuestions,
  setComments,
  setAuditLogs,
  setInclusionConfigsById,
  setQuestionAuditLogFetched,
  setEvidence,
  setCompanyFromResponse,
  setTotalQuestionCount,
  setAssessmentQuestionStatusFromPoll,
  setQuestionAnswerFromPoll,
} = questionnaireAnswerSlice.actions;
export default questionnaireAnswerSlice.reducer;
