import React from 'react';
import { Chip } from '@mui/material';
import { Application, ApplicationMeta, Version } from '../../../../interfaces/users.interface';
import { useAppSelector } from '../../../../app/hooks';

type ApplicationPillProps = {
  application: Application;
  handleRemoveSelectedApplication: (id:number) => void;
  version?: Version;
};

export default function ApplicationPill(props: ApplicationPillProps) {
  const { application, handleRemoveSelectedApplication } = props;
  const { companies } = useAppSelector(state => state.companyList);
  const companyName =
    application.company ?
      typeof application.company === 'number' ?
        companies.find(co => co.id === application.company) ? companies.find(co => co.id === application.company)!.name : '' :
        application.company.name :
      !!application.meta?.length ? (application.meta as ApplicationMeta[])[0].title : '';
      
  return <Chip key={application.id} label={companyName + ' ' + application.name + ' ' + (props.version ? props.version.value : !!application.versions?.length ? (application.versions as Version[])[0].value : '')} onDelete={() => handleRemoveSelectedApplication(application.id!)} />;
}