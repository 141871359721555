import axios from 'axios';
import getAuthSession from './auth';
import { Invoice, InvoicePatchPostObj } from '../interfaces/billing.interface';

export default async function getInvoices() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/invoices/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Invoice[];
}

export async function createInvoice(invoice: Invoice | InvoicePatchPostObj) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/invoices/`,
    { ...invoice },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Invoice;
}

export async function patchInvoice(invoice: Invoice | InvoicePatchPostObj) {
  if (!invoice.id) throw new Error('Invoice ID is required');
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/invoices/${invoice.id}/`,
    { ...invoice },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Invoice;
}

export async function deleteInvoice(invoice: Invoice) {
  if (!invoice.id) throw new Error('Invoice ID is required');
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/invoices/${invoice.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}