import axios from 'axios';

import getAuthSession from './auth';
import { Question } from '../interfaces/question.interface';

export async function getQuestion(questionId: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/questions/${questionId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Question;
}

export async function fetchQuestionnaireQuestions(questionnaireId:number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/questions/?questionnaire=${questionnaireId}&limit=99999`,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return (response.data.results) as Question[];
}

export default {};