import axios from 'axios';

import getAuthSession from './auth';
import { Application, ApplicationMeta, ApplicationReponse, ApplicationType, Version, Badge, ApplicationVersion } from '../interfaces/users.interface';
import { ApplicationMeta as MetaApplication } from '../interfaces/metaData.interface';
import { HardeningDocument } from '../interfaces/hardeningDocuments';

export async function getApplications() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/applications/?limit=99999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Application[];
}

export async function getApplicationsFull(filters?: { limit?: number, search?: string, offset?: number, applicationType?: number, application_company_name?: string, has_hardening_documents?: boolean, is_public?: boolean, admin_approved?: boolean, has_company?: boolean }) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/applications/?limit=${filters?.limit || '9999'}${filters?.has_company !== undefined ? '&has_company=' + filters.has_company.toString() : ''}${filters?.has_hardening_documents !== undefined ? '&has_hardening_documents=' + filters.has_hardening_documents.toString() : ''}${filters?.admin_approved !== undefined ? '&admin_approved=' + filters.admin_approved.toString() : ''}${filters?.is_public !== undefined ? '&is_public=' + filters.is_public.toString() : ''}${filters?.application_company_name ? '&application_company_name=' + filters.application_company_name : ''}${filters?.search ? '&search=' + filters.search : ''}${filters?.offset ? '&offset=' + filters.offset : ''}${filters?.applicationType ? '&application_types=' + filters.applicationType : ''}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationReponse;
}

export async function getAllApplicationTypes() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/application-types/?limit=9999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as ApplicationType[];
}

export async function getApplicationMeta() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/applications/meta-data/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as MetaApplication;
}

export async function getVersion(id:number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/versions/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Version;
}

export async function updateVersionsAndTypes(id:number, versions: number[], applicationTypes: number[]) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/applications/${id}/`,
    {
      versions,
      applicationTypes,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Application;
}

export async function updateVersionBadge(id:number, badge: number | null) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/versions/${id}/`,
    {
      badge,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Version;
}

export async function updateApplicationDocs(app: Application, version: Version, hardeningDocument: number, isNew: boolean) {
  const authSession = await getAuthSession();
  const appDocs = isNew ?
    app.hardeningDocuments?.length ? [ ...(app.hardeningDocuments as HardeningDocument[]).map(hd => typeof hd === 'number' ? hd : hd.id), hardeningDocument] : [hardeningDocument] :
    app.hardeningDocuments?.length ? (app.hardeningDocuments as HardeningDocument[]).map(hd => typeof hd === 'number' ? hd : hd.id).filter(hd => hd !== hardeningDocument) : [];
  const verDocs = isNew ?
    version.hardeningDocuments?.length ? [ ...(version.hardeningDocuments as HardeningDocument[]).map(hd => typeof hd === 'number' ? hd : hd.id), hardeningDocument] : [hardeningDocument] :
    version.hardeningDocuments?.length ? (version.hardeningDocuments as HardeningDocument[]).map(hd => typeof hd === 'number' ? hd : hd.id).filter(hd => hd !== hardeningDocument) : [];
  const nversion = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/versions/${version.id}/`,
    {
      hardeningDocuments: verDocs,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  const napp = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/applications/${app.id}/`,
    {
      hardeningDocuments: appDocs,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return { napp: napp.data, nversion: nversion.data };
}

export async function updateApplication(application: any) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/applications/${application.id}/`,
    {
      ...application,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Application;
}

export async function createApplication(application: Application) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/applications/`,
    {
      ...application,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Application;
}

export async function createVersion(version: Version) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/versions/`,
    {
      ...version,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Application;
}

export async function requestVersion(ownerid: number, appid: number, requestorid: number, version: string) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/new-version-request/${ownerid}/application/${appid}/requester/${requestorid}/`,
    {
      'version_str': version,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Application;
}

export async function createApplicationVersion(version: ApplicationVersion) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/application-versions/`,
    {
      ...version,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationVersion;
}

export async function getApplicationVersion(id: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/application-versions/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationVersion;
}

export async function deleteApplicationVersion(id: number) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/application-versions/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationVersion;
}


export async function createApplicationMeta(title: string) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/application-tags/`,
    {
      title,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationMeta;
}

export async function deleteApplication(application: Application) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/applications/${application.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function postApplicationType(type:string) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/application-types/`,
    { type },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationType;
}

export async function patchApplicationType(id:number, type:string) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/application-types/${id}/`,
    { type },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ApplicationType;
}

export async function deleteApplicationType(id:number) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/application-types/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function createHardeningDocument(document:HardeningDocument) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/hardening-documents/`,
    { ...document },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as HardeningDocument;
}

export async function deleteHardeningDocument(id:number) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/hardening-documents/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as HardeningDocument;
}

export async function createBadge(badge:Badge) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/badges/`,
    badge,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Badge;
}

export function getBadgesFromVersions(versions: Version[]) {
  const filteredVersions = versions?.filter(av => av?.badge !== undefined);
  if (!filteredVersions || filteredVersions?.length === 0) {
    return undefined;
  }

  return filteredVersions.map(v => v.badge as Badge);
}
