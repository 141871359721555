import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm, FieldValues } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SKU } from '../../interfaces/billing.interface';
import { createSKU, patchSKU } from '../../services/skus';
import { StandardDialogActions } from '../Modals/StandardDialog';

export default function SKUForm(props: { sku: SKU | null, callBack: any, handleClose: any }) {
  const {
    sku,
    callBack,
    handleClose,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ defaultValues: sku ? sku : undefined });

  const onSubmit = (data: FieldValues) => {
    const skuData: SKU = {
      id: sku?.id ? sku.id : null,
      item: data.item,
      itemTotal: data.itemTotal,
      description: data.description,
      updatedAt: new Date(),
      createdAt: sku?.createdAt ? sku.createdAt : new Date(),
    };

    clearErrors();
    reset();

    if (sku) {
      patchSKU(skuData).then((updatedSKU: SKU) => {
        callBack(updatedSKU);
        handleClose();
      });
    } else {
      createSKU(skuData).then((createdSKU: SKU) => {
        callBack(createdSKU);
        handleClose();
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }} component="form" method={sku ? 'patch' : 'post'} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            error={!!errors.item}
            helperText={!!errors.item && errors.item.message}
            required
            id='item'
            label='Item'
            sx={{ width: '100%' }}
            autoFocus
            {...register('item')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!errors.itemTotal}
            helperText={!!errors.itemTotal && errors.itemTotal.message}
            required
            id='itemTotal'
            label='Item Total'
            sx={{ width: '100%' }}
            autoFocus
            {...register('itemTotal')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='description'
            label='Description'
            rows={3}
            multiline
            sx={{ width: '100%' }}
            fullWidth
            {...register('description')}
          />
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}