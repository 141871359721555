import React, { Component, useState, ReactNode, CSSProperties } from 'react';
import { Auth } from 'aws-amplify';
import logCloudWatchEvent from './services/cloudWatchLogging';
import { CloudWatchError } from './interfaces/errors.interface';

async function logErrorToCloudWatch(details: CloudWatchError) {
  const logGroup = 'tpn-client/error-handler';
  const logStream = `${process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() || 'dev'}-errors`;
  const message = JSON.stringify({ details });

  try {
    await logCloudWatchEvent(logGroup, logStream, message);
  } catch (err) {
    console.error('Error logging to CloudWatch:', err);
  }
}

const loggedErrors = new Set();

class ErrorCatcher extends Component<{ onError: (error: Error, errorInfo: React.ErrorInfo) => void; children: ReactNode }> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

const styles: { [key: string]: CSSProperties } = {
  errorContainer: {
    padding: '30px',
    textAlign: 'center',
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    margin: '50px',
    color: '#424242',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  errorTitle: {
    fontSize: '30px',
    fontWeight: 600,
    color: '#1B3B6F',
  },
  refreshButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#1B3B6F',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
    transition: 'background-color 0.3s',
  },
};

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = async (error: Error, errorInfo: React.ErrorInfo) => {
    const userEmail = (await Auth.currentAuthenticatedUser())?.attributes?.email;
    const errorName = error.toString();

    if (loggedErrors.has(errorName)) {
      console.log('Error already logged:', errorName);
      return;
    }

    setHasError(true);
    const errorDetails = {
      errorMessage: errorName,
      user: userEmail,
      location: window.location.href,
      componentStack: errorInfo.componentStack,
    };

    try {
      await logErrorToCloudWatch(errorDetails);
      loggedErrors.add(errorName);
    } catch (err) {
      console.error('Error logging to CloudWatch:', err);
    }
  };

  if (hasError) {
    return (
    <div style={styles.errorContainer}>
        <img src={`${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`} alt="TPN Logo" height="50" />
        <div style={styles.errorTitle}>Oops! Something went wrong.</div>
        <div>We have recorded this issue and our team is working on it. Please refresh your page or try again shortly.</div>
        <button
        onClick={() => window.location.reload()}
        style={styles.refreshButton}
        onMouseOver={({ currentTarget }) => (currentTarget.style.backgroundColor = '#2E5078')}
        onMouseOut={({ currentTarget }) => (currentTarget.style.backgroundColor = '#1B3B6F')}
        >
        Refresh page
        </button>
    </div>
    );
  }

  return (
    <ErrorCatcher onError={handleError}>
    {children}
    </ErrorCatcher>
  );
};

export default ErrorBoundary;
