import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Skeleton, Paper, Tooltip } from '@mui/material';
import { widgetStyles, blue } from './widgetStyles';
import { getCompaniesBadgeMetadata } from '../../../services/company';
import { RadialChart, Hint } from 'react-vis';

interface AssessmentLevels {
  gold: number;
  blue: number;
  cert: number;
  none: number;
}

interface RadialAngles {
  angle: number;
  label: string;
  className?: string;
}

const getAngle = (total: number, count: number) => {
  if (count === 0) return count;
  return 360 * (count / total);
};

const createAngle = (total: number, count: number, label: string, className?: string) => {
  return { angle: getAngle(total, count), label, className };
};

export default function AssessmentLevelWidget() {
  const styles = widgetStyles();
  const [radialAngles, setRadialAngles] = useState<RadialAngles[] | undefined>(undefined);
  const [total, setTotal] = useState(0);
  const [hoverValue, setHoverValue] = useState<any>(false);

  useEffect(() => {
    getCompaniesBadgeMetadata().then(counts => {
      const companyCounts:AssessmentLevels = {
        gold: counts.tpnAssessedCount,
        blue: counts.tpnSelfReportedCount,
        cert: counts.nonTpnSelfReportedCount,
        none: counts.tpnMemberCount,
      };
      const ntotal = counts.tpnMemberCount + counts.nonTpnSelfReportedCount + counts.tpnSelfReportedCount + counts.tpnAssessedCount;
      const newRadial:RadialAngles[] = [];
      if (companyCounts.gold) newRadial.push(createAngle(ntotal, companyCounts.gold, 'Gold Shield: ' + companyCounts.gold, styles.goldShield));
      if (companyCounts.blue) newRadial.push(createAngle(ntotal, companyCounts.blue, 'Blue Shield: ' + companyCounts.blue, styles.blueShield));
      if (companyCounts.cert) newRadial.push(createAngle(ntotal, companyCounts.cert, 'Non TPN Self Reported: ' + companyCounts.cert, styles.certShield));
      if (companyCounts.none) newRadial.push(createAngle(ntotal, companyCounts.none, 'None: ' + companyCounts.none, styles.noneShield));
      setRadialAngles(newRadial);
      setTotal(ntotal);
    });
  }, []);

  return (
    <Paper className={styles.widget}>
      <Box className={styles.widgetHeader}>
        <Typography variant='caption'>Service Provider&apos;s</Typography>
        <Typography variant='h4' fontSize='1.8rem' color={blue}>Shield Status</Typography>
        <Divider />
      </Box>
      <Box className={`${styles.skeletonContainer} ${styles.skeletonCircle}`}>
        {!radialAngles && <div />}
        {!radialAngles && <Skeleton variant="circular" width={200} height={200} />}
        {radialAngles && <Box sx={{ position: 'relative' }}>
          <Tooltip title={`There are currently ${total} active Service Providers`}><Typography variant="h5" fontSize={36} sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>{total}</Typography></Tooltip>
          <RadialChart
            data={radialAngles}
            width={300}
            height={300}
            innerRadius={80}
            radius={130}
            padAngle={0.04}
            onValueMouseOver={row => setHoverValue(row)}
            onSeriesMouseOut={() => setHoverValue(false)}
          >
            {hoverValue &&
              <Hint value={hoverValue}>
                <Box className={styles.toolTipStyling}>{hoverValue.label}</Box>
              </Hint>
            }
          </RadialChart>
        </Box>}
        {!radialAngles && <div />}
      </Box>
    </Paper>
  );
}