import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateAssessmentSurveyArrayAssessorAnswer, updateAssessmentAssessmentSurvey, updateAssessment } from '../../app/store/assessmentSlice';
import { setAllAssessmentQuestionStatus, setAssessmentQuestionStatusFromPoll, setAssessmentQuestionStatusId, setAssessmentStatus, setAuditLogs, setComments } from '../../app/store/questionnaireAnswerSlice';
import { AssessmentSurvey, AssessorAnswer } from '../../interfaces/assessment.interface';
import { AuditLog } from '../../interfaces/auditLog.interface';
import { Survey } from '../../interfaces/survey.interface';
import { createAssessorAnswer, fetchAssessment, getCommentsAndLogs, updateAssessmentSurvey, updateAssessorAnswer } from '../../services/assessments';
import { updateAuditLog } from '../../services/commentsLogs';
import SurveyExperience from '../Surveys/Survey';
import { updateWorkflow } from '../../app/store/workflowSlice';
import { REMEDIATION_OPTIONS } from '../../services/surveyHelpers';

export default function Assessment() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const surveyId:number = parseInt(params.surveyId!);
  const assessmentId:number = parseInt(params.assessmentId!);
  const [pollingBegun, setPollingBegun] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const { assessment } = useAppSelector(state => state.assessments);
  const { assessmentQuestionsStatus, questionStatus, questions, auditLogs, bpStatus, bestPractice, certControlsById, assessmentStatus } = useAppSelector(state => state.questionnaireAnswer);
  const workflow = useAppSelector(state => state.workflow?.workflows?.find(wf => wf.assessment === assessment?.id));
  useEffect(() => {
    if (
      !assessmentId ||
      !bestPractice ||
      !certControlsById ||
      !Object.keys(questionStatus).length ||
      !Object.keys(bpStatus).length ||
      !bpStatus[Object.keys(bpStatus)[0]].id ||
      !assessmentQuestionsStatus ||
      !assessmentStatus ||
      ['Remediation', 'Complete', 'Completed'].indexOf(assessmentStatus) > -1 ||
      pollingBegun
    ) return;
    
    setPollingBegun(true);
  }, [assessmentId, bestPractice, certControlsById, questionStatus, bpStatus, assessmentQuestionsStatus, assessmentStatus]);

  useEffect(() => {
    if (!pollingBegun) return;
    const assessmentPoll = setInterval(() => {
      fetchAssessment(assessmentId).then(pollAssessment => {
        const assessmentSurvey = (pollAssessment.surveys as AssessmentSurvey[]).find(assSur => (assSur.survey as Survey).id === parseInt(params.surveyId as string));
        if (!assessmentSurvey) return;

        (assessmentSurvey.assessorAnswers as AssessorAnswer[]).forEach(aa => {
          const qid = typeof aa.question === 'number' ? aa.question : aa.question.id;
          if (!assessmentQuestionsStatus || (assessmentQuestionsStatus[qid] && assessmentQuestionsStatus[qid]?.id)) return;
          const curQ = questions?.find(q => q.id === qid);
          if (!curQ) return;
          dispatch(setAssessmentQuestionStatusFromPoll({
            ...aa,
            id: aa.id as number,
            question: curQ,
            status: aa.status!,
            contentOwnerPriority: aa.contentOwnerPriority as number[],
            assessorAnswer: aa.answer,
          }));
        });
        if (assessmentSurvey.status !== assessmentStatus) {
          dispatch(updateAssessment(pollAssessment));
          if (workflow) dispatch(updateWorkflow({ id: workflow.id, status: pollAssessment.status }));
        }
      });
    }, 10000);

    return () => {
      setPollingBegun(false);
      clearInterval(assessmentPoll);
    };
  }, [pollingBegun]);

  useEffect(() => {
    if (!assessment) return;
    const curSurvey = (assessment.surveys as AssessmentSurvey[]).find(assSur => (assSur.survey as Survey).id === parseInt(params.surveyId as string));
    if (!user) return;
    dispatch(setAssessmentStatus(curSurvey && curSurvey.status ? curSurvey.status : assessment.status));
    if (curSurvey && curSurvey.assessorAnswers && Object.keys(auditLogs).length === 0) {
      getCommentsAndLogs(curSurvey.assessorAnswers as AssessorAnswer[]).then(commentsAndLogs => {
        if (commentsAndLogs.comments && commentsAndLogs.comments.length > 0) dispatch(setComments(commentsAndLogs.comments));
        if (commentsAndLogs.auditLogs && commentsAndLogs.auditLogs.length > 0) dispatch(setAuditLogs(commentsAndLogs.auditLogs));
        const newAssessmentQuestionStatus = (curSurvey.assessorAnswers as AssessorAnswer[]).reduce(
          (obj, item) => Object.assign(obj, { [typeof item.question === 'number' ? item.question : item.question.id]: item }), {},
        );
        dispatch(setAllAssessmentQuestionStatus(newAssessmentQuestionStatus));
      });
    }
  }, [assessment]);

  const getRemediationCounts = () => {
    let bestPracticeRemediationCount = 0;
    let implementationGuidanceRemediationCount = 0;
    if (!assessmentQuestionsStatus || !assessment) return { bestPracticeRemediationCount, implementationGuidanceRemediationCount };

    Object.keys(assessmentQuestionsStatus).forEach(assAnsId => {
      const { status, vendorStatus, question } = assessmentQuestionsStatus[assAnsId];
      if (!status || ['remediation', 'priority'].indexOf(status) === -1  || (vendorStatus && [REMEDIATION_OPTIONS.remediated].indexOf(vendorStatus) !== -1)) return;
      let isBpQuestion = false;
      if (typeof question !== 'number') {
        isBpQuestion = question.isBestPractice;
      } else {
        if (questionStatus[question as number]) isBpQuestion = questionStatus[question as number].question.isBestPractice;
        if (questionStatus[question as number] && questions) isBpQuestion = questions.find(q => q.id === (question as number)) ? questions.find(q => q.id === (question as number))!.isBestPractice : false; //Need extra test on questions here to support dev submit for assessment before all questions answered
      }

      if (isBpQuestion) bestPracticeRemediationCount++;
      if (!isBpQuestion) implementationGuidanceRemediationCount++;
    });
    return { bestPracticeRemediationCount, implementationGuidanceRemediationCount };
  };

  useEffect(() => {
    if (!assessmentQuestionsStatus || !assessment) return;
    const surveyIndex = (assessment.surveys as AssessmentSurvey[]).findIndex(assSur => (assSur.survey as Survey).id === surveyId);
    if (surveyIndex === -1) return;

    Object.keys(assessmentQuestionsStatus).forEach(questionId => {
      if (!assessmentQuestionsStatus[questionId].id) {
        createAssessorAnswer(assessmentQuestionsStatus[questionId]).then(assessorAnswer => {
          dispatch(setAssessmentQuestionStatusId(assessorAnswer));
          const newAnswers = [ ...Object.keys(assessmentQuestionsStatus).map(key => assessmentQuestionsStatus[key]).filter(aa => aa.id !== undefined && aa.id !== assessorAnswer.id), assessorAnswer];
          const newSurvey = { ...(assessment.surveys as AssessmentSurvey[])[surveyIndex] };
          newSurvey.assessorAnswers = newAnswers;
          const { bestPracticeRemediationCount, implementationGuidanceRemediationCount } = getRemediationCounts();
          if (newSurvey.bestPracticeRemediationCount !== bestPracticeRemediationCount || newSurvey.implementationGuidanceRemediationCount !== implementationGuidanceRemediationCount) updateAssessmentSurvey({ ...newSurvey, bestPracticeRemediationCount, implementationGuidanceRemediationCount });
          dispatch(updateAssessmentAssessmentSurvey({ ...newSurvey, bestPracticeRemediationCount, implementationGuidanceRemediationCount }));
          if (assessorAnswer.auditLogEntries && assessorAnswer.auditLogEntries.length > 0) {
            assessorAnswer.auditLogEntries.forEach(auditLog => {
              const fullAuditLog = typeof auditLog !== 'number' ? auditLog : assessmentQuestionsStatus[questionId].auditLogEntries ? (assessmentQuestionsStatus[questionId].auditLogEntries as AuditLog[]).find(alog => alog.id === auditLog) : false;
              if (fullAuditLog) updateAuditLog({ ...fullAuditLog, assessorAnswer: assessorAnswer.id });
            });
          }
        });
      } else {
        const newAnswer = assessmentQuestionsStatus[questionId];
        const curAnswer = ((assessment.surveys[surveyIndex] as AssessmentSurvey).assessorAnswers as AssessorAnswer[]).find(assAnsw => assAnsw.id === newAnswer.id);
        if (!newAnswer || !curAnswer) return;
        const newCOPrio = !newAnswer.contentOwnerPriority || !newAnswer.contentOwnerPriority.length ? false : newAnswer.contentOwnerPriority;
        const curCOPrio = !curAnswer.contentOwnerPriority || !curAnswer.contentOwnerPriority.length ? false : curAnswer.contentOwnerPriority;
        if (
          newAnswer.answer !== curAnswer.answer ||
          newAnswer.status !== curAnswer.status ||
          newAnswer.vendorStatus != curAnswer.vendorStatus ||
          (newAnswer.assessorFinding !== curAnswer.assessorFinding && (newAnswer.assessorFinding || curAnswer.assessorFinding)) ||
          (newAnswer.comments && (!curAnswer.comments || newAnswer.comments.length !== curAnswer.comments.length)) ||
          (newAnswer.attachments && (!curAnswer.attachments || newAnswer.attachments.length !== curAnswer.attachments.length)) ||
          (newAnswer.auditLogEntries && (!curAnswer.auditLogEntries || newAnswer.auditLogEntries.length !== curAnswer.auditLogEntries.length)) ||
          (newCOPrio !== curCOPrio || (newCOPrio && curCOPrio && newCOPrio.length !== curCOPrio.length))
        ) {
          updateAssessorAnswer(newAnswer);
          dispatch(updateAssessmentSurveyArrayAssessorAnswer(newAnswer));
          if (surveyIndex === -1) return;
          const newSurvey = { ...(assessment.surveys as AssessmentSurvey[])[surveyIndex] };
          const { bestPracticeRemediationCount, implementationGuidanceRemediationCount } = getRemediationCounts();
          if (newSurvey.bestPracticeRemediationCount !== bestPracticeRemediationCount || newSurvey.implementationGuidanceRemediationCount !== implementationGuidanceRemediationCount) updateAssessmentSurvey({ ...newSurvey, bestPracticeRemediationCount, implementationGuidanceRemediationCount });
          dispatch(updateAssessmentAssessmentSurvey({ ...newSurvey, bestPracticeRemediationCount, implementationGuidanceRemediationCount }));
        }
      }
    });
  }, [assessmentQuestionsStatus]);

  return (<SurveyExperience />);
}
