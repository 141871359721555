import React, { useEffect, useState } from 'react';

import AccordionDetails from '@mui/material/AccordionDetails';
import User, { Company } from '../../../interfaces/users.interface';
import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { DataCell, DataRow } from '../../Tables/DataList';
import { useParams } from 'react-router-dom';
import { Button, Link, Stack, Typography } from '@mui/material';
import { handlePendingUser } from '../../../services/user';

export default function PendingUsersAccordion(props: { company: Company, companyCallback: any }) {
  const { profileSection } = useParams();
  const { company } = props;
  const [users, setUsers] = useState(company.pendingEmployees);
  const [curUser, setCurUser] = useState<User | undefined>(undefined);
  const [approveUserOpen, setApproveUserOpen] = useState(false);
  const handleApproveUserOpen = () => setApproveUserOpen(true);
  const handleApproveUserClose = () => {setApproveUserOpen(false); setCurUser(undefined);};

  const [rejectUserOpen, setRejectUserOpen] = useState(false);
  const handleRejectUserOpen = () => setRejectUserOpen(true);
  const handleRejectUserClose = () => {setRejectUserOpen(false); setCurUser(undefined);};

  useEffect(() => {
    setUsers(company.pendingEmployees);
  }, [props.company]);

  const handlePendingAction = async (action: 'accept' | 'reject') => {
    if (!curUser) return;
    const handlePending = await handlePendingUser(curUser.id, company, action);
    props.companyCallback(handlePending);
    handleApproveUserClose();
    handleRejectUserClose();
  };

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'users');
  }, [profileSection]);

  return (
    <AccordionCollapse expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
      <AccordionCollapseSummary
        name="pending-users-contacts-header"
        title='Pending Users'
        count={users.length}
      >
      </AccordionCollapseSummary>
      <AccordionDetails>
        <DataRow onClick={() => {}} header>
          <DataCell xs={4}>Email</DataCell>
          <DataCell xs={3}>First</DataCell>
          <DataCell xs={3}>Last</DataCell>
          <DataCell xs={2}>Approve/Reject</DataCell>
        </DataRow>
        {users && users.map((user: User) => 
          <DataRow key={user.id}>
            <DataCell xs={4}>
              <Link href={`mailto:${user.email}`}>{user.email}</Link>
            </DataCell>
            <DataCell xs={3}>{user.firstName}</DataCell>
            <DataCell xs={3}>{user.lastName}</DataCell>
            <DataCell xs={2}>
              <Stack spacing={1} alignItems='center'>
                <Button variant='outlined' sx={{ width: '150px' }} onClick={(e) => {e.stopPropagation(); handleApproveUserOpen(); setCurUser(user);}}>Approve</Button>
                <Button variant='outlined' color='error' sx={{ width: '150px' }} onClick={(e) => {e.stopPropagation(); handleRejectUserOpen(); setCurUser(user);}}>Reject</Button>
              </Stack>
            </DataCell>
          </DataRow>,
        )}
      </AccordionDetails>
      <StandardDialog title={`Reject ${curUser ? curUser.firstName + ' ' + curUser.lastName : ''}`} handleClose={handleRejectUserClose} isOpen={rejectUserOpen}>
        <Typography>Are you sure you want to reject {curUser ? curUser.firstName + ' ' + curUser.lastName : ''}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleRejectUserClose()}>Cancel</Button>
          <Button variant="contained" color='error' onClick={() => handlePendingAction('reject')}>Reject</Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog title={`Approve ${curUser ? curUser.firstName + ' ' + curUser.lastName : ''}`} handleClose={handleApproveUserClose} isOpen={approveUserOpen}>
        <Typography>Are you sure you want to approve {curUser ? curUser.firstName + ' ' + curUser.lastName : ''}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleApproveUserClose()}>Cancel</Button>
          <Button variant="contained" onClick={() => handlePendingAction('accept')}>Approve</Button>
        </StandardDialogActions>
      </StandardDialog>
    </AccordionCollapse>
  );
}