import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Outlet, Link, useLocation } from 'react-router-dom';

import { SideNav } from '../Navigation/SideNav';
import './styleOverrides.css';
import { Button, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAssessmentLocalStorageInit } from '../../app/store/assessmentSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

function Layout({ buttonIcon, pageTitle, children, link, buttonText, includeHeader, includeDivider }: { buttonIcon?: React.ElementType, pageTitle: string, children: any, link?:string, buttonText?:string, includeHeader: boolean, includeDivider: boolean }) {
  const { user } = useAppSelector(state => state.user);
  const firstPath = location.pathname.split('/')[1];
  const { assessmentLocalStorageItems } = useAppSelector(state => state.assessments);
  const dispatch = useAppDispatch();
  const routerLocation = useLocation();
  const theme = useTheme();
  const [userInfoOpen, setUserInfoOpen] = useState(true);
  const handleUserInfoClose = () => setUserInfoOpen(false);

  useEffect(() => {
    if (!assessmentLocalStorageItems.length) dispatch(setAssessmentLocalStorageInit());
  }, []);

  const isInQuestionnairePage = () => {
    if (routerLocation.pathname.includes('questionnaire')) {
      return true;
    }

    return false;
  };

  return (
    <Box sx={{ display: 'flex', minWidth: '320px' }}>
      <SideNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, bgcolor: '#F3F5FB', minHeight: '100vh' }}>
      {includeHeader && (
   <Box
     sx={{
       display: 'flex',
       justifyContent: 'space-between',
       alignItems: 'center',
     }}
   >
     <Typography
       variant="h5"
       sx={{ fontWeight: '300', color: isInQuestionnairePage() ? theme.palette.customThemeColors?.darkGrey : 'currentcolor' }}
     >
          {isInQuestionnairePage()
            ? pageTitle
            : <Link to={'/' + firstPath} style={{
              textDecoration: 'none',
              fontWeight: '300',
              color: theme.palette.customThemeColors?.darkGrey,
            }}>{pageTitle}</Link>
          }
          </Typography>
     
     {link && (
       <Box
         sx={{
           ml: 'auto',
         }}
       >
         <Link to={link}>
          <Button variant='outlined' size='small' startIcon={buttonIcon ? React.createElement(buttonIcon) : undefined}>
             {buttonText}
           </Button>
         </Link>
       </Box>
     )}
   </Box>
      )}
        {includeDivider && <Divider sx={{ mb: 1, pt: 1 }}/>}
        <Outlet />
        { children }
        {!user?.firstName && !user?.lastName && location.pathname.indexOf('user-profile') === -1 && <StandardDialog title='Please Finish Entering your User Information' handleClose={handleUserInfoClose} isOpen={userInfoOpen}>
          <Typography>It looks like you are missing some user information on your profile. Please click below to enter it now.</Typography>
          <StandardDialogActions>
            <Button variant="outlined" onClick={() => handleUserInfoClose()}>Close</Button>
            <Link to={'/user-profile/'}><Button variant="contained">Go To User Info</Button></Link>
          </StandardDialogActions>
        </StandardDialog>}
      </Box>
    </Box>
  );
}

Layout.defaultProps = {
  includeHeader: true,
  includeDivider: true,
};

export default Layout;
