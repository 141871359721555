import axios from 'axios';

import getAuthSession from './auth';

export default async function sendPendingEmployeeNotice(companyId: number) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/${companyId}/pending-employees-notice/`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
}

export async function sendAdmitEmployeeNotice(companyId: number, employeeId: number) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/${companyId}/admit-employee/${employeeId}/`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
}

export async function sendRejectEmployeeNotice(companyId: number, employeeId: number) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/${companyId}/deny-employee/${employeeId}/`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
}