//import { Company } from './users.interface';

import { GridRowParams } from '@mui/x-data-grid';
import { DocusignWaiver } from './users.interface';

export enum BillingFrequency {
  Quarterly = 'quarterly',
  Annually = 'annually',
}

export interface SKU {
  id: number | null;
  item: string;
  itemTotal: number;
  description: string;
  updatedAt: Date | null
  createdAt: Date | null;
}

export interface BillingRecord {
  id: number;
  companyId: number;
  billingCustomerId: string;
  parentCompanyBillingCustomerId: string | null;
  status: string | null;
  notes: string | null;
  billingInvoices: BillingRecordInvoice[];
  updatedAt: Date | null
  createdAt: Date | null;
  handleRowClick?: (params: GridRowParams, event: any) => void;
  mostRecentInvoice?: BillingRecordInvoice;
  currentInvoice?: BillingRecordInvoice;
  shownInvoice?: BillingRecordInvoice | null;
}

export interface BillingRecordPatchObj extends Omit<BillingRecord, 'billingInvoices' | 'companyId' | 'billingCustomerId' | 'createdAt' | 'parentCompanyBillingCustomerId'> {}

export interface Invoice {
  id: number | null;
  startDate: Date;
  endDate: Date;
  sku: SKU;
  discountPercent: number | null;
  discountAmount: number | null;
  poNumber: string | null;
  notes: string | null;
  invoiceTotal: number | null;
  isPaid: boolean;
  billingRecord: BillingRecord | number | null;
  billingFrequencyCaptured: BillingFrequency;
  paidDate: Date | null;
  updatedAt: Date | null
  createdAt: Date | null;
  waiver?: DocusignWaiver;
  status: string;
}

export interface InvoicePatchPostObj extends Omit<Invoice, 'sku'> {
  sku: number | null | undefined;
}

export interface BillingRecordInvoice extends Omit<Invoice, 'billingRecord'> {
  billingRecord: number;  // This is a shallow depth reference. Using the actual object would cause a circular reference.
}

export const billingFrequencyOptions = Object.entries(BillingFrequency).map(([key, value]) => ({
  key,
  value,
}));