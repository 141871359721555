import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import React from 'react';

export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.ttpn.org/">
        Trusted Partner Network
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <br />
      <a href="https://www.ttpn.org/terms-of-use/" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>Terms of Use</a>
    </Typography>
  );
}
