import React from 'react';
import { Control, Controller, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { PaletteKey, QuestionStatusById } from '../../../app/store/questionnaireAnswerSlice';
import { Question } from '../../../interfaces/question.interface';

type QuestionOnQuestionnaireAnswerProps = {
  question: Question;
  questionStatus: QuestionStatusById;
  curQuestionStatus: string;
  control: Control;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  readOnly: boolean;
  preview: boolean;
  submitted: boolean;
  color: PaletteKey;
};

const QuestionOnQuestionnaireAnswer = (props: QuestionOnQuestionnaireAnswerProps) => {
  const {
    question,
    questionStatus,
    curQuestionStatus,
    control,
    setValue,
    register,
    readOnly,
    preview,
    submitted,
    color,
  } = props;

  const checkboxChecked = (id: number, value: number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const handleCheckboxCheck = (id: string, value: number | number[] | undefined) => {
    const idNum = parseInt(id);
    if (value === undefined || typeof value === 'number') {
      setValue('answer', [idNum]);
      return;
    }
    if (value.indexOf(idNum) === -1) {
      setValue('answer', [...value, idNum]);
      return;
    }
    const newValue = [...value];
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('answer', newValue);
  };

  return question.type && question.type.includes('text') ? (
    <Grid item xs={12}>
      <TextField
        required
        id={`${question.id}-field`}
        label='Answer'
        fullWidth
        InputProps={{
          readOnly: readOnly && (!preview || submitted),
        }}
        {...register('text')}
      />
    </Grid>
  ) : (
    <Grid item xs={12} md={question.includeComment ? 6 : 12}>
      <FormControl>
        {question.type === 'single_select' && (
          <Controller
            control={control}
            name='answer'
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                aria-labelledby={question.title}
                name='answer'
                value={value ? value : 0}
                onChange={(e) => onChange(e.target.value)}
              >
                {question.answers.map((answer, key) => (
                  <FormControlLabel
                    sx={{
                      alignItems: 'flex-start',
                      '& .MuiRadio-root': {
                        padding: '0 .5em',
                      },
                      padding: '.5em 0',
                    }}
                    disabled={readOnly && (!preview || submitted)}
                    key={key}
                    value={answer.id}
                    control={<Radio />}
                    label={
                      <Typography
                        sx={{
                          fontWeight:
                            curQuestionStatus === 'cert' &&
                            questionStatus[question.id].fakeAnswers &&
                            (questionStatus[question.id].fakeAnswers as number[]).indexOf(answer.id) > -1
                              ? 'bold'
                              : 'inherit',
                          color:
                            curQuestionStatus === 'cert' &&
                            questionStatus[question.id].fakeAnswers &&
                            (questionStatus[question.id].fakeAnswers as number[]).indexOf(answer.id) > -1
                              ? `${[color]}.main`
                              : 'black',
                        }}
                      >
                        {answer.text}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            )}
          />
        )}
        {question.type === 'multi_select' && (
          <FormGroup>
            <Controller
              control={control}
              name='answer'
              render={({ field: { value } }) => (
                <>
                  {question.answers.map((answer, key) => (
                    <FormControlLabel
                      key={key}
                      sx={{
                        alignItems: 'flex-start',
                        '& .MuiCheckbox-root': {
                          padding: '0 .5em',
                        },
                        padding: '.5em 0',
                      }}
                      label={
                        <Typography
                          sx={{
                            fontWeight:
                              curQuestionStatus === 'cert' &&
                              questionStatus[question.id].fakeAnswers &&
                              (questionStatus[question.id].fakeAnswers as number[]).indexOf(answer.id) > -1
                                ? 'bold'
                                : 'inherit',
                            color:
                              curQuestionStatus === 'cert' &&
                              questionStatus[question.id].fakeAnswers &&
                              (questionStatus[question.id].fakeAnswers as number[]).indexOf(answer.id) > -1
                                ? `${[color]}.main`
                                : 'black',
                          }}
                        >
                          {answer.text}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          disabled={readOnly && (!preview || submitted)}
                          checked={checkboxChecked(answer.id, value)}
                          value={answer.id}
                          onChange={(e) => handleCheckboxCheck(e.target.value, value)}
                        />
                      }
                    />
                  ))}
                </>
              )}
            />
          </FormGroup>
        )}
      </FormControl>
    </Grid>
  );
};

export default QuestionOnQuestionnaireAnswer;
