import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Moment from 'moment/moment';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MultipleSelectChip from '../../Vendors/Forms/MultiSelect';
import SelectInput from '../../Forms/SelectInput';
import { Application, ApplicationVersion, Company, CompanyApplication, CompanyCertificationSubmitData, CompanySubmissionData, Contact, Version } from '../../../interfaces/users.interface';
import getCertifications from '../../../services/certifications';
import { Certification } from '../../../interfaces/certification.interface';
import createCompanyCertification from '../../../services/companyCertifications';
import { updateContact } from '../../../services/contact';
import { updateCompanyApplication } from '../../../services/companyApplication';
import { useAppSelector } from '../../../app/hooks';
import { Questionnaire } from '../../../interfaces/questionnaire.interface';
import FileUploaderComp from '../../Forms/FileUpload';
import { getCompany, updateCompany } from '../../../services/company';
import { StandardDialogActions } from '../../Modals/StandardDialog';
import { AssessmentSurvey } from '../../../interfaces/assessment.interface';
import { Survey } from '../../../interfaces/survey.interface';
import { createApplicationVersion, createBadge, updateVersionBadge } from '../../../services/applications';
import moment from 'moment/moment';

export default function CompanyCertificationForm(props: { company: Company, handleClose: any, uploadCallback?: any, site?: number, application?: number }) {
  const { company, handleClose, uploadCallback, site, application } = props;
  const { results: assessments } = useAppSelector(state => state.assessments);
  const {
    handleSubmit,
    register,
    control,
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [certifications, setCertifications] = useState<Array<Certification>>([]);
  const [selectedApplications, setSelectedApplications] = useState<number[]>(application ? [application] : []);
  const [selectedSites, setSelectedSites] = useState<Array<number>>(site ? [site] : []);
  const [selectedCertification, setSelectedCertification] = useState<number | undefined>();

  const { surveys } = useAppSelector(state => state.surveys);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(()=> {
    getCertifications('&null_companies_and_company_id=' + company.id).then((certificationResponse: Certification[]) => {
      setCertifications(certificationResponse);
    });
  }, []);

  const onSubmit = async (data: FieldValues) => {
    if (!company.id) {
      setErrorMessage('There was an error, please refresh the page and try again');
      return;
    }
    if (!selectedCertification) {
      setErrorMessage('You must select a certification type');
      return;
    }
    if (!selectedFile) {
      setErrorMessage('You must select a file to upload');
      return;
    }
    if (selectedSites.length === 0 && selectedApplications.length === 0) {
      setErrorMessage('You must select at least one site or application');
      return;
    }

    let sas = undefined;
    const appList:number[] = [];
    const avs:ApplicationVersion[] = [];
    if (selectedApplications.length > 0) {
      company.companyApplications?.forEach(ca => (ca.application.versions as Version[])?.forEach(cav => {
        if (selectedApplications.findIndex(sa => sa === cav.id) === -1) return;
        
        avs.push({ application: ca.application.id!, version: cav.id! });
        if (appList.findIndex(al => al === ca.application.id) > -1) appList.push(ca.application.id!);
        if (!cav.badge) {
          const date = moment();
          createBadge({
            title: 'non_tpn_self_reported',
            awardedDate: date.format('YYYY-MM-DD'),
            expirationDate: date.add(2, 'years').format('YYYY-MM-DD'),
          }).then(newBadge => updateVersionBadge(cav.id!, newBadge.id!));
        }
      }));
      sas = await Promise.all(avs.map(a => createApplicationVersion(a))).then(a => a.map(aa => aa.id!));
    }

    const companyCertificationSubmitData: CompanyCertificationSubmitData = {
      company: company.id,
      document: selectedFile,
      applicationVersions: sas,
      applications: appList,
      sites: selectedSites,
      certification: selectedCertification,
      issueDate: Moment(data.issueDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ'),
      expirationDate: Moment(data.expirationDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ'),
    };
    const companyCertificationResponse = await createCompanyCertification(companyCertificationSubmitData);
    const updatedCompany = await getCompany(company.id!.toString());
    if (companyCertificationResponse.certification.company) {
      if (uploadCallback) uploadCallback(updatedCompany);
      handleClose();
      return;
    }

    let surveyExists = undefined;

    if (selectedSites && selectedSites.length > 0) {
      company.locations.forEach(location => {
        if (selectedSites.indexOf(location.id!) > -1 && (!location.badge || location.badge === '')) {
          surveyExists = surveys && surveys.find(sur => (sur.questionnaire as Questionnaire).type === 'mpa_best_practice' && sur.site === location.id);
          if (!surveyExists) return;

          const locIndex = updatedCompany.locations.findIndex(loc => loc.id === location.id);
          if (locIndex > -1) {
            updatedCompany.locations[locIndex].badge = 'non_tpn_self_reported';
          }

          updateContact({
            ...location,
            primaryContact: location.primaryContact ? location.primaryContact.id : undefined,
            badge: 'non_tpn_self_reported',
          });
          if (!company.displayShieldType || company.displayShieldType === '' || company.displayShieldType === 'tpn_member') {
            updateCompany({
              ...updatedCompany,
              companyCertifications: updatedCompany.companyCertifications?.map(item => item.id),
              companyApplications: updatedCompany.companyApplications?.map(item => item.id),
              companyDocuments: updatedCompany.companyDocuments?.map(item => item.id),
              employees: updatedCompany.employees?.map(item => item.id),
              pendingEmployees: updatedCompany.pendingEmployees.map((employee) => employee.id),
              locations: updatedCompany.locations?.map(item => item.id),
              services: updatedCompany.services?.map(item => item.id),
              primaryContactUser: updatedCompany.primaryContactUser?.id,
              displayShieldType: 'non_tpn_self_reported',
              admins: updatedCompany.admins.map(item => item.id),
              subsidiaries: updatedCompany.subsidiaries.map(item => item.id),
            } as CompanySubmissionData);
          }
        }
      });
    }

    if (avs.length > 0) {
      company.companyApplications?.forEach(companyApplication => {
        if (avs.findIndex(a => a.application === companyApplication.application.id && !companyApplication.badge) === -1) return;

        const caIndex = updatedCompany.companyApplications?.findIndex(ca => ca.id === companyApplication.id);
        if (caIndex !== undefined && caIndex > -1) {
          (updatedCompany.companyApplications as CompanyApplication[])[caIndex].badge = 'non_tpn_self_reported';
        }

        updateCompanyApplication({
          company: companyApplication.company,
          id: companyApplication.id,
          application: companyApplication.application.id!,
          sites: companyApplication.sites ? companyApplication.sites.map(csite => csite.id!) : undefined,
          services: companyApplication.services.map(service => service.id),
          badge: 'non_tpn_self_reported',
        });
        if (!company.displayShieldType || company.displayShieldType === '' || company.displayShieldType === 'tpn_member') {
          updateCompany({
            ...updatedCompany,
            companyCertifications: updatedCompany.companyCertifications?.map(item => item.id),
            companyApplications: updatedCompany.companyApplications?.map(item => item.id),
            companyDocuments: updatedCompany.companyDocuments?.map(item => item.id),
            employees: updatedCompany.employees?.map(item => item.id),
            pendingEmployees: updatedCompany.pendingEmployees.map((employee) => employee.id),
            locations: updatedCompany.locations?.map(item => item.id),
            services: updatedCompany.services?.map(item => item.id),
            primaryContactUser: updatedCompany.primaryContactUser?.id,
            displayShieldType: 'non_tpn_self_reported',
            admins: updatedCompany.admins.map(item => item.id),
            subsidiaries: updatedCompany.subsidiaries.map(item => item.id),
          } as CompanySubmissionData);
        }
      });
    }
    if (uploadCallback) uploadCallback(updatedCompany);
    handleClose();
  };

  const locationOptions = (locations: Contact[]) => {
    if (!assessments) return locations;
    return locations.filter(loc => !assessments.find(ass => (ass.surveys as AssessmentSurvey[])?.find(asssur => (asssur.survey as Survey).site === loc.id)));
  };

  const applicationOptions = (companyApplications?: CompanyApplication[]) => {
    if (!companyApplications?.length) return [];
    companyApplications = companyApplications.filter(companyApp => (
      (typeof companyApp.application.company === 'number' ? companyApp.application.company === company.id : companyApp.application.company?.id === company.id) 
        && companyApp.application.versions && companyApp.application.versions.length > 0
    ));
    let options:Array<Application | Version> = [];
    companyApplications.forEach(ca => { options = options.concat([ca.application, ...(ca.application.versions as Version[])]);});
    return options;
    //return companyApplications.filter(compApp => !assessments.find(ass => (ass.surveys as AssessmentSurvey[])?.find(asssur => (asssur.survey as Survey).application === compApp.application.id))).map(capp => capp.application);
  };

  return (
    <Box component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems='center' mb={2}>
        <Grid item xs={12}>
          <SelectInput options={certifications} selectCallback={setSelectedCertification} inputLabel='Certification'/>
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
          <FileUploaderComp multiple={false} callback={(file: any) => setSelectedFile(file[0].file)} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="issueDate"
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="MM/DD/yyyy"
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} {...register('issueDate')}/>}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name="expirationDate"
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="MM/DD/yyyy"
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} {...register('expirationDate')} />}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MultipleSelectChip options={locationOptions(company.locations)} optionsCallback={setSelectedSites} selectedOptions={selectedSites} inputLabel='Sites'/>
          <MultipleSelectChip options={applicationOptions(company.companyApplications)} optionsCallback={setSelectedApplications} selectedOptions={selectedApplications} inputLabel='Applications' isApplication={true}/>
        </Grid>
      </Grid>
      {errorMessage.length > 0 && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
      <StandardDialogActions>
        <Button variant='outlined' onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClose();
        }}>Close</Button>
        <Button type='submit' variant="contained">Upload Certification</Button>
      </StandardDialogActions>
    </Box>
  );
}