import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { blue, widgetStyles } from './widgetStyles';
import { getApplicationMeta } from '../../../services/applications';
import { getCompaniesMetadata } from '../../../services/company';
import { ApplicationMeta, CompaniesMeta } from '../../../interfaces/metaData.interface';
import DownloadReportHeader from '../../DownloadReport/DownloadReportHeader';

const WidgetWrapper = (props: { data: any, title: string, caption?: string, children: any }) => {
  const styles = widgetStyles();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper className={styles.widget} sx={{ minHeight: '200px' }}><>
        <Box className={styles.widgetHeader}>
          {props.caption && <Typography variant='caption'>{props.caption}</Typography>}
          <Typography variant='h4' fontSize='1.8rem' color={blue}>{props.title}</Typography>
          <Divider />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '170px', width: '100%' }}>{props.data ? props.children : <Skeleton className={styles.fullSizeSkeleton} />}</Box>
      </></Paper>
    </Grid>
  );
};

const RevenueItems = (props: { companyMeta: CompaniesMeta | undefined }) => {
  if (!props.companyMeta) return <></>;
  const styles = widgetStyles();
  let total = props.companyMeta.grossRevenueSelfEmployed +
    props.companyMeta.grossRevenueUpTo2m +
    props.companyMeta.grossRevenue2mTo5m +
    props.companyMeta.grossRevenue5mTo10m +
    props.companyMeta.grossRevenue10mTo15m +
    props.companyMeta.grossRevenue15mTo25m +
    props.companyMeta.grossRevenue25mTo35m +
    props.companyMeta.grossRevenue35mTo50m +
    props.companyMeta.grossRevenue50mTo200m +
    props.companyMeta.grossRevenue200mPlus
  ;
  const revenueOptions = [
    { label: 'Self-Employed', count: props.companyMeta.grossRevenueSelfEmployed },
    { label: 'Up to $2M', count: props.companyMeta.grossRevenueUpTo2m },
    { label: '$2 - 5M', count: props.companyMeta.grossRevenue2mTo5m },
    { label: '$5 - 10M', count: props.companyMeta.grossRevenue5mTo10m },
    { label: '$10 - 15M', count: props.companyMeta.grossRevenue10mTo15m },
    { label: '$15 - 25M', count: props.companyMeta.grossRevenue15mTo25m },
    { label: '$25 - 35M', count: props.companyMeta.grossRevenue25mTo35m },
    { label: '$35 - 50M', count: props.companyMeta.grossRevenue35mTo50m },
    { label: '$50 - 200M', count: props.companyMeta.grossRevenue50mTo200m },
    { label: '$200M+', count: props.companyMeta.grossRevenue200mPlus },
  ];
  return (<>
    {revenueOptions.map(ro => 
      <Box key={ro.label} className={styles.labelCountBox}>
        <Typography sx={{ width: '150px' }}>{ro.label}:</Typography>
        <Typography>{ro.count}</Typography>
        <Typography sx={{ ml: 2, width: '35px', textAlign: 'right' }}>{Math.floor(ro.count / total * 100)}%</Typography>
      </Box>,
    )}
  </>);
};

const CompanyApplicationsCount = (props: { applicationMeta: ApplicationMeta | undefined }) => {
  if (!props.applicationMeta) return <></>;
  const styles = widgetStyles();

  return (<>
    {props.applicationMeta.topApplication.map(ac => 
      <Box key={ac.name + ac.companyCount} className={styles.labelCountBox}>
        <Typography sx={{ width: '150px' }}>{ac.name}:</Typography>
        <Typography>{ac.companyCount}</Typography>
      </Box>,
    )}
  </>);
};

export default function AdminModules() {
  const [ applicationMeta, setApplicationMeta ] = useState<ApplicationMeta | undefined>(undefined);
  const [ companyMeta, setCompanyMeta ] = useState<CompaniesMeta | undefined>(undefined);
  const [ timePeriod, setTimePeriod ] = useState<{ label: string, value: keyof CompaniesMeta }>({ label: '48 Hours', value: 'newInLast48Hours' });

  const companiesTimePeriod = companyMeta ? companyMeta[timePeriod.value] : '-';

  useEffect(() => {
    getApplicationMeta().then(am => setApplicationMeta(am));
    getCompaniesMetadata().then(cm => setCompanyMeta(cm));
  }, []);
  
  return (
    <>
      <DownloadReportHeader title='Admin Metrics'/>
        <Grid container spacing={2} sx={{ width: '100%', mt: 3 }}>
          <WidgetWrapper data={companyMeta} title='Total' caption='Service Providers'>
            <Typography variant='h2' textAlign='center'>{companyMeta && companyMeta.numCompanies}</Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title={`New: ${timePeriod.label}`} caption='Service Providers'>
            <Stack spacing={1} sx={{ mr: 1, width: '110px' }}>
              <Button size='small' variant={timePeriod.value === 'newInLast24Hours' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '24 Hours', value: 'newInLast24Hours' })}>24 Hours</Button>
              <Button size='small' variant={timePeriod.value === 'newInLast48Hours' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '48 Hours', value: 'newInLast48Hours' })}>48 Hours</Button>
              <Button size='small' variant={timePeriod.value === 'newInLast7Days' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '7 days', value: 'newInLast7Days' })}>7 days</Button>
            </Stack>
            <Stack spacing={1} sx={{ mr: 1, width: '110px' }}>
              <Button size='small' variant={timePeriod.value === 'newInLastMonth' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '1 month', value: 'newInLastMonth' })}>1 Month</Button>
              <Button size='small' variant={timePeriod.value === 'newInLast2Months' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '2 months', value: 'newInLast2Months' })}>2 Months</Button>
              <Button size='small' variant={timePeriod.value === 'newInLast3Months' ? 'contained' : 'outlined'} onClick={() => setTimePeriod({ label: '3 months', value: 'newInLast3Months' })}>3 Months</Button>
            </Stack>
            <Typography sx={{ flexGrow: 2 }} variant='h2' textAlign='center'>{companiesTimePeriod}</Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title='Waiting to Sign' caption='Service Providers'>
            <Typography component='div' textAlign='center' sx={{ margin: '0 2em' }}>Waiting for SP<br /><Typography variant='h2'>{companyMeta && companyMeta.waitingForClientSignature}</Typography></Typography>
            <Typography component='div' textAlign='center' sx={{ margin: '0 2em' }}>Waiting for TPN<br /><Typography variant='h2'>{companyMeta && companyMeta.waitingForTpnSignature}</Typography></Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title='Billable'  caption='Service Providers'>
            <Typography variant='h2' textAlign='center'>{companyMeta && companyMeta.billableCount}</Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title='Paid' caption='Service Providers'>
            <Typography variant='h2' textAlign='center'>{companyMeta && companyMeta.isPaidCount}</Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title='Total Sites Added' caption='Service Provider'>
            <Typography variant='h2' textAlign='center'>{companyMeta && companyMeta.companyLocationCount}</Typography>
          </WidgetWrapper>
          <WidgetWrapper data={companyMeta} title='Gross Revenue Counts' caption='Service Provider'>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '450px' }}>
              <RevenueItems companyMeta={companyMeta} />
            </Box>
          </WidgetWrapper>
          <WidgetWrapper data={applicationMeta} title='Applications In Use Count' caption='Top 10'>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '450px' }}>
              <CompanyApplicationsCount applicationMeta={applicationMeta} />
            </Box>
          </WidgetWrapper>
          <WidgetWrapper data={applicationMeta} title='Total Applications Added' caption='Service Provider'>
            <Typography component='div' textAlign='center' sx={{ margin: '0 2em' }}>In House Developed<br /><Typography variant='h2'>{applicationMeta && applicationMeta.customApplications}</Typography></Typography>
            <Typography component='div' textAlign='center' sx={{ margin: '0 2em' }}>3rd Party<br /><Typography variant='h2'>{applicationMeta && applicationMeta.licensedApplications}</Typography></Typography>
          </WidgetWrapper>
        </Grid>
      <Divider sx={{ margin: '2em 0' }} />
    </>
  );
}