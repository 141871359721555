import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { DataRow, DataCell, DataRowActions, DraggableItem } from '../Tables/DataList';
import { Link } from 'react-router-dom';
import { Domain } from '../../interfaces/domain.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { updateQuestionnaire } from '../../app/store/questionnaireSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DomainEditForm } from './DomainForm';
import { deleteDomain } from '../../app/store/domainSlice';



export default function DomainListItem(props: { canEdit?: boolean, domain: Domain, questionnaire: Questionnaire, idx: number, moveItem?: any, dropItem?: any }) {
  const [deleteDomainOpen, setDeleteDomainOpen] = useState(false);
  const handleDeleteDomainOpen = () => setDeleteDomainOpen(true);
  const handleDeleteDomainClose = () => setDeleteDomainOpen(false);

  const [domain, setDomain] = useState(props.domain);
  const [editDomainOpen, setEditDomainOpen] = useState(false);
  const handleEditDomainOpen = () => setEditDomainOpen(true);
  const handleEditDomainClose = (newDomain:Domain) => {
    if (newDomain) setDomain(newDomain);
    setEditDomainOpen(false);
  };

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm();

  const dispatch = useAppDispatch();
  const onSubmit = () => {
    const { domains } = props.questionnaire;
    const domainsIdsArr = domains.filter(domainItem => domain.id !== domainItem.id).map((domainItem) => domainItem.id);
    dispatch(updateQuestionnaire({ ...props.questionnaire, domains: domainsIdsArr }));
    dispatch(deleteDomain(domain.id));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleDeleteDomainClose();
    }
  }, []);

  const renderRow = () => {
    return (
      <>
        <Link to={domain.slug} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 87%)' }}>
          <DataRow hover>
            <DataCell xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              { props.moveItem && props.canEdit ? <DragIndicatorIcon sx={{ cursor: 'grab', mr: '.25em' }} /> : null }
              <Box>{domain.code + '. ' + domain.title}</Box>
            </DataCell>
            <DataCell xs={4}><Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={domain.description}>{domain.description}</Box></DataCell>
            <DataCell xs={2}>
              {props.idx + 1}
              { props.canEdit && ( <DataRowActions>
                <IconButton size="small" name="Edit Domain" onClick={(e) => {e.stopPropagation(); e.preventDefault(); handleEditDomainOpen();}}><EditIcon /></IconButton>
                <IconButton size="small" name="Delete Domain" onClick={(e)=> {e.stopPropagation(); e.preventDefault(); handleDeleteDomainOpen(); }}><DeleteIcon /></IconButton>
              </DataRowActions>
              )}
            </DataCell>
          </DataRow>
        </Link>
        <StandardDialog title={`Edit Domain: ${domain.title}`} handleClose={handleEditDomainClose} isOpen={editDomainOpen}>
          <DomainEditForm domain={domain} handleClose={handleEditDomainClose} />
        </StandardDialog>
        <StandardDialog title={`Remove ${domain.title} from questionnaire ${props.questionnaire.title}`} handleClose={handleDeleteDomainClose} isOpen={deleteDomainOpen}>
          <Typography>Are you sure you want to remove {domain.title} from questionnaire {props.questionnaire.title}?</Typography>
          <StandardDialogActions component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Button variant="outlined" onClick={(e) => {e.stopPropagation(); e.preventDefault(); handleDeleteDomainClose();}}>Cancel</Button>
            <Button type="submit" variant="contained" color="error">Remove</Button>
          </StandardDialogActions>
        </StandardDialog>
      </>
    );
  };

  return (
    <>
      { props.moveItem && props.canEdit ?
        <DraggableItem
            key={props.idx}
            index={props.idx}
            id={props.domain.id}
            moveItem={props.moveItem}
            dropItem={props.dropItem}
          >
          {renderRow()}
        </DraggableItem>
        :
        <>{renderRow()}</>
      }
    </>
  );
}