import { Grid, Autocomplete, TextField, InputAdornment, Box, Button, Chip, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Alert, Snackbar } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import { Search } from '@mui/icons-material';
import { Application, ApplicationMeta, ApplicationType, Version } from '../../../../interfaces/users.interface';
import React, { useEffect, useState } from 'react';
import getAuthSession from '../../../../services/auth';
import axios, { AxiosResponse } from 'axios';
import { throttle } from 'lodash';
import { useForm } from 'react-hook-form';
import MultipleSelectChip from '../../../Vendors/Forms/MultiSelect';
import { createApplication, createApplicationMeta, createVersion, updateVersionsAndTypes } from '../../../../services/applications';
import { fetchAllApplicationTypes } from '../../../../app/store/serviceCategories';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

type LicensedAppProps = {
  isOpen: boolean;
  handleClose: () => void;
  callBack: any;
  application?: Application
};

export default function LicensedApplication(props: LicensedAppProps) {
  const dispatch = useAppDispatch();
  const {
    getValues,
    register,
  } = useForm();
  const { applicationTypes, fetchingApplicationTypes } = useAppSelector(state => state.serviceCategories);

  const [JWToken, setJWToken] = useState('');
  const [applicationMeta, setApplicationMeta] = useState<ApplicationMeta[]>([]);
  const [businessName, setBusinessName] = useState<string | ApplicationMeta>('');
  const [selectedCategories, setSelectedCategories] = useState<Array<number>>((props.application?.applicationTypes as ApplicationType[])?.map(at => at.id) || []);
  const [versions, setVersions] = useState<Version[]>((props.application?.versions as Version[]) || [] );
  const [formError, setFormError] = useState(false);
  const [appError, setAppError] = useState<boolean | string>(false);

  const handleAsAService = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVersions(prevVersions => {
      const newVersions = [ ...prevVersions ];
      if (e.target.value === 'false') return newVersions.filter(v => v.value !== 'As a Service');
      if (e.target.value === 'true') newVersions.push({ value: 'As a Service' });
      return newVersions;
    });
  };

  const handleVersionChange = (vers: (Version | string)[]) => {
    const newVersions = vers.map(v => typeof v === 'string' ? { value: v } : v);
    setVersions(newVersions);
  };

  const shouldShowVersionDelete = (value:string) => {
    if (value === 'As a Service') return false;
    if (!props.application) return true;
    if (props.application.versions && (props.application.versions as Version[]).find(v => v.value === value)) return false;
    return true;
  };

  const createVersions = async () => {
    const newVersions = versions.filter(v => !v.id);
    const ids = versions.filter(v => v.id).map(v => v.id!);

    const promiseReponse = await Promise.all(newVersions.map(nv => createVersion(nv)));
    return [ ...ids, ...promiseReponse.map(v => v.id!)];
  };

  const fetchItems =  (searchText: string, type: string) => {
    if (!searchText || !JWToken) return;
    try {
      axios.get(
        `${process.env.REACT_APP_BASE_API}/${type}/?search=${searchText}`,
        {
          headers: { 'Authorization': `Bearer ${JWToken}` },
        },
      ).then((response: AxiosResponse) => {
        if (type === 'application-tags') setApplicationMeta(response.data.results as ApplicationMeta[]);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const throttledFetchItems = throttle(fetchItems, 2500, { leading: true, trailing: true });

  const handleCompanyChange = async (name: string) => {
    setBusinessName(name);
    await throttledFetchItems(name, 'application-tags');
  };

  const submitForm = async () => {
    setFormError(false);
    const data = getValues();
    if (!props.application && (!data.name || !businessName || versions.length === 0 || selectedCategories.length === 0)) return setFormError(true);
    const newVersions = await createVersions();
    let newApp: undefined | Application | void = undefined;
    if (!props.application) {
      const newAppMeta = typeof businessName === 'string' ? await createApplicationMeta(businessName) : businessName;
      newApp = await createApplication({ name: data.name, isPublic: true, adminApproved: true, meta: [(newAppMeta.id as number)], versions: newVersions, applicationTypes: selectedCategories }).catch(e => {
        const message = Object.keys(e.response.data)[0]!;
        setAppError(e.response.data[message][0]);
      });
      if (!newApp || !(newApp as Application).id) return;
    } else {
      newApp = await updateVersionsAndTypes(props.application.id!, newVersions, selectedCategories);
    }
    
    props.callBack(newApp as Application);
    props.handleClose();
  };

  useEffect(() =>{
    setApplicationMeta([]);
    setBusinessName('');
    if (!props.application) {
      setVersions([]);
      setSelectedCategories([]);
    } else {
      setVersions((props.application?.versions as Version[]) || []);
      setSelectedCategories((props.application?.applicationTypes as ApplicationType[])?.map(at => at.id) || []);
    }
  }, [props.application]);

  useEffect(() => {
    if (!applicationTypes.length && !fetchingApplicationTypes) dispatch(fetchAllApplicationTypes());
    getAuthSession().then((session) => {     
      setJWToken(session.getIdToken().getJwtToken());
    });
  }, []);

  return (
    <StandardDialog title={props.application ? `Edit ${props.application.name}` : 'Add new 3rd Party Licensed Application'} isOpen={props.isOpen} handleClose={() => {props.handleClose(); setFormError(false);}}><Box sx={{ width: '100%' }}>
      {!props.application &&
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <Autocomplete
              id='application-meta-search'
              fullWidth
              freeSolo
              options={applicationMeta}
              getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
              sx={{ '& .MuiFormControl-root': { mt: 0 } }}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              onChange={(event, newValue) => {
                setBusinessName(newValue ? newValue : '');
              }}
              onInputChange={(e, i) => handleCompanyChange(i)}
              renderInput={(params) =>
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  label='Company Name'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>),
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField required fullWidth label='Application Name' {...register('name')} />
          </Grid>
          <Grid item xs={4}>
            <MultipleSelectChip margin='0' options={applicationTypes} getOption={(option: ApplicationType) => option.type} optionsCallback={setSelectedCategories} selectedOptions={selectedCategories} inputLabel='Application Types*' />
          </Grid>
        </Grid>
      }
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Indicate any/all deployed versions and if App Type is a suite of tools, list included applications.</Typography>
          <FormControl sx={{ display: 'flex', gap: 2, flexDirection: 'row', alignItems: 'center', mb: 1 }}>
          <FormLabel id='is-service-radio-buttons-group-label' sx={{ color: 'black', fontSize: '.8em' }}>{'Is your application available "As a Service"? Please note that "As a Service" is considered a version.'}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="is-service-radio-buttons-group-label"
              defaultValue={false}
              onChange={handleAsAService}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <Typography mb={1} component='div' variant="caption">Type below and hit <Typography variant='caption' sx={{ display: 'inline-block', padding: '0 .25em', border: '1px solid black', borderRadius: '5px', textTransform: 'uppercase' }}>enter</Typography> to add a version(s) and/or included applications.</Typography>
          <Autocomplete
            multiple
            options={[] as Version[]}
            fullWidth
            freeSolo
            value={versions}
            renderInput={(params) => (
              <TextField {...params} label="Versions/Included Applications" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => {
                const { onDelete, key, ...restProps } = getTagProps({ index });
                return <Chip key={key} label={(option as Version).value} onDelete={shouldShowVersionDelete((option as Version).value) ? onDelete : undefined} {...restProps} />;
              })
            }
            onChange={(e, v) => handleVersionChange(v)}
          />
        </Grid>
        {formError && <Grid item xs={12}>
          <Alert severity='error'>Company Name, Application Name, and at least one Application Type and Version are required.</Alert>
        </Grid>}
      </Grid>
      <StandardDialogActions>
        <Button variant='outlined' onClick={() => {props.handleClose(); setFormError(false);}}>Cancel</Button>
        <Button variant='contained' onClick={submitForm}>Save</Button>
      </StandardDialogActions>
    </Box>
    <Snackbar
      open={!!appError}
      autoHideDuration={6000}
      onClose={() => setAppError(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={() => setAppError(false)} severity="error" sx={{ width: '100%' }}>
        {appError}
      </Alert>
    </Snackbar>
    </StandardDialog>
  );
}