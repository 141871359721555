import axios from 'axios';
import { InclusionConfigurationResultArray } from '../interfaces/inclusionConfiguration.interface';
import getAuthSession from './auth';

export default async function getIncluisionConfigurationsById(inclusionConfigIds:number[]) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/inclusion-configurations/?ids=${inclusionConfigIds.join(',')}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as InclusionConfigurationResultArray;
}

export const postInclusionConfiguration = async (questionId: number, answerId: number) => {
  const authSession = await getAuthSession();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API}/inclusion-configurations/`,
      {
        question: questionId,
        answer: answerId,
      },
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );
    return response.data as InclusionConfigurationResultArray;
  } catch (error) {
    console.error('Error creating inclusion configuration:', error);
    return error;
  }
};

export const postExclusionConfigurations = async (questionId: number, excludedAnswerIds: number[], exclusionQuestionId: number) => {
  const authSession = await getAuthSession();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API}/exclusion-configurations/${exclusionQuestionId}/${questionId}/`,
      {
        excluded_answer_ids: excludedAnswerIds,
      },
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error posting inclusion configurations:', error);
  }
};

export const deleteExclusionConfigurations = async (
  questionId: number,
  excludedAnswerIds: number[],
  inclusionQuestionId: number,
) => {
  const authSession = await getAuthSession();
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_API}/exclusion-configurations/${inclusionQuestionId}/${questionId}/`,
      {
        data: { excluded_answer_ids: excludedAnswerIds },
        headers: { Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting exclusion configurations:', error);
  }
};