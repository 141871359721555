import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AutocompleteSearch from '../Forms/AutoCompleteSearch';

import { useForm, FieldValues, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';

import { Certification } from '../../interfaces/certification.interface';
import { CertificationControl } from '../../interfaces/certificationControl.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createCertificationControl, resetCertificationControl } from '../../app/store/certificationControlSlice';
import { createCertification, resetCertification } from '../../app/store/certificationSlice';

const renderOption = (certification:Certification) => {
  if (!certification || certification.title.length < 1) return null;
  return (
    <Typography>
      {certification.title}: {certification.version}
    </Typography>
  );
};

export default function CertMapping(props: {
  questionUpdate: any,
  certList?: CertificationControl[],
  handleClose: any,
}) {
  const {
    handleSubmit,
    register,
    control,
    reset,
  } = useForm();
  const dispatch = useAppDispatch();
  const [isPosting, setIsPosting] = useState(false);
  const { certificationControl: certificationControlResult } = useAppSelector(state => state.certificationControl);
  const { certification: certificationResult } = useAppSelector(state => state.certification);

  const onCertificateSelect = (certification:Certification) => {
    dispatch(createCertificationControl(certification.id));
  };

  const onSubmit = (data: FieldValues) => {
    if (isPosting) return;
    
    setIsPosting(true);
    const releaseDate = Moment(data.releaseDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
    dispatch(createCertification({ ...data, releaseDate }));
  };

  useEffect(() => {
    if (!certificationResult) return;

    reset();
    onCertificateSelect(certificationResult);
    dispatch(resetCertification());

  }, [certificationResult]);

  useEffect(() => {
    if (!certificationControlResult || !('id' in certificationControlResult)) return;

    const newCerts = props.certList ? [ ...props.certList, certificationControlResult] : [ certificationControlResult ];
    props.questionUpdate({ excludedByCertifications: newCerts });
    dispatch(resetCertificationControl());
    setIsPosting(false);
    props.handleClose();
  }, [certificationControlResult]);

  return (
    <Box>
      <AutocompleteSearch 
        onChangeHandler={onCertificateSelect}
        api='certifications'
        label='Search for Certification'
        renderOption={renderOption}
      />
      <Typography variant='h6' component='h6' mb={1} mt={2}>Add new:</Typography>
      <Box component="form" method="post" onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
          <TextField
            required
            id='title'
            label='Title'
            sx={{ flexGrow: 2 }}
            {...register('title')}
          />
          <TextField
            required
            id='version'
            label='Version'
            sx={{ flexGrow: 0 }}
            {...register('version')}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              control={control}
              name="releaseDate"
              render={({ field: { onChange, value } }) => (
                <DesktopDatePicker
                  label="Release Date"
                  inputFormat="MM/DD/yyyy"
                  value={value}
                  onChange={(newValue) => {onChange(newValue);}}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Button type="submit" variant="contained" sx={{ mt: 1, float: 'right' }}>{ !isPosting ? 'Add New Certification' : <CircularProgress color="info" /> }</Button>
      </Box>
    </Box>
  );
}