import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment/moment';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import { Application, Company, Version } from '../../../interfaces/users.interface';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { CompanyCertification } from '../../../interfaces/users.interface';
import {  deleteCompanyCertification } from '../../../services/companyCertifications';
import { DataCell, DataRow, DataRowActions } from '../../Tables/DataList';
import { usePrevious } from '../../../services/stateHelpers';
import CompanyCertificationForm from './CompanyCertificationForm';
import { Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AssessmentSurvey } from '../../../interfaces/assessment.interface';
import { Survey } from '../../../interfaces/survey.interface';
import ChipsWithOverflow from '../../Layout/ChipsWithOverflow';
import { fetchApplicationVersion } from '../../../app/store/serviceCategories';

export const CompanyCertificationDataRow = (props: { companyCertification: CompanyCertification, companySuccessCallback: any, userCanAdminCompany: boolean, company: Company }) => {
  const {
    companyCertification,
    companySuccessCallback,
    userCanAdminCompany,
    company,
  } = props;
  const dispatch = useAppDispatch();
  const [deleteCertOpen, setDeleteCertOpen] = useState(false);
  const { results: resultAssessments } = useAppSelector(state => state.assessments);
  const { applicationVersions: avs } = useAppSelector(state => state.serviceCategories);
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteCertOpen = () => setDeleteCertOpen(true);
  const handleDeleteCertClose = () => setDeleteCertOpen(false);
  const handleSiteDeleteSubmit = () => {
    deleteCompanyCertification(companyCertification).then(() => {
      const companyCertifications: CompanyCertification[] = company?.companyCertifications ? company.companyCertifications : [];
      const updatedCompany: Company = {
        ...company,
        companyCertifications: companyCertifications.filter((cert: CompanyCertification) => cert.id != companyCertification.id),
      };
      companySuccessCallback(updatedCompany);
      handleDeleteCertClose();
    });
  };
  const applicationVersions = useMemo(() => {
    if (!avs.length || !companyCertification.applicationVersions || companyCertification.applicationVersions.length === 0) return undefined;
    const newAVS = avs.filter(av => companyCertification.applicationVersions!.findIndex(ccav => ccav === av.id) > -1);
    if (!newAVS?.length) return undefined;
    return [...new Set(newAVS.map(av => (av.application as Application).name + ' ' + (av.version as Version).value))];
  }, [avs, companyCertification.applicationVersions]);

  useEffect(() => {
    if (!companyCertification.applicationVersions || companyCertification.applicationVersions.length === 0) return;
    companyCertification.applicationVersions.map(av => {
      if (avs.findIndex(cav => cav.id! === av) === -1) dispatch(fetchApplicationVersion(av));
    });
  }, [companyCertification.applicationVersions]);

  useEffect(() => {
    if (!userCanAdminCompany) return;
    if (!resultAssessments || (companyCertification.sites.length === 0 && companyCertification.applications.length === 0)) {
      setShowDelete(true);
      return;
    }
    const sites = companyCertification.sites.map(s => s.id);
    const applications = companyCertification.applications.map(a => a.id);

    const assessmentSurveys = resultAssessments.map(cassessment => cassessment.surveys).flat(1);
    const invalidatingAssessmenSurvey = assessmentSurveys.find(assessmentSurvey => (
      (sites.indexOf(((assessmentSurvey as AssessmentSurvey).survey as Survey).site) !== -1 || applications.indexOf(((assessmentSurvey as AssessmentSurvey).survey as Survey).application) !== -1)
      && ['Assigned', 'Rejected', 'Pre-Assessment'].indexOf((assessmentSurvey as AssessmentSurvey).status) === -1
    ));
    setShowDelete(invalidatingAssessmenSurvey ? false : true);
  }, [resultAssessments]);
  return (
    <DataRow onClick={() => {}} hover >
      <StandardDialog title={`Delete ${companyCertification.certification.title}?`} handleClose={handleDeleteCertClose} isOpen={deleteCertOpen}>
        <Typography>Are you sure you want to delete {companyCertification.certification.title}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleDeleteCertClose()}>Cancel</Button>
          <Button variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => handleSiteDeleteSubmit()}>Delete</Button>
        </StandardDialogActions>
      </StandardDialog>
      <DataCell xs={2}>
        <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><Tooltip title={`${companyCertification.certification.title}${companyCertification.certification.version && companyCertification.certification.version.length > 0 ? `: ${companyCertification.certification.version}` : ''}`}><Typography sx={{ fontSize: '1.2em' }}>{companyCertification.certification.title}{companyCertification.certification.version && companyCertification.certification.version.length > 0 ? `: ${companyCertification.certification.version}` : ''}</Typography></Tooltip></Box>
      </DataCell>
      <DataCell xs={2}>{ChipsWithOverflow(companyCertification.sites?.map(site => site.name))}</DataCell>
      <DataCell xs={2}>{ChipsWithOverflow(applicationVersions)}</DataCell>
      <DataCell xs={2}>{Moment(companyCertification.issueDate).format('MM/DD/YYYY')}</DataCell>
      <DataCell xs={2}>{Moment(companyCertification.expirationDate).format('MM/DD/YYYY')}</DataCell>
      <DataCell xs={1}>{companyCertification?.adminApproved ? 'Accepted' : 'Rejected'}</DataCell>
      <DataCell xs={1}>
        <DataRowActions>
          <a href={companyCertification.document} target="_blank" rel="noreferrer">
            <IconButton size='small' name='View Certification'>
              <LinkOutlinedIcon />
            </IconButton>
          </a>
          {showDelete && <IconButton size='small' name='Delete Certification' onClick={() => handleDeleteCertOpen()} disabled={!userCanAdminCompany}>
            <DeleteIcon />
          </IconButton>}
        </DataRowActions>
      </DataCell>
    </DataRow>
  );
};


export default function CompanyCertificationsAccordion(props: { company: Company, companyCallback: any, userCanAdminCompany: boolean }) {
  const {
    company,
    companyCallback,
    userCanAdminCompany,
  } = props;
  const { profileSection } = useParams();
  const prevCerts = usePrevious(company.companyCertifications);
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const handleUploadDocumentOpen = () => setUploadDocumentOpen(true);
  const handleUploadDocumentClose = () => setUploadDocumentOpen(false);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'certifications');
  }, [profileSection]);

  useEffect(() => {
    if (!prevCerts || !company.companyCertifications || (prevCerts as CompanyCertification[]).length === company.companyCertifications.length) return;
    setExpanded(true);
  }, [company.companyCertifications]);

  return (
    <AccordionCollapse expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
      <AccordionCollapseSummary
        name="certifications-header"
        title='Certifications'
        count={company?.companyCertifications?.length}
        addItem={() => handleUploadDocumentOpen()}
        addItemLabel='Certification'
        addItemTest={userCanAdminCompany}
      >
        <StandardDialog title="Certification Upload" handleClose={handleUploadDocumentClose} isOpen={uploadDocumentOpen}>
          <CompanyCertificationForm company={company} handleClose={() => { handleUploadDocumentClose(); }} uploadCallback={companyCallback} />
        </StandardDialog>
      </AccordionCollapseSummary>
      <AccordionDetails>
        <DataRow header>
          <DataCell xs={2}>Certification</DataCell>
          <DataCell xs={2}>Sites</DataCell>
          <DataCell xs={2}>Applications</DataCell>
          <DataCell xs={2}>Issue Date</DataCell>
          <DataCell xs={2}>End Date</DataCell>
          <DataCell xs={1}>Status</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {company.companyCertifications && company.companyCertifications.map((companyCert) => <CompanyCertificationDataRow key={companyCert.id} companyCertification={companyCert} companySuccessCallback={companyCallback} userCanAdminCompany={userCanAdminCompany} company={company} />)}
      </AccordionDetails>
    </AccordionCollapse>
  );
}