import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const BreadCrumbItem = (props: { label:string, slug:string, urlString?:string, title:string, linkOverride?: any } ) => {
  return (
    <Box mb={3}>
      <Typography sx={{ lineHeight: 1 }} variant='overline'>{props.label}</Typography>
      { props.linkOverride ? 
        <a href="#" onClick={(e) => { e.preventDefault(); props.linkOverride(); }}>
          <Typography sx={{ lineHeight: 1 }} variant='h6'>{props.title}</Typography>
        </a>
        :
        <Link to={`${props.urlString ? props.urlString : null}${props.slug}`}>
          <Typography sx={{ lineHeight: 1 }} variant='h6'>{props.title}</Typography>
        </Link>
      }
    </Box>
  );
};

export default function HeaderDetailCard( props: {
  canEdit?: boolean,
  title: string,
  detail?: string,
  editClick?: any,
  overTitle?: string,
  breadCrumbs?: any,
  previewClick?: any
} ) {
  const descRef = useRef();
  const [descriptionExpandedButton, setDescriptionExpandedButton] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const styles = () => {
    if (descriptionExpanded) return { transition: 'max-height .25s ease', maxHeight: '800px', overflow: 'hidden' };
    if (!descriptionExpanded)  return { transition: 'max-height .25s ease', maxHeight: '48px', textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: '2', lineClamp: '2', WebkitBoxOrient: 'vertical', boxOrient: 'vertical', display: '-webkit-box' };
  };

  useEffect(() => {
    if (!props.detail) return;
    const descitem:HTMLElement = descRef.current!;
    const isTextClamped = (paraItem:Element) => paraItem.scrollHeight > paraItem.clientHeight;

    if (isTextClamped(descitem.children[0])) setDescriptionExpandedButton(true);
    
  }, []);

  return (
    <>
      <Box mb={3} mt={2}>
        { props.breadCrumbs && props.breadCrumbs.length > 0 &&
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {props.breadCrumbs && props.breadCrumbs.map((breadCrumb:any) => (
              <BreadCrumbItem
                key={breadCrumb.urlString}
                label={breadCrumb.label}
                slug={breadCrumb.slug}
                urlString={breadCrumb.urlString}
                title={breadCrumb.title}
                linkOverride={breadCrumb.linkOverride}
              />
            ))}
          </Breadcrumbs>
        }
        {props.overTitle && <Typography variant='overline'>{props.overTitle}</Typography>}
        <Typography variant='h5' mr={4}>
          {props.title}
          { props.canEdit && <IconButton size="small" name="Edit" title="Edit" onClick={(e)=> {e.preventDefault(); props.editClick(); }}><EditIcon /></IconButton>}
          {props.previewClick && <IconButton size="small" name="Preview Questionnaire" title="Preview Questionnaire" onClick={(e)=> {e.preventDefault(); props.previewClick(); }}><VisibilityIcon /></IconButton>}
          </Typography>
        {props.detail &&
          <Box ref={descRef}>
            <Typography sx={{ ...styles(), whiteSpace: 'pre-wrap' }}>
              {props.detail}
            </Typography>
            { descriptionExpandedButton &&
              <Divider onClick={() => setDescriptionExpanded(!descriptionExpanded)} sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '.75em', cursor: 'pointer' }}>
                { descriptionExpanded ? 
                  <><ExpandLessIcon /><Box>Show Less</Box></>
                  :
                  <><ExpandMoreIcon /><Box>Show More</Box></>
                }
                </Box>
              </Divider>
            }
          </Box>
        }        
      </Box>
    </>
  );
}