import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchTopic, updateTopic, resetTopic } from '../../app/store/topicSlice';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { TopicEditForm } from './TopicForm';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import BestPracticeListItem from '../BestPractices/BestPracticeListItem';
import { DataListContain, DataRow, DataCell } from '../Tables/DataList';
import HeaderDetailCard from '../Cards/HeaderDetailCard';
import StandardDialog from '../Modals/StandardDialog';
import BestPracticeForm from '../BestPractices/BestPracticeForm';

import { resetBestPractice } from '../../app/store/bestPracticeSlice';
import { buildQuestionnaireBreadCrumbs } from '../../services/breadCrumbs';

import QuestionnairePreview from '../Preview/QuestionnairePreview';

export default function Topic() {
  const params = useParams();
  const topicSlug:string = params.topicSlug!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTopic());
    dispatch(fetchTopic(topicSlug));
    dispatch(resetBestPractice());
  }, []);

  const { user } = useAppSelector(state => state.user);
  const { topic } = useAppSelector(state => state.topics);
  const [curView, setCurView] = useState('edit');
  const [newBestPracticeOpen, setNewBestPracticeOpen] = useState(false);
  const handleNewBestPracticeSetOpen = () => setNewBestPracticeOpen(true);
  const handleNewBestPracticeClose = () => setNewBestPracticeOpen(false);

  const [editTopicOpen, setEditTopicOpen] = useState(false);
  const handleEditTopicOpen = () => setEditTopicOpen(true);
  const handleEditTopicClose = () => setEditTopicOpen(false);

  const [bestPractices, setBestPractices] = useState(topic?.bestPractices as BestPractice[]);
  const [bestPracticeFilter, setBestPracticeFilter] = useState(null);

  const bestPracticeSearchClickCallback = useCallback((e: Event, value: any) => {
    e.preventDefault();
    if (value) { setBestPracticeFilter(typeof(value) !== 'string' ? value.bestPractice.id : value); } else { setBestPracticeFilter(null); }
  }, []);

  const moveBestPractice = useCallback((dragIndex: number, hoverIndex: number) => {
    setBestPractices((prevBestPractices: BestPractice[]) =>
      update(prevBestPractices, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevBestPractices[dragIndex] as BestPractice],
        ],
      }),
    );
  }, []);

  const dropBestPractice = () => {
    const bestPracticesIdsArr = bestPractices.map((bestPractice) => bestPractice.id);
    dispatch(updateTopic({ ...topic, bestPractices: bestPracticesIdsArr }));
  };

  const buildBestPracticeSearchList = () => {
    if (!topic) return false;
    return topic.bestPractices.map(bestPractice => {return { label: bestPractice.title, bestPractice: bestPractice };});
  };

  const [bestPracticeSearchList, setBestPracticeSearchList] = useState(buildBestPracticeSearchList);
  const [breadCrumbs, setBreadCrumbs] = useState<any[] | undefined>(undefined);
  
  const buildBreadCrumbs = () => {
    if (!topic) return;
    if (typeof topic.questionnaire === 'number') return;
    if (typeof topic.domain === 'number') return;

    const crumbItems = [
      { ...topic.questionnaire, label: 'Questionnaire' },
      { ...topic.domain, label: 'Domain' },
    ];
    const breadCrumbsList = buildQuestionnaireBreadCrumbs(crumbItems, '/questionnaires/');
    setBreadCrumbs(breadCrumbsList);
  };

  useEffect(() => {
    let newBestPracticeList = topic?.bestPractices as BestPractice[];
    if (topic && topic.bestPractices) setBestPracticeSearchList(buildBestPracticeSearchList);
    if (bestPracticeFilter) {
      if (typeof(bestPracticeFilter) === 'number') {
        newBestPracticeList = newBestPracticeList.filter(bestPractice => bestPractice.id === bestPracticeFilter);
      } else {
        newBestPracticeList = newBestPracticeList.filter(bestPractice => bestPractice.title.toLowerCase().includes(bestPracticeFilter));
      }
    }
    setBestPractices(newBestPracticeList);
    buildBreadCrumbs();
  }, [topic, bestPracticeFilter]);
  
  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      { curView === 'edit' && topic ? 
        <>
          <HeaderDetailCard canEdit={canEdit} overTitle='Topic' title={topic.title} detail={topic.description} editClick={handleEditTopicOpen} breadCrumbs={breadCrumbs} previewClick={() => setCurView('preview')}  />
          <StandardDialog title={`Edit Topic: ${topic.title}`} handleClose={handleEditTopicClose} isOpen={editTopicOpen}>
            <TopicEditForm topic={topic} handleClose={handleEditTopicClose} />
          </StandardDialog>
          <StandardDialog title="New Best Practice" handleClose={handleNewBestPracticeClose} isOpen={newBestPracticeOpen}>
            <BestPracticeForm topic={topic} handleClose={handleNewBestPracticeClose} />
          </StandardDialog>
          <DataListContain title="Best Practices" buttonTitle={canEdit ? 'Best Practices' : '' } buttonClick={handleNewBestPracticeSetOpen} searchList={bestPracticeSearchList} onChange={bestPracticeSearchClickCallback}>
            <DataRow header>
              <DataCell xs={6}>Title</DataCell>
              <DataCell xs={4}>Description</DataCell>
              <DataCell xs={2}>Order</DataCell>
            </DataRow>
            { bestPracticeFilter ?
              <>
                {bestPractices && bestPractices.map((bestPractice: BestPractice) => (
                  <BestPracticeListItem canEdit={canEdit} key={bestPractice.id} bestPractice={bestPractice} topic={topic} idx={topic?.bestPractices.indexOf(bestPractice)} />
                ))}
              </>
              :
              <DndProvider backend={HTML5Backend}>
                {bestPractices && bestPractices.map((bestPractice: BestPractice) => (
                  <BestPracticeListItem canEdit={canEdit} key={bestPractice.id} bestPractice={bestPractice} topic={topic} idx={topic?.bestPractices.indexOf(bestPractice)} moveItem={moveBestPractice} dropItem={dropBestPractice} />
                ))}
              </DndProvider>
            }
          </DataListContain>
        </>
        :
        <></>
      }
      { curView === 'preview' && topic &&
        <QuestionnairePreview
          buttonClick={() => setCurView('edit')}
          buttonText={topic.title}
          topic={topic}
        />
      }
    </>
  );
}