import axios from 'axios';
// import { FieldValues } from 'react-hook-form';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import User from '../../interfaces/users.interface';
import getAuthSession from '../../services/auth';

const baseUrl = process.env.REACT_APP_BASE_API;

export interface UsersResponse {
  count: number;
  next?: any;
  previous?: any;
  results: any[];
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  results: [] as User[],
};

export const getUsersByType = createAsyncThunk(
  'users/getUsersByType',
  async (userType: string) => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/users/?type=${userType}&limit=99999`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as UsersResponse;
    } catch (error: any) {
      return error;
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsersByType.fulfilled, (state, action: PayloadAction<UsersResponse>) => {
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      state.results = action.payload.results;
    });
  },
});

export default usersSlice.reducer;