import { Add, ChevronLeft } from '@mui/icons-material';
import { Application, ApplicationType, Version, Company, CompanyApplication, CompanyApplicationSubmitData, ApplicationVersion } from '../../../../interfaces/users.interface';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import { Alert, Button, Typography, Box, Divider, Grid, TextField, Autocomplete, FormControl, RadioGroup, FormControlLabel, Radio, Chip, FormLabel, Stack, Select, MenuItem, InputLabel, Snackbar } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { createApplication, createVersion, createHardeningDocument, deleteHardeningDocument, updateApplication, updateApplicationDocs, deleteApplicationVersion, createApplicationVersion, getApplicationVersion } from '../../../../services/applications';
import createCompanyApplication, { updateCompanyApplicationSimple } from '../../../../services/companyApplication';
import React, { useState, useEffect, useMemo } from 'react';
import MultipleSelectChip from '../../../Vendors/Forms/MultiSelect';
import { Service } from '../../../../interfaces/vendor.interface';
import SearchLicensedApps from './SearchLicensedApps';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import ApplicationPill from './ApplicationPill';
import FileUploaderComp from '../../../Forms/FileUpload';
import { createFile } from '../../../../services/questionAnswer';
import { HardeningDocument } from '../../../../interfaces/hardeningDocuments';
import { fetchDocument } from '../../../../app/store/serviceCategories';

type NewCustomAppProps = {
  isOpen: boolean;
  handleClose: () => void;
  back?: () => void;
  company: Company;
  companyApplication?: CompanyApplication;
  callBack: any
};

export default function NewCustomApp(props: NewCustomAppProps) {
  const dispatch = useAppDispatch();
  const company = useMemo(() => props.company, [props.company]);
  const [companyApplication, setCompanyApplication] = useState(props.companyApplication);
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      name: companyApplication?.application.name || undefined,
      description: companyApplication?.application.description || undefined,
    },
  });
  const { applicationTypes, documents } = useAppSelector(state => state.serviceCategories);

  const [isPublic, setIsPublic] = useState(companyApplication?.application.isPublic === false ? false : true);
  const [services, setServices] = useState<Service[]>(company.services ? company.services : []);
  const [selectedServices, setSelectedServices] = useState<Array<number>>(companyApplication ? companyApplication.services.map(service => typeof service === 'number' ? service : service.id as number) : []);
  const [selectedSites, setSelectedSites] = useState<Array<number>>(companyApplication ? companyApplication.sites.map(site => typeof site === 'number' ? site : site.id as number) : []);
  const [selectedCategories, setSelectedCategories] = useState<Array<number>>(companyApplication ? (companyApplication.application.applicationTypes as ApplicationType[])?.map(site => typeof site === 'number' ? site : site.id as number) : []);
  const [versions, setVersions] = useState<Version[]>((companyApplication?.application.versions as Version[]) || [] );
  const [hardeningDocuments, setHardeningDocuments] = useState<HardeningDocument[]>((companyApplication?.application.hardeningDocuments as HardeningDocument[]) || [] );
  const [applicationVersions, setApplicationVersions] = useState<ApplicationVersion[]>([]);
  const [isIntegratedAppOpen, setIsIntegratedAppOpen] = useState(false);

  const [createHardeningDocumentOpen, setCreateHardeningDocumentOpen] = useState(false);
  const [hardeningDocument, setHardeningDocument] = useState(undefined);
  const [documentError, setDocumentError] = useState('');
  const [selectedVersion, setSelectedVersion] = useState<undefined | Version>(undefined);
  const [hardeningDocName, setHardeningDocName] = useState('');
  const [documentToDelete, setDocumentToDelete] = useState<undefined | HardeningDocument>(undefined);
  const [congratsOpen, setCongratsOpen] = useState(false);
  const [formError, setFormError] = useState(false);
  const [appError, setAppError] = useState<boolean | string>(false);

  const handleCreateHardeningDocumentOpen = () => setCreateHardeningDocumentOpen(true);
  const handleCreateHardeningDocumentClose = () => {
    setCreateHardeningDocumentOpen(false);
    setHardeningDocument(undefined);
    setSelectedVersion(undefined);
  };

  const asAService = useMemo(() => {
    if (!props.companyApplication || !props.companyApplication.application.versions) return false;
    return !!(props.companyApplication.application.versions as Version[]).find(v => v.value === 'As a Service');
  }, [props.companyApplication]);

  const cleanUp = () => {
    setValue('name', undefined);
    setValue('description', undefined);
    setApplicationVersions([]);
    setVersions([]);
    setSelectedCategories([]);
    setFormError(false);
    setSelectedSites([]);
    setSelectedServices([]);
  };

  const handleAddApplication = (newApplication: Application) => {
    const companyAppData: CompanyApplicationSubmitData = {
      company: company.id!,
      application: newApplication.id as number,
      sites: selectedSites,
      services: selectedServices,
    };
    createCompanyApplication(companyAppData).then((companyAppResponse: CompanyApplication) => {
      const companyApps = company.companyApplications ? company.companyApplications : [];
      const updatedCompany: Company = {
        ...company,
        companyApplications: [...companyApps, companyAppResponse],
      };
      props.callBack(updatedCompany);
      cleanUp();
      props.handleClose();
    });
  };

  const removeDeleted = (prevAVs:ApplicationVersion[], appVers:ApplicationVersion[]) => {
    const removeDeletedAVs:ApplicationVersion[] = [];
    prevAVs.forEach(preavs => {
      if (!appVers.find(appVer => appVer.id === preavs.id)) {
        deleteApplicationVersion(preavs.id!);
      } else {
        removeDeletedAVs.push(preavs);
      }
    });
    return removeDeletedAVs;
  };

  const handleIntegratedCallback = (appVers: ApplicationVersion[]) => {
    const newAVs:ApplicationVersion[] = [];
    appVers.forEach(avs => {
      if (!avs.id) newAVs.push({ application: (avs.application as Application).id!, version: (avs.version as Version).id! });
    });
    if (newAVs.length) {
      Promise.all(newAVs.map(avs => createApplicationVersion(avs))).then(avs => {
        setApplicationVersions(prevAVs => {
          const remainingAvs = removeDeleted(prevAVs, appVers);
          return [ ...remainingAvs, ...avs ];
        });
      });
    } else {
      setApplicationVersions(prevAVs => {
        const remainingAvs = removeDeleted(prevAVs, appVers);
        return remainingAvs;
      });
    }
  };

  const handleRemoveSelectedApplication = (id:number) => {
    deleteApplicationVersion(id).then(() => {
      setApplicationVersions(prevApps => {
        return prevApps.filter(app => app.id !== id);
      });
    });
  };

  const handleAsAService = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVersions(prevVersions => {
      const newVersions = [ ...prevVersions ];
      if (e.target.value === 'false') return newVersions.filter(v => v.value !== 'As a Service');
      if (e.target.value === 'true') newVersions.push({ value: 'As a Service' });
      return newVersions;
    });
  };

  const handleVersionChange = (vers: (Version | string)[]) => {
    const newVersions = vers.map(v => typeof v === 'string' ? { value: v } : v);
    setVersions(newVersions);
  };

  const shouldShowVersionDelete = (value:string) => {
    if (value === 'As a Service') return false;
    if (!companyApplication) return true;
    if (companyApplication.application.versions && (companyApplication.application.versions as Version[]).find(v => v.value === value)) return false;
    return true;
  };

  const createVersions = async () => {
    const newVersions = versions.filter(v => !v.id);
    const ids = versions.filter(v => v.id).map(v => v.id!);

    const promiseReponse = await Promise.all(newVersions.map(nv => createVersion(nv)));
    return [ ...ids, ...promiseReponse.map(v => v.id!)];
  };

  const onSubmit = async (data: FieldValues) => {
    setFormError(false);
    if (!data.name || selectedCategories.length === 0 || versions.length === 0 || selectedServices.length === 0) return setFormError(true);
    const newVersions = await createVersions();
    if (companyApplication) {
      await updateApplication({
        id: companyApplication.application.id,
        name: data.name,
        description: data.description,
        isPublic,
        versions: newVersions,
        applicationTypes: selectedCategories,
        applicationVersions: applicationVersions.map(avs => avs.id!),
      });
      const newCA = await updateCompanyApplicationSimple(companyApplication.id!, {
        sites: selectedSites,
        services: selectedServices,
      });
      props.callBack(newCA);
      return;
    }

    const newApplication = await createApplication({
      name: data.name,
      description: data.description,
      isPublic,
      adminApproved: true,
      company: company.id,
      versions: newVersions,
      applicationTypes: selectedCategories,
      applicationVersions: applicationVersions.map(avs => avs.id!),
    }).catch(e => {
      const message = Object.keys(e.response.data)[0]!;
      setAppError(e.response.data[message][0]);
    });
    if (!newApplication || !(newApplication as Application).id) return;
    setCongratsOpen(true);
    handleAddApplication(newApplication as Application);
  };

  const onDocumentSubmit = async () => {
    if (!companyApplication) return;
    if (!hardeningDocName.length) {
      setDocumentError('Please Enter a Document Name');
      return;
    }
    if (!selectedVersion) {
      setDocumentError('Please Select Version');
      return;
    }
    if (!hardeningDocument) {
      setDocumentError('Please Select A File to Upload');
      return;
    }
    const newDocument = await createFile(hardeningDocument);
    const newHardeningDocument = await createHardeningDocument({ type: hardeningDocName, version: selectedVersion.value, document: newDocument.id });
    dispatch(fetchDocument(newHardeningDocument.document));
    updateApplicationDocs(companyApplication.application, selectedVersion, newHardeningDocument.id!, true).then(({ napp, nversion }) => {
      setCompanyApplication(prev => {
        if (!prev || !nversion) return prev;
        return { ...prev, application: napp };
      });
    });
    setHardeningDocuments(prevHD => [ ...prevHD, newHardeningDocument ]);

    handleCreateHardeningDocumentClose();
  };

  const deleteDocument = () => {
    deleteHardeningDocument(documentToDelete?.id!);
    setHardeningDocuments(prevHD => prevHD.filter(hd => hd.id !== documentToDelete?.id!));
    setDocumentToDelete(undefined);
  };

  const handleDocClick = (id?:number) => {
    if (!id) return;
    const doc = documents.find(cdoc => cdoc.id === id);
    if (!doc) return;

    const link = document.createElement('a');
    link.setAttribute('href', doc.document);
    link.setAttribute('target', '_blank');
    link.click();
  };

  useEffect(() => {
    if (!companyApplication?.application.applicationVersions) return;
    Promise.all(companyApplication?.application.applicationVersions.map(avs => getApplicationVersion(avs as number))).then(avs => setApplicationVersions(avs));
  }, [companyApplication?.application.applicationVersions]);

  useEffect(() => {
    setServices(company.services ? company.services : []);
  }, [company.services]);

  return (<>
    <Snackbar
      open={!!appError}
      autoHideDuration={6000}
      onClose={() => setAppError(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={() => setAppError(false)} severity="error" sx={{ width: '100%' }}>
        {appError}
      </Alert>
    </Snackbar>
    <StandardDialog title={'Congratulations on adding your new in-house developed application'} isOpen={congratsOpen} handleClose={() => setCongratsOpen(false)}>
      <Typography>You can now edit your application and hardening guidelines.</Typography>
      <StandardDialogActions>
          <Button variant='outlined' onClick={() => setCongratsOpen(false)}>Close</Button>
        </StandardDialogActions>
    </StandardDialog>
    <StandardDialog
      title={props.companyApplication ? `Edit ${props.companyApplication.application.name}` : 'Create New Application'}
      isOpen={props.isOpen}
      handleClose={() => {if (!props.companyApplication) cleanUp(); props.handleClose();}}
    >
      <Typography>Please provide the following details about your in-house developed application.</Typography>
      <Box sx={{ width: '100%', marginTop: '16px' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField required fullWidth label="Application Name" {...register('name')} />
          </Grid>
          <Grid item xs={6}>
            <MultipleSelectChip margin='0' options={applicationTypes} getOption={(option: ApplicationType) => option.type} optionsCallback={setSelectedCategories} selectedOptions={selectedCategories} inputLabel='Application Types*' />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Description" multiline rows={4} {...register('description')} />
            <Typography variant='caption'>Please be aware that this description will be visible to Content Owners and other Service Providers if it is licensable.</Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Typography>Indicate any/all deployed versions and if App Type is a suite of tools, list included applications.</Typography>
            <FormControl sx={{ display: 'flex', gap: 2, flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormLabel id='is-service-radio-buttons-group-label' sx={{ color: 'black', fontSize: '.8em' }}>{'Is your application available "As a Service"? Please note that "As a Service" is considered a version.'}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="is-service-radio-buttons-group-label"
                defaultValue={asAService}
                onChange={handleAsAService}
              >
                <FormControlLabel disabled={asAService} value={true} control={<Radio />} label="Yes" />
                <FormControlLabel disabled={asAService} value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Typography mb={1} component='div' variant="caption">Type below and hit <Typography variant='caption' sx={{ display: 'inline-block', padding: '0 .25em', border: '1px solid black', borderRadius: '5px', textTransform: 'uppercase' }}>enter</Typography> to add a version(s) and/or included applications.</Typography>
            <Autocomplete
              multiple
              options={[] as Version[]}
              fullWidth
              freeSolo
              value={versions}
              renderInput={(params) => (
                <TextField {...params} label="Versions/Included Applications" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => {
                  const { onDelete, key, ...restProps } = getTagProps({ index });
                  return <Chip key={key} label={(option as Version).value} onDelete={shouldShowVersionDelete((option as Version).value) ? onDelete : undefined} {...restProps} />;
                })
              }
              onChange={(e, v) => handleVersionChange(v)}
            />
            {companyApplication && !!companyApplication.application.versions?.length && <>
              <Typography sx={{ mt: 1 }}>Hardening Guidelines</Typography>
              <Box sx={{ display: 'flex', gap: '8px', mt: '8px' }}>
                {!!hardeningDocuments.length && (hardeningDocuments as HardeningDocument[]).map(hd => <Chip key={hd.id} label={hd.version + ' ' + hd.type} onDelete={() => setDocumentToDelete(hd)} onClick={() => handleDocClick(hd.document)} />)}
                <Chip label='Add New' icon={<Add />} onClick={() => handleCreateHardeningDocumentOpen()} />
              </Box>
              <StandardDialog title={`Add new Hardening Guidelines to ${getValues('name')}`} isOpen={createHardeningDocumentOpen} handleClose={() => handleCreateHardeningDocumentClose()}>
                <Grid container spacing={2} component="form" method="post" onSubmit={handleSubmit(onDocumentSubmit)}>
                  <Grid item xs={6}>
                    <TextField label='Hardening Guidelines Name' fullWidth required value={hardeningDocName} onChange={(e) => setHardeningDocName(e.target.value)} />
                  </Grid>
                  <Grid item xs={6}><FormControl fullWidth>
                    <InputLabel id="doc-version-label">Version</InputLabel>
                    <Select
                      label='Version'
                      required value={selectedVersion?.id}
                      labelId="doc-version-label"
                      id="doc-version"
                    >
                     {(companyApplication.application.versions as Version[]).map((av, ind) => <MenuItem key={av.id! + ind} onClick={() => setSelectedVersion(av)} value={av.id}>{av.value}</MenuItem>)}
                    </Select>
                    </FormControl></Grid>
                  <Grid item xs={12}>
                    <FileUploaderComp multiple={false} callback={(file: any) => {setDocumentError(''); setHardeningDocument(file[0].file);}} />
                    {documentError && <Alert severity="error" sx={{ mt:1 }}>{documentError}</Alert>}
                  </Grid>
                </Grid>
                <StandardDialogActions>
                  <Button variant='outlined' onClick={() => handleCreateHardeningDocumentClose()}>Cancel</Button>
                  <Button variant='contained' onClick={() => onDocumentSubmit()}>Upload</Button>
                </StandardDialogActions>
              </StandardDialog>
              <StandardDialog title={`Are you sure you want to delete ${documentToDelete?.version} ${documentToDelete?.type}`} isOpen={documentToDelete} handleClose={() => setDocumentToDelete(undefined)}>
                <Typography>Are you sure you want to delete {documentToDelete?.version} {documentToDelete?.type}? Please note this is irreversable.</Typography>
                <StandardDialogActions>
                  <Button variant='outlined' onClick={() => setDocumentToDelete(undefined)}>Cancel</Button>
                  <Button variant='contained' color='error' onClick={() => deleteDocument()}>Delete</Button>
                </StandardDialogActions>
              </StandardDialog>
            </>}
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Grid container mt={2} mb={2}>
              <Grid item xs={8}>
                <Typography variant='h6' fontWeight='400'>Is this application licensable to other Service Providers?</Typography>
                <Typography variant='body2'>{'Please note that "Licensable Apps" will be visible for other Service Providers to select when filling out this form.'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="is-public-radio-buttons-group-label"
                    value={isPublic}
                    onChange={() => setIsPublic(prev => !prev)}
                  >
                    <FormControlLabel labelPlacement='top' value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel labelPlacement='top' value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Typography>List any 3rd party application integrations (eg: API integration to your customized app) by searching the TPN+ directory or adding new.</Typography>
            <Box sx={{ display: 'flex', gap: '8px', mt: '8px' }}>
              {applicationVersions.map(av => (
                <ApplicationPill key={av.id} application={av.application as Application} version={av.version as Version} handleRemoveSelectedApplication={handleRemoveSelectedApplication} />
              ))}
              <Chip label='Add New' icon={<Add />} onClick={() => setIsIntegratedAppOpen(true)} />
            </Box>
            <SearchLicensedApps isCustom={true} isOpen={isIntegratedAppOpen} handleClose={() => setIsIntegratedAppOpen(false)} callBack={handleIntegratedCallback} selectedApplicationVersions={applicationVersions}  />
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography>Indicate which Site locations operate or host this application. (i.e. do not include cloud instances)</Typography>
              <MultipleSelectChip options={company.locations} optionsCallback={setSelectedSites} selectedOptions={selectedSites} inputLabel='Sites' />
              <MultipleSelectChip options={services} optionsCallback={setSelectedServices} selectedOptions={selectedServices} inputLabel='Services*' />
            </Stack>
          </Grid>
          {formError && <Grid item xs={12}>
            <Divider sx={{ mb: 2 }} />
            <Alert severity='error'>Application Name, and at least one Version, Application Type, and Service are required.</Alert>
          </Grid>}
        </Grid>
        <StandardDialogActions>
          {props.back && <Button variant='outlined' onClick={() => {cleanUp(); if (props.back) props.back();}} startIcon={<ChevronLeft />}>Back</Button>}
          <Button variant='outlined' onClick={() => {if (!props.companyApplication) cleanUp(); props.handleClose();}}>Cancel</Button>
          <Button variant='contained' type='submit'>{props.companyApplication ? 'Save' : 'Create'} Application</Button>
        </StandardDialogActions>
      </Box>
    </StandardDialog>
  </>);
}