import axios from 'axios';

import { BillingRecord, BillingRecordPatchObj } from '../interfaces/billing.interface';
import getAuthSession from './auth';

export default async function getAllBillingRecords() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/billing-records/?limit=99999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as BillingRecord[];
}

export async function getBillingRecord(billingCustomerId: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/billing-records/?billing_customer_id=${billingCustomerId}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as BillingRecord[];
}

export async function patchBillingRecord(billingRecord: BillingRecordPatchObj) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/billing-records/${billingRecord.id}/`,
    { ...billingRecord },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as BillingRecord;
}