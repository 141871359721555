import React from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Question } from '../../../interfaces/question.interface';
import User from '../../../interfaces/users.interface';

type AssessorFindingsFormProps = {
  user: User | undefined;
  question: Question;
  assessorAnswer: string;
  assessmentStatus: string | undefined;
  assessorFindings: string;
  onAssessorAnswerChange: (answer: string) => void;
  onAssessorFindingsChange: (findings: string) => void;
};

const AssessorFindingsForm = (props: AssessorFindingsFormProps) => {
  const {
    user,
    question,
    assessorAnswer,
    assessmentStatus,
    assessorFindings,
    onAssessorAnswerChange,
    onAssessorFindingsChange,
  } = props;

  const handleAssessorAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAssessorAnswerChange(e.target.value);
  };

  const handleAssessorFindingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAssessorFindingsChange(e.target.value);
  };

  return (
    <>
      <RadioGroup
        aria-labelledby={question.title}
        name='assessor-answer'
        value={assessorAnswer}
        onChange={handleAssessorAnswerChange}
      >
        <FormControlLabel
          disabled={
            user &&
            user.type === 'assessor' &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Fully Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Fully Implemented' ? 'bold' : 'inherit' }}
            >
              Fully Implemented
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === 'assessor' &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Partially Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Partially Implemented' ? 'bold' : 'inherit' }}
            >
              Partially Implemented
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === 'assessor' &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Not Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Not Implemented' ? 'bold' : 'inherit' }}
            >
              Not Implemented
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === 'assessor' &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Not Applicable'
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Not Applicable' ? 'bold' : 'inherit' }}>
              Not Applicable
            </Typography>
          }
        />
      </RadioGroup>
      <Typography variant='caption'>Finding is required</Typography>
      <TextField
        id={`${question.id}-assessor-comment`}
        multiline
        rows={5}
        label={'Assessor Finding'}
        fullWidth
        onChange={handleAssessorFindingsChange}
        value={assessorFindings}
        required
        sx={{ mt: 2 }}
        inputProps={{
          readOnly:
            user &&
            user.type === 'assessor' &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true,
          sx: { overflowY: 'scroll' },
        }}
      />
    </>
  );
};

export default AssessorFindingsForm;
