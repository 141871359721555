import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Question, LogicList } from '../../interfaces/question.interface';
import { Answer } from '../../interfaces/answer.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { resetInclusionConfiguration } from '../../app/store/inclusionConfigurationSlice';
import { fetchQuestionnaire, fetchTopics, fetchBestPractices } from '../../services/questionnaires';
import { getQuestion } from '../../services/question';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup } from '@mui/material';

import { DataRow, DataCell } from '../Tables/DataList';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FormControl, FormLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { Domain } from '../../interfaces/domain.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';

export default function NewLogicMapping(props: {
  canEdit?: boolean,
  updateQuestion: any,
  inclusionList?: InclusionConfiguration[],
  handleClose: any,
  logicList: LogicList,
  originalLogicList?: LogicList,
  handleSetLogicList?:  (newLogicList: LogicList) => void,
}) {
  const dispatch = useAppDispatch();
  const {
    logicList,
    originalLogicList,
    handleSetLogicList,
    updateQuestion,
    canEdit,
    inclusionList,
    handleClose } = props;
  const { inclusionConfiguration } = useAppSelector(state => state.inclusionConfigurations);
  const { results: questionnaires, questionnaire } = useAppSelector(state => state.questionnaires);
  const [logicQuestion, setLogicQuestion] = useState<Question | undefined>(undefined);

  const [isAdditionalRecommendation, setIsAdditionalRecommendation] = useState(false);
  const [fetchUnpublished, setFetchUnpublished] = useState(false);

  const [questionnaireSlug, setQuestionnaireSlug] = useState<number | string | undefined>(questionnaire?.id);
  const [selectedDomainSlug, setSelectedDomainSlug] = useState<string | undefined>();
  const [bpSlug, setBpSlug] = useState<string>('');
  const [domains, setDomains] = useState<Domain[] | null>(null);
  const [topics, setTopics] = useState<Domain | null>(null);
  const [bestPractice, setBestPractice] = useState<BestPractice[] | string | null>(null);
  const [bestPracticeQuestions, setBestPracticeQuestions] = useState<BestPractice | null>(null);

  const onQuestionSelect = async (questionId: string) => {
    try {
      const question = await getQuestion(parseInt(questionId));
      setLogicQuestion(question);
    } catch (error) {
      console.error('Error fetching question', error);
    }
  };

  const handleFetchTopics = useCallback(async (slug: string) => {
    setSelectedDomainSlug(slug);
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const fetchedTopics = await fetchTopics(slug);
    setTopics(fetchedTopics);
  }, []);

  const handleFetchBestPracticeQuestions = useCallback(async (slug: string) => {
    setBpSlug(slug);
    const fetchedQuestions = await fetchBestPractices(slug);
    setBestPracticeQuestions(fetchedQuestions);
  }, []);

  const handleFetchDomains = useCallback(async (slug: string) => {
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const resp = await fetchQuestionnaire(slug);
    setDomains(resp.domains);
  }, [questionnaireSlug, setDomains]);

  const handleQuestionnaireSelect = useCallback((slug: string) => {
    setQuestionnaireSlug(slug);
    handleFetchDomains(slug);
    setTopics(null);
    setBestPracticeQuestions(null);
    setBestPractice(null);
  }, [setQuestionnaireSlug, handleFetchDomains]);

  const processNewLogic = useCallback((answerId: number, questionId: number) => {
    if (!handleSetLogicList) return;

    if (logicList[answerId]) {
      const newLogicList = { ...logicList };
      delete newLogicList[answerId];
      handleSetLogicList(newLogicList);
      return;
    }

    handleSetLogicList({
      ...logicList,
      ...{
        [answerId]: {
          question: questionId,
          answer: answerId,
        },
      },
    });
  }, [logicList, handleSetLogicList]);

  const handeSetAdditionalRecommendation = useCallback(() => {
    if (!handleSetLogicList) return;

    const newLogicList = logicQuestion?.answers.reduce((acc, answer) => {
      if (!['Not Applicable', 'Not Implemented'].includes(answer.text || '')) {
        acc[answer.id] = {
          question: logicQuestion.id,
          answer: answer.id,
        };
      }
      return acc;
    }, {} as LogicList);

    if (!isAdditionalRecommendation ) {
      handleSetLogicList(newLogicList || {});
      return;
    }

    const originalLogicListAsLogicListItem =
      originalLogicList ? Object.values(originalLogicList).reduce((acc, key) => {
        acc[key.answer] = originalLogicList[key.answer];
        return acc;
      }, {} as LogicList) : {};
    handleSetLogicList(originalLogicListAsLogicListItem);
    setIsAdditionalRecommendation(false);

  }, [logicQuestion?.answers, handleSetLogicList, isAdditionalRecommendation, originalLogicList]);

  useEffect(() => {
    if (inclusionConfiguration && 'id' in inclusionConfiguration) {
      const newInclusions = inclusionList ? [ ...inclusionList, inclusionConfiguration] : [ inclusionConfiguration ];
      updateQuestion({ inclusions: newInclusions });
      dispatch(resetInclusionConfiguration());
      setLogicQuestion(undefined);
      handleClose();
    }
  }, [inclusionConfiguration]);

  useEffect(() => {
    dispatch(fetchAll(fetchUnpublished));
  }, [fetchUnpublished]);

  useEffect(() => {
    // Check if all answers are either 'Not Applicable', 'Not Implemented', or exist in logicList
    if (logicQuestion?.answers) {
      const hasAdditionalRecommendations = logicQuestion.answers.every(answer =>
        ['Not Applicable', 'Not Implemented'].includes(answer.text || '') || logicList[answer.id],
      );
      setIsAdditionalRecommendation(hasAdditionalRecommendations);
    }
  }, [logicList, logicQuestion?.answers]);

  return (
    <>
      { !logicQuestion && <>
        <Grid container gap={2}>
          <Grid item xs={12}>
            {!!questionnaires?.length && <>
              <FormControl fullWidth>
                <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
                <Select
                  labelId="questionnaire-label"
                  id="questionnaire"
                  value={questionnaireSlug || ''}
                  label="Questionnaire"
                  disabled={canEdit === false}
                  onChange={(e) => handleQuestionnaireSelect(e.target.value as string)}
                >
                  {questionnaires.map(q=> <MenuItem key={q.slug} value={q.slug}>{q.title} {q.version}</MenuItem>)}
                </Select>
              </FormControl>
              <FormLabel>Show Unpublished Questionnaires
                <Switch onChange={() => setFetchUnpublished(!fetchUnpublished)} checked={!fetchUnpublished} />
              </FormLabel>
            </>}
            {domains && domains.length > 0 && (
              <FormControl sx={{ marginBottom: '10px' }} fullWidth>
                <InputLabel id="domain-label">Domain</InputLabel>
                <Select
                  labelId="domain-label"
                  id="domain"
                  value={selectedDomainSlug || ''}
                  onChange={(e) => handleFetchTopics(e.target.value)}
                  label="Domain"
                >
                {domains.map((domain, idx) => <MenuItem key={idx} value={domain.slug}>{domain.title}</MenuItem>)}
                </Select>
              </FormControl>
            )}
          {topics && topics.topics.length > 0 && (
            <FormControl sx={{ marginBottom: '10px' }} fullWidth>
              <InputLabel id="topic-label">Topic</InputLabel>
                <Select
                  labelId="topic-label"
                  id="topic"
                  value={bestPractice || ''}
                  onChange={(e) => { setBestPractice(e.target.value); setBestPracticeQuestions(null);}}
                  label="Topic"
                >
                  {topics.topics.map((topic: any, idx: number) => <MenuItem key={idx} value={topic?.bestPractices}>{topic.title}</MenuItem>)}
                </Select>
              </FormControl>
          )}
            {bestPractice && Array.isArray(bestPractice) && bestPractice.length > 0 && (
              <FormControl sx={{ marginBottom: '10px' }} fullWidth>
                <InputLabel id="best-practice-label">Best Practice</InputLabel>
                  <Select
                    labelId="best-practice-label"
                    id="best-practice"
                    value={bpSlug}
                    onChange={(e) => handleFetchBestPracticeQuestions(e.target.value as string)}
                    label="Best Practice"
                  >
                {bestPractice.map((bp: BestPractice, idx: number) => <MenuItem key={idx} value={bp.slug}>{bp.title}</MenuItem>)}
                </Select>
              </FormControl>
            )}
            {bestPracticeQuestions && bestPracticeQuestions.questions.length > 0 && (
              <FormControl
                sx={{
                  marginBottom: '10px',
                }}
                fullWidth
              >
                <InputLabel id="question-label">Question</InputLabel>
              <Select
                labelId="question-label"
                id="question"
                value={logicQuestion}
                onChange={(e) => onQuestionSelect(e.target.value || '')}
                label="Question"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      overflow: 'auto',
                    },
                  },
                }}
              >
              {bestPracticeQuestions.questions.map((question: Question, idx: number) => (
                <MenuItem key={idx} value={question.id}>
                  {question.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            )}
        </Grid>
      </Grid>
    </>}
      {logicQuestion && (
  <>
    <Typography mb={2} variant='h5'>Question: {logicQuestion.title}</Typography>
    {(logicQuestion.answers.length > 0) ? (
      <>
<FormGroup>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Switch
      checked={isAdditionalRecommendation}
      onChange={handeSetAdditionalRecommendation}
    />
    <label
      htmlFor="additional-recommendation-switch"
      style={{ marginLeft: '8px', cursor: 'pointer' }}
    >
      Default Selections for Additional Recommendations?
    </label>
  </div>
</FormGroup>

      <Grid container>
        {logicQuestion.answers.map((answer: Answer) => (
          <DataRow
            key={answer.id}
            p={1}
            mb={0.01}
            hover
          >
            <DataCell xs={10} p={1}>
              {answer.text}
            </DataCell>
            <DataCell xs={2} p={1} sx={{ textAlign: 'right' }}>
              <Checkbox
                disabled={originalLogicList && originalLogicList[answer.id] !== undefined}
                checked={!!logicList[answer.id]}
                onChange={() => processNewLogic(answer.id, logicQuestion.id)}
              />
            </DataCell>
          </DataRow>
        ))}
      </Grid>
      </>
    ) : (
      <Typography>No Answers Available</Typography>
    )}
  </>
      )}
    </>
  );
}