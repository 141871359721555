import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Company } from '../../interfaces/users.interface';

interface InitialCompanyState {
  companies: Company[];
  fetchingCompanies: boolean;
}

const initialState = {
  companies: [],
  fetchingCompanies: false,
} as InitialCompanyState;

const baseUrl = process.env.REACT_APP_BASE_API;
export const fetchAllCompaniesStore = createAsyncThunk(
  'companies/fetchAllCompaniesStore',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/companies/list/?limit=99999`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as Company[];
    } catch (error: any) {
      return error;
    }
  },
);

const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAllCompaniesStore.fulfilled, (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      state.fetchingCompanies = false;
    });
    builder.addCase(fetchAllCompaniesStore.pending, (state) => {
      state.fetchingCompanies = true;
    });
    builder.addCase(fetchAllCompaniesStore.rejected, (state) => {
      state.fetchingCompanies = false;
    });
  },
});

export default companyListSlice.reducer;