export const buildQuestionnaireBreadCrumbs = (items: any[], urlString: string) => {
  if (!items) return;

  let curUrlString = urlString;
  let breadCrumbs:any[] = [];

  items.forEach(item => {
    breadCrumbs.push({
      title: item.title,
      label: item.label,
      slug: item.slug,
      urlString: curUrlString,
      linkOverride: item.linkOverride,
    });
    curUrlString += item.slug + '/';
  });
  return breadCrumbs;
};