import axios from 'axios';
import getAuthSession from './auth';
import { Certification } from '../interfaces/certification.interface';

export default async function getCertifications(filter?:string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/certifications/?limit=9999${filter ? filter : ''}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Certification[];
}

export async function createCertification(certification: Certification) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/certifications/`,
    { ...certification },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Certification;
}

export async function patchCertification(certId: string | number, field: keyof Certification, value: any) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/certifications/${certId}/`,
    {
      [field]: value,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Certification;
}

export async function deleteCertification(certId: number | string) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/certifications/${certId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Certification;
}